<template>
  <section class="section">
    <h2 class="subtitle">Просмотр очереди</h2>
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Поиск"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-1"
      >
        <template slot="headerCell" slot-scope="props">
          <v-tooltip bottom>
            <span slot="activator">
              {{ props.header.text }}
            </span>
            <span>
              {{ props.header.text }}
            </span>
          </v-tooltip>
        </template>

        <template slot="items" slot-scope="props">
          <tr>
            <td>{{ props.item.queue_number }}</td>
            <td>{{ props.item.month }}</td>
            <td>{{ props.item.fio }}</td>
            <td>{{ props.item.date }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>

<script>
import { CommonListMixin } from "../../mixins/common-list-mixin";
import { mapGetters } from "vuex";
import { getNameFromUserInfo, formatDate } from "../../services/helper";

export default {
  name: "QueueList",
  mixins: [CommonListMixin],
  computed: {
    ...mapGetters({
      role: "user/getUserRole"
    }),
    formattedItems() {
      return {};
      /*
      return this.items.map(item => {
        return {
          // timeOfReceipt: this.formatDate(item.claim.time_of_receipt),
          // iin: item.claim.claim_data.childsData.iin,
          // name: this.getNameFromUserInfo(item.claim.claim_data.childsData),
          // multiplicityOfReception:
          // item.claim.claim_data.childsData.multiplicityOfReception,
          // pmpkName: this.getPmpkName(item.claim.claim_data.childsData.area),
          // isDisabled: item.claim.claim_data.childsData.isDisabled
          //     ? "Есть"
          //     : "Нет",
          // direction: item.claim_history.additional_data.direction,
          // organisationName: item.claim_history.additional_data.organisationName,
          // childCategory: item.claim_history.additional_data.childCategory,
          // conclusion: item.claim_history.additional_data.conclusion,
          // recommendations: item.claim_history.additional_data.recommendations,
          //
          // claimId: item.claim_id,
          // slug: item.claim.service.slug
        };
      });
     */
    }
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Номер в очереди",
          align: "left",
          value: "queue_number"
        },
        {
          text: "Месяц",
          align: "left",
          value: "month"
        },
        {
          text: "ФИО",
          align: "left",
          value: "fio"
        },
        {
          text: "Время подачи",
          align: "left",
          value: "date"
        }
      ]
    };
  },
  methods: {
    getNameFromUserInfo,
    formatDate
  },
  beforeMount() {
    this.apiResource = this.$apiResource.make("QueueList");
    this.listMethod = "list";
  }
};
</script>

<style scoped></style>
