class Upload {
  constructor(axios) {
    this.axios = axios;
  }

  static getRoute(url) {
    return "/api/file/" + url;
  }

  upload(name, file, progressevent, returnencode = false) {
    if (progressevent === undefined) {
      progressevent = () => {};
    }

    let formdata = new FormData();

    formdata.append(name, file);
    if (returnencode) {
      formdata.append("returnencode", returnencode);
    }
    formdata.append("fieldname", name);
    if (file.hasOwnProperty("uploadedNumber")) {
      formdata.append("uploaded_number", file.uploadedNumber);
    }
    return this.axios.post(Upload.getRoute("upload"), formdata, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressevent
    });
  }

  download(fileId) {
    return this.axios.get(Upload.getRoute("download/" + fileId), {
      responseType: "arraybuffer"
    });
  }

  downloadZip(claimId) {
    return this.axios.get("/api/claims/zip-file/" + claimId, {
      responseType: "arraybuffer"
    });
  }
}

export default Upload;
