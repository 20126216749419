import ResourceControllerApiService from "./resource-controller-api-service";

class Users extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/admin/users";
  }

  list(data) {
    return this.axios.get(this.getMainRoute(), {
      params: data
    });
  }
}

export default Users;
