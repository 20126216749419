import Vue from "vue";
import ApiResourceFactory from "../api/api-resource/api-resource-factory";
import Buefy from "buefy";
import VeeValidate, { Validator } from "vee-validate";
import { ExtendValidator } from "../validation-extender";
import validatorLocaleRu from "../../translations/vee-validate/ru.js";
import validatorLocaleKk from "../../translations/vee-validate/kk.js";
import axiosInstance from "../axios-instance";
import VueMeta from "vue-meta";
import Vuetify from "vuetify";
import FontAwesomeInject from "./font-awesome";
import vueDateFilters from "./vue-date-filter";
import vueTextFilters from "./vue-text-filter";
import "./ml";
import BuefyCommonSelect from "@/components/form-components/BuefyCommonSelect";
import BuefySimpleSelect from "@/components/form-components/BuefySimpleSelect";
import VPagination from "@/components/form-components/VPagination";

Validator.localize("ru", validatorLocaleRu);
Validator.localize("kk", validatorLocaleKk);

Vue.use(Vuetify);

Vue.component("BuefySimpleSelect", BuefySimpleSelect);
Vue.component("BuefyCommonSelect", BuefyCommonSelect);
Vue.component("VPagination", VPagination);

Vue.use(VeeValidate, {
  locale: "ru",
  classes: true,
  classNames: {
    valid: "is-success",
    invalid: "is-danger"
  }
});

ExtendValidator(Validator);

FontAwesomeInject(Vue);

Vue.prototype.$apiResource = new ApiResourceFactory(axiosInstance);
Vue.use(VueMeta);
Vue.filter("moment", vueDateFilters.getFormattedDate);
Vue.filter("momentFrom", vueDateFilters.getFormattedFrom);
Vue.filter("ifUndef", vueTextFilters.ifUndef);

Vue.use(Buefy, {
  defaultIconPack: ["far"],
  defaultFirstDayOfWeek: 1,
  defaultDayNames: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
  defaultMonthNames: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
  ]
});

Vue.config.productionTip = false;

Array.prototype.contains = Array.prototype.includes;

export default Vue;
