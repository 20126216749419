var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[(_vm.currentService)?_c('h2',{staticClass:"subtitle service"},[(_vm.$ml.current === 'ru')?[_vm._v(_vm._s(_vm.$ml.get("creating_homeschooling")))]:_vm._e(),_vm._v(" \""+_vm._s(_vm.$ml.get(_vm.currentService.slug))+"\" "),(_vm.$ml.current === 'kk')?[_vm._v(_vm._s(_vm.$ml.get("creating_homeschooling")))]:_vm._e()],2):_vm._e(),_c('ul',{staticClass:"steps"},_vm._l((_vm.steps),function(stepR){return _c('li',{key:'step_' + stepR,staticClass:"step-item",class:{
        'is-completed': _vm.step > stepR,
        'is-active': _vm.currentStep === stepR
      }},[_c('div',{staticClass:"step-marker"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" "+_vm._s(stepR)+" ")])])])}),0),_c(_vm.componentName,{tag:"component",attrs:{"slug":_vm.slug,"required-files-mode":_vm.requiredFilesMode,"registered-claim":_vm.registeredClaim,"api-resource":_vm.apiResource,"current-step":_vm.currentStep,"first-step":_vm.firstStep,"last-step":_vm.lastStep,"declarertype":this.declarertype ? this.declarertype : null},on:{"onModelChange":_vm.onModelChange,"tablesfilled":_vm.checkTablesFilled,"additionalFilesFilled":_vm.checkAdditionalFilesFilled,"nextStep":function($event){return _vm.nextStep()},"previousStep":function($event){return _vm.previousStep()},"activateStep":_vm.activateStep,"deactivateStep":_vm.deactivateStep,"onSumbit":_vm.onSubmit,"onSubmitSigned":_vm.onSubmitSigned,"doneCallback":function($event){return _vm.doneCallback()}},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('footer',{staticClass:"wizard-footer"},[_c('div',{staticClass:"columns is-gapless is-mobile"},[_c('div',{staticClass:"column is-half"},[_c('div',{staticClass:"field"},[(_vm.canStepBack)?_c('div',{staticClass:"control"},[_c('button',{staticClass:"button",on:{"click":function($event){return _vm.previousStep()}}},[_c('fa-icon',{attrs:{"icon":"arrow-left"}}),_vm._v(" "+_vm._s(_vm.$ml.get("back"))+" ")],1)]):_vm._e()])]),_c('div',{staticClass:"column is-half"},[_c('div',{staticClass:"field is-grouped is-grouped-right"},[_c('div',{staticClass:"control"},[(
                _vm.currentStep !== _vm.lastStep &&
                  _vm.currentStep + 1 !== _vm.lastStep &&
                  _vm.$ml.current === 'ru'
              )?_c('span',[_vm._v(" Кнопка \"Далее\" будет активна, только при заполнении всех полей ")]):_vm._e(),(
                _vm.currentStep !== _vm.lastStep &&
                  _vm.currentStep + 1 !== _vm.lastStep &&
                  _vm.$ml.current === 'kk'
              )?_c('span',[_vm._v(" Барлық өрістер толтырылған кезде ғана, «Келесі» түймесі белсенді болады ")]):_vm._e(),(_vm.currentStep !== _vm.lastStep && _vm.currentStep + 1 !== _vm.lastStep)?_c('button',{staticClass:"button is-info",attrs:{"disabled":!_vm.currentStepFinished ||
                  !_vm.fileUploaded ||
                  (_vm.currentStep === 2 &&
                  ['teachers-attestation', 'principals-attestation'].contains(
                    _vm.slug
                  )
                    ? !_vm.tableFilled || !_vm.additionalFilesFilled
                    : false),"title":_vm.currentStepFinished
                  ? _vm.$ml.get('next')
                  : 'Пожалуйста, заполните все данные.'},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" "+_vm._s(_vm.$ml.get("next"))+" "),_c('fa-icon',{attrs:{"icon":_vm.currentStepFinished ? 'arrow-right' : 'times'}})],1):_vm._e()]),(_vm.currentStep + 1 === _vm.lastStep)?[(_vm.canGoFurtherWithoutSign)?_c('div',{staticClass:"control"},[_c('button',{staticClass:"button is-info",attrs:{"disabled":!_vm.currentStepFinished || _vm.inProgress || !_vm.fileUploaded,"title":_vm.currentStepFinished
                    ? _vm.$ml.get('next')
                    : 'Пожалуйста, заполните все данные.'},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$ml.get("send_claim"))+" "),_c('fa-icon',{attrs:{"icon":"check"}})],1)]):_vm._e(),_c('div',{staticClass:"control"},[_c('sign-data-button',{attrs:{"data":_vm.model,"disabled":!_vm.currentStepFinished || _vm.inProgress || !_vm.fileUploaded,"title":_vm.currentStepFinished
                    ? _vm.$ml.get('next')
                    : 'Пожалуйста, заполните все данные.'},on:{"onSign":_vm.onSubmitSigned}})],1)]:_vm._e(),(_vm.currentStep === _vm.lastStep)?_c('div',{staticClass:"control"},[_c('button',{staticClass:"button is-success",on:{"click":function($event){return _vm.doneCallback()}}},[_vm._v(" "+_vm._s(_vm.doneButtonName)+" "),_c('fa-icon',{attrs:{"icon":"check"}})],1)]):_vm._e()],2)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }