import ApiResourceFactory from "./api-resource-factory";
import store from "../../../store";
import router from "../../../router";
import axiosInstance from "../../axios-instance";

const token = store.getters["user/getToken"];

if (token !== null) {
  axiosInstance.defaults.headers["Authorization"] = "Bearer " + token;
}
axiosInstance.interceptors.request.use(request => {
  const token = store.getters["user/getToken"];
  if (token !== null) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
});
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error instanceof Error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        // @todo: add notification that logout happened
        store.dispatch("user/logOutUser");
        router.push({ name: "home", replace: true });
        return undefined;
      }
    }
    return Promise.reject(error);
  }
);

export default new ApiResourceFactory(axiosInstance);
