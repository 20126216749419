<template>
  <section class="section">
    <form @submit.prevent="submitForm(item)">
      <h2 class="service subtitle">Редактирование пользователя</h2>
      <div class="modal-padded-body">
        <div class="field">
          <label for="name" class="label">Резидент РК</label>
          <div class="control">
            <input id="name" v-model="item.resident" type="checkbox" />
          </div>
        </div>

        <div class="field">
          <label for="iin" class="label">ИИН</label>
          <div class="control">
            <input
              id="iin"
              v-model="item.name"
              :disabled="!isEditableProfile"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="fio" class="label">{{ $ml.get("fio") }}</label>
          <div class="control">
            <input
              id="fio"
              :value="getNameFromUserInfo(profile)"
              :disabled="!isEditableProfile"
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="email" class="label">{{ $ml.get("email") }}</label>
          <div class="control">
            <input
              id="email"
              v-model="item.email"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="work_place" class="label">{{
            $ml.get("workplace")
          }}</label>
          <div class="control">
            <input
              id="work_place"
              v-model="item.work_place"
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="phone_number" class="label">{{ $ml.get("phone") }}</label>
          <div class="control">
            <input
              id="phone_number"
              v-model="item.phone_number"
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Роль</label>
          <div class="control">
            <BuefySimpleSelect :options="rolesOptions" v-model="item.role_id" />
          </div>
        </div>

        <div class="field">
          <label class="label">Школа</label>
          <div class="control">
            <BuefyCommonSelect
              :options="schoolsOptions"
              v-model="item.school_id"
            />
            <button
              class="button is-warning"
              type="button"
              @click="resetSchool()"
            >
              Сбросить
            </button>
          </div>
        </div>

        <div
          class="field"
          v-if="hasPermission('MANAGER_EDIT_SPECIAL_GENERAL_EDUCATION')"
        >
          <label class="label">Интернат</label>
          <div class="control">
            <BuefySimpleSelect
              :options="specialSchoolsOptions"
              v-model="item.special_school_id"
            />
            <button
              class="button is-warning"
              type="button"
              @click="resetSpecialSchool()"
            >
              Сбросить
            </button>
          </div>
        </div>

        <div class="field">
          <label class="label">КППК</label>
          <div class="control">
            <BuefySimpleSelect :options="kppksOptions" v-model="item.kppk_id" />
            <button
              class="button is-warning"
              type="button"
              @click="resetKppk()"
            >
              Сбросить
            </button>
          </div>
        </div>

        <div class="field">
          <label class="label">ПМПК</label>
          <div class="control">
            <BuefySimpleSelect :options="pmpksOptions" v-model="item.pmpk_id" />
            <button
              class="button is-warning"
              type="button"
              @click="resetPmpk()"
            >
              Сбросить
            </button>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <label class="label">Районы</label>

              <div :key="'area_' + index" v-for="(area, index) in areasList">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    :checked="isCheckedArea(area.id)"
                    @click="areaTrigger(!isCheckedArea(area.id), area)"
                  />
                  {{ area.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <footer class="modal-card-foot">
        <button class="button is-success">Сохранить изменения</button>
        <router-link
          :to="{ name: 'admin.users.list' }"
          :tag="'button'"
          :class="'button'"
          >{{ $ml.get("back") }}</router-link
        >
      </footer>
    </form>
  </section>
</template>

<script>
import {
  displayError,
  getErrorsText,
  getNameFromUserInfo
} from "../../../services/helper";
import { PermissionsMixin } from "@/mixins/permissions-mixin";

export default {
  name: "UsersEdit",
  mixins: [PermissionsMixin],
  props: ["id"],
  data() {
    return {
      profile: {},
      item: {
        areas: []
      },
      rolesList: [],
      areasList: [],
      schoolsOptions: [],
      kppksOptions: [],
      pmpksOptions: [],
      specialSchoolsOptions: [],
      schoolsApi: this.$apiResource.make("Schools"),
      apiResource: this.$apiResource.make("Users"),
      rolesResource: this.$apiResource.make("Roles"),
      areaResource: this.$apiResource.make("Areas"),
      kppkApiResource: this.$apiResource.make("Kppks"),
      pmpkApiResource: this.$apiResource.make("Pmpks"),
      specialSchoolApi: this.$apiResource.make("SpecialSchools")
    };
  },
  computed: {
    isEditableProfile() {
      return !this.item.id;
    },
    rolesOptions() {
      return this.rolesList.map(item => {
        return {
          text: item.name,
          id: item.id
        };
      });
    }
  },
  methods: {
    getNameFromUserInfo,
    resetSchool() {
      this.item.school_id = null;
    },
    resetSpecialSchool() {
      this.item.special_school_id = null;
    },
    resetKppk() {
      this.item.kppk_id = null;
    },
    resetPmpk() {
      this.item.pmpk_id = null;
    },
    setKppksOptions() {
      this.kppkApiResource.list().then(response => {
        this.kppksOptions = response.data.map(item => {
          return {
            text: item.name,
            id: item.id
          };
        });
        this.kppksOptions.unshift({
          text: "Не указана (отображаются все заявки)",
          id: null
        });
      });
    },
    setPmpksOptions() {
      this.pmpkApiResource.list().then(response => {
        this.pmpksOptions = response.data.map(item => {
          return {
            text: item.name,
            id: item.id
          };
        });
        this.pmpksOptions.unshift({
          text: "Не указана (отображаются все заявки)",
          id: null
        });
      });
    },
    setSchoolsOptions() {
      this.schoolsApi.list().then(response => {
        this.schoolsOptions = response.data.map(item => {
          return {
            text: item.name,
            id: item.id
          };
        });
        this.schoolsOptions.unshift({
          text: "Не указана (отображаются все заявки)",
          id: null
        });
      });
    },
    setSpecialSchoolsOptions() {
      this.specialSchoolApi.list().then(response => {
        this.specialSchoolsOptions = response.data.map(item => {
          return {
            text: item.name,
            id: item.id
          };
        });
        this.specialSchoolsOptions.unshift({
          text: "Не указана (отображаются все интернаты)",
          id: null
        });
      });
    },
    submitForm(item) {
      if (item.id) {
        this.apiResource
          .update(item.id, item)
          .then(() => {
            return this.$router.push({ name: "admin.users.list" });
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      }
    },
    isCheckedArea(id) {
      if (this.item.areas.length > 0) {
        return !!this.item.areas.find(area => area.id === parseInt(id));
      }
      return false;
    },
    areaTrigger(isAdd, area) {
      if (isAdd) {
        this.item.areas.push(area);
      } else {
        this.item.areas = this.item.areas.filter(areaItem => {
          return areaItem.id !== area.id;
        });
      }
    }
  },
  beforeMount() {
    if (this.id) {
      this.apiResource.edit(this.id).then(response => {
        this.item = response.data;
        this.profile = this.item.profile;
      });
    }

    this.rolesResource.list(1, 1000).then(response => {
      this.rolesList = response.data.data;
    });

    this.areaResource.list(1, 1000).then(response => {
      this.areasList = response.data.data;
    });

    this.setSchoolsOptions();
    this.setKppksOptions();
    this.setPmpksOptions();
    this.setSpecialSchoolsOptions();
  }
};
</script>

<style scoped></style>
