import "./assets/scss/app.scss";

import "babel-polyfill";
import "url-search-params-polyfill";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vue from "./services/vue-instance";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
