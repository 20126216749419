<template>
  <section>
    <div class="modal-padded-body">
      <div class="field">
        <label class="label" for="localValue.usersData.iin">{{
          $ml.get("my_iin")
        }}</label>
        <div class="control">
          <input
            id="localValue.usersData.iin"
            type="text"
            class="input"
            v-model="localValue.usersData.iin"
            v-validate="usersIinRules"
            readonly
            name="localValue.usersData.iin"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.iin')"
          >
            {{ errors.first("localValue.usersData.iin") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("fio") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getNameFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("birthday") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="usersProfile.birth_date"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("address_registr") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getAddressFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label" for="usersData.email">{{
          $ml.get("email")
        }}</label>
        <div class="control">
          <input
            id="usersData.email"
            type="text"
            class="input"
            v-validate="'required|email'"
            :data-vv-as="$ml.get('email')"
            v-model="localValue.usersData.email"
            name="localValue.usersData.email"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.email')"
          >
            {{ errors.first("localValue.usersData.email") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label" for="usersData.phone">{{
          $ml.get("phone")
        }}</label>
        <div class="control">
          <input
            id="usersData.phone"
            type="text"
            class="input"
            v-validate="'required|min:10|max:10|numeric'"
            :data-vv-as="$ml.get('phone')"
            v-model="localValue.usersData.phone"
            name="localValue.usersData.phone"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.phone')"
          >
            {{ errors.first("localValue.usersData.phone") }}
          </p>
        </div>
      </div>

      <b-field label="Район проживания">
        <b-select
          placeholder="Пожалуйста, выберите район"
          v-model="localValue.usersData.area"
          style="width: 50%"
        >
          <option
            v-for="area in this.areasList"
            :value="area.name"
            :key="area.id"
            style="width: 100%"
          >
            {{ area.name }}
          </option>
        </b-select>
      </b-field>
      <button
        class="button is-success"
        type="button"
        style="margin-top: 20px; margin-bottom: 10px"
        @click="addChildren()"
      >
        <fa-icon icon="plus"></fa-icon> &nbsp; Добавить ребёнка
      </button>
      <!--<pre>{{ errors }}</pre>-->
      <template v-for="(childsData, index) in localValue.children">
        <div
          class="field is-horizontal"
          :key="childsData.uniqueId + '_child_field_group'"
        >
          <div class="field-body">
            <div
              class="field"
              :key="childsData.uniqueId + '_child_iin'"
              style="width: 25%"
            >
              <label
                class="label"
                :for="'localValue.children[' + childsData.uniqueId + '].iin'"
                >ИИН ребёнка</label
              >
              <div class="control">
                <input
                  :id="'localValue.children[' + childsData.uniqueId + '].iin'"
                  :name="'localValue.children[' + childsData.uniqueId + '].iin'"
                  :data-vv-as="'ИИН ребёнка'"
                  type="text"
                  class="input"
                  @keyup="iinChanged(childsData.uniqueId)"
                  v-validate="childsIinRules"
                  v-model="localValue.children[index].iin"
                />
                <p class="help is-danger">
                  {{
                    errors.first(
                      "localValue.children[" + childsData.uniqueId + "].iin"
                    )
                  }}
                </p>
              </div>
            </div>

            <div
              class="field"
              :key="childsData.uniqueId + '_child_fio'"
              style="width: 25%"
            >
              <label class="label">{{ $ml.get("fio") }}</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  :value="
                    childrenInfo[childsData.uniqueId]
                      ? getNameFromUserInfo(childrenInfo[childsData.uniqueId])
                      : ''
                  "
                  disabled
                />
              </div>
            </div>

            <div
              class="field"
              :key="childsData.uniqueId + '_child_birthday'"
              style="width: 25%"
            >
              <label class="label">{{ $ml.get("birthday") }}</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  :value="
                    childrenInfo[childsData.uniqueId]
                      ? childrenInfo[childsData.uniqueId].birth_date
                      : ''
                  "
                  disabled
                />
              </div>
            </div>

            <div
              class="field"
              :key="childsData.uniqueId + '_child_delete_button'"
            >
              <label class="label">&nbsp;</label>
              <button
                class="button is-danger"
                type="button"
                :disabled="index === 0"
                @click="removeChildren(childsData.uniqueId)"
                title="Убрать из списка"
              >
                <fa-icon icon="minus"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </template>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input
              id="localValue.agreement"
              type="checkbox"
              v-validate="'required'"
              :data-vv-as="$ml.get('agreement')"
              v-model="localValue.agreement"
              name="localValue.agreement"
            />
            {{ $ml.get("accept_my_info") }}
          </label>
          <p class="help is-danger" v-if="errors.has('localValue.agreement')">
            {{ errors.first("localValue.agreement") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CommonFormMixin } from "../../mixins/common-form-mixin";

export default {
  name: "AdoptionFamilyPaymentsForm",
  mixins: [CommonFormMixin],

  data() {
    return {
      areasList: []
    };
  },
  mounted() {
    this.localValue = this.value;
    this.$apiResource
      .make("Areas")
      .list(1, 100)
      .then(response => {
        this.areasList = response.data.data;
      });
    this.addChildren();
  }
};
</script>
