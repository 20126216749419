class Ncalayer {
  constructor(onOpenSocket, onCloseSocket, onMessage) {
    this.webSocket = new WebSocket("wss://127.0.0.1:13579/");
    this.webSocket.onopen = onOpenSocket;
    this.webSocket.onclose = onCloseSocket;
    this.webSocket.onmessage = onMessage;
  }

  signXml(xmlToSign, storageName = "PKCS12", keyType = "SIGNATURE") {
    const signXml = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "signXml",
      args: [storageName, keyType, xmlToSign, "", ""]
    };
    this.webSocket.send(JSON.stringify(signXml));
  }

  getDefaultXml(data) {
    return (
      "<?xml version='1.0' encoding='utf-8'?><root><![CDATA[<content>" +
      JSON.stringify(data) +
      "</content>]]></root>"
    );
  }
}

export default Ncalayer;
