class Checking {
  constructor(axios) {
    this.axios = axios;
  }

  static getMainRoute(route) {
    return "/api/" + route;
  }

  check(data) {
    return this.axios.get(Checking.getMainRoute("check_child_iin"), {
      params: data
    });
  }
}

export default Checking;
