import store from "../store";
import { userHasManyPermissions } from "./helper";

class RightChecker {
  static check(permissions, next) {
    store
      .dispatch("user/setUser")
      .then(r => {
        let user = r.data;

        if (
          user &&
          user.role &&
          userHasManyPermissions(user.role.permissions, permissions)
        ) {
          return next();
        } else {
          return next("/");
        }
      })
      .catch(() => {
        return next("/");
      });
  }
}

export default RightChecker;
