class Authorization {
  constructor(axios) {
    this.axios = axios;
  }

  static getMainRoute(route) {
    return "/api/auth/" + route;
  }

  doLogin(access_token) {
    return this.axios.post(Authorization.getMainRoute("login"), {
      token: access_token
    });
  }

  getProfile() {
    return this.axios.get(Authorization.getMainRoute("profile"));
  }

  canCallNewClaim($serviceId) {
    return this.axios.get(
      Authorization.getMainRoute("can_call_new_claim/" + $serviceId)
    );
  }
}

export default Authorization;
