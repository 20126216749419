<template>
  <section class="section">
    <form @submit.prevent="submitForm(item)">
      <h2 class="service subtitle">Редактирование санатория</h2>
      <div class="modal-padded-body">
        <div class="field">
          <label for="name" class="label">Наименование</label>
          <div class="control">
            <input
              id="name"
              v-model="item.name"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="address" class="label">Адрес</label>
          <div class="control">
            <input
              id="address"
              v-model="item.address"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="phone" class="label">{{ $ml.get("phone") }}</label>
          <div class="control">
            <input
              id="phone"
              v-model="item.phone"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="email" class="label">Электронный адрес</label>
          <div class="control">
            <input
              id="email"
              v-model="item.email"
              required
              type="email"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="email" class="label">Свободные места</label>
          <a @click.prevent="addRoom" class="button is-info is-small">+</a>
        </div>

        <div
          class="section"
          v-for="(room, index) in item.rooms"
          :key="`room_${index}`"
        >
          <div class="field">
            <label for="email" class="label">Места</label>
            <div class="control">
              <input
                id="room"
                v-model="room.room"
                required
                type="number"
                class="input"
              />
            </div>
          </div>

          <div class="field">
            <label class="label">Месяц заезда</label>

            <div class="select">
              <select
                v-model="room.month"
                name="month"
                v-validate="'required'"
                id="month"
              >
                <option
                  v-for="(month, key) in months"
                  :key="`month${key}`"
                  :value="month"
                  >{{ month }}
                </option>
              </select>
            </div>
          </div>

          <div class="field">
            <label for="disability_category_id" class="label"
              >Категория инвалидности</label
            >
            <div class="select">
              <select
                v-model="room.disability_category_id"
                name="disability_category_id"
                id="disability_category_id"
              >
                <option
                  v-for="(category, key) in disabilityCategories"
                  :key="`category_${key}`"
                  :value="category.id"
                  >{{ category.name }}</option
                >
              </select>
            </div>
          </div>

          <div class="field">
            <div class="control">
              <a
                @click.prevent="deleteRoom(index)"
                class="button is-small is-danger"
                >Удалить</a
              >
            </div>
          </div>
        </div>
      </div>

      <footer class="modal-card-foot">
        <button class="button is-success">Сохранить изменения</button>
        <router-link
          :to="{ name: 'sanatoriums.list' }"
          :tag="'button'"
          :class="'button'"
          >{{ $ml.get("back") }}</router-link
        >
      </footer>
    </form>
  </section>
</template>

<script>
import { displayError, getErrorsText } from "../../../services/helper";
import { DatepickerMixin } from "../../../mixins/datepicker-mixin";

export default {
  name: "SanatoriumEdit",
  props: ["id"],
  mixins: [DatepickerMixin],
  data() {
    return {
      item: {
        name: "",
        address: "",
        phone: "",
        email: "",
        rooms: []
      },
      months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      disabilityCategories: [],
      apiResource: this.$apiResource.make("Sanatorium"),
      apiResourceCategories: this.$apiResource.make("DisabilityCategories")
    };
  },
  methods: {
    submitForm(item) {
      let itemToSave = JSON.parse(JSON.stringify(item));
      // itemToSave.rooms.forEach(room => {
      //   room.date = this.dateToString(room.date);
      // });

      if (itemToSave.id) {
        this.apiResource
          .update(itemToSave.id, itemToSave)
          .then(() => {
            return this.$router.push({ name: "sanatoriums.list" });
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      } else {
        this.apiResource
          .store(itemToSave)
          .then(() => {
            return this.$router.push({ name: "sanatoriums.list" });
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      }
    },
    addRoom() {
      this.item.rooms.push({
        month: "",
        disability_category_id: null,
        room: null
      });
    },
    deleteRoom(index) {
      this.item.rooms.splice(index, 1);
    }
  },
  beforeMount() {
    if (this.id) {
      this.apiResource.edit(this.id).then(response => {
        const data = response.data;
        if (data) {
          // data.rooms.forEach(room => {
          //   room.date = this.stringToDate(room.date);
          // });
          this.item = data;
        }
      });
    }

    this.apiResourceCategories.list().then(response => {
      this.disabilityCategories = response.data;
    });
  }
};
</script>

<style scoped></style>
