export default {
  REGISTERED: {
    title: "",
    class: "is-light",
    translate: "registered"
  },
  IN_PROGRESS: {
    title: "",
    class: "is-info",
    translate: "in_progress"
  },
  ENDED: {
    title: "",
    class: "is-success",
    translate: "completed"
  },
  REJECTED: {
    title: "",
    class: "is-danger",
    translate: "rejected"
  },
  AWAITING_FOR_RECEPTION: {
    title: "",
    class: "is-info",
    translate: "awaiting_for_reception"
  },
  ENDED_NON_APPEARANCE: {
    title: "",
    class: "is-warning",
    translate: "ended_non_appearance"
  },
  DIRECTION_ISSUED: {
    title: "",
    class: "is-success",
    translate: "direction_issued"
  },
  AWAITING_FOR_COURSE: {
    title: "",
    class: "is-info",
    translate: "awaiting_for_course"
  },
  COURSE_IN_PROGRESS: {
    title: "",
    class: "is-info",
    translate: "course_in_progress"
  },
  COURSE_ENDED: {
    title: "",
    class: "is-success",
    translate: "course_ended"
  },
  SENT: {
    title: "",
    class: "is-info",
    translate: "sent_to_the_akimat"
  },
  ON_CONSIDERATION: {
    title: "",
    class: "is-info",
    translate: "on_consideration"
  },
  IN_QUEUE: {
    title: "",
    class: "is-info",
    translate: "in_queue"
  },
  DIRECTION_ISSUED_SPECIAL_SCHOOL: {
    title: "",
    class: "is-success",
    translate: "direction_issued_special_school"
  },
  RECEIVED_SPECIAL_SCHOOL: {
    title: "",
    class: "is-info",
    translate: "received_special_school"
  },
  LEAVED_SPECIAL_SCHOOL: {
    title: "",
    class: "is-info",
    translate: "leaved_special_school"
  },
  REJECTED_SPECIAL_SCHOOL: {
    title: "",
    class: "is-warning",
    translate: "rejected_special_school"
  },
  ENDED_NON_APPEARANCE_SPECIAL_SCHOOL: {
    title: "",
    class: "is-warning",
    translate: "ended_non_appearance_special_school"
  }
};
