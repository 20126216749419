import Api from "../../services/api/api-resource/api-instance";

const tokenSessionKey = "session-token";
const tokenExpireTimeKey = "session-token-expired-at";

const tokenLifePeriod = 10800;

const token = localStorage.getItem(tokenSessionKey) || null;
const expiredAt = parseInt(localStorage.getItem(tokenExpireTimeKey));

const state = {
  expiredAt: expiredAt,
  token: token,
  profile: {},
  role: {},
  permissions: [],
  user: {},
  canCallNewClaim: false
};

const getters = {
  getExpiredAt(state) {
    return state.expiredAt;
  },
  getUser: state => state.user,
  isLoggedIn: state => state.token !== null,
  isProfileLoaded: state => state.profile.iin !== undefined,
  getToken: state => state.token,
  getUserProfile: state => state.profile,
  getUserPermissions: state => state.permissions,
  getUserRole: state => state.role,
  getCanCallNewClaim: state => state.canCallNewClaim
};

const mutations = {
  setUsersProfile(state, data) {
    state.profile = data;
  },
  setUser(state, data) {
    state.user = data;
  },
  setUserLoggedOut(state) {
    state.profile = {};
    state.token = null;
  },
  setUserToken(state, token) {
    state.token = token;
  },
  setUserRole(state, role) {
    state.role = role;
  },
  setUserPermissions(state, permissions) {
    state.permissions = permissions;
  },
  setExpiredAt(state, value) {
    localStorage.setItem(tokenExpireTimeKey, value);
    state.expiredAt = value;
  },
  extendTokenLife(state) {
    const newExpiredAt = parseInt(
      Math.floor(Date.now() / 1000) + tokenLifePeriod
    );
    localStorage.setItem(tokenExpireTimeKey, newExpiredAt);
    state.expiredAt = newExpiredAt;
  },
  canCallNewClaimChange(state, value) {
    state.canCallNewClaim = value;
  }
};

const actions = {
  setUsersProfile({ commit }, payload) {
    commit("setUsersProfile", payload);
  },
  setUser() {
    return Api.make("Authorization").getProfile();
  },
  setUserRole({ commit }, payload) {
    commit("setUserRole", payload);
  },
  setUserPermissions({ commit }, payload) {
    commit("setUserPermissions", payload);
  },
  setUserToken({ commit }, token) {
    localStorage.setItem(tokenSessionKey, token);
    commit("setUserToken", token);
    commit("extendTokenLife");
  },
  loginUser({ dispatch }, payload) {
    dispatch("setUserToken", payload.token);
    dispatch("setUsersProfile", payload.profile);
  },
  logOutUser({ commit }) {
    localStorage.removeItem(tokenSessionKey);
    localStorage.removeItem(tokenExpireTimeKey);
    commit("services/clearStorage", null, { root: true });
    commit("setUserLoggedOut");
  },
  extendTokenLife({ commit }) {
    commit("extendTokenLife");
  },
  checkCanCallNewClaim({ commit }, slug) {
    Api.make("Authorization")
      .canCallNewClaim(slug)
      .then(response => {
        commit("canCallNewClaimChange", response.data.success);
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
