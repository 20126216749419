export default [
  {
    key: 1,
    name: "Задержка психического развития",
    server_disability_category: "Задержка психического развития"
  },
  {
    key: 2,
    name: "Легкая и умеренная умственная отсталость",
    server_disability_category: "Интеллектуальные нарушения"
  },
  {
    key: 3,
    name: "Тяжелая и глубокая умственная отсталость",
    server_disability_category: null
  },
  {
    key: 4,
    name: "Неслышащие",
    server_disability_category: "Нарушение слуха"
  },
  {
    key: 5,
    name: "Слабослышащие",
    server_disability_category: "Нарушение слуха"
  },
  {
    key: 6,
    name: "Незрячие",
    server_disability_category: "Нарушение зрения"
  },
  {
    key: 7,
    name: "Слабовидящие",
    server_disability_category: "Нарушение зрения"
  },
  {
    key: 8,
    name: "Слепоглухота",
    server_disability_category: null
  },
  {
    key: 9,
    name: "Нарушение опорно-двигательного аппарата",
    server_disability_category: "Нарушение опорно-двигательного аппарата"
  },
  {
    key: 10,
    name: "Нарушение речи",
    server_disability_category: "Нарушение речи"
  },
  {
    key: 11,
    name: "Расстройства эмоционально-волевой сферы и поведения (аутизм)",
    server_disability_category: null
  },
  {
    key: 12,
    name: "Дети с кохлеарным имплантом",
    server_disability_category: null
  },
  {
    key: 13,
    name: "Соматическое заболевание",
    server_disability_category: null
  },
  {
    key: 14,
    name: "Эпилепсия",
    server_disability_category: null
  },
  {
    key: 15,
    name: "Интеллектуальная норма",
    server_disability_category: null
  }
];
