<template>
  <section>
    <div class="tabs">
      <ul>
        <li
          v-for="tab in updatedAttachmentTabs"
          :class="{ 'is-active': tab.name === attachmentTabName }"
          :key="'view_tab_' + tab.name"
        >
          <a @click.prevent="setActiveAttachentTab(tab.name)">{{
            $ml.get(tab.title)
          }}</a>
        </li>
      </ul>
    </div>

    <div v-if="attachmentTabName === 'attachment_tab_1'">
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.requiredFiles"
        :key="`att1_${index}`"
      >
        <div v-for="(file, index2) in files" :key="`att12_${index2}`">
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Заявление"
                  ? $ml.get("statement")
                  : file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Трудовая_Деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.testDocsFiles"
        :key="`att22_${index}`"
      >
        <div v-for="(file, index2) in files" :key="`att222_${index2}`">
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Справка_НКТ"
                  ? $ml.get("nkt_statement")
                  : file.name === "Трудовая_деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.watchDocsFiles"
        :key="`att32_${index}`"
      >
        <div v-for="(file, index2) in files" :key="`att33_${index2}`">
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Трудовая_деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Лист_наблюдения"
                  ? $ml.get("watch_list")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_2'">
      <table class="attestation-table" v-if="declarerType == 1">
        <thead>
          <tr>
            <th>№</th>
            <th>{{ $ml.get("date_of_assignment") }}</th>
            <th>{{ $ml.get("certificate_number") }}</th>
            <th>{{ $ml.get("course_subject") }}</th>
            <th>{{ $ml.get("place_of_passage") }}</th>
            <th>{{ $ml.get("certificate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in claimData.usersData.tablesData
              .attestationTable"
            :key="`row_${index}`"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{
                new Date(Date.parse(row.assignDate)).getDate() +
                  "-" +
                  (new Date(Date.parse(row.assignDate)).getMonth() + 1) +
                  "-" +
                  new Date(Date.parse(row.assignDate)).getFullYear()
              }}
            </td>
            <td>{{ row.certNumber }}</td>
            <td>{{ row.course }}</td>
            <td>{{ row.spot }}</td>
            <td>
              <span
                v-for="(file, index) in row.cert.attachedFile.files"
                :key="`file_${index}`"
                class="file"
                @click="showFilePopup(file)"
                >{{ $ml.get("download_word") }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="declarerType == 2">
        <table
          class="attestation-table"
          v-for="(files, index) in filteredData"
          :key="`row_${index}`"
        >
          <thead>
            <tr>
              <th>№</th>
              <th style="width: 50%">{{ $ml.get("document_name") }}</th>
              <th style="width: 50%">{{ $ml.get("info_from_applicant") }}</th>
              <th>{{ $ml.get("certificate") }}</th>
            </tr>
          </thead>
          <tbody v-for="(file, index2) in files" :key="`tab2_${index2}`">
            <tr :key="`row_${index}`">
              <td>{{ index2 + 1 }}</td>
              <td>{{ file.label }}</td>
              <td>{{ file.placeholder }}</td>
              <td>
                <span @click="showFilePopup(file, true)" class="file">{{
                  $ml.get("download_word")
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_3'">
      <table class="category-table" v-if="false">
        <thead>
          <tr>
            <th>№</th>
            <th>{{ $ml.get("date_of_assignment") }}</th>
            <th>{{ $ml.get("certificate_number") }}</th>
            <th>{{ $ml.get("assigned_category") }}</th>
            <th>{{ $ml.get("place_of_passage") }}</th>
            <th>{{ $ml.get("certificate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in claimData.usersData.tablesData.categoryTable"
            :key="`row_${index}`"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{
                new Date(Date.parse(row.assignDate)).getDate() +
                  "-" +
                  (new Date(Date.parse(row.assignDate)).getMonth() + 1) +
                  "-" +
                  new Date(Date.parse(row.assignDate)).getFullYear()
              }}
            </td>
            <td>{{ row.certNumber }}</td>
            <td>{{ row.category }}</td>
            <td>{{ row.spot }}</td>
            <td>
              <span
                v-for="(file, index) in row.cert.attachedFile.files"
                :key="`file_${index}`"
                class="file"
                @click="showFilePopup(file)"
                >{{ $ml.get("download_word") }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <div
          class="required-attachment-image-row"
          v-for="(row, index) in claimData.usersData.tablesData.categoryTable"
          :key="`tab_${index}`"
        >
          <div
            v-for="(file, index2) in row.cert.attachedFile.files"
            :key="`tab2_${index2}`"
          >
            <div
              class="required-attachment-image"
              @click="showFilePopup(file, true)"
            >
              <template v-if="getFileExtension(file.filename) === 'pdf'">
                <span class="pdf">PDF</span>
              </template>
              <template v-else>
                <img :src="additionalFileDownloadSrc(file.id)" />
              </template>
              <div class="required-attachment-name">
                {{
                  file.name.replace(/_/g, " ") +
                    " " +
                    (index + 1) +
                    " (" +
                    (index2 + 1) +
                    ")"
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="required-attachment-image-row"
          v-for="(files, index) in claimData.gradeDocsFiles"
          :key="`tab_${index}`"
        >
          <div v-for="(file, index2) in files" :key="`tab2_${index2}`">
            <div
              class="required-attachment-image"
              @click="showFilePopup(file, true)"
            >
              <template v-if="getFileExtension(file.filename) === 'pdf'">
                <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
                <span class="pdf">PDF</span>
              </template>
              <template v-else>
                <img :src="additionalFileDownloadSrc(file.id)" />
              </template>
              <div class="required-attachment-name">
                {{
                  file.name === "Удостоверение"
                    ? $ml.get("identity_card")
                    : file.name === "Трудовая_деятельность"
                    ? $ml.get("labor_activity")
                    : file.name === "Показатель"
                    ? $ml.get("mark_doc")
                    : file.name === "Диплом"
                    ? $ml.get("diplom")
                    : file.name.replace(/_/g, " ")
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_4'">
      <table
        class="attestation-table"
        v-for="(files, index) in filteredData"
        :key="`row_${index}`"
      >
        <thead>
          <tr>
            <th>№</th>
            <th style="width: 50%">{{ $ml.get("document_name") }}</th>
            <th style="width: 50%">{{ $ml.get("info_from_applicant") }}</th>
            <th>{{ $ml.get("certificate") }}</th>
          </tr>
        </thead>
        <tbody v-for="(file, index2) in files" :key="`tab2_${index2}`">
          <tr :key="`row_${index}`">
            <td>{{ index2 + 1 }}</td>
            <td>{{ file.label }}</td>
            <td>{{ file.placeholder }}</td>
            <td>
              <span @click="showFilePopup(file, true)" class="file">{{
                $ml.get("download_word")
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="pro-table" v-if="declarerType == 2">
        <thead>
          <tr>
            <th>№</th>
            <th>{{ $ml.get("date_word") }}</th>
            <th>{{ $ml.get("type_of_awards") }}</th>
            <th>{{ $ml.get("teacher_level") }}</th>
            <th>{{ $ml.get("teacher_form_participation") }}</th>
            <th>{{ $ml.get("certificate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in claimData.usersData.tablesData.proTable"
            :key="`row_${index}`"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{
                new Date(Date.parse(row.date)).getDate() +
                  "-" +
                  (new Date(Date.parse(row.date)).getMonth() + 1) +
                  "-" +
                  new Date(Date.parse(row.date)).getFullYear()
              }}
            </td>
            <td>{{ $ml.get(row.type || "") }}</td>
            <td>{{ $ml.get(row.level || "") }}</td>
            <td>{{ $ml.get(row.form || "") }}</td>
            <td>
              <span
                v-for="(file, index) in row.cert.attachedFile.files"
                :key="`file_${index}`"
                class="file"
                @click="showFilePopup(file)"
                >{{ $ml.get("download_word") }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_6'"></div>
    <div v-if="attachmentTabName === 'attachment_tab_7'">
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.workRulesFile"
        :key="`tab_${index}`"
      >
        <div v-for="(file, index2) in files" :key="`tab2_${index2}`">
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Трудовая_деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Показатель"
                  ? $ml.get("mark_doc")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_8'">
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in filteredData"
        :key="`tab_${index}`"
      >
        <div v-for="(file, index2) in files" :key="`tab2_${index2}`">
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Трудовая_деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Показатель"
                  ? $ml.get("mark_doc")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_9'">
      <table class="attestation-table" v-if="declarerType == 2">
        <thead>
          <tr>
            <th>№</th>
            <th>{{ $ml.get("date_of_assignment") }}</th>
            <th>{{ $ml.get("certificate_number") }}</th>
            <th>{{ $ml.get("course_subject") }}</th>
            <th>{{ $ml.get("place_of_passage") }}</th>
            <th>{{ $ml.get("certificate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in claimData.usersData.tablesData
              .attestationTable"
            :key="`row_${index}`"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{
                new Date(Date.parse(row.assignDate)).getDate() +
                  "-" +
                  (new Date(Date.parse(row.assignDate)).getMonth() + 1) +
                  "-" +
                  new Date(Date.parse(row.assignDate)).getFullYear()
              }}
            </td>
            <td>{{ row.certNumber }}</td>
            <td>{{ row.course }}</td>
            <td>{{ row.spot }}</td>
            <td>
              <span
                v-for="(file, index) in row.cert.attachedFile.files"
                :key="`file_${index}`"
                class="file"
                @click="showFilePopup(file)"
                >{{ $ml.get("download_word") }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "PrincipalsAttestationFilesTabs",
  props: {
    claimData: {
      type: Object,
      required: true
    },
    declarerType: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    attachmentTabName: "attachment_tab_1"
  }),
  computed: {
    updatedAttachmentTabs() {
      let tabs = [
        {
          name: "attachment_tab_1",
          title: "tabs_basic_info"
        },
        {
          name: "attachment_tab_2",
          title:
            this.declarerType == 1 ? "tabs_qualification" : "tabs_study_marks"
        },
        {
          name: "attachment_tab_3",
          title: "tabs_attestationo"
        },
        {
          name: "attachment_tab_4",
          title:
            this.declarerType == 1
              ? "tabs_study_marks"
              : "tabs_complex_analytics"
        },
        /*{
          name: "attachment_tab_6",
          title: "tabs_grade_docs"
        },*/
        {
          name: "attachment_tab_7",
          title: "tabs_work_rules"
        },
        {
          name: "attachment_tab_8",
          title: "tabs_confirm_claimed_category"
        },
        {
          name: "attachment_tab_9",
          title: "tabs_qualification"
        }
      ];

      if (this.declarerType == 1) {
        tabs = tabs.filter(
          i =>
            ![
              "attachment_tab_7",
              "attachment_tab_8",
              "attachment_tab_9"
            ].includes(i.name)
        );
      }

      if (this.declarerType == 2) {
        tabs = tabs.filter(
          i =>
            ![
              "attachment_tab_4",
              "attachment_tab_7",
              "attachment_tab_8"
            ].includes(i.name)
        );
      }

      return tabs;
    },
    filteredData() {
      return this.claimData.confirmClaimedCategoryFiles.filter(e => e.length);
    }
  },
  methods: {
    setActiveAttachentTab(tabName) {
      this.attachmentTabName = tabName;
    },
    showFilePopup(file, filename = false, refreshToken = null) {
      this.$emit("showFilePopup", { file, filename, refreshToken });
    },
    additionalFileDownloadSrc(fileId) {
      return (
        process.env["VUE_APP_API_BASE_URL"] +
        "api/claims" +
        "/additional-file/" +
        fileId +
        "?_token=" +
        this.$store.getters["user/getToken"]
      );
    },
    getFileExtension(fileName) {
      let reg = /(?:\.([^.]+))?$/;
      return reg.exec(fileName)[1].toLowerCase();
    }
  }
});
</script>
