export default {
  "spa-treatment": [
    {
      name: "Принять в работу",
      statusesToShow: ["REGISTERED"],
      statusToChange: "IN_PROGRESS",
      class: "is-info",
      key: "CHANGE_TO_IN_PROGRESS",
      translate: "button_in_progress"
    },
    // {
    //   name: "Принять",
    //   statusesToShow: ["IN_PROGRESS"],
    //   statusToChange: "AWAITING_FOR_RECEPTION",
    //   class: "is-success",
    //   key: "CHANGE_TO_AWAITING_FOR_RECEPTION",
    //   translate: "Принять"
    // },
    {
      name: "Принять",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "IN_QUEUE",
      class: "is-success",
      key: "IN_QUEUE",
      translate: "Принять"
    },
    {
      name: "Отказать",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "REJECTED",
      class: "is-warning",
      rejectPopupShow: true,
      key: "CHANGE_TO_REJECTED",
      popupConfig: {
        popupTitle: "Отказ",
        commentTitle: "Причина",
        commentValidationRules: "required|min:5",
        translate: "rejecting",
        commentTranslate: "reason"
      },
      translate: "reject_claim"
    },
    {
      name: "Получить направление",
      statusesToShow: ["IN_QUEUE"],
      statusToChange: "DIRECTION_ISSUED",
      class: "is-success",
      key: "CHANGE_TO_DIRECTION_ISSUED",
      showSanatoriumSelectPopup: true,
      translate: "Получить направление"
    }
  ]
};
