<template>
  <div style="display: inline-block; margin: 0 15px 15px 0;">
    <button
      class="button"
      :key="'status_pre_button_' + statusButton.key"
      :class="statusButton.class"
      v-if="statusButton.statusesToShow.indexOf(item.status.code) !== -1"
      @click="openPopup(statusButton.key)"
    >
      {{ statusButton.name }}
    </button>

    <b-modal
      :key="'status_b_modal_' + statusButton.key"
      :active.sync="localPopupOpened"
      v-if="localPopupOpened"
      has-modal-card
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Дополнительная информация</p>
        </header>
        <section class="modal-card-body">
          <form>
            <b-field label="Дата">
              <b-datepicker
                placeholder="Нажмите для выбора даты..."
                v-model="additionalData.undefinedDate"
              >
              </b-datepicker>
            </b-field>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button"
            type="button"
            @click="closePopup(statusButton.key)"
          >
            {{ $ml.get("cancel") }}
          </button>
          <sign-data-button
            :disabled="!isFormValid"
            :title="!isFormValid ? 'Пожалуйста, заполните все данные' : ''"
            :key="'status_button_' + statusButton.key"
            :classes="statusButton.class"
            :data="dataToSign"
            :name="statusButton.name"
            v-if="statusButton.statusesToShow.indexOf(item.status.code) !== -1"
            @onSign="onSign"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ExternalPopupMixin } from "../../../../mixins/external-popup-mixin";

export default {
  mixins: [ExternalPopupMixin],
  name: "UndefinedDatePopup"
};
</script>

<style scoped></style>
