import ResourceControllerApiService from "./resource-controller-api-service";

class Settings extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/settings";
  }

  list(data) {
    return this.axios.get(this.getMainRoute(), {
      params: data
    });
  }
}

export default Settings;
