const AllClaims = {
  title: "Все заявления",
  key: "all",
  translate: "all",
  statuses: []
};

const AllClaimsExcludedSpecialSchool = {
  title: "Все заявления",
  key: "all",
  translate: "all",
  statuses: [
    "REGISTERED",
    "ENDED",
    "REJECTED",
    "RECEIVED",
    "AWAITING_FOR_RECEPTION",
    "AWAITING_FOR_COURSE",
    "DIRECTION_ISSUED",
    "ENDED_NON_APPEARANCE",
    "COURSE_IN_PROGRESS",
    "COURSE_ENDED",
    "IN_PROGRESS",
    "ON_CONSIDERATION",
    "SENT",
    "IN_QUEUE"
  ]
};

export default {
  default: [
    AllClaims,
    {
      title: "Поступившие",
      key: "incoming",
      translate: "incoming",
      statuses: ["REGISTERED"]
    },
    {
      title: "В работе",
      key: "in-progress",
      translate: "in-progress",
      statuses: ["IN_PROGRESS"]
    },
    {
      title: "Архив",
      key: "archive",
      translate: "archive",
      statuses: ["ENDED", "REJECTED"]
    }
  ],
  "special-needs-social-services": [
    AllClaims,
    {
      title: "Поступившие",
      key: "incoming",
      statuses: ["REGISTERED"]
    },
    {
      title: "Ожидающие прием",
      key: "awaiting-for-reception",
      statuses: ["AWAITING_FOR_RECEPTION"]
    },
    {
      title: "Получившие направления в КППК",
      key: "direction-issued",
      statuses: ["DIRECTION_ISSUED"]
    },
    {
      title: "Получившие направления в интернаты",
      key: "direction-issued-special-school",
      statuses: ["DIRECTION_ISSUED_SPECIAL_SCHOOL"]
    },
    {
      title: "Архив",
      key: "archive",
      statuses: ["ENDED", "REJECTED", "ENDED_NON_APPEARANCE"]
    }
  ],
  "reabilitation-and-adaptation": [
    AllClaimsExcludedSpecialSchool,
    {
      title: "Получившие направления в КППК",
      key: "direction-issued",
      statuses: ["DIRECTION_ISSUED"]
    },
    {
      title: "Ожидающие курс КППК",
      key: "awaiting-for-course",
      statuses: ["AWAITING_FOR_COURSE"]
    },
    {
      title: "Список детей, которые проходят курс КППК",
      key: "course-in-progress",
      statuses: ["COURSE_IN_PROGRESS"]
    },
    {
      title: "Список детей, прошедших курс КППК",
      key: "course-ended",
      statuses: ["COURSE_ENDED"]
    },
    {
      title: "Отклоненные заявления",
      key: "rejected",
      statuses: ["REJECTED"]
    }
  ],
  homeschooling: [
    AllClaims,
    {
      title: "Поступившие",
      key: "incoming",
      statuses: ["REGISTERED"]
    },
    {
      title: "В работе",
      key: "in-progress",
      statuses: ["IN_PROGRESS"]
    },
    {
      title: "Ожидающие зачисления",
      key: "awaiting-for-course",
      statuses: ["AWAITING_FOR_COURSE"]
    },
    {
      title: "Список детей, обучающихся на дому",
      key: "course-in-progress",
      statuses: ["COURSE_IN_PROGRESS"]
    },
    {
      title: "Архив детей, обучившихся на дому",
      key: "course-ended",
      statuses: ["COURSE_ENDED"]
    },
    {
      title: "Отклоненные заявления",
      key: "rejected",
      statuses: ["REJECTED"]
    }
  ],
  "nuclear-victims": [
    AllClaims,
    {
      title: "Поступившие",
      key: "arrived",
      statuses: ["REGISTERED"]
    },
    {
      title: "На рассмотрении",
      key: "on_consideration",
      statuses: ["ON_CONSIDERATION"]
    },
    {
      title: "Направлено в акимат",
      key: "sent-to-the-akimat",
      statuses: ["SENT"]
    },
    {
      title: "Архив",
      key: "archive",
      statuses: ["ENDED", "REJECTED"]
    }
  ],
  "nuclear-victims-akimat": [
    // {
    //   title: "Все заявки",
    //   key: "all",
    //   statuses: []
    // },
    // {
    //   title: "Поступившие",
    //   key: "arrived",
    //   statuses: ["REGISTERED"]
    // },
    // {
    //   title: "На рассмотрении",
    //   key: "on_consideration",
    //   statuses: ["ON_CONSIDERATION"]
    // },

    {
      title: "Направлено в акимат",
      key: "sent-to-the-akimat",
      statuses: ["SENT"]
    },
    {
      title: "В работе",
      key: "in-progress",
      statuses: ["IN_PROGRESS"]
    },
    {
      title: "Архив",
      key: "archive",
      statuses: ["ENDED", "REJECTED"]
    }
  ],
  "spa-treatment": [
    AllClaims,
    {
      title: "Поступившие",
      key: "incoming",
      statuses: ["REGISTERED"]
    },
    {
      title: "В работе",
      key: "in-progress",
      statuses: ["IN_PROGRESS"]
    },
    {
      title: "Реестр очередников",
      key: "in_queue",
      statuses: ["IN_QUEUE"]
    },
    {
      title: "Архив",
      key: "archive",
      statuses: ["ENDED", "REJECTED", "DIRECTION_ISSUED"]
    }
  ],
  "special-schools-claims-list": [
    {
      title: "Все заявления",
      key: "all",
      translate: "all_claims",
      statuses: [
        "RECEIVED_SPECIAL_SCHOOL",
        "LEAVED_SPECIAL_SCHOOL",
        "REJECTED_SPECIAL_SCHOOL",
        "ENDED_NON_APPEARANCE_SPECIAL_SCHOOL",
        "DIRECTION_ISSUED_SPECIAL_SCHOOL"
      ]
    },
    {
      title: "Зачисленные дети",
      translate: "received_childs",
      key: "received-special-school",
      statuses: ["RECEIVED_SPECIAL_SCHOOL"]
    },
    {
      title: "Убывшие дети",
      translate: "leaved_childs",
      key: "leaved-special-school",
      statuses: ["LEAVED_SPECIAL_SCHOOL"]
    },
    {
      title: "Отклоненные заявления",
      translate: "rejected_claims",
      key: "rejected-special-school",
      statuses: ["REJECTED_SPECIAL_SCHOOL"]
    },
    {
      title: "Завершенные заявления по неявке",
      translate: "ended_non_appearance_claims",
      key: "ended-non-appearance-special-school",
      statuses: ["ENDED_NON_APPEARANCE_SPECIAL_SCHOOL"]
    }
  ]
};
