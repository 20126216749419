<template>
  <section>
    <div class="modal-padded-body">
      <div class="field">
        <label class="label" for="localValue.usersData.iin">{{
          $ml.get("my_iin")
        }}</label>
        <div class="control">
          <input
            id="localValue.usersData.iin"
            type="text"
            class="input"
            v-model="localValue.usersData.iin"
            v-validate="usersIinRules"
            readonly
            name="localValue.usersData.iin"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.iin')"
          >
            {{ errors.first("localValue.usersData.iin") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("fio") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getNameFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("birthday") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="usersProfile.birth_date"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("address_registr") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getAddressFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <label class="label" for="usersData.email">{{
              $ml.get("email")
            }}</label>
            <div class="control">
              <input
                id="usersData.email"
                type="text"
                class="input"
                v-validate="'required|email'"
                :data-vv-as="$ml.get('email')"
                v-model="localValue.usersData.email"
                name="localValue.usersData.email"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.email')"
              >
                {{ errors.first("localValue.usersData.email") }}
              </p>
            </div>
          </div>

          <div class="field">
            <label class="label" for="usersData.phone">{{
              $ml.get("phone")
            }}</label>
            <div class="control">
              <input
                id="usersData.phone"
                type="text"
                class="input"
                v-validate="'required|min:10|max:10|numeric'"
                :data-vv-as="$ml.get('phone')"
                v-model="localValue.usersData.phone"
                name="localValue.usersData.phone"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.phone')"
              >
                {{ errors.first("localValue.usersData.phone") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">
          {{ $ml.get("livingPeriodStart") }}
        </label>
        <div class="control">
          <b-datepicker
            placeholder="Нажмите для выбора даты..."
            v-model="localValue.usersData.livingPeriodStart"
          >
          </b-datepicker>
        </div>
      </div>

      <div class="field">
        <label class="label">
          {{ $ml.get("livingPeriodEnd") }}
        </label>
        <div class="control">
          <b-datepicker
            placeholder="Нажмите для выбора даты..."
            v-model="localValue.usersData.livingPeriodEnd"
          >
          </b-datepicker>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input
              id="localValue.agreement"
              type="checkbox"
              v-validate="'required'"
              :data-vv-as="$ml.get('agreement')"
              v-model="localValue.agreement"
              name="localValue.agreement"
            />
            {{ $ml.get("accept_my_info") }}
          </label>
          <p class="help is-danger" v-if="errors.has('localValue.agreement')">
            {{ errors.first("localValue.agreement") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CommonFormMixin } from "../../mixins/common-form-mixin";

export default {
  name: "GraduateDocsDuplicateForm",
  mixins: [CommonFormMixin]
};
</script>
