export default '<p class="paragraph">Алушылар</p>\n' +
  "            Жеке тұлғалар\n" +
  '            <p class="paragraph">Қызметті қандай Мемлекеттік орган көрсетеді</p>\n' +
  "            Министрлік және облыстардың, Астана және Алматы қалаларының, облыстық маңызы бар аудандар мен қалалардың жергілікті атқарушы органдары (бұдан әрі – көрсетілетін қызметті беруші\n" +
  '            <p class="paragraph">Қызмет көрсету формасы</p>\n' +
  "            Автоматтандырылған\n" +
  '            <p class="paragraph">Керек құжаттар</p>\n' +
  "            <ol>\n" +
  "                <li>Жеке басын куәландыратын құжат көшірмесі;</li>\n" +
  "                <li>Білімі туралы диплом көшірмесі;</li>\n" +
  "                <li>Біліктілікті арттыру туралы құжат көшірмесі;</li>\n" +
  "                <li>Қызметкердің еңбек қызметін растайтын құжатының көшірмесі;</li>\n" +
  "                <li>Бұрын берген біліктілік санаты туралы куәлік көшірмесі (берудің соңғы жылы);</li>\n" +
  "                <li>\n" +
  "                    Білім және ғылым саласындағы азаматтық қызметшілерді аттестаттаудан өткізу қағидалары мен шарттарына, сондай-ақ бастауыш, негізгі орта, жалпы орта, техникалық және кәсіптік, орта білімнен кейінгі білімнің білім беретін оқу бағдарламаларын іске асыратын білім беру ұйымдарында жұмыс істейтін педагог қызметкерлер мен оларға теңестірілген тұлғаларды аттестаттаудан өткізу қағидалары мен шарттарына сәйкес кәсіптік жетістіктері (олар болған жағдайда) туралы мәліметтер, Қазақстан Республикасы Білім және ғылым министрінің 2016 жылғы 27 қаңтардағы № 83 бұйрығымен бекітілген.\n" +
  "                </li>\n" +
  "            </ol>\n" +
  '            <p class="paragraph">Қандай жағдайда Мемлекеттік қызмет көрсетуден бас тартылуы немесе ол тоқтатыла тұруы мүмкін. Мемлекеттік қызмет көрсетуден мынадай негіздер бойынша бас тартылады:</p>\n' +
  "            <ul>\n" +
  "                <li>Құжаттардың оқылмайтын сканерленген көшірмелері;</li>\n" +
  "                <li>Құжаттардың толық емес пакеті;</li>\n" +
  "                <li>Басқа.</li>\n" +
  "            </ul>\n" +
  '            <p class="paragraph">Қызмет көрсету мерзімі</p>\n' +
  "            <ul>\n" +
  "                <li>Өтініш беру-5 минут;</li>\n" +
  "                <li>Қызмет көрсету нәтижесін алу - тіркелу күнін есептемегенде 3 күнтізбелік күн</li>\n" +
  "            </ul>\n" +
  '            <p class="paragraph">Қызмет құны және төлеу тәртібі</p>\n' +
  "            Тегін\n" +
  '            <p class="paragraph">Қызмет көрсету нәтижесі</p>\n' +
  "            <p>Мектепке дейінгі тәрбие мен оқыту, бастауыш, негізгі орта, жалпы орта, техникалық және кәсіптік, орта білімнен кейінгі  білім беру бағдарламаларын іске асыратын білім беру ұйымдарының педагог қызметкерлері мен оларға теңестірілген тұлғаларға біліктілік санаттарын беру (растау) үшін аттестаттаудан өткізуге құжаттар қабылдау немесе немесе Мемлекеттік қызмет көрсетуден себептемелі жауаппен бас тарту туралы қолхат.</p>" +
  "            <div style='text-align: center'>" +
  '            <iframe style="width: 50%; height: 300px" src="https://www.youtube.com/embed/lVBQFtTA5Vw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' +
  '            <a target="_blank" href="https://testter.kz/site/index?language=kz-KZ"><img src="/tester_kz.jpg"/></a>' +
  "            </div>";
