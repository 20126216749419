import ResourceControllerApiService from "./resource-controller-api-service";

class Statistics extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/statistics";
  }

  list(data) {
    return this.axios.get(this.getMainRoute(), {
      params: data
    });
  }
}

export default Statistics;
