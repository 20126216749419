<template>
  <nav class="navbar is-primary app-navbar" style="font-size: 12px">
    <div class="navbar-brand">
      <router-link :to="{ name: 'home' }" exact class="navbar-item">
        <div @click="close">
          <fa-icon icon="graduation-cap"></fa-icon>&nbsp;
          <span>
            ГУ
            <sub>&beta;</sub>
          </span>
        </div>
      </router-link>
      <a
        role="button"
        class="navbar-burger"
        :class="isNavbarOpenedClass"
        @click="activateNavigation"
      >
        <span />
        <span />
        <span />
      </a>
      <a
        class="navbar-item"
        v-for="lang in $ml.list"
        :key="lang"
        @click="changeLanguage(lang)"
        v-text="lang.toUpperCase()"
        v-show="lang !== $ml.current"
        >{{ lang }}</a
      >
    </div>
    <div class="navbar-menu" :class="isNavbarOpenedClass">
      <div class="navbar-start">
        <div
          class="navbar-item has-dropdown is-hoverable"
          v-if="isLoggedIn && hasManagerListPermission"
        >
          <div class="navbar-link">{{ $ml.get("journal") }}</div>
          <div class="navbar-dropdown limited-width">
            <span
              v-for="(service, index) in managerServices"
              :key="`service_${index}`"
            >
              <router-link
                :service="service"
                v-if="
                  !['teachers-attestation', 'principals-attestation'].includes(
                    service.slug
                  )
                "
                :key="service.id"
                :to="{
                  name: 'claims.managers-list',
                  params: { slug: service.slug }
                }"
                class="navbar-item"
              >
                <div @click="close">
                  <fa-icon icon="feather-alt"></fa-icon>
                  <div
                    v-for="(part, idx) in chunked($ml.get(service.slug))"
                    :key="idx"
                  >
                    {{ part }}
                    <br />
                  </div>
                </div>
              </router-link>
              <router-link
                v-else-if="service.slug === 'teachers-attestation'"
                v-for="(declarerType, index) in getTeacherDeclarerTypes"
                :service="service"
                :key="index"
                class="navbar-item"
                :to="{
                  name: 'claims.managers-list',
                  params: {
                    slug: service.slug,
                    declarertype: declarerType.number
                  }
                }"
              >
                <div @click="close">
                  <fa-icon icon="feather-alt"></fa-icon>
                  <span
                    v-for="(part, index) in chunked(
                      $ml.get(`${declarerType.slug}_short`)
                    )"
                    :key="`part_${index}`"
                  >
                    {{ part }}
                    <br />
                  </span>
                </div>
              </router-link>
              <router-link
                v-else-if="service.slug === 'principals-attestation'"
                v-for="(declarerType, index) in getPrincipalsDeclarerTypes"
                :service="service"
                :key="index"
                class="navbar-item"
                :to="{
                  name: 'claims.managers-list',
                  params: {
                    slug: service.slug,
                    declarertype: declarerType.number
                  }
                }"
              >
                <div @click="close">
                  <fa-icon icon="feather-alt" />
                  <span
                    v-for="(part, index) in chunked($ml.get(declarerType.slug))"
                    :key="`p_part_${index}`"
                  >
                    {{ part }}
                    <br />
                  </span>
                </div>
              </router-link>
            </span>

            <router-link
              class="navbar-item"
              :to="{
                name: 'statistics'
              }"
              v-if="isLoggedIn && hasPermission('MANAGER_STATISTICS')"
            >
              <div>
                <fa-icon icon="feather-alt"></fa-icon>
                Статистика
              </div>
            </router-link>
            <router-link
              class="navbar-item"
              :to="{
                name: 'claims.special-schools-list'
              }"
              v-if="
                isLoggedIn &&
                  hasPermission('MANAGER_LIST_SPECIAL_GENERAL_EDUCATION')
              "
            >
              <div>
                <fa-icon icon="feather-alt"></fa-icon>
                <span
                  v-for="(part, index) in chunked(
                    $ml.get('spec_schools.claims_title')
                  )"
                  :key="`p_part_${index}`"
                >
                  {{ part }}
                  <br />
                </span>
              </div>
            </router-link>

            <router-link
              :to="{ name: 'reports' }"
              class="navbar-item"
              v-if="
                isLoggedIn &&
                  hasManagerListPermission() &&
                  hasManagerEditPermission() &&
                  hasPermission('MANAGER_LIST_PMPK') &&
                  hasPermission('MANAGER_LIST_PMPK_APPOINTMENT')
              "
            >
              <div>
                <fa-icon icon="feather-alt"></fa-icon>
                {{ $ml.get("reports") }}
              </div>
            </router-link>
          </div>
        </div>

        <div
          class="navbar-item has-dropdown is-hoverable"
          v-if="isLoggedIn && hasPermission('CREATE_CLAIM')"
        >
          <div class="navbar-link">{{ $ml.get("services") }}</div>
          <div class="navbar-dropdown limited-width">
            <router-link
              v-for="service in filteredServices"
              :service="service"
              :key="service.id"
              :to="{ name: 'service.main', params: { slug: service.slug } }"
              class="navbar-item"
            >
              <div @click="close">
                <fa-icon icon="feather-alt"></fa-icon>
                <div
                  v-for="(part, idx) in chunked($ml.get(service.slug))"
                  :key="idx"
                >
                  {{ part }}
                  <br />
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <div
          class="navbar-item has-dropdown is-hoverable"
          v-if="isLoggedIn && hasPermission('ADMIN')"
        >
          <div class="navbar-link">{{ $ml.get("administration") }}</div>
          <div class="navbar-dropdown admin-dropdown limited-width">
            <router-link :to="{ name: 'admin.roles.list' }" class="navbar-item">
              <div @click="close">
                <fa-icon icon="list"></fa-icon>
                &nbsp; {{ $ml.get("roles") }}
              </div>
            </router-link>

            <router-link :to="{ name: 'admin.users.list' }" class="navbar-item">
              <div @click="close">
                <fa-icon icon="list"></fa-icon>
                &nbsp; {{ $ml.get("users") }}
              </div>
            </router-link>

            <router-link
              :to="{ name: 'admin.services.list' }"
              class="navbar-item"
            >
              <div @click="close">
                <fa-icon icon="list"></fa-icon>
                &nbsp; {{ $ml.get("services") }}
              </div>
            </router-link>

            <router-link
              :to="{ name: 'admin.schools.list' }"
              class="navbar-item"
            >
              <div @click="close">
                <fa-icon icon="list"></fa-icon>
                &nbsp; {{ $ml.get("schools") }}
              </div>
            </router-link>

            <router-link
              :to="{ name: 'admin.calendar-days' }"
              class="navbar-item"
            >
              <div @click="close">
                <fa-icon icon="list"></fa-icon>
                &nbsp; {{ $ml.get("holidays_weekends") }}
              </div>
            </router-link>

            <router-link
              :to="{ name: 'admin.teacher-attestation-allow' }"
              class="navbar-item"
            >
              <div @click="close">
                <fa-icon icon="list"></fa-icon>
                &nbsp; {{ $ml.get("teacher_attestation_allow") }}
              </div>
            </router-link>
          </div>
        </div>

        <router-link
          :to="{ name: 'claims.appointments' }"
          v-if="isLoggedIn && hasPermission('MANAGER_LIST_PMPK_APPOINTMENT')"
          class="navbar-item"
          >{{ $ml.get("appointments") }}
        </router-link>

        <router-link
          :to="{ name: 'claims.pmpk-list' }"
          v-if="isLoggedIn && hasPermission('MANAGER_LIST_PMPK')"
          class="navbar-item"
          >{{ $ml.get("base_pmpk") }}
        </router-link>

        <!--UOALM-335 только для тестового-->
        <!--<router-link
          :to="{ name: 'claims.queue-list' }"
          v-if="isLoggedIn && hasPermission('MANAGER_LIST_QUEUES')"
          class="navbar-item"
          >Просмотр очереди</router-link
        >

        <router-link
          :to="{ name: 'sanatoriums.list' }"
          class="navbar-item"
          v-if="isLoggedIn && hasPermission('LIST_SANATORIUMS')"
          >{{ $ml.get("sanatoriums") }}</router-link
        >-->

        <div
          class="navbar-item has-dropdown is-hoverable"
          v-if="isLoggedIn && hasPermission('ADD_PLACE_TO_SPECIAL_ORG')"
        >
          <div class="navbar-link">{{ $ml.get("special_schools") }}</div>
          <div class="navbar-dropdown limited-width">
            <router-link
              :to="{ name: 'special-schools-places' }"
              class="navbar-item"
              >{{ $ml.get("spec_schools.places_base") }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="navbar-end">
        <p class="navbar-item is-bordered" v-if="isLoggedIn">
          <fa-icon :icon="['far', 'user']"></fa-icon>
          {{ getNameFromUserInfo(userProfile) }}
          <small class="role">[ {{ roleName() }} ]</small>
        </p>

        <router-link
          :to="{ name: 'remote.logout' }"
          v-if="isLoggedIn"
          class="navbar-item"
        >
          <fa-icon icon="sign-out-alt"></fa-icon>
          &nbsp;{{ $ml.get("exit") }}
        </router-link>
        <router-link
          :to="{ name: 'remote.login' }"
          v-if="!isLoggedIn"
          class="navbar-item"
        >
          <fa-icon icon="sign-in-alt"></fa-icon>
          &nbsp;{{ $ml.get("enter") }}
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getNameFromUserInfo } from "../services/helper";
import { PermissionsMixin } from "../mixins/permissions-mixin";
import moment from "../services/moment-instance.js";
import eventBus from "../services/event-bus";

export default {
  name: "Navbar",
  mixins: [PermissionsMixin],
  data: function() {
    return {
      opened: false,
      declarerTypePermissions: [
        {
          number: 1,
          permission: "MANAGER_LIST_TEACHERS_ATTESTATION_TEACHER"
        },
        {
          number: 2,
          permission: "MANAGER_LIST_TEACHERS_ATTESTATION_DDO"
        },
        {
          number: 3,
          permission: "MANAGER_LIST_TEACHERS_ATTESTATION_TIPO"
        },
        {
          number: 4,
          permission: "MANAGER_LIST_TEACHERS_ATTESTATION_NSS"
        },
        {
          number: 5,
          permission: "MANAGER_LIST_TEACHERS_ATTESTATION_DVO"
        },
        {
          number: 6,
          permission: "MANAGER_LIST_TEACHERS_ATTESTATION_TIPO_2"
        },
        {
          number: 7,
          permission: "MANAGER_LIST_TEACHERS_ATTESTATION_DOD"
        },
        {
          number: 8,
          permission: "MANAGER_LIST_TEACHERS_ATTESTATION_SKU"
        },
        {
          number: 9,
          permission: "MANAGER_LIST_TEACHERS_ATTESTATION_IPK"
        },
        {
          number: 10,
          permission: "MANAGER_LIST_PMPK_AND_KPPK"
        },
        {
          number: 11,
          permission: "MANAGER_LIST_BOARDING_SCHOOLS_EDU_TEACHER"
        }
      ]
    };
  },
  computed: {
    getTeacherDeclarerTypes() {
      if (
        this.hasPermission("ADMIN") ||
        this.hasPermission("MANAGER_LIST_TEACHERS_ATTESTATION")
      ) {
        return this.declarerTypes("teachers-attestation");
      }
      return this.declarerTypes("teachers-attestation").filter(declarerType => {
        return this.hasPermission(
          this.declarerTypePermissions.find(
            type => type.number === declarerType.number
          ).permission
        );
      });
    },
    getPrincipalsDeclarerTypes() {
      if (
        this.hasPermission("ADMIN") ||
        this.hasPermission("MANAGER_LIST_PRINCIPALS_ATTESTATION")
      ) {
        return this.declarerTypes("principals-attestation");
      }
      return [];
    },
    managerServices() {
      return this.services.filter(item => {
        return (
          this.hasReadServicePermission(item.slug) ||
          item.slug === "teachers-attestation"
        );
      });
    },
    filteredServices() {
      return this.services.filter(item => {
        return item.show;
      });
    },
    isNavbarOpenedClass() {
      return this.opened ? "is-active" : "";
    },
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      services: "services/getList",
      userProfile: "user/getUserProfile",
      role: "user/getUserRole",
      permissions: "user/getUserPermissions",
      declarerTypes: "services/getDeclarerTypes"
    })
  },
  methods: {
    roleName() {
      let code = (this.role ? this.role.code : "") + "";
      let name = (this.role ? this.role.name : "") + "";

      if (code.length > 0) {
        let codeTranslation = this.$ml.get(code);

        if (codeTranslation && codeTranslation !== code) {
          return codeTranslation;
        }
      }

      if (name.length > 0) {
        let nameTraslation = this.$ml.get(name);

        if (nameTraslation && nameTraslation.length > 0) {
          return nameTraslation;
        }
        return name;
      }
      return "";
    },
    activateNavigation() {
      this.opened = !this.opened;
    },
    close() {
      this.opened = false;
    },
    getNameFromUserInfo,
    chunked(string) {
      const size = 100;
      const numChunks = Math.ceil(string.length / size);
      const chunks = new Array(numChunks);

      for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks[i] = string.substr(o, size);
      }

      return chunks;
    },
    changeLanguage(lang) {
      this.$ml.change(lang);
      this.$validator.locale = lang;
      moment.locale(lang);
      eventBus.$emit("languageChanged", lang);
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/variables";

.app-navbar {
  .navbar-brand {
    font-weight: bold;
    text-transform: capitalize;
  }

  .role {
    text-transform: uppercase;
    color: $white;
    text-shadow: 0 -1px 0 $black;
  }

  .navbar-dropdown {
    a.navbar-item {
      > div {
        position: relative;
        padding-left: 25px;

        svg {
          position: absolute;
          left: 0;
          top: 3px;
        }
      }
    }

    &.admin-dropdown {
      a.navbar-item {
        > div {
          position: relative;
          padding-left: 16px;

          svg {
            position: absolute;
            left: 0;
            top: 3px;
          }
        }
      }
    }
  }
}
</style>
