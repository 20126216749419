<template>
  <b-select :placeholder="placeholder" v-model="localValue" :expanded="true">
    <option
      v-for="option in options"
      :selected="option.id === localValue"
      :value="option.id"
      :key="option.id"
    >
      {{ option.text }}
    </option>
  </b-select>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default() {
        return [
          {
            text: "---",
            id: 0
          }
        ];
      }
    },
    value: {
      default: () => ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: 0
    };
  },
  name: "BuefySimpleSelect",
  methods: {
    select(value) {
      this.localValue = value;
      this.$emit("input", value);
    },
    changeInitialOption() {
      this.localValue = this.value;
    }
  },
  watch: {
    value() {
      this.changeInitialOption();
    },
    options() {
      this.changeInitialOption();
    },
    localValue(newValue) {
      this.select(newValue);
    }
  },
  beforeMount() {
    this.changeInitialOption();
  }
};
</script>
<style lang="scss">
@import "../../assets/scss/variables";

.autocomplete.is-danger {
  input.input {
    border-color: $danger;
  }
}
</style>
