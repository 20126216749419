import { render, staticRenderFns } from "./SchoolSelect.vue?vue&type=template&id=436e44bc&scoped=true&"
import script from "./SchoolSelect.vue?vue&type=script&lang=js&"
export * from "./SchoolSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436e44bc",
  null
  
)

export default component.exports