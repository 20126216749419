import ResourceControllerApiService from "./resource-controller-api-service";

class CalendarDays extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/admin/calendar-days";
  }

  list(data) {
    return this.axios.get(this.getMainRoute(), { params: data });
  }
}

export default CalendarDays;
