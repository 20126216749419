import { render, staticRenderFns } from "./PersonalList.vue?vue&type=template&id=1848b654&scoped=true&"
import script from "./PersonalList.vue?vue&type=script&lang=js&"
export * from "./PersonalList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1848b654",
  null
  
)

export default component.exports