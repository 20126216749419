import eventBus from "../services/event-bus";
import moment from "moment";

export const DatepickerMixin = {
  data() {
    return {
      localLanguage: this.$ml.current,
      dayNames: {
        ru: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        kk: ["Жсн", "Дс", "Сс", "Ср", "Бс", "Жм", "Сб"]
      },
      monthNames: {
        ru: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь"
        ],
        kk: [
          "Қаңтар",
          "Ақпан",
          "Наурыз",
          "Сәуiр",
          "Мамыр",
          "Маусым",
          "Шiлде",
          "Тамыз",
          "Қыркүйек",
          "Қазан",
          "Қараша",
          "Желтоқсан"
        ]
      }
    };
  },
  computed: {
    getDayNames() {
      return this.dayNames[this.localLanguage];
    },
    getMonthNames() {
      return this.monthNames[this.localLanguage];
    }
  },
  methods: {
    changeLocalLanguage(language) {
      this.localLanguage = language;
    },
    dateToString(date, format = "YYYY-MM-DD HH:mm") {
      return moment(date).format(format);
    },
    stringToDate(date) {
      return moment(date).toDate();
    }
  },
  beforeMount() {
    eventBus.$on("languageChanged", this.changeLocalLanguage);
  }
};
