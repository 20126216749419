<template>
  <button
    class="button"
    :disabled="disabled"
    :title="title"
    :class="classes"
    @click="onSign"
  >
    {{ buttonName ? buttonName : $ml.get(name) }}&nbsp;<fa-icon
      :icon="disabled ? 'times' : 'check-double'"
    ></fa-icon>
  </button>
</template>

<script>
import { SignDataButtonMixin } from "../../mixins/sign-data-button-mixin";

export default {
  name: "SignDataButton",
  mixins: [SignDataButtonMixin],
  props: {
    buttonName: {
      type: String,
      default: ""
    }
  }
};
</script>
