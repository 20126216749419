export default [
  {
    key: "special_organizations",
    name: "Специальные организации образования"
  },
  {
    key: "special_classes",
    name:
      "Специальные классы (группы) при общеобразовательных организациях образования"
  },
  {
    key: "correction_organisations",
    name:
      "Общеобразовательные организации с коррекционно-педагогической поддержкой"
  },
  {
    key: "kppk",
    name: "КППК"
  },
  {
    key: "not_correction_organisations",
    name:
      "Общеобразовательные организации без коррекционно-педагогической поддержки"
  },
  {
    key: "homeschooling",
    name: "Обучение на дому"
  },
  {
    key: "other_organisations",
    name:
      "Другие организации образования, здравоохранения, соц.защиты, общественные другие организации образования, здравоохранения, социальной защиты "
  },
  {
    key: "not_students",
    name: "Временно не охваченные воспитанием и обучением по разным причинам"
  },
  {
    key: "continuation",
    name: "Продолжение обучения/ пребывания"
  },
  {
    key: "kgd_pmpk",
    name: "КДГ при ПМПК"
  }
];
