class Kppks {
  constructor(axios) {
    this.axios = axios;
  }
  list() {
    return this.axios.get("/api/kppks");
  }
}

export default Kppks;
