<template>
  <ul>
    <li v-for="(file, index) in files" :key="'generated_files_' + index">
      <a
        class="download-item-link"
        target="_blank"
        download
        :href="getDownloadLink(file.claim_id, file.id)"
        ><fa-icon :icon="['far', 'file-alt']"></fa-icon>&nbsp;<span>{{
          translate(file.name.substring(0, file.name.length - 4), file.name)
        }}</span></a
      >
    </li>
  </ul>
</template>

<script>
import { TranslateMixin } from "@/mixins/translate-mixin";

export default {
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },
  name: "ClaimGeneratedFilesComponent",
  mixins: [TranslateMixin],
  data() {
    return {};
  },
  mounted: function() {},
  methods: {
    getDownloadLink(claimId, fileId) {
      return (
        process.env["VUE_APP_API_BASE_URL"] +
        "api/claims" +
        "/file/" +
        claimId +
        "/" +
        fileId +
        "?_token=" +
        this.$store.getters["user/getToken"]
      );
    }
  }
};
</script>
