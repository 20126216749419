<template>
  <section class="section">
    <h2 class="service subtitle">Список санаториев</h2>
    <div class="field">
      <label for="search" class="label">{{ $ml.get("search") }}</label>
      <div class="control">
        <input
          v-model="requestParams.search_query"
          @keyup="loadItems()"
          id="search"
          type="text"
          class="input"
        />
      </div>
    </div>
    <router-link
      class="button is-success"
      :to="{ name: 'sanatoriums.create' }"
      style="margin-bottom: 20px"
    >
      Добавить
    </router-link>
    <table v-if="items.length > 0" class="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Название</th>
          <th>{{ $ml.get("actions") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="'table_item_' + itemIndex" v-for="(item, itemIndex) in items">
          <td>{{ item.name }}</td>
          <td>
            <router-link
              class="button is-info is-small"
              :to="{ name: 'sanatoriums.edit', params: { id: item.id } }"
            >
              Редактировать
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <VPagination
      @change="changePage"
      :icon-pack="'far'"
      :total="totalPages"
      :current="currentPage"
      :order="order"
      :per-page="perPage"
    />
  </section>
</template>

<script>
import { CommonListMixin } from "../../../mixins/common-list-mixin";

export default {
  name: "SanatoriumsList",
  mixins: [CommonListMixin],
  data() {
    return {
      items: [],
      requestParams: {
        search_query: ""
      }
    };
  },
  beforeMount() {
    this.apiResource = this.$apiResource.make("Sanatorium");
    this.listMethod = "list";
  }
};
</script>

<style scoped></style>
