import ResourceControllerApiService from "./resource-controller-api-service";

class Service extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/admin/services";
  }

  list(data) {
    return this.axios.get(this.getMainRoute(), {
      params: data
    });
  }
}

export default Service;
