<template>
  <section>
    <div class="content wizard-content" v-if="currentStep === formStep">
      <h3>{{ $ml.get("full_info") }}</h3>
      <main-form
        v-model="localValue"
        @onValidate="onValidateForm"
        @onInvalid="onInvalidForm"
        :service="currentService"
        :api-resource="apiResource"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === fileStep">
      <h3>{{ $ml.get("upload_req_files") }}</h3>

      <file-upload-list
        :list-key="'required_file'"
        :file-list="localValue['requiredFiles']"
      />
    </div>

    <div class="content wizard-content" v-if="currentStep === signStep">
      <p>
        Просим обратить внимание!<br />
        При подписании заявления электронно-цифровой подписью (ЭЦП), вы сможете
        получить результат оказания услуги (разрешение) в электронном виде на
        портале.<br />
        В случае отправки заявления без подписи ЭЦП, результат оказания услуги
        (разрешение) необходимо будет получать в бумажном виде у Услугодателя
      </p>
    </div>
    <common-last-step v-if="currentStep === lastStep" />
  </section>
</template>

<script>
import { CommonWizardMixin } from "../../mixins/common-wizard-mixin";
import { ParentalVisitPermitForm } from "../forms";

export default {
  mixins: [CommonWizardMixin],
  name: "ParentalVisitPermitWizard",
  components: {
    mainForm: ParentalVisitPermitForm
  },
  beforeMount() {
    this.deactivateStep(this.formStep);
    this.deactivateStep(this.fileStep);

    this.$set(this.localValue, "usersData", {
      iin: "",
      phone: "",
      email: "",
      area: ""
    });

    this.$set(this.localValue, "children", []);

    this.setRequiredFiles([
      {
        label: "Копия решения суда о лишении родительских прав",
        name: "file_1",
        files: []
      },
      {
        label: "Характеристика органов внутренних дел",
        name: "file_2",
        files: []
      },
      {
        label: "Характеристика с места работы",
        name: "file_3",
        files: []
      },
      {
        label: "Справка о несудимости (судимости)",
        name: "file_4",
        files: []
      },
      {
        label: "Правоустанавливающие документы на квартиру (дом)",
        name: "file_5",
        files: []
      },
      {
        label: "Акт обследования жилищно-бытовых условий",
        name: "file_6",
        files: []
      },
      {
        label: "Справка с наркологического диспансера",
        name: "file_7",
        files: []
      },
      {
        label: "Справка с психиатрического диспансера",
        name: "file_8",
        files: []
      },
      {
        label: "Справка с туберкулезного диспансера",
        name: "file_9",
        files: []
      },
      {
        label: "Копии документов, удостоверяющих личность",
        name: "file_10",
        files: []
      }
    ]);
  }
};
</script>
