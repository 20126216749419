<template>
  <section>
    <div class="modal-padded-body">
      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input
              id="localValue.usersData.resident"
              type="checkbox"
              :data-vv-as="'Резидент РК'"
              v-model="localValue.usersData.resident"
              name="localValue.usersData.resident"
            />
            Резидент РК
          </label>
        </div>
      </div>

      <!--<div class="field">-->
      <!--<label class="label" for="usersData.iin">{{ $ml.get('iin_claim_man') }}</label>-->
      <!--<div class="control">-->
      <!--<input id="usersData.iin"-->
      <!--type="text"-->
      <!--class="input"-->
      <!--:value="usersProfile.iin"-->
      <!--disabled-->
      <!--name="localValue.usersData.iin"/>-->
      <!--</div>-->
      <!--</div>-->
      <div class="field">
        <label class="label" for="usersData.iin">{{
          $ml.get("iin_claim_man")
        }}</label>
        <div class="control">
          <input
            id="usersData.iin"
            type="text"
            class="input"
            @keyup="usersIinChanged()"
            v-validate="'required|iin_control'"
            :data-vv-as="$ml.get('iin_claim_man')"
            v-model="localValue.usersData.iin"
            name="localValue.usersData.iin"
          />
          <p class="help is-danger">
            {{ errors.first("localValue.usersData.iin") }}
          </p>
        </div>
      </div>

      <template v-if="usersUserInfo.iin">
        <div class="field">
          <label class="label">{{ $ml.get("fio_claim_man") }}</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :value="getNameFromUserInfo(usersUserInfo)"
              disabled
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Дата рождения заявителя</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :value="usersUserInfo.birth_date"
              disabled
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Адрес</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :value="getAddressFromUserInfo(usersUserInfo)"
              disabled
            />
          </div>
        </div>
      </template>

      <div class="field">
        <label class="label" for="usersData.email">Электронная почта</label>
        <div class="control">
          <input
            id="usersData.email"
            type="text"
            class="input"
            v-validate="'required|email'"
            :data-vv-as="'Электронная почта'"
            v-model="localValue.usersData.email"
            name="localValue.usersData.email"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.email')"
          >
            {{ errors.first("localValue.usersData.email") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label" for="usersData.phone">{{
          $ml.get("phone")
        }}</label>
        <div class="control">
          <input
            id="usersData.phone"
            type="text"
            class="input"
            v-validate="'required|min:10|max:10|numeric'"
            :data-vv-as="$ml.get('phone')"
            v-model="localValue.usersData.phone"
            name="localValue.usersData.phone"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.phone')"
          >
            {{ errors.first("localValue.usersData.phone") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label" for="childsData.iin">ИИН ребенка</label>
        <div class="control">
          <input
            id="childsData.iin"
            type="number"
            class="input"
            @keyup="childsIinChanged()"
            v-validate="childsIinRules"
            :data-vv-as="'ИИН ребенка'"
            v-model="localValue.childsData.iin"
            name="localValue.childsData.iin"
          />
          <p class="help is-danger">
            {{ errors.first("localValue.childsData.iin") }}
          </p>
        </div>
      </div>

      <div v-show="!!childsUserInfo.iin">
        <div class="field">
          <label class="label">ФИО ребенка</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :value="getNameFromUserInfo(childsUserInfo)"
              disabled
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Дата рождения ребенка</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :value="childsUserInfo.birth_date"
              disabled
            />
          </div>
        </div>
        <div class="field">
          <label class="label">{{ $ml.get("address_registr") }}</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :value="getAddressFromUserInfo(childsUserInfo)"
              disabled
            />
          </div>
        </div>
        <div class="field">
          <label class="label" for="localValue.childsData.actualAddress"
            >Адрес фактического проживания</label
          >
          <div class="control">
            <input
              id="localValue.childsData.actualAddress"
              type="text"
              class="input"
              v-validate="'required|min:5|max:200'"
              :data-vv-as="'Адрес фактического проживания'"
              v-model="localValue.childsData.actualAddress"
              name="localValue.childsData.actualAddress"
            />
            <p
              class="help is-danger"
              v-if="errors.has('localValue.childsData.actualAddress')"
            >
              {{ errors.first("localValue.childsData.actualAddress") }}
            </p>
          </div>
        </div>

        <b-field label="№ ПМПК">
          <b-select
            placeholder="Пожалуйста, выберите № ПМПК"
            v-model="localValue.pmpk_id"
            v-validate="'required'"
          >
            <option
              v-for="pmpk in pmpksOptions"
              :value="pmpk.id"
              :key="pmpk.id"
            >
              {{ pmpk.text }}
            </option>
          </b-select>
          <p class="help is-danger" v-if="errors.has('localValue.pmpk_id')">
            {{ errors.first("localValue.pmpk_id") }}
          </p>
        </b-field>

        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input
                id="localValue.childsData.isDisabled"
                type="checkbox"
                :data-vv-as="'Наличие инвалидности'"
                v-model="localValue.childsData.isDisabled"
                name="localValue.childsData.isDisabled"
              />
              Наличие инвалидности
            </label>
          </div>
        </div>

        <b-field label="Кратность приема">
          <b-select
            placeholder="Кратность приема"
            v-model="localValue.childsData.multiplicityOfReception"
          >
            <option v-for="item in receptionTypes" :value="item" :key="item">
              {{ item }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Причина обращения">
          <b-select
            placeholder="Причина обращения"
            v-model="localValue.childsData.reason"
          >
            <option v-for="item in reasonTypes" :value="item" :key="item">
              {{ item }}
            </option>
          </b-select>
        </b-field>

        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input
                id="localValue.agreement"
                type="checkbox"
                v-validate="'required'"
                :data-vv-as="$ml.get('agreement')"
                v-model="localValue.agreement"
                name="localValue.agreement"
              />
              Даю согласие на использование сведений, составляющих охраняемую
              законом тайну, содержашихся в информационных системах
            </label>
            <p class="help is-danger" v-if="errors.has('localValue.agreement')">
              {{ errors.first("localValue.agreement") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CommonFormMixin } from "../../mixins/common-form-mixin";
// import areas from "../../configs/areas";
import { mapGetters } from "vuex";

export default {
  name: "ManagerSpecialNeedsSocialServicesForm",
  mixins: [CommonFormMixin],
  computed: {
    service() {
      return (
        this.services.find(
          service => service.slug === "special-needs-social-services"
        ) || {}
      );
    },
    ...mapGetters({
      services: "services/getList"
    })
  },
  data() {
    return {
      receptionTypes: ["Первичное", "Вторичное"],
      reasonTypes: [
        "Проблемы с развитием речи",
        "Проблемы со слухом",
        "Проблемы со зрением",
        "Нарушения опорно-двигательного аппарата",
        "Нарушения психического развития",
        "Проблемы со школьной успеваемостью",
        "Обучение на дому"
      ],
      usersUserInfo: {}
    };
  },
  methods: {
    usersIinChanged() {
      this.$validator
        .validate("localValue.usersData.iin")
        .then(validationResult => {
          if (validationResult) {
            this.getUserInfo(this.localValue.usersData.iin)
              .then(response => {
                this.usersUserInfo = response.data;
              })
              .catch(error => {
                this.displayError(this, this.getErrorsText(error));
              });
          } else {
            this.usersUserInfo = {};
          }
        });
    }
  },
  beforeMount() {
    this.setPmpksOptions();
  }
};
</script>
