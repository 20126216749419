<template>
  <section>
    <div class="modal-padded-body">
      <div class="field">
        <label class="label" for="localValue.usersData.iin">Мой ИИН</label>
        <div class="control">
          <input
            id="localValue.usersData.iin"
            type="number"
            class="input"
            v-model="localValue.usersData.iin"
            v-validate="
              [
                'required',
                'iin_control',
                'unique_active_water-supply_iin:' + service.slug
              ].join('|')
            "
            :data-vv-as="'ИИН'"
            readonly
            name="localValue.usersData.iin"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.iin')"
          >
            {{ errors.first("localValue.usersData.iin") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label">ФИО</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getNameFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Дата рождения</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="usersProfile.birth_date"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Адрес прописки</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getAddressFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label" for="usersData.email">Email</label>
        <div class="control">
          <input
            id="usersData.email"
            type="text"
            class="input"
            v-validate="'required|email'"
            :data-vv-as="'Email'"
            v-model="localValue.usersData.email"
            name="localValue.usersData.email"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.email')"
          >
            {{ errors.first("localValue.usersData.email") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label" for="usersData.phone">Номер телефона</label>
        <div class="control">
          <input
            id="usersData.phone"
            type="text"
            class="input"
            v-validate="'required|min:10|max:10|numeric'"
            :data-vv-as="'Номер телефона'"
            v-model="localValue.usersData.phone"
            name="localValue.usersData.phone"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.phone')"
          >
            {{ errors.first("localValue.usersData.phone") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label for="disability_category_id" class="label">Инвалидность</label>
        <div class="select">
          <select
            v-model="localValue.usersData.disability_category_id"
            name="disability_category_id"
            v-validate="'required'"
            id="disability_category_id"
          >
            <option
              v-for="(category, key) in disabilityCategories"
              :key="`category_${key}`"
              :value="category.id"
              >{{ category.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="field">
        <label for="usersData.doc_type" class="label"
          >Вид документа, удостоверяющего личность</label
        >
        <div class="control">
          <input
            id="usersData.doc_type"
            type="text"
            class="input"
            v-validate="'required'"
            :data-vv-as="'Тип документа'"
            v-model="localValue.usersData.doc_type"
            name="localValue.usersData.doc_type"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.doc_type')"
          >
            {{ errors.first("localValue.usersData.doc_type") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label for="usersData.issued_by" class="label">Кем выдан</label>
        <div class="control">
          <input
            id="usersData.issued_by"
            type="text"
            class="input"
            v-validate="'required'"
            :data-vv-as="'Кем выдан'"
            v-model="localValue.usersData.issued_by"
            name="localValue.usersData.issued_by"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.issued_by')"
          >
            {{ errors.first("localValue.usersData.issued_by") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label">Дата выдачи</label>
        <input
          type="hidden"
          id="issued_date"
          v-model="localValue.usersData.issued_date"
        />
        <div class="control">
          <b-datepicker
            name="localValue.usersData.issued_date"
            icon-pack="far"
            :month-names="getMonthNames"
            :day-names="getDayNames"
            v-validate="'required'"
            :data-vv-as="'Дата выдачи'"
            v-model="issuedDate"
            placeholder="Нажмите для выбора даты..."
          >
          </b-datepicker>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input
              id="localValue.agreement"
              type="checkbox"
              v-validate="'required'"
              :data-vv-as="'Соглашение'"
              v-model="localValue.agreement"
              name="localValue.agreement"
            />
            Даю согласие на использование сведений, составляющих охраняемую
            законом тайну, содержашихся в информационных системах
          </label>
          <p class="help is-danger" v-if="errors.has('localValue.agreement')">
            {{ errors.first("localValue.agreement") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CommonFormMixin } from "../../mixins/common-form-mixin";
import { DatepickerMixin } from "../../mixins/datepicker-mixin";
import moment from "moment";

export default {
  name: "WaterSupplyForm",
  mixins: [CommonFormMixin, DatepickerMixin],
  data: function() {
    return {
      disabilityCategories: [],
      issuedDate: new Date()
    };
  },
  watch: {
    issuedDate(value) {
      this.localValue.usersData.issued_date = moment(value).format(
        "YYYY-MM-DD"
      );
    }
  },
  beforeMount() {
    this.disabilityCategoryApi.list().then(response => {
      this.disabilityCategories = response.data;
    });
  }
};
</script>
