<template>
  <section class="loading">
    <div class="pageloader is-active">
      <span class="title">{{ $ml.get("redirect_another") }}...</span>
    </div>
  </section>
</template>

<script>
import remoteAuthService from "../../services/remote-auth-service";
export default {
  name: "Redirector",
  methods: {
    doRedirect: function(link) {
      window.location.href = link;
    }
  },
  mounted: function() {
    const nonce = remoteAuthService.getNonce();
    const link = remoteAuthService.getLink(nonce);
    setTimeout(() => {
      this.doRedirect(link);
    }, 2000);
  }
};
</script>
<style lang="scss"></style>
