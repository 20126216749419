import debounce from "lodash.debounce";

export const CommonListMixin = {
  props: {
    title: {
      type: String,
      default: "journal_claims"
    },
    perPage: {
      type: Number,
      default: 10
    },
    tableNamesMapping: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      items: [],
      declarerType: null,
      currentPage: 1,
      totalPages: 1,
      order: "",
      requestParams: {},
      apiResource: this.$apiResource.make("Claims"),
      listMethod: "managersList",
      inProgress: false
    };
  },
  computed: {
    /**
     * Осуществлять поиск по заявителю или его ребёнку в зависимости от типа заявления
     * @returns {boolean}
     */
    searchByChild() {
      return [
        "special-needs-social-services",
        "reabilitation-and-adaptation"
      ].includes(this.slug);
    }
  },
  methods: {
    loadItems: debounce(function() {
      this.inProgress = true;
      this.apiResource[this.listMethod]({
        page: this.currentPage,
        per_page: this.perPage,
        declarer_type: this.declarerType ? this.declarerType : null,
        area: this.area ? this.area : null,
        search_by_child: this.searchByChild ? 1 : 0,
        ...this.requestParams
      })
        .then(response => {
          const data = response["data"];
          this.items = data["data"];
          this.totalPages = parseInt(data["total"]);
          this.inProgress = false;
        })
        .catch(() => {
          this.inProgress = false;
        });
    }, 500),
    changePage(newValue) {
      this.currentPage = newValue;
      this.loadItems();
    },
    changeDeclarerType() {
      this.declarerType = this.$route.params.declarertype;
    }
  },
  mounted() {
    if (this.$route.params.hasOwnProperty("declarertype")) {
      if (this.$route.params.declarertype !== "") {
        this.declarerType = this.$route.params.declarertype;
      }
    }
    this.loadItems();
  }
};
