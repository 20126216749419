<template>
  <section class="section">
    <form @submit.prevent="submitForm(item)">
      <h2 class="service subtitle">Редактирование школы</h2>
      <div class="modal-padded-body">
        <div class="field">
          <label for="name" class="label">Наименование школы</label>
          <div class="control">
            <input
              id="name"
              v-model="item.name"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="address" class="label">Адрес</label>
          <div class="control">
            <input
              id="address"
              v-model="item.address"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="phone" class="label">{{ $ml.get("phone") }}</label>
          <div class="control">
            <input
              id="phone"
              v-model="item.phone"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="email" class="label">Электронный адрес</label>
          <div class="control">
            <input
              id="email"
              v-model="item.email"
              required
              type="email"
              class="input"
            />
          </div>
        </div>
      </div>

      <footer class="modal-card-foot">
        <button class="button is-success">Сохранить изменения</button>
        <router-link
          :to="{ name: 'admin.schools.list' }"
          :tag="'button'"
          :class="'button'"
          >{{ $ml.get("back") }}</router-link
        >
      </footer>
    </form>
  </section>
</template>

<script>
import { displayError, getErrorsText } from "../../../services/helper";

export default {
  name: "SchoolsEdit",
  props: ["id"],
  data() {
    return {
      item: {
        name: ""
      },
      apiResource: this.$apiResource.make("School")
    };
  },
  methods: {
    submitForm(item) {
      if (item.id) {
        this.apiResource
          .update(item.id, item)
          .then(() => {
            return this.$router.push({ name: "admin.schools.list" });
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      } else {
        this.apiResource
          .store(item)
          .then(() => {
            return this.$router.push({ name: "admin.schools.list" });
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      }
    }
  },
  beforeMount() {
    if (this.id) {
      this.apiResource.edit(this.id).then(response => {
        this.item = response.data;
      });
    }
  }
};
</script>

<style scoped></style>
