<template>
  <section class="section">
    <div class="claim_popup_background" v-if="showConfirmModal">
      <div class="claim_popup_container">
        <div class="claim_popup_close_button" @click="showConfirmModal = false">
          <fa-icon icon="times"></fa-icon>
        </div>
        <div class="claim_popup_header blue color-white p-20-10 f-bold">
          <div>{{ translate("you_sure_that_data_is_right") }}</div>
        </div>
        <div class="claim_popup_body p-20-10">
          <div>
            <p class="control">
              <span class="f-bold"
                >{{ translate("special_school") }}:&nbsp;</span
              >
              <span>{{ specialSchoolName }}</span>
            </p>
          </div>
          <div>
            <p class="control">
              <span class="f-bold">{{ translate("category") }}:&nbsp;</span>
              <span>{{ categoryName }}</span>
            </p>
          </div>
          <div>
            <p class="control">
              <span class="f-bold">{{ translate("class") }}:&nbsp;</span>
              <span>{{ className }}</span>
            </p>
          </div>
          <div>
            <p class="control">
              <span class="f-bold"
                >{{ translate("instruction_language") }}:&nbsp;</span
              >
              <span>{{ languageName }}</span>
            </p>
          </div>
          <div>
            <p class="control">
              <span class="f-bold">{{ translate("count_places") }}:&nbsp;</span>
              <span>{{ item.count_places }}</span>
            </p>
          </div>
          <div class="center p-20-10 f-bold">
            {{ translate("cant_edit_after_saving") }}
          </div>
        </div>
        <div class="claim_popup_footer border-bottom p-20-10">
          <div class="level-right">
            <button class="button is-primary" @click="submitCreate">
              Ок
            </button>
            <button
              class="button is-light m-0-0-0-10"
              @click="showConfirmModal = false"
            >
              {{ translate("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <form>
      <h2 class="service subtitle">
        {{ translate("add_place_to_special_school") }}
      </h2>

      <div class="field" v-if="specialSchools">
        <label class="label">{{ translate("choose_special_school") }}</label>
        <div class="control">
          <BuefySimpleSelect
            :options="specialSchoolsOptions()"
            v-model="item.special_school_id"
          />
        </div>
      </div>

      <div class="field" v-if="disabilityCategories">
        <label class="label">{{ translate("category") }}</label>
        <div class="control">
          <BuefySimpleSelect
            :options="disabilityCategoriesOptions()"
            v-model="item.disability_category_id"
          />
        </div>
      </div>

      <div class="field" v-if="classes">
        <label class="label">{{ translate("choose_class") }}</label>
        <div class="control">
          <BuefySimpleSelect
            :options="classesOptions()"
            v-model="item.class_id"
          />
        </div>
      </div>

      <div class="field" v-if="instructionLanguages">
        <label class="label">{{
          translate("choose_instruction_language")
        }}</label>
        <div class="control">
          <BuefySimpleSelect
            :options="instructionLanguagesOptions()"
            v-model="item.instruction_language_id"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ translate("indicate_place_count") }}</label>
        <div class="control">
          <input
            type="number"
            v-model="item.count_places"
            min="1"
            max="999"
            required
          />
        </div>
      </div>

      <router-link
        :to="{ name: 'special-schools-places' }"
        :tag="'button'"
        :class="'button'"
        >{{ translate("back") }}</router-link
      >
      <button
        class="button is-success m-0-0-0-10"
        @click="showConfirmModal = true"
        type="button"
        :disabled="!isAllFieldFilled"
      >
        {{ translate("to_save") }}
      </button>
    </form>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { TranslateMixin } from "@/mixins/translate-mixin";

export default {
  name: "SpecialSchoolsPlacesCreate",
  mixins: [TranslateMixin],
  data() {
    return {
      item: {
        special_school_id: 0,
        disability_category_id: 0,
        class_id: 0,
        instruction_language_id: 0,
        count_places: 1
      },
      languagesApi: this.$apiResource.make("InstructionLanguages"),
      disabilityCategoryApi: this.$apiResource.make("DisabilityCategories"),
      specialSchoolsApi: this.$apiResource.make("SpecialSchools"),
      classesApi: this.$apiResource.make("InstructionClasses"),
      specialSchoolPlacesApi: this.$apiResource.make("SpecialSchoolsPlaces"),
      instructionLanguages: null,
      disabilityCategories: null,
      specialSchools: null,
      classes: null,
      showConfirmModal: false
    };
  },
  computed: {
    isAllFieldFilled() {
      let mark = 0;
      for (let name in this.item) {
        mark += this.item[name] == 0 ? 1 : 0;
      }
      return mark === 0;
    },
    specialSchoolName() {
      if (this.item.special_school_id > 0) {
        return this.translate(
          this.specialSchools.find(
            school => school.id === this.item.special_school_id
          ).name
        );
      }
      return "";
    },
    categoryName() {
      if (this.item.disability_category_id > 0) {
        return this.translate(
          this.disabilityCategories.find(
            category => category.id === this.item.disability_category_id
          ).name
        );
      }
      return "";
    },
    className() {
      if (this.item.class_id > 0) {
        return this.translate(
          this.classes.find(c => c.id === this.item.class_id).name
        );
      }
      return "";
    },
    languageName() {
      if (this.item.instruction_language_id > 0) {
        return this.translate(
          this.instructionLanguages.find(
            instr => instr.id === this.item.instruction_language_id
          ).name
        );
      }
      return "";
    }
  },
  beforeMount() {
    this.setUser().then(response => {
      this.user = response.data;
    });
    this.languagesApi.list().then(response => {
      this.instructionLanguages = response.data;
    });

    this.disabilityCategoryApi.list().then(response => {
      this.disabilityCategories = response.data;
    });

    this.specialSchoolsApi.list().then(response => {
      this.specialSchools = response.data;
    });

    this.classesApi.list().then(response => {
      this.classes = response.data;
    });
  },
  methods: {
    specialSchoolsOptions() {
      let filtered =
        this.user.special_school_id == undefined
          ? this.specialSchools
          : this.specialSchools.filter(v => {
              return v.id == this.user.special_school_id;
            });

      return filtered.map(v => {
        return { id: v.id, text: this.translate(v.name.replace(/\./g, "_")) };
      });
    },
    disabilityCategoriesOptions() {
      return this.disabilityCategories.map(v => {
        return { id: v.id, text: this.translate(v.name) };
      });
    },
    classesOptions() {
      return this.classes.map(v => {
        return { id: v.id, text: this.translate(v.name) };
      });
    },
    instructionLanguagesOptions() {
      return this.instructionLanguages.map(v => {
        return { id: v.id, text: this.translate(v.name) };
      });
    },
    submitCreate() {
      this.specialSchoolPlacesApi
        .store(this.item)
        .then(() => {
          this.$router.push({ name: "special-schools-places" });
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    },
    ...mapActions({
      setUser: "user/setUser"
    })
  }
};
</script>

<style scoped>
.claim_popup_background {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.26);
  z-index: 9;
}
.claim_popup_container {
  position: relative;
  max-width: 1024px;
  margin: 15% auto 0;
  background-color: white;
  border: 1px solid blue;
  border-radius: 4px;
}
.claim_popup_close_button {
  position: absolute;
  right: 15px;
  top: 0;
}
</style>
