<template>
  <section>
    <div class="content wizard-content" v-if="currentStep === formStep">
      <h3>{{ $ml.get("full_info") }}</h3>
      <main-form
        v-model="localValue"
        @onValidate="onValidateForm"
        @onInvalid="onInvalidForm"
        :service="currentService"
        :api-resource="apiResource"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === fileStep">
      <h3>Загрузка файлов</h3>
      <file-upload-list
        :list-key="'required_file'"
        :file-list="localValue['requiredFiles']"
      />
    </div>
    <common-last-step v-if="currentStep === lastStep" />
  </section>
</template>

<script>
import { CommonWizardMixin } from "../../mixins/common-wizard-mixin";
import { NuclearVictimsForm } from "../forms";

export default {
  mixins: [CommonWizardMixin],
  name: "NuclearVictimsWizard",
  components: {
    mainForm: NuclearVictimsForm
  },
  beforeMount() {
    this.deactivateStep(this.formStep);

    this.$set(this.localValue, "usersData", {
      iin: "",
      email: "",
      phone: "",
      livingPeriodStart: new Date(),
      livingPeriodEnd: new Date()
    });

    this.setRequiredFiles([
      {
        label: "Документы",
        name: "file_1",
        files: []
      }
    ]);
  }
};
</script>

<style scoped></style>
