<template>
  <section>
    <div class="content wizard-content" v-if="currentStep === formStep">
      <h3>{{ $ml.get("full_info") }}</h3>
      <main-form
        v-model="localValue"
        @onValidate="onValidateForm"
        @onInvalid="onInvalidForm"
        :service="currentService"
        :api-resource="apiResource"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === fileStep">
      <h4>{{ $ml.get("upload_req_files") }}</h4>
      <file-upload-list
        :list-key="'required_file'"
        :file-list="localValue['requiredFiles']"
        :is-translate="true"
        :single="false"
        :show-plus="true"
        :max-mb-size="5"
      />

      <template>
        <label class="table-input-label">{{
          $ml.get("copy_qualification")
        }}</label>
        <table class="attestation-table">
          <thead>
            <tr>
              <th>№</th>
              <th>{{ $ml.get("date_of_assignment") }}</th>
              <th>{{ $ml.get("certificate_number") }}</th>
              <th>{{ $ml.get("course_subject") }}</th>
              <th>{{ $ml.get("place_of_passage") }}</th>
              <th>{{ $ml.get("certificate") }}</th>
              <th>{{ $ml.get("actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in attestationTable" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <b-datepicker
                  icon-pack="far"
                  v-model="item.assignDate"
                  :month-names="JSON.parse($ml.get('months_names'))"
                  :day-names="JSON.parse($ml.get('days_names'))"
                  placeholder="Нажмите для выбора даты..."
                >
                </b-datepicker>
              </td>
              <td>
                <the-mask
                  mask="#############"
                  type="text"
                  v-model="item.certNumber"
                ></the-mask>
              </td>
              <td><input type="text" v-model="item.course" /></td>
              <td><input type="text" v-model="item.spot" /></td>
              <td>
                <v-form-upload
                  v-model="item.cert.attachedFile"
                  :label="item.cert.label"
                  v-validate="'required'"
                  :name="item.cert.name"
                  :single="false"
                  :save-path="true"
                  :only="tableFormatsOnly"
                  :max-mb-size="5"
                />
              </td>
              <td>
                <span
                  v-if="
                    !isEmpty(item.assignDate) &&
                      !isEmpty(item.certNumber) &&
                      !isEmpty(item.course) &&
                      !isEmpty(item.spot) &&
                      item.cert.attachedFile.files.length > 0
                  "
                  class="add-row"
                  @click="attestationTableAddRow"
                  >+</span
                >
                <span
                  v-if="index !== 0"
                  class="remove-row"
                  @click="attestationTableRemoveRow(index)"
                  >-</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </template>

      <label class="table-input-label">{{
        $ml.get("copy_qual_category")
      }}</label>
      <table
        class="category-table"
        v-if="/*localValue.usersData.declarerType == 1*/ false"
      >
        <thead>
          <tr>
            <th>№</th>
            <th>{{ $ml.get("date_of_assignment") }}</th>
            <th>{{ $ml.get("certificate_number") }}</th>
            <th>{{ $ml.get("assigned_category") }}</th>
            <th>{{ $ml.get("place_of_passage") }}</th>
            <th>{{ $ml.get("certificate") }}</th>
            <th>{{ $ml.get("actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in categoryTable" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <b-datepicker
                icon-pack="far"
                v-model="item.assignDate"
                :month-names="JSON.parse($ml.get('months_names'))"
                :day-names="JSON.parse($ml.get('days_names'))"
                placeholder="Нажмите для выбора даты..."
              >
              </b-datepicker>
            </td>
            <td>
              <the-mask
                mask="#############"
                type="text"
                v-model="item.certNumber"
              ></the-mask>
            </td>
            <td><input type="text" v-model="item.category" /></td>
            <td><input type="text" v-model="item.spot" /></td>
            <td>
              <v-form-upload
                v-model="item.cert.attachedFile"
                :label="item.cert.label"
                v-validate="'required'"
                :name="item.cert.name"
                :single="false"
                :save-path="true"
                :only="tableFormatsOnly"
                :max-mb-size="5"
              />
            </td>
            <td>
              <span
                v-if="
                  !isEmpty(item.assignDate) &&
                    !isEmpty(item.certNumber) &&
                    !isEmpty(item.category) &&
                    !isEmpty(item.spot) &&
                    item.cert.attachedFile.files.length > 0
                "
                class="add-row"
                @click="categoryTableAddRow"
                >+</span
              >
              <span
                v-if="index !== 0"
                class="remove-row"
                @click="categoryTableRemoveRow(index)"
                >-</span
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <div v-for="(item, index) in categoryTable" :key="index">
          <v-form-upload
            v-model="item.cert.attachedFile"
            :label="item.cert.label"
            v-validate="'required'"
            :name="item.cert.name"
            :single="false"
            :show-plus="true"
            :save-path="true"
            :only="tableFormatsOnly"
            :max-mb-size="5"
          />
        </div>
      </div>

      <div v-if="localValue.usersData.declarerType == 2">
        <!--<file-upload-list
          :file-list="localValue.testDocsFiles"
          :show-plus="true"
          :single="false"
          :is-translate="true"
        />-->

        <div class="field" v-if="false">
          <label class="label">Дата прохождения</label>
          <input
            type="hidden"
            id="nationalExamDate"
            v-model="localValue.usersData.nationalExamDate"
          />
          <div class="control">
            <b-datepicker
              name="localValue.usersData.nationalExamDate"
              icon-pack="far"
              :month-names="getMonthNames"
              :day-names="getDayNames"
              :data-vv-as="'Дата прохождения'"
              v-model="nationalExamDate"
              placeholder="Нажмите для выбора даты..."
            >
            </b-datepicker>
          </div>
        </div>
      </div>

      <file-upload-list
        :file-list="localValue.gradeDocsFiles"
        :single="false"
        :show-plus="true"
        :is-translate="true"
      />

      <div v-if="false">
        <label class="table-input-label">
          {{ $ml.get("complex_analytics_doc") }}
        </label>

        <table class="pro-table">
          <thead>
            <tr>
              <th>№</th>
              <th>{{ $ml.get("date_word") }}</th>
              <th>{{ $ml.get("type_of_awards") }}</th>
              <th>{{ $ml.get("teacher_level") }}</th>
              <th>{{ $ml.get("teacher_form_participation") }}</th>
              <th>{{ $ml.get("certificate") }}</th>
              <th>{{ $ml.get("actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in proTable" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <b-datepicker
                  icon-pack="far"
                  v-model="item.date"
                  :month-names="JSON.parse($ml.get('months_names'))"
                  :day-names="JSON.parse($ml.get('days_names'))"
                  placeholder="Нажмите для выбора даты..."
                >
                </b-datepicker>
              </td>
              <td>
                <select v-model="item.type">
                  <option value="teacher_type_olimpiada">
                    {{ $ml.get("teacher_type_olimpiada") }}
                  </option>
                  <option value="teacher_type_competition">
                    {{ $ml.get("teacher_type_competition") }}
                  </option>
                  <option value="teacher_type_contest">
                    {{ $ml.get("teacher_type_contest") }}
                  </option>
                  <option value="teacher_type_sport">
                    {{ $ml.get("teacher_type_sport") }}
                  </option>
                  <option value="teacher_type_music">
                    {{ $ml.get("teacher_type_music") }}
                  </option>
                  <option value="teacher_type_seminar">
                    {{ $ml.get("teacher_type_seminar") }}
                  </option>
                  <option value="teacher_type_publications">
                    {{ $ml.get("teacher_type_publications") }}
                  </option>
                  <option value="teacher_type_other">
                    {{ $ml.get("teacher_type_other") }}
                  </option>
                </select>
              </td>
              <td>
                <select v-model="item.level">
                  <option value="teacher_level_international">
                    {{ $ml.get("teacher_level_international") }}
                  </option>
                  <option value="teacher_level_republic">
                    {{ $ml.get("teacher_level_republic") }}
                  </option>
                  <option value="teacher_level_city">
                    {{ $ml.get("teacher_level_city") }}
                  </option>
                  <option value="teacher_level_district">
                    {{ $ml.get("teacher_level_district") }}
                  </option>
                </select>
              </td>
              <td>
                <select v-model="item.form">
                  <option value="teacher_form_fulltime">
                    {{ $ml.get("teacher_form_fulltime") }}
                  </option>
                  <option value="teacher_form_distance">
                    {{ $ml.get("teacher_form_distance") }}
                  </option>
                </select>
              </td>
              <td>
                <v-form-upload
                  v-model="item.cert.attachedFile"
                  :label="item.cert.label"
                  v-validate="'required'"
                  :name="item.cert.name"
                  :single="false"
                  :save-path="true"
                  :only="tableFormatsOnly"
                  :max-mb-size="5"
                />
              </td>
              <td>
                <span
                  v-if="
                    !isEmpty(item.date) &&
                      !isEmpty(item.type) &&
                      !isEmpty(item.level) &&
                      !isEmpty(item.form) &&
                      item.cert.attachedFile.files.length > 0
                  "
                  class="add-row"
                  @click="proTableAddRow"
                  >+</span
                >
                <span
                  v-if="index !== 0"
                  class="remove-row"
                  @click="proTableRemoveRow(index)"
                  >-</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <file-upload-list-with-input
        v-if="selectedEmplymentTypeId === 6"
        :file-list="localValue.confirmClaimedCategoryFiles"
        :is-translate="true"
        :single="false"
        :show-plus="true"
      />
      <!--http://jira.samgau.com/browse/UOALM-220-->
      <div v-if="selectedEmplymentTypeId !== 6">
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field" style="width: 50%;">
              <label
                for="localValue.usersData.performanceIndicator"
                class="label"
                >{{ $ml.get("performance_indicator") }}</label
              >
              <div class="control">
                <buefy-simple-select
                  v-if="
                    localValue.usersData.declarerType === 2 &&
                      $ml.current === 'ru'
                  "
                  :options="[
                    {
                      id: 1,
                      text:
                        'Руководитель организации образования (Школы, Дошкольное, Дополнительное)'
                    },
                    {
                      id: 2,
                      text:
                        'Руководитель специальной организации образования (специальные детские сады и школы (школы-интернаты)'
                    },
                    {
                      id: 3,
                      text:
                        'Руководитель специальных организаций образования (Психолого-медико-педагогические консультации (далее – ПМПК), Кабинеты психолого-педагогической коррекции, Реабилитационные центры)'
                    },
                    {
                      id: 4,
                      text:
                        'Руководитель организации образования, реализующей образовательные программы технического и профессионального, послесреднего образования'
                    }
                  ]"
                  :value="localValue.usersData.performanceIndicator"
                  @input="selectPerformanceIndicator"
                />
                <buefy-simple-select
                  v-if="
                    localValue.usersData.declarerType === 2 &&
                      $ml.current === 'kk'
                  "
                  :options="[
                    {
                      id: 1,
                      text:
                        'Білім беру ұйымдары басшылары қызметінің тиімділігінің көрсеткіші (Мектептер, Қосымша білім беру, Мектеп-интернеаты)'
                    },
                    {
                      id: 2,
                      text:
                        'Арнайы білім беру ұйымының басшысы қызметінің тиімділік көрсеткіштері (арнайы балабақшалар мен мектептер (мектеп-интернаттар)'
                    },
                    {
                      id: 3,
                      text:
                        'Арнайы білім беру ұйымдары басшысының жұмыс тиімділігінің көрсеткіштері (Психологиялық-медициналық-педагогикалық консультациялар, психологиялық-педагогикалық түзеу кабинеттері, оңалту орталықтары)'
                    },
                    {
                      id: 4,
                      text:
                        'Техникалық және кәсіптік, орта білімнен кейінгі білімнің білім беру бағдарламаларын іске асыратын білім беру ұйымы басшысы қызметінің тиімділік көрсеткіштері'
                    }
                  ]"
                  :value="localValue.usersData.performanceIndicator"
                  @input="selectPerformanceIndicator"
                />
                <buefy-simple-select
                  v-if="
                    localValue.usersData.declarerType === 1 &&
                      $ml.current === 'ru'
                  "
                  :options="[
                    {
                      id: 1,
                      text:
                        'Заместитель руководителя по учебной работе (Школы, Дополнительное, СКШИ)'
                    },
                    {
                      id: 2,
                      text:
                        'Заместитель руководителя по воспитательной работе (Школы, Дополнительное, СКШИ)'
                    },
                    {
                      id: 3,
                      text:
                        'Заместитель руководителя по профильному обучению (Школы, Дополнительное, СКШИ)'
                    },
                    {
                      id: 4,
                      text: 'Заместитель руководителя по учебной работе (ТиПО)'
                    },
                    {
                      id: 5,
                      text:
                        'Заместитель руководителя по воспитательной работе (ТиПО)'
                    },
                    {
                      id: 6,
                      text:
                        'Заместитель руководителя по учебно-производственной работе, профессиональному обучению (ТиПО)'
                    },
                    {
                      id: 7,
                      text:
                        'Заместитель руководителя по учебно-методической, научно-методической работе, учебно-методическому объединению (ТиПО)'
                    }
                  ]"
                  :value="localValue.usersData.performanceIndicator"
                  @input="selectPerformanceIndicator"
                />
                <buefy-simple-select
                  v-if="
                    localValue.usersData.declarerType === 1 &&
                      $ml.current === 'kk'
                  "
                  :options="[
                    {
                      id: 1,
                      text:
                        'Басшының оқу жұмысы жөніндегі орынбасары қызметінің тиімділік көрсеткіштері (Мектептер, Қосымша білім беру, Мектеп-интернеаты)'
                    },
                    {
                      id: 2,
                      text:
                        'Басшының тәрбие жұмысы жөніндегі орынбасары қызметінің тиімділік көрсеткіштері (Мектептер, Қосымша білім беру, Мектеп-интернеаты)'
                    },
                    {
                      id: 3,
                      text:
                        'Профильді оқыту бойынша басшының орынбасары қызметінің тиімділігінің көрсеткіші (Мектептер, Қосымша білім беру, Мектеп-интернеаты)'
                    },
                    {
                      id: 4,
                      text:
                        'ТжКБ ұйымының оқу жұмысы жөніндегі орынбасарының қызметінің тиімділігінің көрсеткіштері'
                    },
                    {
                      id: 5,
                      text:
                        'ТжКБ ұйымының тәрбие жұмысы жөніндегі орынбасарының қызметінің тиімділігінің көрсеткіштері'
                    },
                    {
                      id: 6,
                      text:
                        'ТжКБ ұйымы басшысының оқу-өндірістік жұмыс, кәсіптік оқыту жөніндегі орынбасарының қызметі тиімділігінің көрсеткіштері'
                    },
                    {
                      id: 7,
                      text:
                        'ТжКБ ұйымдарының оқу-әдістемелік, ғылыми-әдістемелік жұмысы, оқу-әдістемелік бірлестігі бойынша басшы орынбасарының қызметі тиімділігінің көрсеткіштері'
                    }
                  ]"
                  :value="localValue.usersData.performanceIndicator"
                  @input="selectPerformanceIndicator"
                />
                <p
                  class="help is-danger"
                  v-if="errors.has('localValue.usersData.performanceIndicator')"
                >
                  {{
                    errors.first("localValue.usersData.performanceIndicator")
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <h4>{{ $ml.get("indicators") }}</h4>
        <file-upload-list-with-input
          :file-list="localValue.confirmClaimedCategoryFiles"
          :is-translate="true"
          :single="false"
          :show-plus="true"
        />
      </div>
      <!--<file-upload-list
        v-if="localValue.usersData.declarerType == 2"
        :file-list="localValue.markStudyFiles"
        :is-translate="true"
        :single="false"
        :show-plus="true"
      />-->
    </div>
    <common-last-step v-if="currentStep === lastStep" />
  </section>
</template>

<script>
import { CommonWizardMixin } from "../../mixins/common-wizard-mixin";
import { DatepickerMixin } from "../../mixins/datepicker-mixin";
import { PrincipalsAttestationForm } from "../forms";
import { TheMask } from "vue-the-mask";
import { mapGetters } from "vuex";

export default {
  mixins: [CommonWizardMixin, DatepickerMixin],
  name: "PrincipalsAttestationWizard",
  components: {
    mainForm: PrincipalsAttestationForm,
    TheMask
  },
  data() {
    return {
      selectedDeclarerTypeId: 0,
      selectedEmplymentTypeId: 0,
      selectedAreaId: 0,
      tableFormatsOnly: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
      attestationTable: [
        {
          assignDate: new Date(),
          certNumber: 1,
          course: "",
          spot: "",
          cert: {
            attachedFile: {
              files: [],
              label: "Приложите файл",
              name: this.$ml.get("tabs_attestationo"),
              uploaded: false,
              filename: "",
              size: 0,
              id: 0,
              hash: ""
            }
          }
        }
      ],

      categoryTable: [
        {
          assignDate: new Date(),
          certNumber: 1,
          category: "",
          spot: "",
          cert: {
            attachedFile: {
              files: [],
              label: "Приложите файл",
              name: this.$ml.get("copy_qual_category"),
              uploaded: false,
              filename: "",
              size: 0,
              id: 0,
              hash: ""
            }
          }
        }
      ],

      proTable: [
        {
          date: new Date(),
          type: 0,
          level: "",
          form: "",
          cert: {
            attachedFile: {
              files: [],
              label: "Приложите файл",
              name: "file_1",
              uploaded: false,
              filename: "",
              size: 0,
              id: 0,
              hash: ""
            }
          }
        }
      ],
      nationalExamDate: new Date(),
      confirmClaimedCategoryFileList: [
        //Заместитель руководителя по учебной работе (Школы, Дополнительное, СКШИ)
        [
          {
            label: this.$ml.get("principals_attestation_1_1"),
            translate: "principals_attestation_1_1",
            name: this.$ml
              .get("principals_attestation_1_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_1_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_2"),
            translate: "principals_attestation_1_2",
            name: this.$ml
              .get("principals_attestation_1_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_3"),
            translate: "principals_attestation_1_3",
            name: this.$ml
              .get("principals_attestation_1_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_1_2_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_4"),
            translate: "principals_attestation_1_4",
            name: this.$ml
              .get("principals_attestation_1_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_5"),
            translate: "principals_attestation_1_5",
            name: this.$ml
              .get("principals_attestation_1_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_6"),
            translate: "principals_attestation_1_6",
            name: this.$ml
              .get("principals_attestation_1_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_1_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_7"),
            translate: "principals_attestation_1_7",
            name: this.$ml
              .get("principals_attestation_1_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_8"),
            translate: "principals_attestation_1_8",
            name: this.$ml
              .get("principals_attestation_1_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_9"),
            translate: "principals_attestation_1_9",
            name: this.$ml
              .get("principals_attestation_1_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_10"),
            translate: "principals_attestation_1_10",
            name: this.$ml
              .get("principals_attestation_1_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_11"),
            translate: "principals_attestation_1_11",
            name: this.$ml
              .get("principals_attestation_1_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_1_12"),
            translate: "principals_attestation_1_12",
            name: this.$ml
              .get("principals_attestation_1_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
        ],
        //Заместитель руководителя по воспитательной работе (Школы, Дополнительное, СКШИ)
        [
          {
            label: this.$ml.get("principals_attestation_2_1"),
            translate: "principals_attestation_2_1",
            name: this.$ml
              .get("principals_attestation_2_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2"),
            translate: "principals_attestation_2_2",
            name: this.$ml
              .get("principals_attestation_2_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3"),
            translate: "principals_attestation_2_3",
            name: this.$ml
              .get("principals_attestation_2_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4"),
            translate: "principals_attestation_2_4",
            name: this.$ml
              .get("principals_attestation_2_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_2_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_5"),
            translate: "principals_attestation_2_5",
            name: this.$ml
              .get("principals_attestation_2_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_6"),
            translate: "principals_attestation_2_6",
            name: this.$ml
              .get("principals_attestation_2_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_7"),
            translate: "principals_attestation_2_7",
            name: this.$ml
              .get("principals_attestation_2_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_8"),
            translate: "principals_attestation_2_8",
            name: this.$ml
              .get("principals_attestation_2_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_9"),
            translate: "principals_attestation_2_9",
            name: this.$ml
              .get("principals_attestation_2_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_10"),
            translate: "principals_attestation_2_10",
            name: this.$ml
              .get("principals_attestation_2_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_4_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_11"),
            translate: "principals_attestation_2_11",
            name: this.$ml
              .get("principals_attestation_2_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_12"),
            translate: "principals_attestation_2_12",
            name: this.$ml
              .get("principals_attestation_2_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_13"),
            translate: "principals_attestation_2_13",
            name: this.$ml
              .get("principals_attestation_2_13")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_14"),
            translate: "principals_attestation_2_14",
            name: this.$ml
              .get("principals_attestation_2_14")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
        ],
        //Заместитель руководителя по профильному обучению (Школы, Дополнительное, СКШИ)
        [
          {
            label: this.$ml.get("principals_attestation_3_1"),
            translate: "principals_attestation_3_1",
            name: this.$ml
              .get("principals_attestation_3_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_3_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_2"),
            translate: "principals_attestation_3_2",
            name: this.$ml
              .get("principals_attestation_3_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_3"),
            translate: "principals_attestation_3_3",
            name: this.$ml
              .get("principals_attestation_3_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_4"),
            translate: "principals_attestation_3_4",
            name: this.$ml
              .get("principals_attestation_3_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            headerTitle: this.$ml.get("principals_attestation_3_2_title"),
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_5"),
            translate: "principals_attestation_3_5",
            name: this.$ml
              .get("principals_attestation_3_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_6"),
            translate: "principals_attestation_3_6",
            name: this.$ml
              .get("principals_attestation_3_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_7"),
            translate: "principals_attestation_3_7",
            name: this.$ml
              .get("principals_attestation_3_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_8"),
            translate: "principals_attestation_3_8",
            name: this.$ml
              .get("principals_attestation_3_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_3_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_9"),
            translate: "principals_attestation_3_9",
            name: this.$ml
              .get("principals_attestation_3_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_10"),
            translate: "principals_attestation_3_10",
            name: this.$ml
              .get("principals_attestation_3_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_11"),
            translate: "principals_attestation_3_11",
            name: this.$ml
              .get("principals_attestation_3_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_3_12"),
            translate: "principals_attestation_3_12",
            name: this.$ml
              .get("principals_attestation_3_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
        ],
        //Заместитель руководителя по учебной работе (ТиПО)
        [
          {
            label: this.$ml.get("principals_attestation_4_1"),
            translate: "principals_attestation_4_1",
            name: this.$ml
              .get("principals_attestation_4_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_4_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_2"),
            translate: "principals_attestation_4_2",
            name: this.$ml
              .get("principals_attestation_4_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_3"),
            translate: "principals_attestation_4_3",
            name: this.$ml
              .get("principals_attestation_4_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_4_2_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_4"),
            translate: "principals_attestation_4_4",
            name: this.$ml
              .get("principals_attestation_4_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_5"),
            translate: "principals_attestation_4_5",
            name: this.$ml
              .get("principals_attestation_4_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_6"),
            translate: "principals_attestation_4_6",
            name: this.$ml
              .get("principals_attestation_4_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_4_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_7"),
            translate: "principals_attestation_4_7",
            name: this.$ml
              .get("principals_attestation_4_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_8"),
            translate: "principals_attestation_4_8",
            name: this.$ml
              .get("principals_attestation_4_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_9"),
            translate: "principals_attestation_4_9",
            name: this.$ml
              .get("principals_attestation_4_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_10"),
            translate: "principals_attestation_4_10",
            name: this.$ml
              .get("principals_attestation_4_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_11"),
            translate: "principals_attestation_4_11",
            name: this.$ml
              .get("principals_attestation_4_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_12"),
            translate: "principals_attestation_4_12",
            name: this.$ml
              .get("principals_attestation_4_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_13"),
            translate: "principals_attestation_4_13",
            name: this.$ml
              .get("principals_attestation_4_13")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_14"),
            translate: "principals_attestation_4_14",
            name: this.$ml
              .get("principals_attestation_4_14")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_15"),
            translate: "principals_attestation_4_15",
            name: this.$ml
              .get("principals_attestation_4_15")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_16"),
            translate: "principals_attestation_4_16",
            name: this.$ml
              .get("principals_attestation_4_16")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_4_17"),
            translate: "principals_attestation_4_17",
            name: this.$ml
              .get("principals_attestation_4_17")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
        ],
        //Заместитель руководителя по воспитательной работе (ТиПО)
        [
          {
            label: this.$ml.get("principals_attestation_5_1"),
            translate: "principals_attestation_5_1",
            name: this.$ml
              .get("principals_attestation_5_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            headerTitle: this.$ml.get("principals_attestation_5_1_title"),
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_2"),
            translate: "principals_attestation_5_2",
            name: this.$ml
              .get("principals_attestation_5_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_3"),
            translate: "principals_attestation_5_3",
            name: this.$ml
              .get("principals_attestation_5_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_5_2_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_4"),
            translate: "principals_attestation_5_4",
            name: this.$ml
              .get("principals_attestation_5_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_5"),
            translate: "principals_attestation_5_5",
            name: this.$ml
              .get("principals_attestation_5_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_5_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_6"),
            translate: "principals_attestation_5_6",
            name: this.$ml
              .get("principals_attestation_5_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_7"),
            translate: "principals_attestation_5_7",
            name: this.$ml
              .get("principals_attestation_5_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_8"),
            translate: "principals_attestation_5_8",
            name: this.$ml
              .get("principals_attestation_5_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_9"),
            translate: "principals_attestation_5_9",
            name: this.$ml
              .get("principals_attestation_5_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_5_4_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_10"),
            translate: "principals_attestation_5_10",
            name: this.$ml
              .get("principals_attestation_5_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_11"),
            translate: "principals_attestation_5_11",
            name: this.$ml
              .get("principals_attestation_5_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_12"),
            translate: "principals_attestation_5_12",
            name: this.$ml
              .get("principals_attestation_5_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_13"),
            translate: "principals_attestation_5_13",
            name: this.$ml
              .get("principals_attestation_5_13")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_14"),
            translate: "principals_attestation_5_14",
            name: this.$ml
              .get("principals_attestation_5_14")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_15"),
            translate: "principals_attestation_5_15",
            name: this.$ml
              .get("principals_attestation_5_15")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_5_16"),
            translate: "principals_attestation_5_16",
            name: this.$ml
              .get("principals_attestation_5_16")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
        ],
        //Заместитель руководителя по учебно-производственной работе, профессиональному обучению (ТиПО)
        [
          {
            label: this.$ml.get("principals_attestation_6_1"),
            translate: "principals_attestation_6_1",
            name: this.$ml
              .get("principals_attestation_6_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_6_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_2"),
            translate: "principals_attestation_6_2",
            name: this.$ml
              .get("principals_attestation_6_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_3"),
            translate: "principals_attestation_6_3",
            name: this.$ml
              .get("principals_attestation_6_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_6_2_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_4"),
            translate: "principals_attestation_6_4",
            name: this.$ml
              .get("principals_attestation_6_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_5"),
            translate: "principals_attestation_6_5",
            name: this.$ml
              .get("principals_attestation_6_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_6"),
            translate: "principals_attestation_6_6",
            name: this.$ml
              .get("principals_attestation_6_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_6_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_7"),
            translate: "principals_attestation_6_7",
            name: this.$ml
              .get("principals_attestation_6_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_8"),
            translate: "principals_attestation_6_8",
            name: this.$ml
              .get("principals_attestation_6_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_9"),
            translate: "principals_attestation_6_9",
            name: this.$ml
              .get("principals_attestation_6_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_10"),
            translate: "principals_attestation_6_10",
            name: this.$ml
              .get("principals_attestation_6_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_11"),
            translate: "principals_attestation_6_11",
            name: this.$ml
              .get("principals_attestation_6_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_12"),
            translate: "principals_attestation_6_12",
            name: this.$ml
              .get("principals_attestation_6_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_13"),
            translate: "principals_attestation_6_13",
            name: this.$ml
              .get("principals_attestation_6_13")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_14"),
            translate: "principals_attestation_6_14",
            name: this.$ml
              .get("principals_attestation_6_14")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_15"),
            translate: "principals_attestation_6_15",
            name: this.$ml
              .get("principals_attestation_6_15")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_16"),
            translate: "principals_attestation_6_16",
            name: this.$ml
              .get("principals_attestation_6_16")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_17"),
            translate: "principals_attestation_6_17",
            name: this.$ml
              .get("principals_attestation_6_17")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_18"),
            translate: "principals_attestation_6_18",
            name: this.$ml
              .get("principals_attestation_6_18")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_6_19"),
            translate: "principals_attestation_6_19",
            name: this.$ml
              .get("principals_attestation_6_19")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
        ],
        //Заместитель руководителя по учебно-методической, научно-методической работе, учебно-методическому объединению (ТиПО)
        [
          {
            label: this.$ml.get("principals_attestation_7_1"),
            translate: "principals_attestation_7_1",
            name: this.$ml
              .get("principals_attestation_7_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_7_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_2"),
            translate: "principals_attestation_7_2",
            name: this.$ml
              .get("principals_attestation_7_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_3"),
            translate: "principals_attestation_7_3",
            name: this.$ml
              .get("principals_attestation_7_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_7_2_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_4"),
            translate: "principals_attestation_7_4",
            name: this.$ml
              .get("principals_attestation_7_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_5"),
            translate: "principals_attestation_7_5",
            name: this.$ml
              .get("principals_attestation_7_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_6"),
            translate: "principals_attestation_7_6",
            name: this.$ml
              .get("principals_attestation_7_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_7_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_7"),
            translate: "principals_attestation_7_7",
            name: this.$ml
              .get("principals_attestation_7_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_8"),
            translate: "principals_attestation_7_8",
            name: this.$ml
              .get("principals_attestation_7_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_9"),
            translate: "principals_attestation_7_9",
            name: this.$ml
              .get("principals_attestation_7_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_10"),
            translate: "principals_attestation_7_10",
            name: this.$ml
              .get("principals_attestation_7_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_11"),
            translate: "principals_attestation_7_11",
            name: this.$ml
              .get("principals_attestation_7_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_12"),
            translate: "principals_attestation_7_12",
            name: this.$ml
              .get("principals_attestation_7_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_13"),
            translate: "principals_attestation_7_13",
            name: this.$ml
              .get("principals_attestation_7_13")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_14"),
            translate: "principals_attestation_7_14",
            name: this.$ml
              .get("principals_attestation_7_14")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_15"),
            translate: "principals_attestation_7_15",
            name: this.$ml
              .get("principals_attestation_7_15")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_16"),
            translate: "principals_attestation_7_16",
            name: this.$ml
              .get("principals_attestation_7_16")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_7_17"),
            translate: "principals_attestation_7_17",
            name: this.$ml
              .get("principals_attestation_7_17")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
        ]
      ],
      confirmClaimedCategoryFileListForDirector: [
        [
          {
            label: this.$ml.get("principals_attestation_2_1_1"),
            translate: "principals_attestation_2_1_1",
            name: this.$ml
              .get("principals_attestation_2_1_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_1_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_2"),
            translate: "principals_attestation_2_1_2",
            name: this.$ml
              .get("principals_attestation_2_1_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_3"),
            translate: "principals_attestation_2_1_3",
            name: this.$ml
              .get("principals_attestation_2_1_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_4"),
            translate: "principals_attestation_2_1_4",
            name: this.$ml
              .get("principals_attestation_2_1_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_5"),
            translate: "principals_attestation_2_1_5",
            name: this.$ml
              .get("principals_attestation_2_1_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_6"),
            translate: "principals_attestation_2_1_6",
            name: this.$ml
              .get("principals_attestation_2_1_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_7"),
            translate: "principals_attestation_2_1_7",
            name: this.$ml
              .get("principals_attestation_2_1_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: false
          },
          {
            label: this.$ml.get("principals_attestation_2_1_8"),
            translate: "principals_attestation_2_1_8",
            name: this.$ml
              .get("principals_attestation_2_1_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: false
          },
          {
            label: this.$ml.get("principals_attestation_2_1_9"),
            translate: "principals_attestation_2_1_9",
            name: this.$ml
              .get("principals_attestation_2_1_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: false
          },
          {
            label: this.$ml.get("principals_attestation_2_1_10"),
            translate: "principals_attestation_2_1_10",
            name: this.$ml
              .get("principals_attestation_2_1_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: false
          },
          {
            label: this.$ml.get("principals_attestation_2_1_11"),
            translate: "principals_attestation_2_1_11",
            name: this.$ml
              .get("principals_attestation_2_1_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: false
          },
          {
            label: this.$ml.get("principals_attestation_2_1_12"),
            translate: "principals_attestation_2_1_12",
            name: this.$ml
              .get("principals_attestation_2_1_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_1_2_title"),
            files: [],
            required: false
          },
          {
            label: this.$ml.get("principals_attestation_2_1_13"),
            translate: "principals_attestation_2_1_13",
            name: this.$ml
              .get("principals_attestation_2_1_13")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_14"),
            translate: "principals_attestation_2_1_14",
            name: this.$ml
              .get("principals_attestation_2_1_14")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_15"),
            translate: "principals_attestation_2_1_15",
            name: this.$ml
              .get("principals_attestation_2_1_15")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_16"),
            translate: "principals_attestation_2_1_16",
            name: this.$ml
              .get("principals_attestation_2_1_16")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_17"),
            translate: "principals_attestation_2_1_17",
            name: this.$ml
              .get("principals_attestation_2_1_17")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_18"),
            translate: "principals_attestation_2_1_18",
            name: this.$ml
              .get("principals_attestation_2_1_18")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: false
          },
          {
            label: this.$ml.get("principals_attestation_2_1_19"),
            translate: "principals_attestation_2_1_19",
            name: this.$ml
              .get("principals_attestation_2_1_19")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_1_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_20"),
            translate: "principals_attestation_2_1_20",
            name: this.$ml
              .get("principals_attestation_2_1_20")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_1_4_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_21"),
            translate: "principals_attestation_2_1_21",
            name: this.$ml
              .get("principals_attestation_2_1_21")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_22"),
            translate: "principals_attestation_2_1_22",
            name: this.$ml
              .get("principals_attestation_2_1_22")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_23"),
            translate: "principals_attestation_2_1_23",
            name: this.$ml
              .get("principals_attestation_2_1_23")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_24"),
            translate: "principals_attestation_2_1_24",
            name: this.$ml
              .get("principals_attestation_2_1_24")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
          /*,
          {
            label: this.$ml.get("principals_attestation_2_1_25"),
            translate: "principals_attestation_2_1_25",
            name: this.$ml
              .get("principals_attestation_2_1_25")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_26"),
            translate: "principals_attestation_2_1_26",
            name: this.$ml
              .get("principals_attestation_2_1_26")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_27"),
            translate: "principals_attestation_2_1_27",
            name: this.$ml
              .get("principals_attestation_2_1_27")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_1_28"),
            translate: "principals_attestation_2_1_28",
            name: this.$ml
              .get("principals_attestation_2_1_28")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }*/
        ],
        [
          {
            label: this.$ml.get("principals_attestation_2_2_1"),
            translate: "principals_attestation_2_2_1",
            name: this.$ml
              .get("principals_attestation_2_2_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_2_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_2"),
            translate: "principals_attestation_2_2_2",
            name: this.$ml
              .get("principals_attestation_2_2_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_3"),
            translate: "principals_attestation_2_2_3",
            name: this.$ml
              .get("principals_attestation_2_2_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_4"),
            translate: "principals_attestation_2_2_4",
            name: this.$ml
              .get("principals_attestation_2_2_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_5"),
            translate: "principals_attestation_2_2_5",
            name: this.$ml
              .get("principals_attestation_2_2_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_6"),
            translate: "principals_attestation_2_2_6",
            name: this.$ml
              .get("principals_attestation_2_2_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_7"),
            translate: "principals_attestation_2_2_7",
            name: this.$ml
              .get("principals_attestation_2_2_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_8"),
            translate: "principals_attestation_2_2_8",
            name: this.$ml
              .get("principals_attestation_2_2_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: false
          },
          {
            label: this.$ml.get("principals_attestation_2_2_9"),
            translate: "principals_attestation_2_2_9",
            name: this.$ml
              .get("principals_attestation_2_2_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_10"),
            translate: "principals_attestation_2_2_10",
            name: this.$ml
              .get("principals_attestation_2_2_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            headerTitle: this.$ml.get("principals_attestation_2_2_2_title"),
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_11"),
            translate: "principals_attestation_2_2_11",
            name: this.$ml
              .get("principals_attestation_2_2_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_12"),
            translate: "principals_attestation_2_2_12",
            name: this.$ml
              .get("principals_attestation_2_2_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_13"),
            translate: "principals_attestation_2_2_13",
            name: this.$ml
              .get("principals_attestation_2_2_13")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_2_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_14"),
            translate: "principals_attestation_2_2_14",
            name: this.$ml
              .get("principals_attestation_2_2_14")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_15"),
            translate: "principals_attestation_2_2_15",
            name: this.$ml
              .get("principals_attestation_2_2_15")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_16"),
            translate: "principals_attestation_2_2_16",
            name: this.$ml
              .get("principals_attestation_2_2_16")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_17"),
            translate: "principals_attestation_2_2_17",
            name: this.$ml
              .get("principals_attestation_2_2_17")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_18"),
            translate: "principals_attestation_2_2_18",
            name: this.$ml
              .get("principals_attestation_2_2_18")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_19"),
            translate: "principals_attestation_2_2_19",
            name: this.$ml
              .get("principals_attestation_2_2_19")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_20"),
            translate: "principals_attestation_2_2_20",
            name: this.$ml
              .get("principals_attestation_2_2_20")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_2_4_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_21"),
            translate: "principals_attestation_2_2_21",
            name: this.$ml
              .get("principals_attestation_2_2_21")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_22"),
            translate: "principals_attestation_2_2_22",
            name: this.$ml
              .get("principals_attestation_2_2_22")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_2_5_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_23"),
            translate: "principals_attestation_2_2_23",
            name: this.$ml
              .get("principals_attestation_2_2_23")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_24"),
            translate: "principals_attestation_2_2_24",
            name: this.$ml
              .get("principals_attestation_2_2_24")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_25"),
            translate: "principals_attestation_2_2_25",
            name: this.$ml
              .get("principals_attestation_2_2_25")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_26"),
            translate: "principals_attestation_2_2_26",
            name: this.$ml
              .get("principals_attestation_2_2_26")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
          /*,
          {
            label: this.$ml.get("principals_attestation_2_2_27"),
            translate: "principals_attestation_2_2_27",
            name: this.$ml
              .get("principals_attestation_2_2_27")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_28"),
            translate: "principals_attestation_2_2_28",
            name: this.$ml
              .get("principals_attestation_2_2_28")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_2_29"),
            translate: "principals_attestation_2_2_29",
            name: this.$ml
              .get("principals_attestation_2_2_29")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }*/
        ],
        [
          {
            label: this.$ml.get("principals_attestation_2_3_1"),
            translate: "principals_attestation_2_3_1",
            name: this.$ml
              .get("principals_attestation_2_3_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_3_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_2"),
            translate: "principals_attestation_2_3_2",
            name: this.$ml
              .get("principals_attestation_2_3_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_3"),
            translate: "principals_attestation_2_3_3",
            name: this.$ml
              .get("principals_attestation_2_3_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_4"),
            translate: "principals_attestation_2_3_4",
            name: this.$ml
              .get("principals_attestation_2_3_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_5"),
            translate: "principals_attestation_2_3_5",
            name: this.$ml
              .get("principals_attestation_2_3_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_6"),
            translate: "principals_attestation_2_3_6",
            name: this.$ml
              .get("principals_attestation_2_3_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_3_2_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_7"),
            translate: "principals_attestation_2_3_7",
            name: this.$ml
              .get("principals_attestation_2_3_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_8"),
            translate: "principals_attestation_2_3_8",
            name: this.$ml
              .get("principals_attestation_2_3_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_9"),
            translate: "principals_attestation_2_3_9",
            name: this.$ml
              .get("principals_attestation_2_3_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_10"),
            translate: "principals_attestation_2_3_10",
            name: this.$ml
              .get("principals_attestation_2_3_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_11"),
            translate: "principals_attestation_2_3_11",
            name: this.$ml
              .get("principals_attestation_2_3_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_12"),
            translate: "principals_attestation_2_3_12",
            name: this.$ml
              .get("principals_attestation_2_3_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_13"),
            translate: "principals_attestation_2_3_13",
            name: this.$ml
              .get("principals_attestation_2_3_13")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_14"),
            translate: "principals_attestation_2_3_14",
            name: this.$ml
              .get("principals_attestation_2_3_14")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_3_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_15"),
            translate: "principals_attestation_2_3_15",
            name: this.$ml
              .get("principals_attestation_2_3_15")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_3_4_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_16"),
            translate: "principals_attestation_2_3_16",
            name: this.$ml
              .get("principals_attestation_2_3_16")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
          /*,
          {
            label: this.$ml.get("principals_attestation_2_3_17"),
            translate: "principals_attestation_2_3_17",
            name: this.$ml
              .get("principals_attestation_2_3_17")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_3_18"),
            translate: "principals_attestation_2_3_18",
            name: this.$ml
              .get("principals_attestation_2_3_18")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }*/
        ],
        [
          {
            label: this.$ml.get("principals_attestation_2_4_1"),
            translate: "principals_attestation_2_4_1",
            name: this.$ml
              .get("principals_attestation_2_4_1")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_4_1_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_2"),
            translate: "principals_attestation_2_4_2",
            name: this.$ml
              .get("principals_attestation_2_4_2")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_3"),
            translate: "principals_attestation_2_4_3",
            name: this.$ml
              .get("principals_attestation_2_4_3")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_4"),
            translate: "principals_attestation_2_4_4",
            name: this.$ml
              .get("principals_attestation_2_4_4")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_5"),
            translate: "principals_attestation_2_4_5",
            name: this.$ml
              .get("principals_attestation_2_4_5")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_6"),
            translate: "principals_attestation_2_4_6",
            name: this.$ml
              .get("principals_attestation_2_4_6")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_7"),
            translate: "principals_attestation_2_4_7",
            name: this.$ml
              .get("principals_attestation_2_4_7")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_8"),
            translate: "principals_attestation_2_4_8",
            name: this.$ml
              .get("principals_attestation_2_4_8")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_4_2_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_9"),
            translate: "principals_attestation_2_4_9",
            name: this.$ml
              .get("principals_attestation_2_4_9")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_10"),
            translate: "principals_attestation_2_4_10",
            name: this.$ml
              .get("principals_attestation_2_4_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_11"),
            translate: "principals_attestation_2_4_11",
            name: this.$ml
              .get("principals_attestation_2_4_11")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_12"),
            translate: "principals_attestation_2_4_12",
            name: this.$ml
              .get("principals_attestation_2_4_12")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_13"),
            translate: "principals_attestation_2_4_13",
            name: this.$ml
              .get("principals_attestation_2_4_13")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_4_3_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_14"),
            translate: "principals_attestation_2_4_14",
            name: this.$ml
              .get("principals_attestation_2_4_14")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_15"),
            translate: "principals_attestation_2_4_15",
            name: this.$ml
              .get("principals_attestation_2_4_15")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_16"),
            translate: "principals_attestation_2_4_16",
            name: this.$ml
              .get("principals_attestation_2_4_16")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_17"),
            translate: "principals_attestation_2_4_17",
            name: this.$ml
              .get("principals_attestation_2_4_17")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_18"),
            translate: "principals_attestation_2_4_18",
            name: this.$ml
              .get("principals_attestation_2_4_18")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_19"),
            translate: "principals_attestation_2_4_19",
            name: this.$ml
              .get("principals_attestation_2_4_19")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_20"),
            translate: "principals_attestation_2_4_20",
            name: this.$ml
              .get("principals_attestation_2_4_20")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_21"),
            translate: "principals_attestation_2_4_21",
            name: this.$ml
              .get("principals_attestation_2_4_21")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_4_4_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_22"),
            translate: "principals_attestation_2_4_22",
            name: this.$ml
              .get("principals_attestation_2_4_22")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_23"),
            translate: "principals_attestation_2_4_23",
            name: this.$ml
              .get("principals_attestation_2_4_23")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_4_5_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_24"),
            translate: "principals_attestation_2_4_24",
            name: this.$ml
              .get("principals_attestation_2_4_24")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_25"),
            translate: "principals_attestation_2_4_25",
            name: this.$ml
              .get("principals_attestation_2_4_25")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_26"),
            translate: "principals_attestation_2_4_26",
            name: this.$ml
              .get("principals_attestation_2_4_26")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }
          /*,
          {
            label: this.$ml.get("principals_attestation_2_4_27"),
            translate: "principals_attestation_2_4_27",
            name: this.$ml
              .get("principals_attestation_2_4_27")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_28"),
            translate: "principals_attestation_2_4_28",
            name: this.$ml
              .get("principals_attestation_2_4_28")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_29"),
            translate: "principals_attestation_2_4_29",
            name: this.$ml
              .get("principals_attestation_2_4_29")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_4_6_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_30"),
            translate: "principals_attestation_2_4_30",
            name: this.$ml
              .get("principals_attestation_2_4_30")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_4_7_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_31"),
            translate: "principals_attestation_2_4_31",
            name: this.$ml
              .get("principals_attestation_2_4_31")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_32"),
            translate: "principals_attestation_2_4_32",
            name: this.$ml
              .get("principals_attestation_2_4_32")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_33"),
            translate: "principals_attestation_2_4_33",
            name: this.$ml
              .get("principals_attestation_2_4_33")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            headerTitle: this.$ml.get("principals_attestation_2_4_8_title"),
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_34"),
            translate: "principals_attestation_2_4_34",
            name: this.$ml
              .get("principals_attestation_2_4_34")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_35"),
            translate: "principals_attestation_2_4_35",
            name: this.$ml
              .get("principals_attestation_2_4_35")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_36"),
            translate: "principals_attestation_2_4_36",
            name: this.$ml
              .get("principals_attestation_2_4_36")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_37"),
            translate: "principals_attestation_2_4_37",
            name: this.$ml
              .get("principals_attestation_2_4_37")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_38"),
            translate: "principals_attestation_2_4_38",
            name: this.$ml
              .get("principals_attestation_2_4_38")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_39"),
            translate: "principals_attestation_2_4_39",
            name: this.$ml
              .get("principals_attestation_2_4_39")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          },
          {
            label: this.$ml.get("principals_attestation_2_4_40"),
            translate: "principals_attestation_2_4_40",
            name: this.$ml
              .get("principals_attestation_2_4_40")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            placeholder: "",
            files: [],
            required: true
          }*/
        ]
      ],
      fieldsCheckingMap: {
        claimedCategory: null,
        performanceIndicator: null
      },
      methodistDocs: [
        {
          label: this.$ml.get("principals_attestation_methodist_1"),
          translate: "principals_attestation_methodist_1",
          name: this.$ml
            .get("principals_attestation_methodist_1")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          headerTitle: this.$ml.get("principals_attestation_methodist_title"),
          files: [],
          required: true
        },
        {
          label: this.$ml.get("principals_attestation_methodist_2"),
          translate: "principals_attestation_methodist_2",
          name: this.$ml
            .get("principals_attestation_methodist_2")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("principals_attestation_methodist_3"),
          translate: "principals_attestation_methodist_3",
          name: this.$ml
            .get("principals_attestation_methodist_3")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("principals_attestation_methodist_4"),
          translate: "principals_attestation_methodist_4",
          name: this.$ml
            .get("principals_attestation_methodist_4")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("principals_attestation_methodist_5"),
          translate: "principals_attestation_methodist_5",
          name: this.$ml
            .get("principals_attestation_methodist_5")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("principals_attestation_methodist_6"),
          translate: "principals_attestation_methodist_6",
          name: this.$ml
            .get("principals_attestation_methodist_6")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("principals_attestation_methodist_7"),
          translate: "principals_attestation_methodist_7",
          name: this.$ml
            .get("principals_attestation_methodist_7")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("principals_attestation_methodist_8"),
          translate: "principals_attestation_methodist_8",
          name: this.$ml
            .get("principals_attestation_methodist_8")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("principals_attestation_methodist_9"),
          translate: "principals_attestation_methodist_9",
          name: this.$ml
            .get("principals_attestation_methodist_9")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("principals_attestation_methodist_10"),
          translate: "principals_attestation_methodist_10",
          name: this.$ml
              .get("principals_attestation_methodist_10")
              .replaceAll(" ", "_")
              .replaceAll('"', "")
              .replaceAll("(", "")
              .replaceAll(")", "")
              .replaceAll(".", "")
              .replaceAll(",", "")
              .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters("services", { declarerTypes: "getDeclarerTypes" })
  },
  watch: {
    attestationTable: {
      handler: function(val, oldVal) {
        val.filter(function(p, idx) {
          return Object.keys(p).some(function(prop) {
            return p[prop] !== oldVal[idx][prop];
          });
        });
        this.handleAttestationTableChange();
      },
      deep: true
    },

    categoryTable: {
      handler: function(val, oldVal) {
        val.filter(function(p, idx) {
          return Object.keys(p).some(function(prop) {
            return p[prop] !== oldVal[idx][prop];
          });
        });
        this.handleCategoryTableChange();
      },
      deep: true
    },

    proTable: {
      handler: function(val, oldVal) {
        val.filter(function(p, idx) {
          return Object.keys(p).some(function(prop) {
            return p[prop] !== oldVal[idx][prop];
          });
        });
        this.handleProTableChange();
      },
      deep: true
    },

    /*"localValue.testDocsFiles": {
      handler: function() {
        this.checkAdditionalFilesFilled();
      },
      deep: true
    },*/

    "localValue.gradeDocsFiles": {
      handler: function() {
        this.checkAdditionalFilesFilled();
      },
      deep: true
    },
    "localValue.usersData": {
      handler: function() {
        this.checkAdditionalFilesFilled();
      },
      deep: true
    },
    nationalExamDate(value) {
      this.localValue.usersData.nationalExamDate = moment(value).format(
        "YYYY-MM-DD"
      );
    }
  },
  methods: {
    isEmpty(str) {
      return !str || 0 === str.length;
    },
    checkAdditionalFilesFilled() {
      let testDocsFilled = true;

      /*let gradeDocsFilled = this.localValue.gradeDocsFiles[0].files.length > 0;*/

      /*let markStudyFilled =
        this.selectedDeclarerTypeId === 2
          ? this.localValue.markStudyFiles.every(row => row.files.length > 0)
          : true;*/

      if (testDocsFilled) {
        if (
          this.localValue.usersData.declarerType === 1 ||
          this.localValue.usersData.declarerType === 2
        ) {
          let addFilesFilledAll = [],
            addFilesFilled = false;
          if (
            this.localValue.usersData.performanceIndicator ||
            this.selectedEmplymentTypeId === 6
          ) {
            this.localValue.confirmClaimedCategoryFiles
              .filter(e => e.required)
              .forEach(v => {
                addFilesFilledAll.push(v.files.length > 0);
              });
            addFilesFilled = !addFilesFilledAll.includes(false);
          }
          let isPlaceholderFilled = this.localValue.confirmClaimedCategoryFiles
            .filter(e => e.required)
            .every(e => e.placeholder.length > 0);
          if (addFilesFilled && isPlaceholderFilled) {
            this.checkTablesFilled();
            this.$emit("additionalFilesFilled", true);
          } else {
            this.$emit("additionalFilesFilled", false);
          }
        } else {
          this.$emit("additionalFilesFilled", true);
        }
      } else {
        this.$emit("additionalFilesFilled", false);
      }
    },
    checkTablesFilled() {
      /*let attestationTableFilled =
        this.attestationTable.length > 0 &&
        this.attestationTable.every(
          row =>
            !this.isEmpty(row.assignDate) &&
            !this.isEmpty(row.certNumber) &&
            !this.isEmpty(row.course) &&
            !this.isEmpty(row.spot) &&
            row.cert.attachedFile.files.length > 0
        );

      if (this.selectedDeclarerTypeId === 2) {
        attestationTableFilled = true;
      }*/

      /*let categoryTableFilled =
        this.categoryTable.length > 0 &&
        this.categoryTable.every(row => {
          if (/!* this.selectedDeclarerTypeId === 1 *!/ false) {
            return (
              !this.isEmpty(row.assignDate) &&
              !this.isEmpty(row.certNumber) &&
              !this.isEmpty(row.category) &&
              !this.isEmpty(row.spot) &&
              row.cert.attachedFile.files.length > 0
            );
          } else {
            return row.cert.attachedFile.files.length > 0;
          }
        });*/

      this.$emit("tablesfilled", true);
    },

    handleAttestationTableChange() {
      this.localValue.usersData.tablesData.attestationTable = this.attestationTable;
      this.checkTablesFilled();
    },

    handleCategoryTableChange() {
      this.localValue.usersData.tablesData.categoryTable = this.categoryTable;
      this.checkTablesFilled();
    },

    handleProTableChange() {
      this.localValue.usersData.tablesData.proTable = this.proTable;
      this.checkTablesFilled();
    },

    attestationTableAddRow() {
      this.attestationTable.push({
        assignDate: new Date(),
        certNumber: 1,
        course: "",
        spot: "",
        cert: {
          attachedFile: {
            files: [],
            label: "Приложите файл",
            name: "file_1",
            uploaded: false,
            filename: "",
            size: 0,
            id: 0,
            hash: ""
          }
        }
      });
    },

    categoryTableAddRow() {
      this.categoryTable.push({
        assignDate: new Date(),
        certNumber: 1,
        category: "",
        spot: "",
        cert: {
          attachedFile: {
            files: [],
            label: "Приложите файл",
            name: "file_1",
            uploaded: false,
            filename: "",
            size: 0,
            id: 0,
            hash: ""
          }
        }
      });
    },

    proTableAddRow() {
      this.proTable.push({
        date: new Date(),
        type: 0,
        level: "",
        form: "",
        cert: {
          attachedFile: {
            files: [],
            label: "Приложите файл",
            name: "file_1",
            uploaded: false,
            filename: "",
            size: 0,
            id: 0,
            hash: ""
          }
        }
      });
    },

    attestationTableRemoveRow(index) {
      this.attestationTable.splice(index, 1);
    },
    categoryTableRemoveRow(index) {
      this.categoryTable.splice(index, 1);
    },
    proTableRemoveRow(index) {
      this.proTable.splice(index, 1);
    },
    selectPerformanceIndicator(id) {
      if (this.localValue.usersData.declarerType === 1) {
        this.localValue.usersData.performanceIndicator = id;
        this.$set(
          this.localValue,
          "confirmClaimedCategoryFiles",
          this.confirmClaimedCategoryFileList[id - 1]
        );
      } else {
        this.localValue.usersData.performanceIndicator = id;
        this.$set(
          this.localValue,
          "confirmClaimedCategoryFiles",
          this.confirmClaimedCategoryFileListForDirector[id - 1]
        );
      }
    }
  },
  beforeMount() {
    this.deactivateStep(this.formStep);

    this.$set(this.localValue, "usersData", {
      iin: "",
      email: "",
      phone: "",

      jobPlace: "",
      deputy: "",
      category: "",
      declarerType: "",
      employmentType: "",
      claimedCategory: "",
      performanceIndicator: "",
      categoryExpirationDate: "",
      nationalExamDate: "",

      studying: "",

      experience: {
        total: "",
        pedagogical: "",
        atThisPosition: ""
      },

      tablesData: {
        attestationTable: this.attestationTable,
        categoryTable: this.categoryTable,
        proTable: this.proTable
      },

      grades: ""
    });

    this.selectedDeclarerTypeId = parseInt(
      this.$route.params.declarertype ? this.$route.params.declarertype : 0
    );
    this.selectedEmplymentTypeId = parseInt(
      this.$route.params.employmenttype ? this.$route.params.employmenttype : 0
    );
    this.selectedAreaId = parseInt(
      this.$route.params.area ? this.$route.params.area : 0
    );
    const declarerTypeExists = this.declarerTypes(this.slug)
      .map(type => type.number)
      .includes(this.selectedDeclarerTypeId);
    if (!declarerTypeExists) {
      return this.$router.push({ name: "service.main" });
    }
    const type = this.declarerTypes(this.slug).find(
      type => type.number === this.selectedDeclarerTypeId
    );
    const declarerTypeAvailableForClaim = type ? type.availableForClaim : false;
    if (!declarerTypeAvailableForClaim) {
      return this.$router.push({ name: "service.main" });
    }

    this.localValue.usersData.declarerType = this.selectedDeclarerTypeId;
    this.localValue.usersData.employmentType = this.selectedEmplymentTypeId;
    this.localValue.usersData.area = this.selectedAreaId;

    /*this.$set(this.localValue, "testDocsFiles", [
      {
        label:
          "Документ о прохождении национального квалификационного тестирования",
        translate: "national_testing_doc",
        name: "Справка_НКТ",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      }
    ]);*/

    this.$set(this.localValue, "gradeDocsFiles", [
      {
        label: "Копия приказа о ранее присвоенной квалификационной категории",
        translate: "qualification_grade_doc",
        name: "Копия_приказа_о_ранее_присвоенной_квалификационной_категории",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      }
    ]);

    this.setRequiredFiles([
      {
        label: "Заявление",
        translate:
          this.localValue.usersData.declarerType === 2
            ? "statement_of_certified_2"
            : "statement_of_certified_1",
        name: "Заявление",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      },
      /*{
        label: "Копия удостоверения личности",
        translate: "copy_passport",
        name: "Удостоверение",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      },*/
      {
        label: "Копия диплома об образовании",
        translate: "copy_diploma",
        name: "Диплом",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      },
      {
        label:
          "Копия документа, подтверждающего трудовую деятельность работника",
        translate: "copy_work_doc",
        name: "Трудовая_Деятельность",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      }
    ]);

    if (this.selectedEmplymentTypeId === 6) {
      this.$set(
        this.localValue,
        "confirmClaimedCategoryFiles",
        this.methodistDocs
      );
      this.$watch(
        "localValue.confirmClaimedCategoryFiles",
        () => {
          this.checkAdditionalFilesFilled();
        },
        { deep: true }
      );
    } else if (
      this.localValue.usersData.declarerType === 1 &&
      this.selectedEmplymentTypeId !== 6
    ) {
      this.$set(this.localValue, "confirmClaimedCategoryFiles", []);

      this.$watch(
        "localValue.confirmClaimedCategoryFiles",
        () => {
          this.checkAdditionalFilesFilled();
        },
        { deep: true }
      );
    } else if (
      this.localValue.usersData.declarerType === 2 &&
      this.selectedEmplymentTypeId !== 6
    ) {
      let markStudyFiles = [
        {
          label: this.$ml.get("effectivness_of_head"),
          translate: "effectivness_of_head",
          name: this.$ml.get("study_mark"),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }
      ];

      if ([1, 2, 3, 4, 5].includes(this.localValue.usersData.employmentType)) {
        markStudyFiles = markStudyFiles.concat([
          {
            label: this.$ml.get("effectivness_of_quality_study"),
            translate: "effectivness_of_quality_study",
            name: this.$ml.get("study_mark"),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            files: []
          },
          {
            label: this.$ml.get("effectivness_of_evolution_innovation"),
            translate: "effectivness_of_evolution_innovation",
            name: this.$ml.get("study_mark"),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            files: []
          },
          {
            label: this.$ml.get("effectivness_of_technical"),
            translate: "effectivness_of_technical",
            name: this.$ml.get("study_mark"),
            only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
            files: []
          }
        ]);
      }

      this.$set(this.localValue, "markStudyFiles", markStudyFiles);
      this.$watch(
        "localValue.markStudyFiles",
        () => {
          this.checkAdditionalFilesFilled();
        },
        { deep: true }
      );
      this.$set(this.localValue, "confirmClaimedCategoryFiles", []);

      this.$watch(
        "localValue.confirmClaimedCategoryFiles",
        () => {
          this.checkAdditionalFilesFilled();
        },
        { deep: true }
      );
    }
  }
};
</script>

<style lang="scss">
.attestation-table,
.category-table,
.pro-table,
.pro-2-table {
  width: 100%;

  input,
  select {
    border: 1px solid #dbdbdb;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
  }

  td {
    vertical-align: top;
  }

  tr {
    &:nth-child(2n) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .add-row,
  .remove-row {
    padding: 1px 8px;
    vertical-align: top;
    display: inline-block;
    margin-right: 7px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50px;
    background: #cad9ff;
  }

  .remove-row {
    padding: 1px 11px !important;
  }

  .file-upload-input-label {
    display: none !important;
  }
}

.table-input-label {
  font-weight: bold;
  margin: 0 5px;
}
</style>
