import { mapGetters } from "vuex";
import {
  getNameFromUserInfo,
  getAddressFromUserInfo,
  displayError,
  getErrorsText
} from "../services/helper";
import debounce from "lodash.debounce";

export const CommonFormMixin = {
  props: {
    id: {
      type: Number,
      default: null,
      required: false
    },
    apiResource: {
      type: Object,
      required: false
    },
    value: {
      type: Object
    },
    service: {
      type: Object
    }
  },
  computed: {
    childsIinRules() {
      const rules = [
        "required",
        "iin_control",
        "unique_active_childs_iin:" + this.service.slug
      ];

      return rules.join("|");
    },
    usersIinRules() {
      const uniqueExceptions = [
        "homeschooling",
        "special-needs-social-services"
      ];
      let rules = ["required", "iin_control"];
      let slug = this.service ? this.service.slug : null;

      if (slug && uniqueExceptions.indexOf(this.service.slug) === -1) {
        /*у учителей своя валидация на беке*/
        let validation = [
          "teachers-attestation",
          "principals-attestation"
        ].includes(this.service.slug)
          ? "unique_active_teachers_iin:"
          : "unique_active_users_iin:";

        rules.push(validation + this.service.slug);
      }
      return rules.join("|");
    },
    ...mapGetters({
      usersProfile: "user/getUserProfile"
    }),
    areas() {
      return this.service.areas.map(item => {
        return item.name;
      });
    }
  },
  data() {
    return {
      childsUserInfo: {},
      childrenInfo: {},
      localValue: {},
      pmpksOptions: [],
      userInfoApi: this.$apiResource.make("PersonalIdentity"),
      checkingApi: this.$apiResource.make("Checking"),
      pmpkApi: this.$apiResource.make("Pmpks"),
      disabilityCategoryApi: this.$apiResource.make("DisabilityCategories")
    };
  },
  methods: {
    getUserInfo(iin) {
      return this.userInfoApi.getUserInfo(iin);
    },
    checkChildIin(data) {
      this.checkingApi.check(data).then(response => {
        if (!response.data.success) {
          this.$emit("onInvalid");
          displayError(this, response.data.message);
        }
      });
    },
    childsIinChanged() {
      this.$validator
        .validate("localValue.childsData.iin")
        .then(validationResult => {
          if (validationResult) {
            this.getUserInfo(this.localValue.childsData.iin)
              .then(response => {
                this.childsUserInfo = response.data;
              })
              .catch(error => {
                displayError(this, getErrorsText(error));
              });
            this.checkChildIin({
              slug: this.service.slug,
              iin: this.localValue.childsData.iin
            });
          } else {
            this.childsUserInfo = {};
          }
        });
    },
    iinChanged(uniqueId) {
      this.$validator
        .validate("localValue.children[" + uniqueId + "].iin")
        .then(validationResult => {
          if (validationResult) {
            const child = this.localValue.children.find(
              child => child.uniqueId === uniqueId
            );
            this.getUserInfo(child.iin)
              .then(response => {
                const userInfo = response.data;
                this.childrenInfo[uniqueId] = userInfo;
                this.$nextTick(() => {
                  this.childrenInfo[uniqueId] = userInfo;
                });
              })
              .catch(error => {
                displayError(this, getErrorsText(error));
              });

            this.checkChildIin({
              slug: this.service.slug,
              iin: child.iin
            });
          } else {
            this.childrenInfo[uniqueId] = {};
          }
        });
    },
    addChildren() {
      const newChild = {
        iin: "",
        uniqueId: new Date().getTime()
      };

      this.$set(this.childrenInfo, newChild.uniqueId, {});
      this.localValue.children.push(newChild);

      this.$nextTick(() => {
        this.validateForm();
      });
    },
    removeChildren(uniqueId) {
      this.localValue.children = this.localValue.children.filter(item => {
        return item.uniqueId !== uniqueId;
      });

      this.$nextTick(() => {
        this.validateForm();
      });
    },
    validateForm() {
      this.$validator.validateAll().then(isFormValid => {
        if (isFormValid) {
          this.$emit("onValidate", this.localValue);
        } else {
          this.$emit("onInvalid");
        }
      });
    },
    setPmpksOptions() {
      this.pmpkApi.list().then(response => {
        this.pmpksOptions = response.data.map(item => {
          return {
            text: item.name,
            id: item.id
          };
        });
      });
    },
    getNameFromUserInfo,
    getAddressFromUserInfo,
    displayError,
    getErrorsText
  },
  watch: {
    localValue: {
      handler: debounce(function() {
        this.validateForm();
      }, 3000),
      deep: true
    },
    usersProfile(value) {
      this.localValue.usersData.iin = value.iin;
    }
  },
  created() {
    this.localValue = this.value;
    this.localValue.usersData.iin = this.usersProfile.iin;
  }
};
