class Validation {
  constructor(axios) {
    this.axios = axios;
  }

  getMainRoute() {
    return "/api/validation";
  }

  uniqueActiveChildsIin(iin, slug) {
    return this.axios.post(this.getMainRoute() + "/unique_active_childs_iin", {
      iin,
      slug
    });
  }

  uniqueActiveUsersIin(iin, slug) {
    return this.axios.post(this.getMainRoute() + "/unique_active_users_iin", {
      iin,
      slug
    });
  }

  uniqueActiveTeachersIin(iin, slug) {
    return this.axios.post(
      this.getMainRoute() + "/unique_active_teachers_iin",
      {
        iin,
        slug
      }
    );
  }

  uniqueActiveSpaTreatmentIin(iin, slug) {
    return this.axios.post(
      this.getMainRoute() + "/unique_active_spa_treatment_iin",
      {
        iin,
        slug
      }
    );
  }
}

export default Validation;
