<template>
  <table v-if="items.length > 0" class="table is-striped is-fullwidth">
    <thead>
      <tr>
        <th>{{ $ml.get("numb_claim") }}</th>
        <th v-if="isSpecialNeeds(slug)">Рег. номер ПМПК</th>
        <th>
          {{
            childLabels(slug)
              ? $ml.get("fio_claim_child")
              : $ml.get("fio_claim_man")
          }}
        </th>
        <th>
          {{
            childLabels(slug)
              ? $ml.get("iin_claim_child")
              : $ml.get("iin_claim_man")
          }}
        </th>
        <!--<th>ФИО {{ getFieldNameForManagerTable(slug) }}</th>-->
        <!--<th>ИИН {{ getFieldNameForManagerTable(slug) }}</th>-->
        <th>{{ $ml.get("status") }}</th>
        <th>{{ $ml.get("date_claim") }}</th>
        <th v-if="isShowArea">{{ $ml.get("area") }}</th>
        <th v-if="isShowEmployment">{{ $ml.get("employment") }}</th>
        <th>{{ $ml.get("actions") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="'table_item_' + itemIndex" v-for="(item, itemIndex) in items">
        <td>{{ item.claim_number }}</td>
        <td v-if="isSpecialNeeds(slug)">{{ getRegistrationNumbPmpk(item) }}</td>
        <td>{{ getNameFromUserInfo(getNameForManagerTable(slug, item)) }}</td>
        <td>{{ getIinForManagerTable(slug, item) }}</td>
        <td>
          <span class="tag" :class="statusesClasses[item.status.code].class">{{
            getStatusName(
              item.status,
              item.service.slug,
              $ml.get(statusesClasses[item.status.code].translate)
            )
          }}</span>
        </td>
        <td class="table-td-dates">
          <span
            class="no-break"
            :title="item.created_at | moment('DD.MM.YYYY HH:mm')"
            >{{ item.created_at | moment("D MMMM, YYYY") }}</span
          ><br />
          <small class="no-break has-text-steel">{{
            item.created_at | momentFrom
          }}</small>
        </td>
        <td v-if="isShowArea">
          {{
            item.claim_data.usersData.area !== 0
              ? $ml.get(`area_${item.claim_data.usersData.area}`)
              : ""
          }}
        </td>
        <td v-if="isShowEmployment">
          {{
            item.claim_data.usersData.employmentType !== 0
              ? $ml.get(
                  `employment_type_${item.claim_data.usersData.employmentType}`
                )
              : ""
          }}
        </td>
        <td>
          <router-link
            class="button is-info is-small"
            :to="{ name: routeName, params: { id: item.id, slug: slug } }"
          >
            {{ $ml.get("view") }}
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import StatusesClasses from "../../../configs/claim-statuses";
import {
  getStatusName,
  getNameFromUserInfo,
  getFieldNameForManagerTable,
  getNameForManagerTable,
  getIinForManagerTable,
  isSpecialNeeds,
  getRegistrationNumbPmpk,
  childLabels
} from "../../../services/helper";

export default {
  props: ["items", "routeName", "slug", "declarerType"],
  name: "ManagerTable",
  data() {
    return {
      statusesClasses: StatusesClasses
    };
  },
  methods: {
    getStatusName,
    getNameFromUserInfo,
    getFieldNameForManagerTable,
    getNameForManagerTable,
    getIinForManagerTable,
    isSpecialNeeds,
    getRegistrationNumbPmpk,
    childLabels
  },
  computed: {
    isShowArea() {
      return (
        (this.slug === "teachers-attestation" &&
          [4, 5, 7, 8, 9].includes(this.declarerType)) ||
        this.slug === "principals-attestation"
      );
    },
    isShowEmployment() {
      return this.slug == "principals-attestation";
    }
  }
};
</script>
