<template>
  <section class="section">
    <div class="pageloader" :class="{ 'is-active': inProgress }">
      <span class="title">Поиск...</span>
    </div>

    <h2 class="service subtitle">{{ $ml.get("spec_schools.places_base") }}</h2>

    <form @submit.prevent="updateItems()">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <b-field :label="translate('special_school_name')">
              <input
                v-model="requestParams.school_name"
                :placeholder="translate('enter_title_')"
                type="text"
                class="input"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('category')">
              <BuefySimpleSelect
                :options="disabilityCategoriesOptions()"
                v-model="requestParams.category_id"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('class')">
              <BuefySimpleSelect
                :options="classesOptions()"
                v-model="requestParams.class_id"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('instruction_language')">
              <BuefySimpleSelect
                :options="instructionLanguagesOptions()"
                v-model="requestParams.instruction_language_id"
              />
            </b-field>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <b-field :label="translate('date_from')">
              <b-datepicker
                icon-pack="far"
                :month-names="getMonthNames"
                :day-names="getDayNames"
                @input="dateChanged()"
                v-model="requestParams.created_at_start"
                :placeholder="translate('press_to_choose_date') + '...'"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('date_to')">
              <b-datepicker
                icon-pack="far"
                :month-names="getMonthNames"
                :day-names="getDayNames"
                @input="dateChanged()"
                v-model="requestParams.created_at_end"
                :placeholder="translate('press_to_choose_date') + '...'"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('user')">
              <BuefySimpleSelect
                :options="usersOptions()"
                v-model="requestParams.user_id"
              />
            </b-field>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <button class="button is-success" type="submit">
            {{ translate("search_short") }}
          </button>
          <button
            class="button is-light m-0-0-0-10"
            type="button"
            @click="clearFilters"
          >
            {{ translate("to_clear") }}
          </button>

          <router-link
            v-if="hasPermission('ADD_PLACE_TO_SPECIAL_ORG')"
            class="button is-success m-0-0-0-10"
            :to="{ name: 'special-schools-places.create' }"
            style="margin-bottom: 20px"
            >{{ translate("add_place") }}</router-link
          >
          <span
            v-else
            class="button is-disabled m-0-0-0-10"
            style="margin-bottom: 20px"
            >{{ translate("add_place") }}</span
          >
        </div>
      </div>
    </form>

    <table v-if="items.length > 0" class="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>{{ translate("special_school_name") }}</th>
          <th>{{ translate("category") }}</th>
          <th>{{ translate("class") }}</th>
          <th>{{ translate("instruction_language") }}</th>
          <th>{{ translate("added_places") }}</th>
          <th>{{ translate("count_free_places") }}</th>
          <th>{{ translate("user") }}</th>
          <th>{{ translate("add_place_date") }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr :key="'table_item_' + itemIndex" v-for="(item, itemIndex) in items">
          <td>{{ translate(item.special_school.name) }}</td>
          <td>{{ translate(item.disability_category.name) }}</td>
          <td>{{ translate(item.class.name) }}</td>
          <td>{{ translate(item.instruction_language.name) }}</td>
          <td>{{ item.count_places }}</td>
          <td>{{ item.count_free_places }}</td>
          <td>{{ translate(getUserName(item.user)) }}</td>
          <td>{{ moment(item.created_at, "DD-MM-YYYY") }}</td>
          <td>
            <button
              v-if="hasPermission('REMOVE_PLACE_TO_SPECIAL_ORG')"
              class="button is-success"
              @click="removePlace(item)"
            >
              {{ translate("to_delete") }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <VPagination
      @change="changePage"
      :icon-pack="'far'"
      :total="pagination.total"
      :current="pagination.current_page"
      :per-page="requestParams.per_page"
    />
  </section>
</template>

<script>
import { PermissionsMixin } from "@/mixins/permissions-mixin";
import moment from "@/services/moment-instance.js";
import { DatepickerMixin } from "@/mixins/datepicker-mixin";
import { TranslateMixin } from "@/mixins/translate-mixin";

export default {
  name: "SpecialSchoolsPlacesList",
  mixins: [PermissionsMixin, DatepickerMixin, TranslateMixin],
  data() {
    return {
      inProgress: false,
      items: [],
      specialSchoolsPlacesApi: this.$apiResource.make("SpecialSchoolsPlaces"),
      languagesApi: this.$apiResource.make("InstructionLanguages"),
      disabilityCategoryApi: this.$apiResource.make("DisabilityCategories"),
      classesApi: this.$apiResource.make("InstructionClasses"),
      usersApi: this.$apiResource.make("Users"),
      instructionLanguages: [],
      disabilityCategories: [],
      classes: [],
      users: [],
      requestDefaultParams: {
        school_name: null,
        category_id: null,
        class_id: null,
        instruction_language_id: null,
        created_at_start: null,
        created_at_end: null,
        user_id: null,
        per_page: 10,
        page: 1
      },
      requestParams: {},
      pagination: {
        current_page: null,
        from: null,
        last_page: null,
        to: null,
        total: null
      }
    };
  },
  methods: {
    disabilityCategoriesOptions() {
      return this.disabilityCategories.map(v => {
        return { id: v.id, text: this.$ml.get(v.name) };
      });
    },
    classesOptions() {
      return this.classes.map(v => {
        return { id: v.id, text: this.translate(v.name) };
      });
    },
    instructionLanguagesOptions() {
      return this.instructionLanguages.map(v => {
        return { id: v.id, text: this.translate(v.name) };
      });
    },
    usersOptions() {
      return this.users.map(v => {
        return { id: v.id, text: v.profile.full_name };
      });
    },
    moment(date, format) {
      return moment(date).format(format);
    },
    getUserName(user) {
      if (user && user.profile && user.profile.full_name) {
        return user.profile.full_name;
      }
      return "";
    },
    removePlace(item) {
      if (confirm("Вы уверены что хотите удалить место?")) {
        this.specialSchoolsPlacesApi.destroy(item.id).then(() => {
          this.updateItems();
        });
      }
    },
    updateItems() {
      this.specialSchoolsPlacesApi.list(this.requestParams).then(response => {
        this.items = response.data.data;

        ["current_page", "from", "last_page", "to", "total"].forEach(name => {
          this.pagination[name] = response.data[name];
        });
      });
    },
    clearFilters() {
      this.requestParams = JSON.parse(
        JSON.stringify(this.requestDefaultParams)
      );
    },
    changePage(num) {
      this.requestParams.page = num;
      this.updateItems();
    }
  },
  created() {
    this.clearFilters();
    this.updateItems();
  },
  beforeMount() {
    this.languagesApi.list().then(response => {
      this.instructionLanguages = response.data;
    });

    this.disabilityCategoryApi.list().then(response => {
      this.disabilityCategories = response.data;
    });

    this.classesApi.list().then(response => {
      this.classes = response.data;
    });

    this.usersApi.list().then(response => {
      this.users = response.data.data;
    });
  }
};
</script>
