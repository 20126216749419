class EmploymentTypes {
  constructor(axios) {
    this.axios = axios;
  }

  get mainRoute() {
    return "/api/dictionaries/employment-types";
  }

  list() {
    return this.axios.get(this.mainRoute);
  }
}

export default EmploymentTypes;
