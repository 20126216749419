import ClaimsRoutes from "./claims-routes";
import ServiceRoutes from "./services";
import AuthRoutes from "./authorization";
import AdminRoutes from "./admin";
import SanatoriumRoutes from "./sanatoriums";

import Home from "../views/Home.vue";
import Statistics from "../views/statistics/StatIndex";
import PmpkList from "../views/claims/PmpklList";
import QueueList from "../views/claims/QueueList";
import AppointmentList from "../views/claims/AppointmentlList";
import PageNotFound from "../views/errors/PageNotFound";
import SpecialSchoolsPlacesList from "../views/special-schools/places/List";
import SpecialSchoolsCreatePlaces from "../views/special-schools/places/Create";
import SpecialSchoolsClaimsList from "../views/claims/SpecialSchoolsClaimsList";
import RightChecker from "../services/rights-checker";

const emptyComponent = {
  render(c) {
    return c("router-view");
  }
};

export default [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("../views/reports/ReportsIndex")
  },
  {
    path: "/statistics",
    name: "statistics",
    component: Statistics
  },
  {
    path: "/claims",
    component: emptyComponent,
    meta: {
      label: "Заявки",
      auth: true
    },
    children: [...ClaimsRoutes]
  },
  {
    path: "/appointments",
    name: "claims.appointments",
    component: AppointmentList,
    meta: {
      label: "Расписание приема",
      auth: true,
      breadcrumb: [{ name: "Расписание приема" }]
    }
  },
  {
    path: "/pmpk-list",
    name: "claims.pmpk-list",
    component: PmpkList,
    meta: {
      label: "База ПМПК",
      auth: true,
      breadcrumb: [{ name: "База ПМПК" }]
    }
  },
  {
    path: "/queue-list",
    name: "claims.queue-list",
    component: QueueList,
    meta: {
      label: "Просмотр очереди",
      auth: true,
      breadcrumb: [{ name: "Просмотр очереди" }]
    }
  },
  ...ServiceRoutes,
  ...AuthRoutes,
  ...AdminRoutes,
  ...SanatoriumRoutes,
  {
    path: "/special-schools-places",
    name: "special-schools-places",
    component: emptyComponent,
    redirect: { name: "special-schools-places.list" },
    meta: {
      auth: true
    },
    children: [
      {
        path: "/",
        name: "special-schools-places.list",
        component: SpecialSchoolsPlacesList,
        meta: {
          breadcrumb: [{ name: "spec_schools.places_base" }]
        },
        beforeEnter(to, from, next) {
          return RightChecker.check(
            ["MANAGER_LIST_SPECIAL_GENERAL_EDUCATION"],
            next
          );
        }
      },
      {
        path: "create",
        name: "special-schools-places.create",
        component: SpecialSchoolsCreatePlaces,
        meta: {
          label: "Добавить место"
        },
        beforeEnter(to, from, next) {
          return RightChecker.check(
            [
              "ADD_PLACE_TO_SPECIAL_ORG",
              "MANAGER_EDIT_SPECIAL_GENERAL_EDUCATION"
            ],
            next
          );
        }
      }
    ]
  },
  {
    path: "/special-schools-claims-list",
    name: "claims.special-schools-list",
    component: SpecialSchoolsClaimsList,
    meta: {
      auth: true,
      breadcrumb: [{ name: "claims_list" }]
    }
  },
  {
    path: "*",
    component: PageNotFound
  }
];
