import RolesList from "../views/admin/roles/RolesList";
import RolesEdit from "../views/admin/roles/RolesEdit";

import UsersList from "../views/admin/users/UsersList";
import UsersEdit from "../views/admin/users/UsersEdit";

import ServicesList from "../views/admin/services/ServicesList";
import ServicesEdit from "../views/admin/services/ServicesEdit";

import SchoolsList from "../views/admin/schools/SchoolsList";
import SchoolsEdit from "../views/admin/schools/SchoolsEdit";

//import TeacherAttestationAllow from "../views/admin/teacher-attestation-allow/TeacherAttestationAllow";

import RightChecker from "../services/rights-checker";

export default [
  // roles
  {
    path: "/admin/roles",
    name: "admin.roles.list",
    props: true,
    component: RolesList,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    meta: {
      auth: true,
      breadcrumb: [{ name: "Список ролей" }]
    }
  },
  {
    path: "/admin/roles/:id/edit",
    name: "admin.roles.edit",
    component: RolesEdit,
    props: true,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "Список ролей",
          link: "/admin/roles"
        },
        { name: "Редактирование роли" }
      ]
    }
  },
  {
    path: "/admin/roles/create",
    name: "admin.roles.create",
    component: RolesEdit,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    props: true,
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "Список ролей",
          link: "/admin/roles"
        },
        { name: "Создание роли" }
      ]
    }
  },

  // users
  {
    path: "/admin/users",
    name: "admin.users.list",
    props: true,
    component: UsersList,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    meta: {
      auth: true,
      breadcrumb: [{ name: "Список пользователей" }]
    }
  },
  {
    path: "/admin/users/:id/edit",
    name: "admin.users.edit",
    component: UsersEdit,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    props: true,
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "Список пользователей",
          link: "/admin/users"
        },
        { name: "Редактирование пользователя" }
      ]
    }
  },

  //services
  {
    path: "/admin/services",
    name: "admin.services.list",
    props: true,
    component: ServicesList,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    meta: {
      auth: true,
      breadcrumb: [{ name: "Список услуг" }]
    }
  },
  {
    path: "/admin/services/:id/edit",
    name: "admin.services.edit",
    component: ServicesEdit,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    props: true,
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "Список услуг",
          link: "/admin/services"
        },
        { name: "Редактирование услуги" }
      ]
    }
  },

  // schools
  {
    path: "/admin/schools",
    name: "admin.schools.list",
    props: {
      perPage: 20
    },
    component: SchoolsList,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    meta: {
      auth: true,
      breadcrumb: [{ name: "Список школ" }]
    }
  },
  {
    path: "/admin/schools/:id/edit",
    name: "admin.schools.edit",
    component: SchoolsEdit,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    props: true,
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "Список школ",
          link: "/admin/schools"
        },
        { name: "Редактирование школы" }
      ]
    }
  },
  {
    path: "/admin/schools/create",
    name: "admin.schools.create",
    component: SchoolsEdit,
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    props: true,
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "Список школ",
          link: "/admin/schools"
        },
        { name: "Создание школы" }
      ]
    }
  },
  {
    path: "/admin/calendar-days",
    name: "admin.calendar-days",
    component: () => import("@/views/admin/calendar/CalendarDays.vue"),
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: "/admin/teacher-attestation-allow",
    name: "admin.teacher-attestation-allow",
    component: () =>
      import(
        "@/views/admin/teacher-attestation-allow/TeacherAttestationAllow.vue"
      ),
    beforeEnter(to, from, next) {
      return RightChecker.check(["ADMIN"], next);
    },
    props: true,
    meta: {
      auth: true
    }
  }
];
