<template>
  <div>
    <section class="section">
      <h2 class="service subtitle">Статистика</h2>

      <div
        class="field"
        v-for="service in filteredServices"
        :key="`services_${service.slug}`"
      >
        <b-radio
          v-model="selectedService"
          size="is-small"
          type="is-info"
          @input="dateChanged()"
          :native-value="service.slug"
        >
          {{ service.title }}
        </b-radio>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <b-field :label="$ml.get('date_from')">
              <b-datepicker
                icon-pack="far"
                :month-names="getMonthNames"
                :day-names="getDayNames"
                @input="dateChanged()"
                v-model="created_at_start"
                placeholder="Нажмите для выбора даты..."
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="field">
            <b-field :label="$ml.get('date_to')">
              <b-datepicker
                icon-pack="far"
                :month-names="getMonthNames"
                :day-names="getDayNames"
                @input="dateChanged()"
                v-model="created_at_end"
                placeholder="Нажмите для выбора даты..."
              >
              </b-datepicker>
            </b-field>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <table v-if="statItems" class="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>Всего поданных заявлений</th>
            <th>Ожидающие прием</th>
            <th>Выдано направлений</th>
            <th>Ожидающие курса</th>
            <th>Завершено</th>
            <th>Завершено (неявка заявителя)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(statItem, key) in statItems" :key="`statInfo_${key}`">
              {{ statItem }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CommonListMixin } from "../../mixins/common-list-mixin";
import { DatepickerMixin } from "../../mixins/datepicker-mixin";
import moment from "moment";
export default {
  name: "StatIndex",
  mixins: [CommonListMixin, DatepickerMixin],
  data: function() {
    return {
      created_at_start: new Date(
        new Date().getFullYear(),
        0,
        1
      ) /*начало текущего года*/,
      created_at_end: new Date(),
      statItems: null,
      selectedService: "",
      statsApi: this.$apiResource.make("Statistics")
    };
  },
  computed: {
    dateBegin() {
      return moment(this.created_at_start).format("YYYY-MM-DD");
    },
    dateEnd() {
      return moment(this.created_at_end).format("YYYY-MM-DD");
    },
    filteredServices() {
      return this.services.filter(item => {
        return item.show;
      });
    },
    ...mapGetters({
      services: "services/getList"
    })
  },
  methods: {
    dateChanged() {
      this.loadStatItems();
    },
    loadStatItems() {
      let params = {
        service: this.selectedService,
        dateBegin: this.dateBegin,
        dateEnd: this.dateEnd
      };

      this.statsApi.list(params).then(response => {
        this.statItems = response.data;
      });
    },
    ...mapActions({
      getServiceList: "services/requestList"
    })
  },
  mounted: function() {
    // this.getServiceList();
    // console.dir(this.filteredServices)
  }
};
</script>

<style scoped></style>
