import RemoteLogin from "../views/auth/Redirector";
import ReturnParser from "../views/auth/ReturnParser";
import Logout from "../views/auth/Logout";
export default [
  {
    path: "/login",
    name: "remote.login",
    component: RemoteLogin,
    meta: {
      title: "Авторизация в удаленной системе",
      auth: false
    }
  },
  {
    path: "/logout",
    name: "remote.logout",
    component: Logout,
    meta: {
      title: "Выход из удаленной системы",
      auth: true
    }
  },
  {
    path: "/oidc/return",
    name: "remote.return",
    component: ReturnParser,
    meta: {
      title: "Обрабатываются данные от удаленной системы...",
      auth: false
    }
  }
];
