import ResourceControllerApiService from "./resource-controller-api-service";

class Claims extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/claims";
  }

  personalList(data) {
    return this.axios.get(this.getMainRoute() + "/personal_list", {
      params: data
    });
  }

  teacherUploadFilelist(data) {
    return this.axios.get(this.getMainRoute() + "/files/get_files_encode", {
      params: data
    });
  }

  managersList(data) {
    return this.axios.get(this.getMainRoute() + "/managers_list", {
      params: data
    });
  }

  changeStatus(data) {
    return this.axios.post(this.getMainRoute() + "/change_status", data);
  }

  changeStatusSigned(data) {
    return this.axios.post(this.getMainRoute() + "/signed/change_status", data);
  }

  view(id) {
    return this.axios.get(this.getMainRoute() + "/view/" + id);
  }

  store(serviceSlug, data) {
    return this.axios.post("/api/claims/" + serviceSlug + "/create", data);
  }

  storeSigned(serviceSlug, data) {
    return this.axios.post(
      "/api/claims/signed/" + serviceSlug + "/create",
      data
    );
  }

  declarersList() {
    return this.axios.get("/api/claims/declarers_list");
  }
}

export default Claims;
