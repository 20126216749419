<template>
  <section class="section">
    <h2 class="service subtitle">Список услуг</h2>
    <table v-if="items.length > 0" class="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Название</th>
          <th width="20%">Код</th>
          <th>{{ $ml.get("actions") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="'table_item_' + itemIndex" v-for="(item, itemIndex) in items">
          <td>{{ item.title }}</td>
          <td>{{ item.slug }}</td>
          <td>
            <router-link
              class="button is-info is-small"
              :to="{ name: 'admin.services.edit', params: { id: item.id } }"
            >
              Редактировать
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <VPagination
      @change="changePage"
      :icon-pack="'far'"
      :total="totalPages"
      :current="currentPage"
      :order="order"
      :per-page="perPage"
    />
  </section>
</template>

<script>
import { CommonListMixin } from "../../../mixins/common-list-mixin";

export default {
  name: "ServicesList",
  mixins: [CommonListMixin],
  data() {
    return {
      items: []
    };
  },
  beforeMount() {
    this.apiResource = this.$apiResource.make("Service");
    this.listMethod = "list";
  }
};
</script>

<style scoped></style>
