export const TranslateMixin = {
  methods: {
    translate(key, defaultPhrase = "") {
      try {
        return this.$ml.get(key);
      } catch (e) {
        if (defaultPhrase.length > 0) {
          return defaultPhrase;
        }
        return key;
      }
    }
  }
};
