<template>
  <section>
    <div class="modal-padded-body">
      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input
              id="localValue.usersData.resident"
              type="checkbox"
              :data-vv-as="'Резидент РК'"
              v-model="localValue.usersData.resident"
              name="localValue.usersData.resident"
            />
            Резидент РК
          </label>
        </div>
      </div>

      <div class="field">
        <label class="label" for="localValue.usersData.iin">{{
          $ml.get("iin_claim_man")
        }}</label>
        <div class="control">
          <input
            id="localValue.usersData.iin"
            type="text"
            class="input"
            v-model="localValue.usersData.iin"
            v-validate="usersIinRules"
            readonly
            name="localValue.usersData.iin"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.iin')"
          >
            {{ errors.first("localValue.usersData.iin") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("fio_claim_man") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getNameFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Дата рождения заявителя</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="usersProfile.birth_date"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Адрес</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getAddressFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label" for="usersData.email">Электронная почта</label>
        <div class="control">
          <input
            id="usersData.email"
            type="text"
            class="input"
            v-validate="'required|email'"
            :data-vv-as="'Электронная почта'"
            v-model="localValue.usersData.email"
            name="localValue.usersData.email"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.email')"
          >
            {{ errors.first("localValue.usersData.email") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label" for="usersData.phone">{{
          $ml.get("phone")
        }}</label>
        <div class="control">
          <input
            id="usersData.phone"
            type="text"
            class="input"
            v-validate="'required|min:10|max:10|numeric'"
            :data-vv-as="$ml.get('phone')"
            v-model="localValue.usersData.phone"
            name="localValue.usersData.phone"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.phone')"
          >
            {{ errors.first("localValue.usersData.phone") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label" for="childsData.iin">ИИН ребенка</label>
        <div class="control">
          <input
            id="childsData.iin"
            type="number"
            class="input"
            @keyup="childsIinChanged()"
            v-validate="childsIinRules"
            :data-vv-as="'ИИН ребенка'"
            v-model="localValue.childsData.iin"
            name="localValue.childsData.iin"
          />
          <p class="help is-danger">
            {{ errors.first("localValue.childsData.iin") }}
          </p>
        </div>
      </div>

      <template v-if="childsUserInfo.iin">
        <div class="field">
          <label class="label">ФИО ребенка</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :value="getNameFromUserInfo(childsUserInfo)"
              disabled
            />
          </div>
        </div>

        <div class="field">
          <label class="label">Дата рождения ребенка</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :value="childsUserInfo.birth_date"
              disabled
            />
          </div>
        </div>
        <div class="field">
          <label class="label">{{ $ml.get("address_registr") }}</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :value="getAddressFromUserInfo(childsUserInfo)"
              disabled
            />
          </div>
        </div>
        <div class="field">
          <label class="label" for="localValue.childsData.actualAddress"
            >Адрес фактического проживания</label
          >
          <div class="control">
            <input
              id="localValue.childsData.actualAddress"
              type="text"
              class="input"
              v-validate="'required|min:5|max:200'"
              :data-vv-as="'Адрес фактического проживания'"
              v-model="localValue.childsData.actualAddress"
              name="localValue.childsData.actualAddress"
            />
            <p
              class="help is-danger"
              v-if="errors.has('localValue.childsData.actualAddress')"
            >
              {{ errors.first("localValue.childsData.actualAddress") }}
            </p>
          </div>
        </div>

        <b-field label="№ ПМПК">
          <b-select
            placeholder="Пожалуйста, выберите № ПМПК"
            v-model="localValue.pmpk_id"
            v-validate="'required'"
            @input="pmpkChanged"
          >
            <option
              v-for="pmpk in pmpksOptions"
              :value="pmpk.id"
              :key="pmpk.id"
            >
              {{ pmpk.text }}
            </option>
          </b-select>
          <p class="help is-danger" v-if="errors.has('localValue.pmpk_id')">
            {{ errors.first("localValue.pmpk_id") }}
          </p>
        </b-field>

        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input
                id="localValue.childsData.isDisabled"
                type="checkbox"
                :data-vv-as="'Наличие инвалидности'"
                v-model="localValue.childsData.isDisabled"
                name="localValue.childsData.isDisabled"
              />
              Наличие инвалидности
            </label>
          </div>
        </div>

        <b-field label="Кратность приема">
          <b-select
            placeholder="Кратность приема"
            v-model="localValue.childsData.multiplicityOfReception"
          >
            <option v-for="item in receptionTypes" :value="item" :key="item">
              {{ item }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Причина обращения">
          <b-select
            placeholder="Причина обращения"
            v-model="localValue.childsData.reason"
          >
            <option v-for="item in reasonTypes" :value="item" :key="item">
              {{ item }}
            </option>
          </b-select>
        </b-field>

        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input
                id="localValue.agreement"
                type="checkbox"
                v-validate="'required'"
                :data-vv-as="$ml.get('agreement')"
                v-model="localValue.agreement"
                name="localValue.agreement"
              />
              Даю согласие на использование сведений, составляющих охраняемую
              законом тайну, содержашихся в информационных системах
            </label>
            <p class="help is-danger" v-if="errors.has('localValue.agreement')">
              {{ errors.first("localValue.agreement") }}
            </p>
          </div>
        </div>

        <b-modal :active.sync="isNotificationModalActive">
          <div class="box">
            <h4>Внимание!</h4>
            <p>В связи с отпуском, запись на прием в ПМПК невозможна!</p>
            <p>Расписание отпусков:</p>
            <p>с 10.06 по 06.08 - ПМПК №2, ПМПК №3;</p>
            <p>c 17.06 по 14.08 - ПМПК №1;</p>
            <p>с 24.06 по 20.08 - ПМПК №4;</p>
            <p>с 01.07 по 26.08 - ПМПК №5;</p>
            <p>с 30.07 по 26.09 - ПМПК №6.</p>
          </div>
        </b-modal>
      </template>
    </div>
  </section>
</template>

<script>
import { CommonFormMixin } from "../../mixins/common-form-mixin";
import moment from "moment";

export default {
  name: "SpecialNeedsSocialServicesForm",
  mixins: [CommonFormMixin],
  data() {
    return {
      receptionTypes: ["Первичное", "Вторичное"],
      reasonTypes: [
        "Проблемы с развитием речи",
        "Проблемы со слухом",
        "Проблемы со зрением",
        "Нарушения опорно-двигательного аппарата",
        "Нарушения психического развития",
        "Проблемы со школьной успеваемостью",
        "Обучение на дому"
      ],
      usersUserInfo: {},
      isNotificationModalActive: false /*http://jira.samgau.com/browse/UOALM-215*/,
      pmpkDisabledRules: [
        ///*http://jira.samgau.com/browse/UOALM-215*/
        {
          dateBegin: "2019-06-17",
          dateEnd: "2019-08-14",
          id: 1
        } /*c 17.06 по 14.08*/,
        {
          dateBegin: "2019-06-10",
          dateEnd: "2019-08-06",
          id: 2
        } /*с 10.06 по 06.08*/,
        {
          dateBegin: "2019-06-10",
          dateEnd: "2019-08-06",
          id: 3
        } /*с 10.06 по 06.08*/,
        {
          dateBegin: "2019-06-24",
          dateEnd: "2019-08-20",
          id: 4
        } /*с 24.06 по 20.08*/,
        {
          dateBegin: "2019-07-01",
          dateEnd: "2019-08-26",
          id: 5
        } /*с 01.07 по 26.08*/,
        {
          dateBegin: "2019-07-30",
          dateEnd: "2019-09-26",
          id: 6
        } /*с 30.07 по 26.09*/
      ]
    };
  },
  computed: {
    isPmpkDisabled() {
      /*http://jira.samgau.com/browse/UOALM-215*/
      let pmpk = this.pmpkDisabledRules.find(
        rule => rule.id === this.localValue.pmpk_id
      );
      if (pmpk) {
        let now = moment().format("YYYY-MM-DD");
        return (
          moment(now).isBetween(pmpk.dateBegin, pmpk.dateEnd) ||
          now === pmpk.dateBegin ||
          now === pmpk.dateEnd
        );
      }

      return false;
    }
  },
  methods: {
    pmpkChanged() {
      /*http://jira.samgau.com/browse/UOALM-215*/
      if (this.isPmpkDisabled) {
        this.localValue.pmpk_id = null;
        this.isNotificationModalActive = true;
      }
    }
  },
  beforeMount() {
    this.setPmpksOptions();
  }
};
</script>
