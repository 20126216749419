import CommonLastStep from "../components/services/wizard-components/CommonLastStep";
import FileUploadList from "../components/services/wizard-components/FileUploadList";
import VFormUpload from "../components/form-components/VFormUpload";
import FileUploadListWithInput from "../components/services/wizard-components/FileUploadListWithInput";
import { mapGetters } from "vuex";

export const CommonWizardMixin = {
  props: {
    currentStep: {
      type: Number
    },
    firstStep: {
      type: Number
    },
    lastStep: {
      type: Number
    },
    requiredFilesMode: {
      type: String,
      default: "AND"
    },
    value: {},
    registeredClaim: {},
    slug: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      services: "services/getList"
    }),
    currentService() {
      return this.services.find(service => service.slug === this.slug) || {};
    },
    registeredClaimNumber() {
      return this.registeredClaim["claim_number"]
        ? this.registeredClaim["claim_number"]
        : "";
    }
  },
  data() {
    return {
      apiResource: this.$apiResource.make("Claims"),
      localValue: {},
      formStep: 1,
      fileStep: 2,
      signStep: 3
    };
  },
  components: {
    VFormUpload,
    CommonLastStep,
    FileUploadList,
    FileUploadListWithInput
  },
  methods: {
    activateStep(step) {
      this.$emit("activateStep", step);
    },
    deactivateStep(step) {
      this.$emit("deactivateStep", step);
    },
    onValidateForm(model) {
      this.activateStep(this.formStep);
      this.localValue = Object.assign(this.localValue, model);
    },
    onInvalidForm() {
      this.deactivateStep(this.formStep);
    },
    onSubmit() {
      this.$emit("onSubmit");
    },
    onSubmitSigned(xml) {
      this.$emit("onSubmitSigned", xml);
    },
    previousStep() {
      this.$emit("previousStep");
    },
    nextStep() {
      this.$emit("nextStep");
    },
    doneCallback() {
      this.$emit("doneCallback");
    },
    setRequiredFiles(filesArray) {
      this.$set(this.localValue, "requiredFiles", filesArray);
    },
    setNotRequiredFiles(filesArray) {
      this.$set(this.localValue, "notRequiredFiles", filesArray);
    }
  },
  watch: {
    localValue: {
      handler() {
        this.$emit("input", this.localValue);
      },
      deep: true
    },
    "localValue.requiredFiles": {
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          let result = false;
          if (this.requiredFilesMode === "AND") {
            result = true;
            this.localValue.requiredFiles.map(item => {
              if (item["uploaded"] === undefined) {
                if (item["files"].length === 0) {
                  result = false;
                }
                item["files"].forEach(child => {
                  if (!child.uploaded) {
                    result = false;
                  }
                });
              } else {
                if (!item.uploaded) {
                  result = false;
                }
              }
            });
          } else if (this.requiredFilesMode === "OR") {
            result = false;
            this.localValue.requiredFiles.map(item => {
              if (item["uploaded"] === undefined) {
                item["files"].forEach(child => {
                  if (child.uploaded) {
                    result = true;
                  }
                });
              } else {
                if (item.uploaded) {
                  result = true;
                }
              }
            });
          }
          if (result) {
            this.activateStep(this.fileStep);
          } else {
            this.deactivateStep(this.fileStep);
          }
        } else {
          this.activateStep(this.fileStep);
        }
      },
      deep: true
    }
  },
  beforeMount() {
    this.localValue = this.value || {};

    this.$set(this.localValue, "agreement", false);
    this.setNotRequiredFiles([
      {
        label: "Дополнительные документы",
        name: "file_1",
        files: []
      }
    ]);
  }
};
