<template>
  <section>
    <div class="content wizard-content" v-if="currentStep === formStep">
      <h3>{{ $ml.get("full_info") }}</h3>
      <main-form
        v-model="localValue"
        @onValidate="onValidateForm"
        @onInvalid="onInvalidForm"
        :service="currentService"
        :api-resource="apiResource"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === fileStep">
      <h3>Загрузка файлов</h3>
      <file-upload-list
        :list-key="'not_required_file'"
        :file-list="localValue['notRequiredFiles']"
        :postfix="' (не обязательно)'"
      />
    </div>
    <common-last-step v-if="currentStep === lastStep" />
  </section>
</template>

<script>
import { CommonWizardMixin } from "../../mixins/common-wizard-mixin";
import { GraduateDocsDuplicateForm } from "../forms";

export default {
  mixins: [CommonWizardMixin],
  name: "GraduateDocsDuplicateWizard",
  components: {
    mainForm: GraduateDocsDuplicateForm
  },
  beforeMount() {
    this.deactivateStep(this.formStep);

    this.$set(this.localValue, "usersData", {
      iin: "",
      email: "",
      phone: "",
      school: "",
      comment: "",
      endingYear: ""
    });
  }
};
</script>

<style scoped></style>
