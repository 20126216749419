<template>
  <div class="modal-padded-body" v-if="item.claim_data">
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label for="item.claim_data.usersData.iin" class="label">ИИН</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.iin"
              :value="item.claim_data.usersData.iin"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <label for="item.claim_data.usersData.fio" class="label">{{
            $ml.get("fio")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.fio"
              :value="getNameFromUserInfo(item.claim_data.usersData)"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label for="item.claim_data.usersData.birth_date" class="label">{{
            $ml.get("birthday")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.birth_date"
              :value="item.claim_data.usersData.birth_date"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <label for="item.claim_data.usersData.fullAddress" class="label">{{
            $ml.get("address_registr")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.fullAddress"
              :value="getAddressFromUserInfo(item.claim_data.usersData)"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label for="item.claim_data.usersData.email" class="label">{{
            $ml.get("email")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.email"
              :value="item.claim_data.usersData.email"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <label for="item.claim_data.usersData.phone" class="label">{{
            $ml.get("phone")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.phone"
              :value="item.claim_data.usersData.phone"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label
            for="item.claim_data.usersData.livingPeriodStart"
            class="label"
            >{{ $ml.get("livingPeriodStart") }}</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.livingPeriodStart"
              :value="item.claim_data.usersData.livingPeriodStart"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <label for="item.claim_data.usersData.livingPeriodEnd" class="label">
            {{ $ml.get("livingPeriodEnd") }}</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.livingPeriodEnd"
              :value="item.claim_data.usersData.livingPeriodEnd"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ViewPersonalMixin } from "../../../mixins/view-personal-mixin";

export default {
  name: "NuclearVictimsViewPersonal",
  mixins: [ViewPersonalMixin]
};
</script>

<style scoped></style>
