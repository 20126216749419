import PersonalList from "../views/claims/PersonalList";
import ManagersList from "../views/claims/ManagersList";
import RightChecker from "../services/rights-checker";
const managerRights = [
  "MANAGER_LIST_HOMESCHOOLING",
  "MANAGER_LIST_GRADUATE_DOCS_DUPLICATE",
  "MANAGER_LIST_PARENTAL_VISIT_PERMIT",
  "MANAGER_LIST_SPECIAL_NEEDS_SOCIAL_SERVICES",
  "MANAGER_LIST_ADOPTION_FAMILY_PAYMENTS",
  "MANAGER_LIST_REHABILITATION_AND_ADAPTATION",
  "MANAGER_LIST_TEACHERS_ATTESTATION",
  "MANAGER_LIST_TEACHERS_ATTESTATION_TEACHER",
  "MANAGER_LIST_TEACHERS_ATTESTATION_DDO",
  "MANAGER_LIST_TEACHERS_ATTESTATION_TIPO",
  "MANAGER_LIST_PRINCIPALS_ATTESTATION"
];

export default [
  {
    path: ":slug/personal-list",
    name: "claims.personal-list",
    props: true,
    component: PersonalList,
    meta: {
      name: "list_yours_cliams",
      auth: true,
      breadcrumb: [{ name: "list_yours_cliams" }]
    }
  },
  {
    path: ":slug/managers-list/:declarertype?",
    name: "claims.managers-list",
    component: ManagersList,
    beforeEnter(to, from, next) {
      return RightChecker.check(managerRights, next);
    },
    props: true,
    meta: {
      name: "list_cliams",
      auth: true,
      breadcrumb: [{ name: "list_cliams" }]
    }
  }
];
