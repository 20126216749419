import moment from "../moment-instance";
const defaultTimezone = "Asia/Almaty";

function loadAsUtc(date) {
  return moment.utc(date);
}
function convertToTz(moment, tz) {
  return moment.tz(tz);
}
function applyFormat(moment, format) {
  return moment.format(format);
}
function getFrom(moment, date = "now") {
  if (date === "now") {
    return moment.fromNow();
  }
  return moment.from(date);
}
// @todo: no timezone guessing, configure
function getFormattedDate(date, format, timezone = defaultTimezone) {
  return applyFormat(convertToTz(loadAsUtc(date), timezone), format);
}

function getFormattedFrom(date, from = "now", timezone = defaultTimezone) {
  return getFrom(convertToTz(loadAsUtc(date), timezone), from);
}
export default {
  getFormattedDate,
  getFormattedFrom
};
