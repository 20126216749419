export default '<p class="paragraph">Получатели</p>\n' +
  "            Физические лица\n" +
  '            <p class="paragraph">Какой госорган оказывает услугу</p>\n' +
  "            Местные исполнительные органы областей, городов Астаны и Алматы, районов и городов областного значения, организации дошкольного, начального, основного среднего, общего среднего, технического и профессионального, послесреднего образования\n" +
  '            <p class="paragraph">Форма оказания услуги</p>\n' +
  "            Автоматизированная\n" +
  '            <p class="paragraph">Необходимые документы</p>\n' +
  "            <ol>\n" +
  "                <li>Копия удостоверения личности;</li>\n" +
  "                <li>Копия диплома об образовании;</li>\n" +
  "                <li>Копия документа о повышении квалификации;</li>\n" +
  "                <li>Копия документа, подтверждающего трудовую деятельность работника;</li>\n" +
  "                <li>Копия удостоверения о ранее присвоенной квалификационной категории  (последний год присвоения);</li>\n" +
  "                <li>\n" +
  "                    Сведения о профессиональных достижениях (при их наличии) в соответствии с Правилами проведения и условиями аттестации гражданских служащих в сфере образования и науки, а также с Правилами проведения и условиями аттестации педагогических работников и приравненных к ним лиц, занимающих должности в организациях образования, реализующих образовательные учебные программы начального, основного среднего, общего среднего и специальные учебные программы утвержденные  приказом Министра образования и науки Республики Казахстан от 27 января 2016 года № 83 (зарегистрирован в Реестре государственной регистрации нормативных правовых актов под № 13317).\n" +
  "                </li>\n" +
  "            </ol>\n" +
  '            <p class="paragraph">В каком случае может быть отказано в предоставлении государственной услуги либо она\n' +
  "                приостановлена. В предоставлении государственной услуги отказывается по следующим основаниям:</p>\n" +
  "            <ul>\n" +
  "                <li>Нечитаемые сканированные копии документов;</li>\n" +
  "                <li>Неполный пакет документов;</li>\n" +
  "                <li>Иное.</li>\n" +
  "            </ul>\n" +
  '            <p class="paragraph">Сроки оказания услуги</p>\n' +
  "            <ul>\n" +
  "                <li>Подача заявления - 5 минут;</li>\n" +
  "                <li>Получение результата оказания услуги - 3 календарных дней, не считая день регистрации;</li>\n" +
  "            </ul>\n" +
  '            <p class="paragraph">Стоимость услуги и порядок оплаты</p>\n' +
  "            Бесплатно\n" +
  '            <p class="paragraph">Результат оказания услуги</p>\n' +
  "            <p>Расписка о приеме заявления для прохождения аттестации на присвоение (подтверждение) квалификационной\n" +
  "            категории педагогическим работникам и приравненным к ним лицам организаций образования, реализующих\n" +
  "            программы дошкольного воспитания и обучения, начального, основного среднего, общего среднего, технического и\n" +
  "            профессионального, послесреднего образования в произвольной форме, либо мотивированный ответ об отказе в\n" +
  "            оказании государственной услуги.</p>" +
  "            <div style='text-align: center'>" +
  '            <iframe style="width: 50%; height: 300px" src="https://www.youtube.com/embed/lVBQFtTA5Vw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' +
  '            <a target="_blank" href="https://testter.kz/"><img style="width: 80%" src="/tester_ru.jpg"/></a>' +
  "            </div>";
