<template>
  <section class="loading">
    <div class="pageloader is-active">
      <span class="title">Выход из системы авторизации...</span>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import AuthApi from "../../services/remote-auth-service";
export default {
  name: "Logout",
  methods: {
    logout: function() {
      if (this.hasToken) {
        const idToken = localStorage.getItem("ids_id_token");
        localStorage.removeItem("ids_id_token");
        this.doLogout();
        const redirectLink = AuthApi.getLogoutLink(idToken);
        setTimeout(() => {
          window.location.href = redirectLink;
        }, 2000);
      } else {
        this.$router.push("/");
      }
    },
    ...mapActions({
      doLogout: "user/logOutUser",
      hasToken: "user/isLoggedIn"
    })
  },
  mounted: function() {
    this.logout();
  }
};
</script>
