class DeclarerTypes {
  constructor(axios) {
    this.axios = axios;
  }

  get mainRoute() {
    return "/api/dictionaries/declarer-types";
  }

  list(serviceSlug) {
    return this.axios.get(this.mainRoute, {
      params: {
        service: serviceSlug
      }
    });
  }
}

export default DeclarerTypes;
