import teacherAttestation from "./service-translates/teacher-attestation-kk";

export default {
  "Сотрудник интерната": "Интернат қызметкері",
  search_short: "Іздеу",
  special_schools: "Интернаттар",
  spec_schools: {
    places_base: "Орындар базасы",
    add_places: "Добавление мест в специальные организации образования",
    claims_title:
      "Арнайы жалпы білім беретін оқу бағдарламалары бойынша оқыту үшін мүмкіндіктері шектеулі балалардың құжаттарын қабылдау және арнайы білім беру ұйымдарына қабылдау"
  },
  reports: "Сводный отчет",
  journal: "Берілген өтініштер журналы (Қызметкер)",
  services: "Қызметтер",
  exit: "Шығу",
  enter: "Кіру",
  enter_another: "Авторизациялау жүйесіне кіру",
  redirect_another: "Авторизациялау жүйесіне көшу",
  choose_directory:
    "Бір тарауды таңдаңыз. Жабық бөлімдерге кіру үшін, авторизациядан өтіңіз.",
  callback: "Қолдау қызметі: ",
  callback_support: "Тех. қолдау номері: ",
  mail_callback: "Тех.қолдау поштасы",
  time_work_callback: "Тех.қолдау - 9:00-ден 18:00-ге дейін жұмыс күндері.",
  time_work_callback_additioanl: "сб - жсн - демалыс",
  enter_to_account: "Жеке кабинетке кіру",
  "teachers-attestation":
    "Мектепке дейінгі тәрбие мен оқыту, бастауыш, негізгі орта, жалпы орта, техникалық және кәсіптік, орта білімнен кейінгі білім беру бағдарламаларын іске асыратын білім беру ұйымдарының педагог қызметкерлері мен оларға теңестірілген тұлғаларға біліктілік санаттарын беру (растау) үшін аттестаттаудан өткізуге құжаттар қабылдау",
  service: "Қызмет",
  show_journal_new_claims: "Берілген өтініштер журналын қарау",
  show_claims: "Өтініштерді қарау",
  create_claim: "Өтініш беру",
  doc_creating_claim:
    "Мектепке дейінгі тәрбие мен оқыту, бастауыш, негізгі орта, жалпы орта, техникалық және кәсіптік, орта білімнен кейінгі  білім беру бағдарламаларын іске асыратын білім беру ұйымдарының педагог қызметкерлері мен оларға теңестірілген тұлғаларға біліктілік санаттарын беру (растау) үшін аттестаттаудан өткізуге құжаттар қабылдау немесе немесе Мемлекеттік қызмет көрсетуден себептемелі жауаппен бас тарту туралы қолхат.",
  list_yours_cliams: "Сіздің өтініштеріңіздің тізімі",
  list_cliams: "Өтініштер тізімі",
  journal_homeschooling: " қызметі бойынша берілген өтініштер журналы",
  creating_homeschooling: " қызметі бойынша өтініш беру",
  full_info: "Негізгі ақпарат",
  my_iin: "Менің ЖСН-ім",
  fio: "ТАӘ",
  birthday: "Туған күні, айы, жылы",
  date_word: "Күні",
  address_registr: "Тіркеу мекенжайы",
  email: "Email",
  phone: "Телефон нөмірі",
  workplace: "Жұмыс орны",
  position: "Лауазымы",
  category_exist: "Санаты болуы",
  time_category: "Санат мерзімі",
  education: "Білім",
  name_univer: "Оқу орнының атауы",
  name_univer2: "Оқу орнының атауы, диплом бойынша мамандығы, біліктілігі",
  time_education: "Оқу кезеңі",
  speciality: "Диплом бойынша мамандығы",
  qualification: "Біліктілігі",
  worktime: "Жұмыс стажы",
  full: "Жалпы",
  from_spec: "Мамандық бойынша",
  teaching: "Педагогикалық",
  in_current_organization: "Осы лауазымда",
  at_this_position: "Осы лауазымда",
  diploms:
    "Алған жылы көрсетілген (берілген) марапаттар, атақтар,  ғылыми атақтар",
  accept_my_info:
    "Ақпараттық жүйелерде қамтылған, заңмен қорғалатын құпияны құрайтын мәліметтерді пайдалануға келісім беремін",
  next: "Ары қарай",
  upload_req_files: "Міндетті файлдарды жүктеу",
  back: "Артқа",
  attach_file: "Файлды қосыңыз",
  send_claim: "Өтінішті жіберу",
  send_with_ecp: "ЭЦҚ-мен қол қою арқылы жіберу",
  thanks:
    "Рахмет, Сіздің өтінішіңіз қабылданды. Статусы мен толық ақпаратты сіз ",
  thanks_link: "берілген өтініштер журналынан көре аласыз",
  done: "Дайын",
  iin_claim_man: "Өтініш берушінің ЖСН-і",
  iin_claim_child: "Өтініш баланың ЖСН-і",
  type_claim: "Өтініш типі",
  status: "Статусы",
  date_claim: "Өтінішті беру күні мен уақыты",
  action: "Әрекеттер",
  view: "Қарау",
  claim_witn_no_ecp: "Өтінішке электрондық қолтаңбамен қол қойылмаған",
  info_claim: "Өтініш жайлы ақпарат",
  history_changes: "Өзгерістер тізімі",
  full_docs: "Жалпы құжаттар",
  notification_registr: "Тіркеу туралы хабарлама",
  notification_negative: "Қабылдамау туралы хабарлама",
  notification_to_work: "Өтінішті жұмысқа қабылдау туралы хабарлама",
  doc_accepting_docs: "Құжаттарды қабылдау туралы қолхат",
  reg_number: "Тіркеу нөмірі",
  fio_claim_man: "Өтініш берушінің ТАӘ-сы",
  fio_claim_child: "Баланың аты-жөні",
  creation_date: "Құрылған күні",
  registrated: "Тіркелді",
  rejected: "Қабылданбады",
  completed: "Аяқталды",
  course_ended: "Аяқталды",
  in_progress: "Жұмыс барысында",
  attached_docs: "Өтінішке қосымшалар",
  docs_from_gbd: "МДҚ-дан құжаттар",
  Заявитель: "Өтінішкер",
  tabs_basic_info: "Жалпы мағлұматтар",
  tabs_qualification: "Біліктілікті арттыру",
  tabs_attestationo: "Аттестаттау",
  tabs_awards_teacher: "Кәсіби жетістіктерін растайтын құжаттар",
  tabs_awards_childs: "Оқушылардың жетістіктері",
  tabs_complex_analytics: "Талдамалық жинақтау",
  tabs_grade_docs: "Біліктілік бағасы",
  download_word: "Жүктеу",
  "Сотрудник (обработка)": "Сотрудник (обработка)",
  Регистратор: "Регистратор",
  "Сотрудник УО/ОО": "Сотрудник УО/ОО",
  Администратор: "Администратор",
  copy_passport: "Жеке басын куәландыратын құжат көшірмесі",
  date_of_assignment: "Берілген күні",
  certificate_number: "Куәлік №",
  course_subject: "Курс тақырыбы",
  place_of_passage: "Өту орны",
  certificate: "Куәлік",
  identity_card: "Куәлік",
  labor_activity: "Еңбек кітапшасы",
  download_document: "Құжатты жүктеу",
  download_file: "Файлды жүктеу",
  diplom: "Диплом",
  assigned_category: "Берілген санат",
  copy_diplom:
    "Білімі туралы диплом көшірмесі, қайта даярлау курстарынан өткені туралы құжат (бар болса)",
  type_of_awards: "Қол жеткізу түрі",
  claimed_qualification_category: "Мәлімделген біліктілік санаты",
  teacher_level: "Деңгейі",
  teacher_form_participation: "Қатысу формасы",
  teacher_type_olimpiada: "Пәндік олимпиада",
  teacher_type_competition: "Ғылыми жарыстар",
  teacher_type_contest: "Конкурстар",
  teacher_type_sport: "Спорттық жарыстар",
  teacher_type_music: "Музыкалық жарыстар",
  teacher_type_seminar: "Семинарлар, конференциялар және т. б.",
  teacher_type_publications: "Жарияланымдар",
  teacher_type_other: "Басқа",
  teacher_level_international: "Халықаралық",
  teacher_level_republic: "Республикалық",
  teacher_level_city: "Қалалық",
  teacher_level_district: "Аудандық",
  teacher_form_fulltime: "Күндізгі",
  teacher_form_distance: "Қашықтықтан",
  declarer_type: "Өтініш берушінің түрі",
  select_declarer_type: "Өтініш берушінің түрін таңдаңыз",
  tabs_awards_pedagog: "Педагогтың жетістіктері",
  tabs_awards_childs_little: "Балалардың жетістіктері",
  copy_qualification:
    "Бағдарламалар бойынша біліктілікті арттыру курстарынан өткені туралы сертификаты",
  doc_qualification:
    "Біліктілік санатын тағайындаумен (растаумен) аттестаттау туралы куәлік",
  indicators: "Көрсеткіштер",
  qualification_grade_doc: "Біліктілік санатын беру туралы бұйрық",
  complex_analytics_doc: "Қызметтің қорытындыларын кешенді талдамалық жинақтау",
  copy_work_doc: "Жұмыскердің еңбек қызметін растайтын құжат",
  copy_qual_category: "Біліктілік санатын беру туралы куәлік",
  copy_qual_category_and_order: "Біліктілік санатын беру туралы бұйрық",
  copy_qualifying_category: "Біліктілік санатын беру туралы куәлік",
  essay: "Эссе",
  copy_vipiska:
    "осы Қағидаларға 31-қосымшаға сәйкес педагогикалық кеңес отырысының хаттамасынан үзінді",
  info_professsional_diploms:
    "Кәсіптік жетістіктері туралы мәліметтер (болған жағдайда): педагогикалық тәжірибені жинақтау материалдары: эссе, шығармашылық есеп, кәсіптік қызметіне өзіндік талдау жасау, педагог қызметкерлердің және оларға теңестірілген тұлғалардың қызметінің кешенді талдамалық жинақтаудың қорытындылары: пікірлер, оқушылар мен тәрбиеленушілерден, ата-аналардан, әріптестер мен әкімшіліктен алынған сауалнамалар нәтижелері; практика базаларының басшылары, жұмыс берушілер тарапынан пікірлер, ЖОО-ға түсу немесе еңбекке орналасу туралы ақпарат, педагогикалық қызмет нәтижелері: аттестатталушы адамның, білім алушылар мен тәрбиеленушілердің оқу, шығармашылық, спорттық, пәндік олимпиадаларға, конкурстарға, байқауларға, жарыстарға, ойындарға қатысуы, марапаттау материалдары, соңғы үш жылдағы қызметкердің кәсіптік қызметі нәтижелілігінің динамикасы (мерзімінен бұрын аттестаттауда – 1-2 жыл.",
  teachers_attestation_desc:
    '<p class="paragraph">Алушылар</p>\n  ' +
    "          Жеке тұлғалар\n            " +
    '<p class="paragraph">Қызметті қандай Мемлекеттік орган көрсетеді </p>\n            ' +
    "Министрлік және облыстардың, Астана және Алматы қалаларының,\n " +
    "            облыстық маңызы бар аудандар мен қалалардың жергілікті \n     " +
    "       атқарушы органдары (бұдан әрі – көрсетілетін қызметті беруші)\n         " +
    '   <p class="paragraph">Қызмет көрсету формасы</p>\n    ' +
    "        Автоматтандырылған\n       " +
    '     <p class="paragraph">Керек құжаттар</p>\n   ' +
    "         <ol>\n          " +
    "      <li>Жеке басын куәландыратын құжат көшірмесі;</li>\n    " +
    "            <li>Білімі туралы диплом көшірмесі;</li>\n     " +
    "           <li>Біліктілікті арттыру туралы құжат көшірмесі;</li>\n   " +
    "             <li>Қызметкердің еңбек қызметін растайтын құжатының көшірмесі;</li>\n       " +
    "         <li>Бұрын берген біліктілік санаты туралы куәлік көшірмесі (жоғары білім беру ұйымдарынан ауысқан және біліктілік санаттары жоқ педагог қызметкерлерден басқа);</li>\n  " +
    "              <li>\n                    Кәсіптік жетістіктері туралы мәліметтер (болған жағдайда):\n           " +
    "         <ul>\n                        <li>Педагогикалық тәжірибені жинақтау материалдары: эссе, шығармашылық есеп, кәсіптік қызметіне өзіндік талдау жасау;</li>\n         " +
    "               <li>Педагог қызметкерлердің және оларға теңестірілген тұлғалардың қызметінің кешенді талдамалық жинақтаудың қорытындылары: пікірлер, оқушылар мен тәрбиеленушілерден, ата-аналардан, әріптестер мен әкімшіліктен алынған сауалнамалар нәтижелері; практика базаларының басшылары, жұмыс берушілер тарапынан пікірлер, ЖОО-ға түсу немесе еңбекке орналасу туралы ақпарат;</li>\n         " +
    "               <li>Педагогикалық қызмет нәтижелері: аттестатталушы адамның, білім алушылар мен тәрбиеленушілердің оқу, шығармашылық, спорттық, пәндік олимпиадаларға, конкурстарға, байқауларға, жарыстарға, ойындарға қатысуы, марапаттау материалдары;</li>\n           " +
    "             <li>Соңғы үш жылдағы қызметкердің кәсіптік қызметі нәтижелілігінің динамикасы (мерзімінен бұрын аттестаттауда – 1-2 жыл).</li>\n        " +
    "            </ul>\n                </li>\n            </ol>\n           " +
    ' <p class="paragraph">Қандай жағдайда Мемлекеттік қызмет көрсетуден бас тартылуы немесе ол тоқтатыла тұруы мүмкін.\n   ' +
    "             Мемлекеттік қызмет көрсетуден мынадай негіздер бойынша бас тартылады:</p>\n " +
    "           <ul>\n                <li>Құжаттардың оқылмайтын сканерленген көшірмелері;</li>\n   " +
    "             <li>Құжаттардың толық емес пакеті;</li>\n            " +
    "    <li>Басқа.</li>\n            </ul>\n        " +
    '    <p class="paragraph">Қызмет көрсету мерзімі</p>\n            <ul>\n     ' +
    "           <li>Өтініш беру-5 минут;</li>\n            " +
    "    <li>Қызмет көрсету нәтижесін алу - тіркелу күнін есептемегенде 3 күнтізбелік күн.</li>\n        " +
    '    </ul>\n            <p class="paragraph">Қызмет құны және төлеу тәртібі</p>\n       ' +
    "     Тегін\n          " +
    '  <p class="paragraph">Қызмет көрсету нәтижесі</p>\n      ' +
    "      Мектепке дейінгі тәрбие мен оқыту, бастауыш, негізгі орта, жалпы орта, техникалық және кәсіптік, \n   " +
    "         орта білімнен кейінгі  білім беру бағдарламаларын іске асыратын білім беру ұйымдарының \n   " +
    "         педагог қызметкерлері мен оларға теңестірілген тұлғаларға біліктілік санаттарын беру (растау) үшін \n       " +
    "     аттестаттаудан өткізуге құжаттар қабылдау немесе немесе Мемлекеттік қызмет көрсетуден себептемелі жауаппен \n  " +
    "          бас тарту туралы қолхат.",
  "graduate-docs-duplicate":
    "Негізгі орта, жалпы орта білім беру туралы құжаттардың телнұсқаларын беру",
  "parental-visit-permit":
    "Балаға кері әсер етпейтін ата-ана құқықтарынан айырылған ата-аналарға баламен кездесуіне рұқсат беру",
  homeschooling:
    "Бастауыш, негізгі орта, жалпы орта білім беру ұйымдарына денсаулығына байланысты ұзақ уақыт бойы бара алмайтын балаларды үйде жеке тегін оқытуды ұйымдастыру үшін құжаттарды қабылдау",
  "adoption-family-payments":
    "Баланы (балаларды) қабылдаушы отбасына тәрбиелеуге беру және оларды асырауға ақшалай қаражат төлеуді тағайындау",
  "special-needs-social-services":
    "Мүмкіндіктері шектеулі балаларды тексеру және оларға психологиялық-медициналық-педагогикалық консультациялық көмек көрсету және мүмкіндіктері шектеулі балаларды тәрбиелеп отырған отбасыларға консультациялық көмек көрсету",
  "reabilitation-and-adaptation":
    "Дамуында проблемалары бар балалар мен жасөспірімдерді оңалту және әлеуметтік бейімдеу",
  "spa-treatment": "Обеспечение инвалидов санаторно-курортным лечением",
  administration: "Басқару",
  roles: "Рөлдер",
  users: "Пайдаланушылар",
  schools: "Мектептер",
  sanatoriums: "Санатории",
  sanatorium_rooms: "Санатории: места",
  appointments: "Қабылдау кестесі",
  base_pmpk: "ПМПК базасы",
  agreement: "Келісім",
  all: "Барлық өтініштер",
  incoming: "Жаңа түскен",
  "in-progress": "Жұмыс барысында",
  archive: "Мұрағат",
  registered: "Тіркелді",
  button_in_progress: "Жұмысқа қабылдау",
  journal_claims: "Берілген өтініштер журналы",
  search: "Жылдам іздеу",
  date_from: "Күні(осы уақыттан)",
  date_to: "Күні(мына уақытқа дейін)",
  numb_claim: "Өтініш нөмірі",
  accept_claim: "Өтінішті қабылдау",
  reject_claim: "Қабылдамау",
  accepting_claim: "Өтінішті қабылдау",
  numb_hand_doc: "Қолхат №",
  rejecting: "Қабыл алмау",
  reason: "Себебі",
  give_copy_doc: "Выдать дубликат документа",
  claim_signed_ecp: "Өтінішке электрондық қолтаңбамен қол қойылған",
  "nuclear-victims":
    "Регистрация граждан, пострадавших вследствии ядерных испытаний на Семипалатинском испытательном ядерном полигоне, выплата единовременной государтсвенной денежной компенсации, выдача удостоверений",
  livingPeriodStart: "Период проживания (с)",
  livingPeriodEnd: "Период проживания (по)",
  send: "Отправить",
  sent_to_the_akimat: "Направлено в акимат",
  on_consideration: "На рассмотрении",
  in_queue: "В очереди",
  accept: "Согласовать",
  months_names:
    '["Қаңтар","Ақпан","Наурыз","Сәуiр","Мамыр","маусым","Шiлде","Тамыз","Қыркүйек","Қазан","Қараша","Желтоқсан"]',
  days_names: '["Дс", "Сс", "Ср", "Бс", "Жм", "Сб", "Жсн"]',
  teacher_attestation_translate: teacherAttestation,
  declarer: "Өтінішкер",
  child: {
    iin: "Баланың ЖСН-і",
    firstName: "Баланың аты",
    lastName: "Баланың тегі ",
    middleName: "Баланың әкесінің аты"
  },
  claims_list: "Өтініштер тізімі",
  claims_log: "Берілген өтініштер журналы",
  child_iin: "Баланың ЖСН-і",
  child_name: "Баланың аты",
  child_secondname: "Баланың тегі",
  child_middlename: "Баланың әкесінің аты",
  special_school: "Интернат",
  press_to_choose_date: "Күнді таңдау үшін басыңыз",
  to_clear: "Тазарту",
  to_add: "Қосу",
  all_claims: "Барлық өтініштер",
  received_childs: "Тіркелген балалар",
  leaved_childs: "Шығарылған балалар",
  rejected_claims: "Қабылданбаған өтініштер",
  ended_non_appearance_claims: "Келмеу бойынша аяқталған өтініштер",
  claim_number: "Өтініш нөмірі",
  child_fio: "Баланың аты-жөні",
  category: "Санат",
  class: "Сынып",
  instruction_language: "Оқыту тілі",
  direction_datetime: "Жолдаманың берілген күні мен уақыты",
  actions: "Әрекет",
  search_by_iin: "ЖСН бойынша іздеу",
  to_find: "Іздеу",
  to_receive: "Тіркеу",
  to_reject: "Қабылдамау",
  reject_reason: "Бас тарту себебі",
  to_confirm_rejection: "Бас тартуды растау",
  direction_processing: "Жолдаманы өңдеу",
  leaving_date: "Кету күні",
  leaving: "Кету",
  leaving_reason: "Кету себебі",
  to_sign_and_send: "Қол қою және жіберу",
  places_base: "Орындар базасы",
  special_school_name: "Интернат атауы",
  user: "Қолданушы",
  added_places: "Қосылған орындар",
  count_free_places: "Бос орындар саны",
  add_place_date: "Орындарды қосу күні",
  to_delete: "Өшіру",
  add_place_to_special_school: "Арнайы білім беру ұйымдарына орын қосу",
  choose_special_school: "Интернатты таңдаңыз",
  chooze_category: "Санатты  таңдаңыз",
  choose_class: "Сыныпты  таңдаңыз",
  choose_instruction_language: "Оқыту тілін  таңдаңыз",
  indicate_place_count: "Орын санын көрсетіңіз",
  to_save: "Сақтау",
  you_sure_that_data_is_right:
    "Назар аударыңыз! Сіз енгізілген деректердің дұрыстығына сенімдіз бе?",
  count_places: "Орын  саны",
  cant_edit_after_saving:
    "Расталғаннан кейін, сақталған деректер редакциялауға жатпайды!",
  ok: "Ок",
  "ШИ №10": "МИ №10",
  Приют: "Балалар үйі ",
  "СК «Жанұя»": "МК «Жанұя»",
  "ШИ №5": "МИ №5",
  "ШИ №2": "МИ №2",
  "ШИ №7": "МИ №7",
  СОДДП: "ДМБББҰ",
  "ШИ №4": "МИ №4",
  "ШИ №6": "МИ №6",
  "ШИ №15": "МИ №15",
  "ШИ №1": "МИ №1",
  "ШИ №9": "МИ №9",
  "«Жастар үйі»": "«Жастар үйі»",
  "Центр адаптации": "Бейімдеу орталығы",
  "ШИ №17": "МИ №17",
  SOS: "SOS",
  "Интеллектуальные нарушения": "Ақыл-ойында кемістігі бар балалар",
  "Нарушение опорно-двигательного аппарата":
    "Тірек-қозғалыс аппаратының функциясында кемістігібар балалар",
  "Нарушение речи": "Сөйлеуінде кемістігі бар балалар",
  "Нарушение зрения": "Көру қабілетінің бұзылуы",
  "Задержка психического развития": "Задержка психического развития",
  "Нарушение слуха": "Есту қабілетінің бұзылуы",
  "Нулевой класс": "Даярлық сынып",
  "Первый класс": "Бірінші сынып",
  "Второй класс": "Екінші сынып",
  "Третий класс": "Үшінші  сынып",
  "Четвертый класс": "Төртінші  сынып",
  "Пятый класс": "Бесінші  сынып",
  "Шестой класс": "Алтыншы  сынып",
  "Седьмой класс": "Жетінші сынып",
  "Восьмой класс": "Сегізінші  сынып",
  "Девятый класс": "Тоғызыншы  сынып",
  "Десятый класс": "Оныншы  сынып",
  "КГУ «Общеобразовательная школа-интернат №10 для детей из малообеспеченных семей»":
    "«Тұрмысы төмен отбасынан шыққан балаларға арналған №10 жалпы білім беретін мектеп - интернаты»  КММ",
  "КГУ «Специальная (коррекционная) школа-интернат №7 для детей с интеллектуальными нарушениями развития»":
    "«Даму интелектуалдық қабілеті төмен балаларға арналған №7 арнайы (түзету) мектеп-интернаты»  КММ",
  "КГУ «Специализированный комплекс «Жанұя»»":
    "«Мамандандырылған «Жанұя» кешені» КММ",
  "КГУ «Центр адаптации и поддержки выпускников социальных учреждений «Жастар үйі»»":
    "«Әлеуметтік мекемелердің түлектерін бейімдеу және қолдау орталығы «Жастар үйі»» КММ",
  "КГУ «Специальная(коррекционная) школа-интернат № 1 для детей с нарушением слуха»":
    "«Есту қабілеті нашар балаларға (естімейтін балаларға) арналған №1 арнайы (түзету) мектеп-интернаты»  КММ",
  "КГУ «Специальная (коррекционная) школа интернат №2 для детей с нарушением опорно- двигательного аппарата»":
    "«Аяқ қолы кемтар балаларға арналған №2 арнайы (түзету) мектеп-интернаты»  КММ",
  "КГУ «Специальная (коррекционная) школа -интернат №5 для детей с нарушениями слуха»":
    "«Есту қабілеті төмен балаларға арналған № 5 арнайы (түзету) мектеп-интернаты»  КММ",
  "КГУ «Специальная школа-интернат № 6 для детей с интеллектуальными нарушениями развития»":
    "«Даму интеллектуалдық қабілеті төмен балаларға арналған №6 арнайы (түзету) мектеп-интернаты»  КММ",
  "КГУ «Специальная(коррекционная) школа-интернат № 9 для детей с тяжелыми нарушениями речи»":
    "«Сөйлеу қабілеті өте нашар балаларға арналған №9 арнайы (түзету) мектеп-интернаты»  КММ",
  "КГУ «Специальная школа-интернат №15 для детей с малыми и затихающими формами туберкулеза санаторного типа»":
    "«Туберкулездің аз және бәсеңдеу түрлерімен ауыратын балаларға арналған арнайы санаториялық үлгідегі №15 мектеп-интернаты»  КММ",
  "КГУ «Центр адаптации несовершеннолетних»":
    "«Кәмелетке толмағандарды бейімдеу орталығы» мемлекеттік мекемесі",
  "КГУ «Школа-интернат №17»": "«№17 мектеп-интернаты» ММ",
  "КГУ «Специальная организация образования для детей с девиантным поведением»":
    "«Девиантты мінез-құлықты балаларға арналған арнаулы білім беру ұйымы» КММ",
  "КГУ «Приют для временного содержания несовершеннолетних»":
    "«Жасөспірімдерді уақытша ұстау орны»  КММ",
  "Негосударственное образовательное учреждение «SOS Детская деревня»":
    "«SOS балалар ауылы» мемлекеттік емес ББМ",
  Казахский: "Қазақша",
  Русский: "Орысша",
  "СО для детей с девиантным поведением": "ДМБББҰ",
  cancel: "Болдырмау",
  enter_title_: "Атауын енгізіңіз",
  "Администратор очереди": "Кезек әкімшісі",
  add_place: "Орын қосу",
  choose_category: "Санатты  таңдаңыз",
  employee_residential_school: "Интернат қызметкері",
  "КГУ «Специальная школа интернат № 4 для слепых и слабовидящих детей имени Н_Островского»":
    "«Н.Островский атындағы зағиптер және көздері нашар көретін балаларға арналған №4 арнайы мектеп-интернаты» КММ",
  holidays_weekends: "Праздничные/выходные дни",
  "Извещение об отклонении": "Қабылдамау туралы хабарлама",
  Направление: "Жолдама",
  "Извещение о зачислении": "Қабылдау туралы хабарлама",
  "Извещение об убытии": "Кету туралы хабарлама",
  "Извещение о завершении": "Аяқтау туралы хабарлама",
  "teachers-attestation_declarer_type_1": "Мектеп және АТМИ мұғалімдері",
  "teachers-attestation_declarer_type_2":
    "Мектепке дейінгі және қосымша білім беру педагогтары",
  "teachers-attestation_declarer_type_3":
    "Техникалық және кәсіптік білім беру педагогтары",
  "teachers-attestation_declarer_type_4":
    "Бастауыш, негізгі орта, жалпы орта білім беру",
  "teachers-attestation_declarer_type_5": "Мектепке дейінгі тәрбие мен оқыту",
  "teachers-attestation_declarer_type_6":
    "Техникалық және кәсіптік білім беру, орта білімнен кейінгі білім беру",
  "teachers-attestation_declarer_type_7": "Қосымша білім беру және басқалары",
  "teachers-attestation_declarer_type_8":
    "Арнайы оқу бағдарламаларын іске асыратын білім беру ұйымдары",
  "teachers-attestation_declarer_type_9": "Әдістемелік кабинеттер (орталықтар)",
  "teachers-attestation_declarer_type_1_short": "Учители школ и СКИШ",
  "teachers-attestation_declarer_type_2_short":
    "Педагоги дошкольного и дополнительного образования",
  "teachers-attestation_declarer_type_3_short":
    "Педагоги технического и профессионального образования",
  "teachers-attestation_declarer_type_4_short": "Мектептер",
  "teachers-attestation_declarer_type_5_short":
    "Мектепке дейінгі білім беру ұйымдары",
  "teachers-attestation_declarer_type_6_short": "ТЖКБ",
  "teachers-attestation_declarer_type_7_short": "Қосымша білім беру ұйымдары",
  "teachers-attestation_declarer_type_8_short": "АТМИ",
  "teachers-attestation_declarer_type_9_short": "БЖИ, әдістемелік кабинеттер",
  "principals-attestation_declarer_type_1": "Директор орынбасарлары",
  "principals-attestation_declarer_type_2": "Білім беру ұйымдарының басшылары",
  employment: "Жұмысбастылық",
  employment_type_1: "Мектепке дейінгі тәрбие мен оқыту",
  employment_type_2: "Бастауыш, негізгі орта, жалпы орта білім беру ",
  employment_type_3:
    "Техникалық және кәсіптік білім беру, орта білімнен кейінгі білім беру",
  employment_type_4: "Қосымша білім беру  және басқалары",
  employment_type_5:
    "Арнайы оқу бағдарламаларын іске асыратын білім беру ұйымдары",
  employment_type_6: "Әдістемелік кабинеттер (орталықтар)",
  employment_type_11:
    "Психологиялық-медициналық-педагогикалық консультация(ПМПК) Психологиялық-педагогикалық түзеу кабинеті (ППТК)",
  select_area: "Ауданды таңдаңыз",
  select_employment: "Жұмысбастылықты таңдаңыз",
  area: "Ауданы",
  area_1: "Алатау",
  area_2: "Алмалы",
  area_3: "Әуезов",
  area_4: "Бостандық",
  area_5: "Жетісу",
  area_6: "Медеу",
  area_7: "Наурызбай",
  area_8: "Түркісіб",
  prof_teacher_awards_info:
    "Кәсіби конкурстарда немесе олимпиадаларда мұғалімнің жетістіктері",
  prof_pedagog_awards_info:
    "Кәсіби конкурстарда немесе олимпиадаларда мұғалімнің жетістіктері",
  statement_of_certified: "Өтініш (6 және 15 қосымша бойынша)",
  statement_of_certified_1: "26-қосымшаға сәйкес өтініш",
  statement_of_certified_2: "23-қосымшаға сәйкес өтініш",
  teacher_awards_childs:
    '"педагог-зерттеуші" немесе "педагог-шебер" біліктілік санатына-тәжірибені жинақтау',
  teacher_awards_childs_little: "Білім алушылардың жетістіктері",
  study_mark: "Көрсеткіші",
  mark_study_result_doc:
    "Жеке дамыту бағдарламасын іске асыру бойынша маман қызметінің нәтижелік көрсеткіштері",
  mark_study_mastering_doc:
    "Білім беру ұйымы басшысының мөрімен және қолымен расталған білім алушылардың, тәрбиеленушілердің таңдаған білім беру бағдарламасын меңгеру көрсеткіштері",
  mark_study_formed_doc:
    "Білім беру ұйымы басшысының мөрімен және қолымен расталған шеберлік пен дағдылардың қалыптасу көрсеткіштері",
  mark_study_quality_doc:
    "Білім беру ұйымы басшысының мөрімен және қолымен расталған оқу жетістіктерін сырттай бағалау және (немесе) ағымдағы және (немесе) қорытынды аттестаттау нәтижелерін қамтитын біліктілік санаттарын беру арасындағы кезеңдегі білім алушылардың білім сапасының көрсеткіштері",
  mark_study_accept_doc:
    "Растайтын құжаттар:\n1)Тиісті деңгейдегі оқу-әдістемелік кеңес мақұлдаған әзірленген әдістемелік құралдардың, ұсынымдардың, оқу-әдістемелік кешендердің болуы (авторы/тең авторы);\n2) Психологиялық-педагогикалық басылымдарда жариялау, ғылыми-практикалық конференцияларда, семинарларда, съездерде, форумдарда сөз сөйлеу;\n3)Жобаларға, зерттеу, инновациялық, эксперименталды қызметке қатысу (эксперименталды/инновациялық алаңның қызметін ұйымдастыру және үйлестіру, зерттеулер жүргізу, жобаларға рецензиялау (әдістемелік, дипломдық және т. б.)",
  additional_docs: "Қосымша құжаттар",
  awards_teacher_doc:
    "Уәкілетті орган бекіткен тізбеге сәйкес кәсіби конкурстарда немесе олимпиадаларда педагогтің жетістіктері",
  awards_child_doc:
    "Педагогикалық тәжірибені жалпылау немесе үздік педагогикалық тәжірибелер мен әзірлемелерді енгізу немесе білім беру ұйымдарында іске асырылған әзірленген бағдарламаларды ұсыну (педагог-зерттеушілерге арналған)",
  declarerData: {
    iin: "ИИН заявителя",
    firstName: "Имя заявителя",
    lastName: "Фамилия заявителя",
    middleName: "Отчество заявителя"
  },
  list_of_watching_doc: "Сабақты бақылау нәтижелері (9-қосымша)",
  national_testing_doc: "Ұлттық біліктілік тестілеуден өткені туралы құжат",
  statement: "Өтініш (6 және 15 қосымша бойынша)",
  nkt_statement: "ҰБТ анықтамасы",
  watch_list: "Бақылау парақтары",
  mark_doc: "Көрсеткіші",
  "principals-attestation":
    "Білім беру ұйымдарының  директор орынбасарлары мен басшыларын аттестаттау",
  deputy: "Директор орынбасары бойынша",
  statistic: "Статистика",
  pushing_to_queue_correcting_kindergarten: "Түзету балабақшасына қабылдау",
  teacher_attestation_allow: "Предоставление доступа по Аттестации",
  "teachers-attestation_declarer_type_10":
    "Психологиялық-медициналық-педагогикалық консультация(ПМПК) Психологиялық-педагогикалық түзеу кабинеті (ППТК)",
  "teachers-attestation_declarer_type_11":
    "Жалпы білім беретін мектеп интернаттары (тәрбиешілерге арналған)",
  video_url: "Сабақтардың /сабақтардың бейнежазбалары",
  declare_type_files_3_1: "Оқыту сапасы",
  declare_type_files_3_2:
    "Біліктілік разрядының, бейіні бойынша санатының болуы (өндірістік оқыту шеберлері үшін)",
  declare_type_files_3_3:
    "Уәкілетті орган бекіткен тізбеге сәйкес кәсіби конкурстарда немесе олимпиадаларда педагогтің жетістіктері",
  knowledge_quality: "Оқыту сапасы",
  underachieving_students:
    "Сабақты зерттеу және инновациялық әдістерді қолдану",
  students_achievements:
    "Уәкілетті орган бекіткен тізбеге сәйкес конкурстарда немесе олимпиадаларда немесе жарыстарда білім алушылардың жетістіктері",
  formation_of_skills: "Оқыту сапасы",
  teachers_attestation_5_2:
    "Сабақты зерттеу және инновациялық әдістерді қолдану",
  mastering_level:
    "Соңғы үш жылда білім алушылардың, тәрбиеленушілердің таңдаған білім беру бағдарламасын меңгеру деңгейі (әзірленген диагностикалық құралдарға сәйкес)",
  students_achievements_1:
    "№ 514 бұйрығына сәйкес конкурстарда немесе олимпиадаларда немесе жарыстарда білім алушылардың жетістіктері",
  individual_mastering_level: "Оқыту сапасы",
  co_author:
    "Тиісті деңгейдегі оқу-әдістемелік кеңеспен келісілген әзірленген бағдарламалардың, әдістемелік құралдың болуы (автор/тең автор), оқу – әдістемелік кешеннің, әзірленген материалдарға авторлық құқықпен қорғалатын объектілерге құқықтардың мемлекеттік тізіліміне мәліметтер енгізу туралы куәлігі бар",
  publication:
    "Жарияланымдар, ғылыми-практикалық конференцияларда немесе семинарларда немесе форумдарда сөз сөйлеу",
  lessons_observation: "Соңғы үш жылдағы мұғалімдердің сабақтарына пікірлер",
  project_participation:
    "Жобаларға, зерттеу немесе Инновациялық немесе эксперименттік қызметке қатысу (эксперименттік/инновациялық алаң қызметін ұйымдастыру және үйлестіру, зерттеулер жүргізу, жобаларды рецензиялау (әдістемелік, дипломдық және т. б.)",
  working_groups_participation:
    "Жұмыс топтарына немесе сараптамалық кеңестерге немесе конкурстық комиссияларға/қазылар алқасына қатысу",
  organized_seminars: "Ұйымдастырылған семинарлар, конференциялар саны",
  working_effectiveness: "Педагогтермен жұмыстағы бастамалардың тиімділігі",
  certificates:
    "Уәкілетті органмен келісілген білім беру бағдарламалары бойынша біліктілікті арттыру курстары туралы сертификаттың болу",
  social_media:
    "Жетекшілік ететін бағыт бойынша ақпаратты әлеуметтік желілердегі парақшаға жүйелі орналастыру",
  children_quality_3_years: "Оқыту сапасы",
  children_family_quality_3_years:
    "Соңғы үш жылда баланың отбасына психологиялық-педагогикалық консультация беру бағдарламасын іске асыру сапасы (арнайы педагогтер, психологтар, әлеуметтік педагогтер үшін)",
  guidelines_development: "Бағдарламаларды немесе ұсыныстарды әзірлеу",
  interaction_units:
    "Әлеуметтік орталықтың құрылымдық бөлімшелерімен өзара іс-қимыл жасау ҮЕҰ серіктестерімен (өткізілген іс-шаралар саны)",
  teachers_attestation_11_3: "Түзету-дамыту немесе тәрбие сабақтарының сапасы",
  copy_diploma: "Білімі туралы диплом көшірмесі",
  performance_indicator: "Қызмет тиімділігінің көрсеткіштері",
  principals_attestation_1_1_title:
    "Білім беру ұйымының ашықтығын қамтамасыз етудің тиімділігі ",
  principals_attestation_1_1:
    "1. Білім беру ұйымының ашықтығы: - сайтта орналастыру",
  principals_attestation_1_2:
    "1.1. Білім беру ұйымының ашықтығы: - әлеуметтік желілердегі бетте",
  principals_attestation_1_2_title:
    "Білім беру сапасын қамтамасыз етудің тиімділігі",
  principals_attestation_1_3:
    "2. Мектеп оқушыларының/жетекшілік ететін бағыт бойынша соңғы үш оқу жылындағы білім сапасы (соңғы үш жылдағы орташа арифметикалық көрсеткіш есептеледі)",
  principals_attestation_1_4:
    "3. Соңғы үш жылда техникалық және кәсіптік, орта білімнен кейінгі білім беру ұйымдарына түскен 9, 11-сынып түлектерінің үлесі (соңғы үш жылдағы орташа арифметикалық көрсеткіш есептеледі)",
  principals_attestation_1_5:
    "4. Жоғары оқу орындарына түскен бітірушілердің үлесі (соңғы үш жылдағы орташа арифметикалық көрсеткіш есептеледі)",
  principals_attestation_1_3_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_1_6:
    "5. Білім беру ұйымы педагогтерінің жалпы санынан жоғары кәсіптік білімі бар педагогтердің үлесі",
  principals_attestation_1_7:
    "6. Ғылыми / академиялық дәрежесі бар педагогтердің үлесі ",
  principals_attestation_1_8:
    '7. Білім беру ұйымдары педагогтерінің жалпы санынан "педагог-зерттеуші", "педагог-шебер" біліктілік санаты бар педагогтердің үлесі ',
  principals_attestation_1_9:
    "8. Білім беру ұйымдары басшыларының орынбасарлары уәкілетті органмен келісілген бағдарлама бойынша 72 сағаттан кем емес менеджмент саласында біліктілікті арттыру курсынан өткен сертификаты болуы",
  principals_attestation_1_10:
    "9. Басшы орынбасарларының әзірлеген бағдарламасы немесе оқу-әдістемелік кешені немесе оқу әдістемелік кеңесімен келісілген әдістемелік ұсынысы/құралы немесе соңғы үш жылда жетекшілік ететін бағыты бойынша авторлық куәлігі бар болуы",
  principals_attestation_1_11:
    "10. Жетекшілік ететін бағыты бойынша мектепішілік бақылау жоспарын іске асырудың тиімділігі",
  principals_attestation_1_12:
    "11. Басшының орынбасарының педагогтермен, оқушылармен, ата-аналармен және т.б. жұмыс барысында іске асатын инициативалары ",
  principals_attestation_2_1_title:
    "Білім беру ұйымдарының ашықтығын қамтамасыз етудің тиімділігі ",
  principals_attestation_2_1:
    "1. Білім беру ұйымының ашықтығы: - сайтта орналастыру",
  principals_attestation_2_2:
    "1.1. Білім беру ұйымының ашықтығы: - әлеуметтік желілердегі бетте",
  principals_attestation_2_3:
    "2. Басшы орынбасарының тәрбие жұмысы бойынша ақпараттық-аналитикалық материалдарын, мақалаларын, көпшілік алдында сөйлеген сөздерін жалпы ақпараттық құралдарында жариялау",
  principals_attestation_2_2_title:
    "Оқушылардың қауіпсіздігін және денсаулығын сақтау үшін жағдайды қамтамасыз етудің тиімділігі ",
  principals_attestation_2_4:
    "3. Салауатты өмір салтын және өмір қауіпсіздігін қалыптастыру бойынша бағдарламалардың болуы",
  principals_attestation_2_5:
    "4. Оқушылардың денсаулығын сақтауға және қалпына келтіруге, суицидтің алдын алуға әсер ететін іс-шараларды ұйымдастыру және өткізу ",
  principals_attestation_2_3_title:
    "Тұлғаны әлеуметтендіру және тәрбиелеу бойынша жұмыстардың тиімділігі",
  principals_attestation_2_6:
    "5. Оқушылардың әртүрлі деңгейдегі шығармашылық, интеллектуалдық және спорттық конкурстарда қатысуының нәтижелілігі (соңғы үш жылда)",
  principals_attestation_2_7:
    "6. Оқушылардық қоғамдық бірлестіктерін ұйымдастыру ",
  principals_attestation_2_8:
    "7. Кәмелетке толмағандардың істері бойынша комиссияның есебінде тұрған оқушылардың болуы",
  principals_attestation_2_9:
    "8. Оқушылар жасаған қылмыстар мен құқық бұзушылықтардың болуы",
  principals_attestation_2_4_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_2_10:
    "9. Білім беру ұйымы басшысының орынбасарында уәкілетті органмен келісілген бағдарлама бойынша менеджмент саласындағы біліктілікті арттыру курстары туралы кемінде 72 сағат сертификаттың болуы",
  principals_attestation_2_11:
    "10. Жетекшілік ететін бағыты бойынша кәсіби шеберлік конкурстарының жеңімпаз педагогтерінің саны *ескерту: балдаржеңімпаздардың санына қарамастан әр деңгейге бөлек беріледі (осы деңгей бойынша ең көп балл 10)",
  principals_attestation_2_12:
    "11. Басшы орынбасарларының әзірлеген бағдарламасы немесе оқу-әдістемелік кешені немесе оқу әдістемелік кеңесімен келісілген әдістемелік ұсынысы/құралы немесе соңғы үш жылда жетекшілік ететін бағыты бойынша авторлық куәлігі бар болуы",
  principals_attestation_2_13:
    "12. Жетекшілік ететін бағыты бойынша МІБ жоспарын іске асырудың тиімділігі",
  principals_attestation_2_14:
    "13. Басшының орынбасарының педагогтермен, оқушылармен, ата-аналармен және т.б. жұмыс барысында іске асатын инициативалары",
  principals_attestation_3_1_title:
    "Білім беру ұйымының ашықтығын қамтамасыз етудің тиімділігі",
  principals_attestation_3_1:
    "1. Білім беру ұйымының ашықтығы: - сайтта орналастыру",
  principals_attestation_3_2:
    "1.1. Білім беру ұйымының ашықтығы: - әлеуметтік желілердегі бетте",
  principals_attestation_3_3:
    "2. Жоғары оқу ұйымдарымен, мекемелермен әлеуметтік серіктестігі",
  principals_attestation_3_2_title:
    "Білім сапасымен қамтамасыз етудің тиімділігі",
  principals_attestation_3_4:
    "3. Соңғы үш оқу жылындағы 10, 11 сынып оқушыларының білім сапасы (ортаарифметикалық көрсеткіш соңғы үш жылмен есептеледі) ",
  principals_attestation_3_5:
    "4. Соңғы үш жылдағы оқу бағыты бойынша орта білімнен кейінгі техникалық және кәсіби білім беру және жоғары оқу ұйымдарына (жаратылыстану-математикалықнемесеқоғамдық-гуманитарлық) түскен 11-ші сынып оқушыларының үлесі (әрбір бағыт бойынша ортаарифметикалық көрсеткіш соңғы үш жылмен есептеледі)",
  principals_attestation_3_6:
    "5. Жетекшілік ететін бағыты бойынша соңғы үш жылда білім саласында уәкілетті органмен бекітілген тізімге сәйкес облыстық, республикалық, халықаралық олимпиадалардан, конкурстардан, жарыстардан жеңімпаз (жүлдегер) атанған 10-11 сынып оқушыларының болуы *ескерту: жеңімпаздар мен жүлдегерлердің санына қарамастан балл әр деңгейге жеке тағайындалады",
  principals_attestation_3_7:
    "6. Бейіндік оқыту мен бейіндік алды дайындық оқытудың тиімділігінің мониторингі",
  principals_attestation_3_3_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_3_8:
    "7. Білім беру ұйымы басшысының орынбасарының уәкілетті органмен келісілген бағдарлама бойынша менеджмент саласындағы біліктілікті арттыру курстары туралы кемінде 72 сағат сертификаттың болуы",
  principals_attestation_3_9:
    "8. Білім беру ұйымында тиісті деңгейдегі немесе соңғы үш жылда оқу-әдістемелік кеңес мақұлдаған немесе авторлық куәлігі бар оқу жоспарының вариативтік бөлігінің немесе оқу-әдістемелік кешендердің немесе бейіналды даярлық немесе бейіндік оқыту жөніндегі әдістемелік ұсынымдардың/құралдардың әзірленген бағдарламаларының болуы",
  principals_attestation_3_10:
    "9. Басшының орынбасары әзірлеген оқу жоспарының вариативтік бөлігінің немесе оқу-әдістемелік кешендердің әзірленген курстарының бағдарламалары немесе тиісті деңгейдегі оқу-әдістемелік кеңес мақұлдаған немесе соңғы үш жыл ішінде немесе авторлық куәлігі бар бейіналды даярлық немесе бейіндік оқыту бойынша Әдістемелік ұсынымдардың/құралдардың болуы",
  principals_attestation_3_11:
    "10. Жетекшілік ететін бағыт бойынша мектепішілік бақылау жоспарларын іске асырудың тиімділігі",
  principals_attestation_3_12:
    "11. Басшының орынбасарының педагогтармен, білім алушылармен, ата-аналармен және т. б. жұмыс жасаудағы бастамалары.",
  principals_attestation_4_1_title:
    "Білім беру ұйымдарының ашықтығын қамтамасыз етудің тиімділігі ",
  principals_attestation_4_1:
    "1. Білім беру ұйымының ашықтығы: - сайтта орналастыру",
  principals_attestation_4_2:
    "1.1. Білім беру ұйымының ашықтығы: - әлеуметтік желілердегі бетте",
  principals_attestation_4_2_title:
    "Білім беру сапасын қамтамасыз етудің тиімділігі ",
  principals_attestation_4_3:
    "3. Соңғы үш оқу жылындағы жетекшілік ететін бағыты бойынша колледж студенттерінің білім сапасы (орта арифметикалық көрсеткіш соңғы үш жылмен есептеледі)",
  principals_attestation_4_4:
    "4. Өткен оқу жылындағы жұмыспен қамтылған және бос емес түлектердің олардың жалпы санындағы үлесі ",
  principals_attestation_4_5:
    "5. Соңғы үш жылда жетекшілік ететін бағыты бойынша білім беру саласындағы уәкілетті орган бекіткен тізім бойынша облыстық, республикалық, халықаралық олимпиадалардың, байқаулардың, жарыстардың жеңімпаздары (жүлдегерлері) атанған оқушылардың/студенттердің болуы. Ескертпе: балдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі (осы өлшем бойынша ең жоғары балл 10)",
  principals_attestation_4_3_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_4_6:
    "6. Білім беру ұйымдарындағы педагогтердің жалпы санынан жоғары кәсіби білімі бар оқытушылардың үлесі",
  principals_attestation_4_7:
    "7. Ғылыми/академиялық дәрежесі бар педагогтердің үлесі ",
  principals_attestation_4_8:
    '8. Білім беру ұйымдары педагогтерінің жалпы санынан "педагог-зерттеуші", "педагог-шебер" біліктілік санаты бар педагогтердің үлесі ',
  principals_attestation_4_9:
    "9. Білім беру ұйымы басшысының орынбасарында уәкілетті органмен келісілген бағдарлама бойынша менеджмент саласындағы біліктілікті арттыру курстары туралы кемінде 72 сағат сертификаттың болуы ",
  principals_attestation_4_10:
    "10. Соңғы үш жылда білім беру саласындағы уәкілетті орган бекіткен тізім бойынша кәсіби шеберлік конкурстарының жеңімпаздары/жүлдегерлері болған педагогтер саны. Ескертпе: Жеңімпаздар мен жүлдегерлер санына қарамастан, әр деңгей үшін баллдар бөлек беріледі.",
  principals_attestation_4_11:
    "11. Білім беру ұйымдарында соңғы үш жылда тиісті деңгейдегі оқу-әдістемелік кеңесімен бекітіліп, әзірленген бағдарламалардың немесе оқу-әдістемелік кешендердің немесе әдістемелік ұсыныстардың/оқу құралдарының болуы. Ескертпе: Санына қарамастан, әр деңгей үшін баллдар бөлек беріледі",
  principals_attestation_4_12:
    "12. Соңғы үш жылда басшының орынбасары жетекшілік ететін бағыты бойынша әзірлеген бағдарламалардың немесе оқу-әдістемелік кешендердің оқу-әдістемелік кеңесімен бекітілген немесе авторлық құқық сертификаты бар әдістемелік ұсыныстардың/оқу құралдарының болуы.Ескертпе: Санына қарамастан, әр деңгей үшін баллдар бөлек беріледі.",
  principals_attestation_4_13:
    "13. Жетекшілік ететін бағыты бойынша колледж ішін бақылау жоспарларын іске асырудың тиімділігі",
  principals_attestation_4_14:
    "14. Басшы орынбасарының педагогтармен, білім алушылармен, ата-аналармен және т. б. жұмыс істеудегі бастамалары жүзеге асырылуы",
  principals_attestation_4_15:
    "15.Білім беру ұйымдарының Халықаралық жобаларға қатысуы",
  principals_attestation_4_16:
    "16. Оқыту процесіне жаңа технологиялар мен білім беру формаларын енгізу (модульді, несиелі,дуальді)",
  principals_attestation_4_17:
    "17. Білім беру ұйымы ұйымдастыратын семинарлар, конференциялар арқылы кәсіби қоғамдастықта жетекшілік ететін бағытта білім беру ұйымының педагогикалық тәжірибесін тарату. Ескертпе: Жеңімпаздар мен жүлдегерлер санына қарамастан, әр деңгей үшін баллдар бөлек беріледі.",
  principals_attestation_5_1_title:
    "Білім беру ұйымдарының ашықтығын қамтамасыз етудің тиімділігі",
  principals_attestation_5_1:
    "1. Білім беру ұйымының ашықтығы: - сайтта орналастыру",
  principals_attestation_5_2:
    "1.1. Білім беру ұйымының ашықтығы: - әлеуметтік желілердегі бетте",
  principals_attestation_5_2_title:
    "Білім алушылардың денсаулығын және қауіпсіздігін сақтау үшін жағдай жасаудың тиімділігі",
  principals_attestation_5_3:
    "3. Салауатты өмір салтын және өмір қауіпсіздігін қалыптастыру бойынша бағдарламалардың болуы",
  principals_attestation_5_4:
    "4. Оқушылардың психикалық және физикалық денсаулығын сақтауға және қалпына келтіруге әсер ететін іс-шараларды ұйымдастыру және өткізу (Денсаулық күні және т.б.)",
  principals_attestation_5_3_title:
    "Тұлғаны тәрбиелеу және әлеуметтендіру бойынша жұмыстың тиімділігі",
  principals_attestation_5_5:
    "5. Оқушылардың әртүрлі деңгейдегі шығармашылық, интеллектуалдық және спорттық конкурстарда қатысуының нәтижелілігі (соңғы үш жылда). Ескертпе: Жеңімпаздар мен жүлдегерлер санына қарамастан, әр деңгей үшін баллдар бөлек беріледі.",
  principals_attestation_5_6:
    "6. Оқушылардың қоғамдық бірлестіктерін ұйымдастыру",
  principals_attestation_5_7:
    "7. Кәмелетке толмағандардың ісі бойынша комиссия есебінде тұратын оқушылардың болуы",
  principals_attestation_5_8:
    "8. Білім алушылармен жасалған құқық бұзушылықтар мен қылмыстар",
  principals_attestation_5_4_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_5_9:
    "9. Білім беру ұйымы басшысы орынбасарларында уәкілетті органмен келісілген бағдарлама бойынша менеджмент саласындағы біліктілікті арттыру курстары туралы кемінде 72 сағат сертификаттың болуы",
  principals_attestation_5_10:
    "10. Жетекшілік ететін бағыт бойынша кәсіби шеберлік конкурстарының жеңімпаздары/жүлдегерлері болған педагогтер саны.* Ескертпе: балдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі (осы өлшем бойынша ең жоғары балл 10)",
  principals_attestation_5_11:
    "11. Басшының орынбасары әзірлеген, оқу-әдістемелік кеңес мақұлдаған немесе авторлық куәлігі бар бағдарламалардың немесе оқу-әдістемелік кешендердің немесе әдістемелік ұсынымдардың/құралдардың болуы.* Ескертпе: балдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_5_12:
    "12. Жетекшілік ететін бағыт бойынша ЖББМ жоспарларын іске асырудың тиімділігі",
  principals_attestation_5_13:
    "13. Басшы орынбасарының педагогтармен, білім алушылармен, ата-аналармен және т. б. жұмыс істеудегі бастамаларының тиімділігі.",
  principals_attestation_5_14:
    "14. Басшы орынбасарының Облыстың білім басқармасы өткізетін кең ауқымды іс-шараларды ұйымдастыруға және өткізуге қатысуы",
  principals_attestation_5_15:
    "15. Білім алушылардың жалпы санынан білім алушылардың кінәсінен болған ЖКО (өткен оқу жылы) жағдайларының болуы",
  principals_attestation_5_16: "16. Суицид жағдайларының болуы",
  principals_attestation_6_1_title:
    "Білім беру ұйымының ашықтығын қамтамасыз етудің тиімділігі",
  principals_attestation_6_1:
    "1. Білім беру ұйымының ашықтығы: - сайтта орналастыру",
  principals_attestation_6_2:
    "1.1. Білім беру ұйымының ашықтығы: - әлеуметтік желілердегі бетте",
  principals_attestation_6_2_title:
    "Білім беру сапасын қамтамасыз етудің тиімділігі",
  principals_attestation_6_3: "3. Білім сапасының динамикасы",
  principals_attestation_6_4:
    "4. Өткен оқу жылының жұмыспен қамтылған және жұмысқа орналасқан түлектерінің олардың жалпы санынан үлесі",
  principals_attestation_6_5:
    "5. Облыстық, республикалық, халықаралық олимпиадалардың, конкурстардың, жарыстардың жеңімпаздары (жүлдегерлері) болған тәрбиеленушілердің/білім алушылардың саны. Ескертпе: баллдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_6_3_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_6_6:
    "6. Білім беру ұйымы педагогтерінің жалпы санынан жоғары кәсіптік білімі бар педагогтердің үлесі",
  principals_attestation_6_7:
    "7. Ғылыми / академиялық дәрежесі бар педагогтердің үлесі ",
  principals_attestation_6_8:
    '8. "Педагог-зерттеуші", "педагог-шебер" біліктілік санаты бар педагогтердің динамикасы ',
  principals_attestation_6_9:
    "9. Тәлімгерлік бойынша жұмыстың нәтижелілігі (жетекшілік ететін бағыт бойынша)",
  principals_attestation_6_10:
    "10. Білім беру ұйымы басшысының орынбасарында уәкілетті органмен келісілген бағдарлама бойынша менеджмент саласындағы біліктілікті арттыру курстары туралы кемінде 72 сағат сертификаттың болуы",
  principals_attestation_6_11:
    "11. Кәсіби шеберлік конкурстарының жеңімпаздары/жүлдегерлері болған педагогтер саны. Ескертпе: баллдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_6_12:
    "12. Білім беру ұйымында әзірленген бағдарламалардың немесе оқу-әдістемелік кешендердің немесе оқу-әдістемелік кеңес мақұлдаған әдістемелік ұсынымдардың/құралдардың болуы ",
  principals_attestation_6_13:
    "13. Басшының орынбасары әзірлеген, оқу-әдістемелік кеңес мақұлдаған немесе авторлық куәлігі бар бағдарламалардың немесе оқу-әдістемелік кешендердің немесе Әдістемелік ұсынымдардың/құралдардың болуы",
  principals_attestation_6_14:
    "14. Инновациялық-эксперименттік қызмет, әлеуметтік / білім беру жобаларына қатысу",
  principals_attestation_6_15:
    "15. Жетекшілік ететін бағыт бойынша колледжішілік жоспарларды іске асырудың тиімділігі",
  principals_attestation_6_16:
    "16. Білім беру ұйымы ұйымдастырған семинарлар, конференциялар өткізу арқылы жетекшілік ететін бағыт бойынша кәсіби қоғамдастықта білім беру ұйымының педагогикалық тәжірибесін тарату",
  principals_attestation_6_17:
    "17. Басшы орынбасарының педагогтермен, білім алушылармен, ата-аналармен және т. б. жұмыс істеудегі бастамаларының тиімділігі.",
  principals_attestation_6_18:
    "18. Білім беру ұйымының инновациялық даму бағдарламасын (жоспарын) әзірлеу",
  principals_attestation_6_19:
    "19. Дуалдық оқыту нысаны бойынша білім алушыларды ұлғайту үлесі",
  principals_attestation_7_1_title:
    "Білім беру ұйымының ашықтығын қамтамасыз етудің тиімділігі",
  principals_attestation_7_1:
    "1. Білім беру ұйымының ашықтығы: - сайтта орналастыру",
  principals_attestation_7_2:
    "1.1. Білім беру ұйымының ашықтығы: - әлеуметтік желілердегі бетте",
  principals_attestation_7_2_title:
    "Білім беру сапасын қамтамасыз етудің тиімділігі",
  principals_attestation_7_3:
    "3. Студенттердің ғылыми бағыттағы жарыстарға, конкурстарға, жобаларға қатысуы. Ескертпе: баллдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_7_4:
    "4. Облыстық, республикалық, халықаралық олимпиадалардың, конкурстардың, жарыстардың жеңімпаздары (жүлдегерлері) болған тәрбиеленушілердің/білім алушылардың саны. Ескертпе: баллдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_7_3_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_7_5:
    "5. Білім беру ұйымы педагогтерінің жалпы санынан жоғары кәсіптік білімі бар педагогтердің үлесі ",
  principals_attestation_7_6:
    "6. Ғылыми / академиялық дәрежесі бар педагогтердің үлесі",
  principals_attestation_7_7:
    '7. "Педагог-зерттеуші", "педагог-шебер" біліктілік санаты бар педагогтердің динамикасы',
  principals_attestation_7_8:
    "8. Тәлімгерлік бойынша жұмыстың нәтижелілігі (жетекшілік ететін бағыт бойынша)",
  principals_attestation_7_9:
    "9. Білім беру ұйымы басшысының орынбасарында уәкілетті органмен келісілген бағдарлама бойынша менеджмент саласындағы біліктілікті арттыру курстары туралы кемінде 72 сағат сертификаттың болуы",
  principals_attestation_7_10:
    "10. Кәсіби шеберлік конкурстарының жеңімпаздары/жүлдегерлері болған педагогтер саны. Ескертпе: баллдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_7_11:
    "11. Білім беру ұйымында әзірленген бағдарламалардың немесе оқу-әдістемелік кешендердің немесе оқу-әдістемелік кеңес мақұлдаған әдістемелік ұсынымдардың/құралдардың болуы. Ескертпе: баллдар әрбір деңгей үшін санына қарамастан жеке беріледі",
  principals_attestation_7_12:
    "12. Басшының орынбасары әзірлеген, оқу-әдістемелік кеңес мақұлдаған немесе авторлық куәлігі бар бағдарламалардың немесе оқу-әдістемелік кешендердің немесе әдістемелік ұсынымдардың/құралдардың болуы. Ескертпе: баллдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_7_13:
    "13. Инновациялық-эксперименттік қызмет, әлеуметтік / білім беру жобаларына қатысу. Ескертпе: баллдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_7_14:
    "14. Жетекшілік ететін бағыт бойынша ДКК жоспарларын іске асырудың тиімділігі. Ескертпе: баллдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_7_15:
    "15. Білім беру ұйымы ұйымдастырған семинарлар, конференциялар өткізу арқылы жетекшілік ететін бағыт бойынша кәсіби қоғамдастықта білім беру ұйымының педагогикалық тәжірибесін тарату. Ескертпе: баллдар әрбір деңгей үшін жеңімпаздар мен жүлдегерлер санына қарамастан жеке беріледі",
  principals_attestation_7_16:
    "16. Басшы орынбасарының педагогтермен, білім алушылармен, ата-аналармен және т. б. жұмыс істеудегі бастамаларының тиімділігі.",
  principals_attestation_7_17:
    "17. Білім беру ұйымының инновациялық даму бағдарламасын (жоспарын) әзірлеу",
  employment_type_7:
    "Психологиялық-медициналық-педагогикалық консультация(ПМПК) Психологиялық-педагогикалық түзеу кабинеті (ППТК)",
  principals_attestation_2_1_1:
    "Білім беру ұйымының ашықтығы:" +
    "<br/>- сайттың болуы (сілтеме көрсету);" +
    "<br/>- әлеуметтік желідегі парақша (сілтеме көрсету)",
  principals_attestation_2_1_2:
    "2. Ерекше білім беруді қажет ететін балалар контингентіне сәйкес арнайы жағдайлардың болуы:" +
    "<br/>- кедергісіз ортаның болуы;" +
    "<br/>- арнайы педагог, психологті қолдау;" +
    "<br/>- ерекше білім беруді қажет ететін балалар үшін бос уақытты ұйымдастыру",
  principals_attestation_2_1_3:
    "3. Қолайлы жағдайлар мен қауіпсіз орта құру:" +
    "<br/>- бейнебақылаумен қамтамасыз ету;" +
    '<br/>- жаппай жиналатын орындарда балаларды бақылау және бақылау мүмкіндігі (сәйкестік "Террористік тұрғыдан осал объектілерді терроризмге қарсы қорғауды ұйымдастыруға қойылатын талаптарды бекіту туралы" Қазақстан Республикасы Үкіметінің 2021 жылғы 6 мамырдағы № 305 қаулысымен);' +
    "<br/>- істен шыққан камералардың болмауы;" +
    "<br/>– басқа мемлекеттік органдар (ішкі істер департаменті (бұдан әрі - ІІД) және төтенше жағдайлар департаменті (бұдан әрі-ТЖД) тарапынан айыппұл санкцияларының болмауы",
  principals_attestation_2_1_4:
    "4. Білім беру ұйымының ғимаратына кіруді бақылауды ұйымдастыру:" +
    "<br/>- кіруді бақылау және басқару жүйесі (турникеттердің болуы (қарапайым, бетті танумен, білезікпен, саусақ ізімен);" +
    '<br/>- ескерту жүйесінің болуы ("дабыл түймесі");' +
    "<br/>- күзет қызметі субъектілерінің болуы: күзетшілер, вахтерлер (ауылдық жерлер үшін)",
  principals_attestation_2_1_5:
    "5. Қосымша біліммен қамтылған білім алушылар мен тәрбиеленушілердің ұлғаю динамикасы",
  principals_attestation_2_1_6:
    "6. Педагогтердің жалпы санынан инклюзивті білім беру бойынша (жалпы білім беру ұйымдары үшін), арнайы білім беру бойынша (арнайы білім беру ұйымдары үшін) біліктілікті арттыру курстарынан өткен педагогтердің үлесі",
  principals_attestation_2_1_7:
    "7. Орта білім беру ұйымдары үшін" +
    "<br/>- білім сапасының динамикасы; мектепке дейінгі тәрбие мен оқытуды ұйымдастыру үшін" +
    "<br/>- дағдылар мен дағдылардың қалыптасу деңгейінің динамикасы;" +
    "<br/>- психологиялық -педагогикалық түзету кабинеттері (бұдан әрі – ППТК), Оңалту орталықтары (бұдан әрі-ОО) үшін-түзету-дамытушылық сабақтар сапасының серпіні",
  principals_attestation_2_1_8:
    '8."Алтын белгі" белгісін алған және ЕНТ 120 және одан жоғары балл жинаған түлектер саны (мектептер үшін)',
  principals_attestation_2_1_9:
    "9.Техникалық және кәсіптік, орта білімнен кейінгі білім беру ұйымдарына түскен түлектердің үлесі (мектептер үшін)",
  principals_attestation_2_1_10:
    "10.Жоғары оқу орындарына түскен түлектердің үлесі (мамандандырылған Білім беру ұйымдары үшін – бюджеттік негізде оқуға түсу) (мектептер үшін)",
  principals_attestation_2_1_11:
    "11.Облыстық, республикалық, халықаралық олимпиадалардың, конкурстардың, жарыстардың (мектептер үшін) жеңімпаздары (жүлдегерлері)болған тәрбиеленушілердің/білім алушылардың саны",
  principals_attestation_2_1_12:
    "12. Білім беру ұйымы педагогтерінің жалпы санынан жоғары кәсіптік білімі бар педагогтердің үлесі",
  principals_attestation_2_1_13:
    "13. Ғылыми / академиялық дәрежесі бар педагогтердің үлесі",
  principals_attestation_2_1_14:
    '14. Білім беру ұйымы педагогтерінің жалпы санынан "педагог-зерттеуші", "педагог-шебер" біліктілік санаты бар педагогтердің үлесі',
  principals_attestation_2_1_15:
    "15. Жас мамандардың үлесі" + "<br/>Жұмыс өтілі 1 жылдан 3 жылға дейін",
  principals_attestation_2_1_16:
    "16. Кәсіби шеберлік конкурстарының жеңімпаздары / жүлдегерлері болған педагогтердің саны (мектептер үшін)",
  principals_attestation_2_1_17:
    "17. Білім беру ұйымында оқу-әдістемелік кеңес мақұлдаған әзірленген бағдарламалардың, оқу-әдістемелік кешендердің, әдістемелік ұсынымдардың/құралдардың болуы",
  principals_attestation_2_1_18:
    "18. Инновациялық-эксперименттік қызмет, әлеуметтік/білім беру жобаларына қатысу",
  principals_attestation_2_1_19:
    "19. Материалдық-техникалық базаны жақсарту (заманауи жабдықтарды, цифрлық зертханаларды, интерактивті жабдықтарды, оқу кабинеттерін және т. б. сатып алу)",
  principals_attestation_2_1_20:
    "20. Оқу-тәрбие процесі кезінде білім беру ұйымының аумағында білім алушылардың/тәрбиеленушілердің, қызметкерлердің денсаулығына зиян келтіруге әкеп соққан жарақаттанудың тіркелген жағдайларының болуы",
  principals_attestation_2_1_21: "21. Суицид жағдайларының болуы",
  principals_attestation_2_1_22:
    "22. Білім алушылар жасаған құқық бұзушылықтардың болуы",
  principals_attestation_2_1_23: "23. Кадрлар айналымының болуы",
  principals_attestation_2_1_24:
    "24. Электрондық журналдар жүйесінде кері байланыстың болмауы (облыстық білім басқармасының немесе КОКСО мониторингі бойынша)",
  principals_attestation_2_1_25: " 23. Кадрлардың тұрақтамауының болуы",
  principals_attestation_2_1_26:
    " 24. ҰБДҚ-ны сапасыз толтыру (облыстық білім басқармасының мониторингі бойынша)",
  principals_attestation_2_1_27:
    " 25. Электрондық журналдар жүйесінде кері байланыстың болмауы (облыстық білім басқармасының мониторингі бойынша)",
  principals_attestation_2_1_28:
    '26. 5 жылда 1 рет біліктілікті арттыру курстарынан өтпеген педагогтердің болуы ("Педагог мәртебесі туралы" Қазақстан Республикасының Заңына сәйкес',
  principals_attestation_2_1_1_title:
    "Сапалы білім берудің қолжетімділігін қамтамасыз етудің тиімділігі",
  principals_attestation_2_1_2_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_2_1_3_title:
    "Материалдық — техникалық қамтамасыз етудің тиімділігі",
  principals_attestation_2_1_4_title: "Ұпайлардың төмендеу көрсеткіштері",
  principals_attestation_2_1_5_title: "Баллдарды төмендету көрсеткіштері",
  principals_attestation_2_2_1_title:
    "Сапалы білімге қолжетімділікті қамтамасыз ету тиімділігі",
  principals_attestation_2_2_2_title:
    "Білім беру сапасын қамтамасыз етудің тиімділігі",
  principals_attestation_2_2_3_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_2_2_4_title:
    "Материалдық — техникалық қамтамасыз етудің тиімділігі",
  principals_attestation_2_2_5_title: "Баллдарды төмендету көрсеткіштері",
  principals_attestation_2_3_1_title:
    "Сапалы білім берудің қолжетімділігін қамтамасыз етудің тиімділігі",
  principals_attestation_2_3_2_title:
    "Адам ресурстарын дамытудың тиімділігі, инновациялық қызмет",
  principals_attestation_2_3_3_title:
    "Материалдық — техникалық қамтамасыз етудің тиімділігі",
  principals_attestation_2_3_4_title: "Ұпайлардың төмендеу көрсеткіштері",
  principals_attestation_2_4_1_title:
    "Сапалы білім берудің қолжетімділігін қамтамасыз етудің тиімділігі",
  principals_attestation_2_4_2_title:
    "Білім беру сапасын қамтамасыз етудің тиімділігі",
  principals_attestation_2_4_3_title:
    "Кадрлық әлеуетті, инновациялық қызметті дамытудың тиімділігі",
  principals_attestation_2_4_4_title:
    "Материалдық-техникалық қамтамасыз етудің тиімділігі",
  principals_attestation_2_4_5_title: "Баллдардың төмендеу көрсеткіштері",
  principals_attestation_2_4_6_title:
    "Материалдық — техникалық қамтамасыз етудің тиімділігі",
  principals_attestation_2_4_7_title: "Баллдарды төмендету көрсеткіштері",
  principals_attestation_2_4_8_title:
    "24. Ыңғайлы жағдайлар мен қауіпсіз ортаның болмауы:",
  principals_attestation_2_2_1:
    "1.Білім беру ұйымының ашықтығы:" +
    "<br/>- сайттың болуы (сілтемені көрсету);" +
    "<br/>- әлеуметтік желілерде парақтың болуы (сілтемені көрсету)",
  principals_attestation_2_2_2:
    "2. Білім беру ұйымы басшысының білім беру саласындағы уәкілетті органмен келісілген менеджмент саласындағы біліктілікті арттыру курстары туралы сертификатының болу",
  principals_attestation_2_2_3:
    "3. Балалар контингентіне сәйкес арнайы жағдайлардың болуы:" +
    "<br/>Кедергісіз ортаның болуы:" +
    "<br/>пандус;" +
    "<br/>лифт;" +
    "<br/>көтергіш;" +
    "<br/>тактильді жолдар;" +
    "<br/>Брайль тақталары;" +
    "<br/>Кадрлармен қамтамасыз ету: арнайы педагогтердің болуы" +
    "<br/>Балаларды дефектологпен, логопедпен, психологпен сүйемелдеуді ұйымдастыру: кабинеттердің, дамытушы жабдықтардың, әдістемелік материалдардың болуы",
  principals_attestation_2_2_4: `4. Балаларды үйде оқытуды ұйымдастыру:<br/>- үйде білім алушылар үшін жеке оқу бағдарламаларының болуы;<br/>- үйде оқитын балаларға мамандармен түзету-дамыту сабақтарын, мектеп жағдайында тәрбие және мектептен тыс іс-шараларды ұйымдастыру`,
  principals_attestation_2_2_5:
    "5. Арнайы бағдарламалар бойынша біліктілікті арттыру курстарынан өткен педагогтердің жалпы санынан үлесі",
  principals_attestation_2_2_6:
    "6. Білім алушылар, оның ішінде үйде оқытылатын балалар үшін бос уақытын ұйымдастыру (жеке физикалық ерекшеліктерін ескере отырып)",
  principals_attestation_2_2_7:
    "7. Қолайлы жағдайлар мен қауіпсіз орта құру:" +
    "<br/>- бейнебақылаумен қамтамасыз ету;" +
    "<br/>- жаппай жиналатын орындарда балаларды бақылау және бақылау мүмкіндігі (3.04.2015 ж. № 191 ҚРК-мен сәйкестік);" +
    "<br/>- істен шыққан камералардың болмауы;" +
    "<br/>- ұрлық пен хакерліктің болмауы;" +
    "<br/>- басқа мемлекеттік органдар тарапынан айыппұл санкцияларының болмауы (ІІД және ТЖД мониторингі бойынша",
  principals_attestation_2_2_8:
    "8. Білім беру ұйымының ғимаратына кіруді бақылауды ұйымдастыру:" +
    "<br/>- білім алушыларды тасымалдауды ұйымдастыру;" +
    '<br/>- ескерту жүйесінің болуы ("дабыл түймесі");' +
    "<br/>- күзет қызметі субъектілерінің болуы: күзетшілер, вахтерлер (ауылдық жерлер үшін)",
  principals_attestation_2_2_9:
    "9. Өткен жылмен салыстырғанда қосымша біліммен қамтылған білім алушылар мен тәрбиеленушілердің ұлғаю динамикасы",
  principals_attestation_2_2_10:
    '10. Қазақстан Республикасы Білім және ғылым министрінің 2013 жылғы 3 сәуірдегі № 115 бұйрығымен бекітілген "Кәсіптік-еңбек оқыту" оқу пәні бойынша үлгілік оқу бағдарламаларында көрсетілген мамандықтар бойынша кәсіптік-еңбек оқытуды ұйымдастыру, өндірістік сыныптың болуы',
  principals_attestation_2_2_11:
    "11. Техникалық және кәсіптік, орта білімнен кейінгі білім беру ұйымдарына түскен түлектердің үлесі",
  principals_attestation_2_2_12:
    "12. Облыстық, республикалық, халықаралық олимпиадалардың, конкурстардың, жарыстардың жеңімпаздары (жүлдегерлері) болған тәрбиеленушілердің/білім алушылардың саны",
  principals_attestation_2_2_13:
    "13. Білім беру ұйымы педагогтерінің жалпы санынан жоғары кәсіптік білімі бар педагогтердің үлесі",
  principals_attestation_2_2_14:
    "14. Ғылыми / академиялық дәрежесі бар педагогтердің үлесі",
  principals_attestation_2_2_15:
    '15. Білім беру ұйымы педагогтерінің жалпы санынан "педагог-сарапшы", "педагог-зерттеуші", "педагог-шебер" біліктілік санаты бар педагогтердің үлесі',
  principals_attestation_2_2_16: "16. Жас мамандардың болуы",
  principals_attestation_2_2_17:
    "17. Кәсіби шеберлік конкурстарының жеңімпаздары / жүлдегерлері болған педагогтер саны",
  principals_attestation_2_2_18:
    "18. Оқу-әдістемелік кеңес мақұлдаған әзірленген бағдарламалардың, оқу-әдістемелік кешендердің, әдістемелік ұсынымдардың/құралдардың болуы",
  principals_attestation_2_2_19:
    "19. Инновациялық қызмет, әлеуметтік/білім беру жобаларына қатысу",
  principals_attestation_2_2_20:
    "20. Материалдық-техникалық базаны жақсарту (заманауи жабдықтарды, цифрлық зертханаларды, интерактивті жабдықтарды, оқу кабинеттерін және т. б. сатып алу)",
  principals_attestation_2_2_21: "21. Компьютерлік техникамен қамтамасыз ету",
  principals_attestation_2_2_22:
    "22. Оқу-тәрбие процесі кезінде білім беру ұйымының аумағында білім алушылардың/тәрбиеленушілердің, қызметкерлердің денсаулығына зиян келтіруге әкеп соққан жарақаттанудың тіркелген жағдайларының болуы",
  principals_attestation_2_2_23: "23. Суицид жағдайларының болуы",
  principals_attestation_2_2_24:
    "24. Білім алушылар жасаған құқық бұзушылықтардың болуы",
  principals_attestation_2_2_25: "25. Кадрлар айналымының болуы",
  principals_attestation_2_2_26:
    "26. Электрондық журналдар жүйесінде кері байланыстың болмауы (облыстық білім басқармасының немесе КОКСО мониторингі бойынша)",
  principals_attestation_2_2_27:
    "27. ҰБДҚ-ны сапасыз толтыру (облыстық білім басқармасының мониторингі бойынша)",
  principals_attestation_2_2_28:
    "28. Электрондық журналдар жүйесінде кері байланыстың болмауы (облыстық білім басқармасының мониторингі бойынша)",
  principals_attestation_2_2_29:
    '29. 5 жылда 1 рет біліктілікті арттыру курстарынан өтпеген педагогтердің болуы ("Педагог мәртебесі туралы" Қазақстан Республикасының Заңына сәйкес',
  principals_attestation_2_3_1:
    "1.Психологиялық-педагогикалық сараптама (ПМПК) және түзеу көмегі (ППТ және ОО) бойынша білім беру қызметтерін көрсету сапасын қамтамасыз ету." +
    "<br/>- педагогикалық қызметкерлердің жалпы санынан психологиялық-педагогикалық емтихан (ПМПК), түзету-дамыту көмегі (ППК, ОО) бойынша біліктілікті арттыру курстарынан өткен педагогтердің үлесі;" +
    "<br/>- Қазақстан Республикасы Білім және ғылым министрлігінің 2016 жылғы 22 қаңтардағы № 70 бұйрығына сәйкес оқу, диагностикалық-консультациялық және коррекциялық-дамыту үдерісін жабдықтармен, оқулықтармен, оқу-әдістемелік кешендермен, дидактикалық материалдармен, әдістемелермен қамтамасыз ету." +
    "<br/>- Мұғалімдерге әдістемелік көмек көрсетуді ұйымдастыру (әдістемелік бірлестіктердің жұмысы, жас мұғалім мектебі);" +
    "<br/>- оқу кабинеттерінің, әзірлеуші құрал-жабдықтардың, әдістемелік материалдардың болуы",
  principals_attestation_2_3_2:
    "2. Арнайы білім беру ұйымдарының ата-аналармен, қоғаммен, жалпы білім беру ұйымдарымен, қосымша білім беру, денсаулық сақтау, әлеуметтік қорғау ұйымдарымен өзара іс-қимылы:" +
    "<br/>- білім беруде ерекше қажеттіліктері бар балалардың ата-аналарына (бұдан әрі - МЖМ), жалпы білім беретін мектептердің мұғалімдері мен мамандарына ақпараттық-танымдық іс-шаралар, консультациялар ұйымдастыру;" +
    "<br/>- ерекше қажеттіліктері бар балалардың құқықтарын қорғау және оларға көмек көрсету жөніндегі мемлекеттік және қоғамдық ұйымдардың іс-шараларына қатысу;" +
    "<br/>- денсаулық сақтау және әлеуметтік қорғау ұйымдарымен өзара әрекеттесу",
  principals_attestation_2_3_3:
    "3. Білім беру ұйымының ашықтығы:" +
    "<br/>- сайттың болуы (сілтемені көрсетіңіз)," +
    "<br/>- әлеуметтік желілерде беттің болуы (сілтемені көрсетіңіз)",
  principals_attestation_2_3_4:
    "4. Кедергісіз ортаның болуы: пандус, лифт, тактильді жолдар, Брайль белгілері",
  principals_attestation_2_3_5:
    "5. Ыңғайлы жағдайлар мен қауіпсіз ортаны құру:" +
    "<br/>- бейнебақылаумен қамтамасыз ету;" +
    "<br/>- адам көп жиналатын орындарда балаларды бақылау және бақылау мүмкіндігі (2015 жылғы 3 сәуірдегі №191 ПРРК сәйкестігі);" +
    "<br/>- істен шыққан камералардың болмауы;" +
    "<br/>- ұрлық пен ұрлықтың болмауы;" +
    "<br/>- басқа мемлекеттік органдардан айыппұлдардың болмауы (ІІД және Төтенше жағдайлар департаментінің мониторингі үшін)",
  principals_attestation_2_3_6:
    "6. Білім беру ұйымы мұғалімдерінің жалпы санындағы жоғары кәсіби білімі бар педагогтердің үлесі",
  principals_attestation_2_3_7:
    '7. Білім беру ұйымы мұғалімдерінің жалпы санындағы "педагог-зерттеуші", "педагог-шебер" біліктілік санаты бар педагогтердің үлесі',
  principals_attestation_2_3_8: "8. Жас мамандардың болуы",
  principals_attestation_2_3_9:
    "9. Білім беру ұйымы басшысының менеджмент саласындағы біліктілікті арттыру курстарынан өткені туралы сертификаты болады",
  principals_attestation_2_3_10:
    "10. Кәсіби шеберлік байқауларының жеңімпаздары/жүлдегерлері атанған мұғалімдер саны",
  principals_attestation_2_3_11:
    "11. Оқу-әдістемелік кеңес бекіткен әзірленген бағдарламалардың, оқу-әдістемелік кешендердің, әдістемелік ұсыныстардың/оқу құралдарының болуы",
  principals_attestation_2_3_12:
    "12. Инновациялық және эксперименттік қызмет, әлеуметтік/білім беру жобаларына қатысу",
  principals_attestation_2_3_13:
    "13. Мұғалімдерге әдістемелік көмекті ұйымдастыру (әдістемелік бірлестіктердің жұмысы, жас мұғалім мектебі)",
  principals_attestation_2_3_14:
    "14. Материалдық-техникалық базаны жақсарту (заманауи жабдықтарды, цифрлық зертханаларды, интерактивті жабдықтарды, оқу кабинеттерін және т. б. сатып алу)",
  principals_attestation_2_3_15:
    "15. Оқу-тәрбие процесі кезінде білім беру ұйымының аумағында білім алушылардың/тәрбиеленушілердің, қызметкерлердің денсаулығына зиян келтіруге әкеп соққан жарақаттанудың тіркелген жағдайларының болуы",
  principals_attestation_2_3_16: "16. Кадрлар айналымының болуы",
  principals_attestation_2_3_17:
    "17. ҰБДҚ (облыстық білім басқармасының мониторингі бойынша) және есеп нысандарын сапасыз толтыру",
  principals_attestation_2_3_18:
    '18. 5 жылда 1 рет біліктілікті арттыру курстарынан өтпеген педагогтердің болуы ("Педагог мәртебесі туралы" Қазақстан Республикасының Заңына сәйкес)',
  principals_attestation_2_4_1:
    "1.Білім беру ұйымының ашықтығы:" +
    "<br/>- сайттың болуы (сілтемені көрсету);" +
    "<br/>- әлеуметтік желілерде парақтың болуы (сілтемені көрсету)",
  principals_attestation_2_4_2:
    "2. Ерекше білім беру қажеттіліктері бар балалар контингентіне сәйкес арнайы жағдайлардың болуы:" +
    "<br/>- білім алушылардың жалпы санынан ерекше білім беру қажеттіліктері бар білім алушылардың үлесі (контингент);" +
    "<br/>- кедергісіз ортаның болуы: пандус, лифт-көтергіш, кіру тобы, қоңырау шалу түймесі, жабдықталған",
  principals_attestation_2_4_3:
    "3. Инклюзивті білім беру бойынша біліктілікті арттыру курстарынан өткен педагогтердің жалпы санынан үлесі",
  principals_attestation_2_4_4:
    "4. Ерекше білім беру қажеттіліктері бар балаларға арналған бос уақыт (жеке физикалық ерекшеліктерін ескере отырып)",
  principals_attestation_2_4_5:
    "5. Үкіметтік емес ұйымдармен өзара іс-қимыл:" +
    "<br/>- оқыту семинарларын ұйымдастыру" +
    "<br/>- тәжірибе алмасу (мастер-кластар)" +
    "<br/>- мәдени-бұқаралық іс-шараларды ұйымдастыру" +
    "<br/>- балалардың шығармашылық жұмыстарын ұйымдастыру (жәрмеңкелер, көрмелер, мұражайлар)",
  principals_attestation_2_4_6:
    "6. Қолайлы жағдайлар мен қауіпсіз орта құру:" +
    "<br/>- бейнебақылаумен қамтамасыз ету;" +
    "<br/>- көпшілік жиналатын орындарда студенттерді бақылау және бақылау мүмкіндігі (3.04.2015 ж. № 191 ҚРК-мен сәйкестік);" +
    "<br/>- істен шыққан камералардың болмауы;" +
    "<br/>- ұрлық пен хакерліктің болмауы;" +
    "<br/>- басқа мемлекеттік органдар тарапынан айыппұл санкцияларының болмауы (ІІД және ТЖД мониторингі бойынша)" +
    "<br/>0 балл" +
    "<br/>Қол жетімділігіне байланысты + 1 балл",
  principals_attestation_2_4_7:
    "7. Жұмыс берушілермен келісілген оқу жұмыс жоспарларының үлесі",
  principals_attestation_2_4_8: "8. Білім сапасының динамикасы",
  principals_attestation_2_4_9:
    "9. Дуальды оқыту нысаны бойынша білім алушыларды ұлғайту үшін",
  principals_attestation_2_4_10:
    "10. Оқу жылының соңына контингенттің сақталуы",
  principals_attestation_2_4_11:
    "11. Жеңімпаз (жүлдегер)атанған білім алушылар саны:" +
    "<br/>облыстық, республикалық, халықаралық олимпиадалар, конкурстар, жарыстар" +
    "<br/>* ескерту:" +
    "<br/>ұпайлар әр деңгей үшін жеңімпаздар мен жүлдегерлердің санына қарамастан жеке беріледі",
  principals_attestation_2_4_12:
    "12. Білім беру ұйымының халықаралық жобаларға қатысуы",
  principals_attestation_2_4_13:
    "13. Білім беру ұйымы педагогтерінің жалпы санынан жоғары педагогикалық және кәсіптік білімі бар педагогтердің үлесі",
  principals_attestation_2_4_14:
    "14. Ғылыми / академиялық дәрежесі бар педагогтердің үлесі",
  principals_attestation_2_4_15:
    '15. Білім беру ұйымы педагогтерінің жалпы санынан "педагог-зерттеуші", "педагог-шебер" біліктілік санаты (жоғары санат), педагог-сарапшы (бірінші санат) бар педагогтердің үлесі',
  principals_attestation_2_4_16: "16. Жас мамандардың педагогтердің болуы",
  principals_attestation_2_4_17:
    "17. Білім беру ұйымы басшысының менеджмент (басқару қызметі бойынша) саласындағы біліктілікті арттыру курстары туралы кемінде 72 сағат сертификатының болуы",
  principals_attestation_2_4_18:
    "18. Кәсіби шеберлік конкурстарының жеңімпаздары / жүлдегерлері болған педагогтердің саны" +
    "<br/>* ескерту:" +
    "<br/>ұпайлар әр деңгей үшін жеңімпаздар мен жүлдегерлердің санына қарамастан жеке беріледі",
  principals_attestation_2_4_19:
    "19. Оқу-әдістемелік кеңес мақұлдаған әзірленген бағдарламалардың, оқу-әдістемелік кешендердің, әдістемелік ұсынымдардың/құралдардың болуы" +
    "<br/>* ескерту:" +
    "<br/>ұпайлар әр деңгей үшін жеңімпаздар мен жүлдегерлердің санына қарамастан жеке беріледі",
  principals_attestation_2_4_20:
    "20. Инновациялық-эксперименттік қызмет, әлеуметтік/білім беру жобаларына қатысу",
  principals_attestation_2_4_21:
    "21. Материалдық-техникалық базаны жақсарту (заманауи жабдықтарды, цифрлық зертханаларды, интерактивті жабдықтарды, оқу кабинеттерін және т. б. сатып алу)",
  principals_attestation_2_4_22:
    "22. Оқу-тәрбие процесі кезінде білім беру ұйымының аумағында білім алушылардың/тәрбиеленушілердің, қызметкерлердің денсаулығына зиян келтіруге әкеп соққан жарақаттанудың тіркелген жағдайларының болуы",
  principals_attestation_2_4_23: "23. Суицид жағдайларының болуы",
  principals_attestation_2_4_24:
    "24. Қылмыстардың, құқық бұзушылықтардың болуы",
  principals_attestation_2_4_25:
    "25. Қолайлы жағдайлар мен қауіпсіз ортаның болмауы:" +
    "<br/>- бейнебақылаудың болмауы;" +
    "<br/>- жаппай жиналатын орындарда балаларды бақылау және бақылау мүмкіндігінің болмауы (3.04.2015 ж. № 191 ҚРК-мен сәйкестік);" +
    "<br/>- істен шыққан камералардың болуы;" +
    "<br/>- ұрлық пен хакерліктің болуы;" +
    "<br>- басқа мемлекеттік органдар тарапынан айыппұл санкцияларының болуы (ІІД және ТЖД мониторингі бойынша)",
  principals_attestation_2_4_26: "26. Кадрлар айналымының болуы",
  principals_attestation_2_4_27:
    " 18. Әзірленген бағдарламалардың, оқу-әдістемелік кешендердің, оқу-әдістемелік кеңес мақұлдаған әдістемелік ұсынымдардың/құралдардың болуы." +
    "Ескертпе: балдар әрбір деңгей үшін санына қарамастан жеке беріледі",
  principals_attestation_2_4_28:
    " 19. Инновациялық-эксперименттік қызмет, әлеуметтік / білім беру жобаларына қатысу",
  principals_attestation_2_4_29:
    " 20. Материалдық-техникалық базаны жақсарту (қазіргі заманғы жабдықтарды, цифрлық зертханаларды, интерактивті жабдықтарды, оқу кабинеттерін және т. б. сатып алу)",
  principals_attestation_2_4_30:
    " 21. Оқу-тәрбие процесі кезінде білім беру ұйымының аумағында білім алушылардың/тәрбиеленушілердің, қызметкерлердің денсаулығына зиян келтіруге әкеп соққан жарақаттанудың тіркелген жағдайларының болуы",
  principals_attestation_2_4_31: " 22. Суицид жағдайларының болуы",
  principals_attestation_2_4_32:
    " 23. Қылмыстардың, құқық бұзушылықтардың болуы",
  principals_attestation_2_4_33: " - бейнебақылаудың болмауы;",
  principals_attestation_2_4_34:
    " - жаппай жиналатын жерлерде балаларды бақылау және бақылау мүмкіндігінің болмауы(3.04.2015 ж. № 191 ҚРҮҚ сәйкес);",
  principals_attestation_2_4_35: " - істен шыққан камералардың болуы;",
  principals_attestation_2_4_36: " - ұрлықтар мен бұзулардың болуы;",
  principals_attestation_2_4_37:
    " - басқа мемлекеттік органдар тарапынан айыппұл санкцияларының болуы (ІІД және ТЖД мониторингі бойынша)",
  principals_attestation_2_4_38: " 25. Кадрлардың тұрақсыздығының болуы",
  principals_attestation_2_4_39: " 26. ҰБДҚ-ны сапасыз толтыру",
  principals_attestation_2_4_40:
    ' 27. 5 жылда 1 рет біліктілікті арттыру курстарынан өтпеген педагогтердің болуы ("педагог мәртебесі туралы"ҚР Заңына сәйкес)',
  declare_type_files_3_3_1:
    "Уәкілетті орган бекіткен тізбеге сәйкес кәсіби конкурстарда немесе олимпиадаларда педагогтің жетістіктері",
  declare_type_files_4_3:
    "Жеке дамыту бағдарламасын іске асыру сапасы (ПМПК педагогтерін қоспағанда)",
  awards_tab: "Жетістіктер",
  course: "Тақырыбы",
  methodist_title: "Білім беру ұйымының ашықтығын қамтамасыз етудің тиімділігі",
  methodist_1:
    "Тиісті деңгейдегі оқу-әдістемелік кеңеспен келісілген әзірленген бағдарламалардың, әдістемелік құралдың болуы (автор/тең автор), оқу – әдістемелік кешеннің, әзірленген материалдарға авторлық құқықпен қорғалатын объектілерге құқықтардың мемлекеттік тізіліміне мәліметтер енгізу туралы куәлігі бар",
  methodist_2:
    "Ғылыми-практикалық конференцияларда немесе семинарларда немесе форумда жарияланымдары, баяндамалары",
  methodist_3: "Соңғы үш жылдағы мұғалімдердің сабақтарына пікірлер",
  methodist_4:
    "Жобаларға, зерттеу немесе инновациялық немесе эксперименттік қызметке қатысу (эксперименттік/инновациялық алаң қызметін ұйымдастыру және үйлестіру, зерттеулер жүргізу, жобаларды рецензиялау (әдістемелік, дипломдық және т. б.)",
  methodist_5:
    "Жұмысшы тобына немесе сараптамалық кеңеске, немесе конкурстық космиссиялар/сарапшыларға қатысу",
  methodist_6: "Ұйымдастырылған семинарлар, конференциялар саны",
  methodist_7: "Педагогтермен жұмыстағы бастамалардың тиімділігі",
  methodist_8:
    "Уәкілетті органмен рұқсат берілген біліктілікті арттыру курстары туралы сертификаттың болуы",
  methodist_9:
    "Әлеуметтік желілер арқылы кәсіби қоғамдастықта жетекшілік ететін бағыт бойынша әдіскердің педагогикалық тәжірибесін тарату",
  principals_attestation_methodist_title: "Көрсеткіштер",
  principals_attestation_methodist_1:
    "Білім беру ұйымының ашықтығы:<br/>" +
    "- сайттың болуы (сілтемелерді көрсету);<br/>" +
    "- әлеуметтік желілерде парақтың болуы (сілтемелерді көрсету)",
  principals_attestation_methodist_2:
    "Ғылыми немесе академиялық дәрежесінің болуы",
  principals_attestation_methodist_3:
    "Әдіскерлер санынан ғылыми / академиялық дәрежесі бар әдіскерлердің үлесі",
  principals_attestation_methodist_4:
    'Әдіскерлер санынан "педагог-зерттеуші", "педагог-шебер" біліктілік санаты бар әдіскерлердің үлесі',
  principals_attestation_methodist_5:
    "Әдістемелік жұмыс тәжірибесін тарату бойынша республикалық/халықаралық іс-шараларда басшының (басшының орынбасарының) сөз сөйлеуі/өткізу",
  principals_attestation_methodist_6:
    "Әзірленген бағдарламалардың, оқу-әдістемелік кешеннің, әдістемелік ұсынымдардың/оқу-әдістемелік кеңес мақұлдаған немесе авторлық құқығы туралы куәлігі бар әдістемелік жұмыс жөніндегі құралдың авторы/бең авторы",
  principals_attestation_methodist_7:
    "Кәсіби конкурстарда жеңімпаз/жүлдегер атанған немесе әлеуметтік/білім беру жобаларына қатысқан әдіскерлер саны",
  principals_attestation_methodist_8:
    "Әдістемелік жұмыс бойынша біліктілікті арттыру курстары туралы сертификаттың болуы",
  principals_attestation_methodist_9:
    "Пән мұғалімдерінің белсенді жұмыс істейтін қауымдастықтарының саны",
  principals_attestation_methodist_10:
    "Әдістемелік кабинет (орталық) басшысының (басшының орынбасарының) жұмыс немесе сараптама топтарына қатысуы"
};
