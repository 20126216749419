export default {
  "adoption-family-payments": [
    {
      name: "Принять в работу",
      statusesToShow: ["REGISTERED"],
      statusToChange: "IN_PROGRESS",
      class: "is-info",
      key: "CHANGE_TO_IN_PROGRESS",
      translate: "button_in_progress"
    },
    {
      name: "Передать на воспитание",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "ENDED",
      class: "is-success",
      adoptionPopupShow: true,
      key: "CHANGE_TO_ENDED"
    },
    {
      name: "Отклонить",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "REJECTED",
      class: "is-warning",
      rejectPopupShow: true,
      key: "CHANGE_TO_REJECTED",
      popupConfig: {
        popupTitle: "Отклонение",
        commentTitle: "Причина",
        commentValidationRules: "required|min:5",
        translate: "rejecting",
        commentTranslate: "reason"
      },
      translate: "reject_claim"
    }
  ]
};
