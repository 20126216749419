<template>
  <div>
    <p :key="listKey + '_p_' + index" v-for="(fileObject, index) in fileList">
      <v-form-upload
        v-model="fileList[index]"
        :postfix="postfix"
        :key="'required_file_' + index"
        :label="fileObject.label + postfix"
        :translate="isTranslate ? fileObject.translate : false"
        :only="fileObject.only"
        :preview="preview"
        :file-encode="fileEncode"
        :single="single"
        :max-mb-size="maxMbSize"
        :name="fileObject.name"
        :show-plus="showPlus"
      />
    </p>
  </div>
</template>

<script>
import VFormUpload from "../../form-components/VFormUpload";

export default {
  components: { VFormUpload },
  props: {
    fileList: {
      type: Array
    },
    listKey: {
      type: String
    },
    postfix: {
      type: String,
      default: ""
    },
    preview: {
      type: Boolean,
      default: false
    },
    fileEncode: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    showPlus: {
      type: Boolean,
      default: false
    },
    maxMbSize: {
      type: Number,
      default: 20
    },
    isTranslate: {
      type: Boolean,
      default: false
    }
  },
  name: "FileUploadList"
};
</script>
