<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>{{ $ml.get("callback") }} <a href="tel:1308">1308</a></strong>
        <br />
        <strong>{{ $ml.get("callback_support") }} <a href="tel:+77710301748">+7 771 030 1748</a></strong>
        <br />
        <b>{{ $ml.get("mail_callback") }}</b> -
        <a href="mailto:support@bilimalmaty.kz">gos.bilimalmaty@gmail.com</a>
        <br />
        <b>{{ $ml.get("time_work_callback") }}</b>
        <br />
        <b>{{ $ml.get("time_work_callback_additioanl") }}</b>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<style scoped>
.footer {
  border-top: 5px solid #0e61a7;
  padding: 20px;
}
</style>
