<template>
  <section class="section">
    <form @submit.prevent="submitForm(item)">
      <h2 class="service subtitle">Редактирование роли</h2>
      <div class="modal-padded-body">
        <div class="field">
          <label for="name" class="label">Название</label>
          <div class="control">
            <input
              id="name"
              v-model="item.name"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="code" class="label">Код(уникальное)</label>
          <div class="control">
            <input
              id="code"
              v-model="item.code"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <label class="label">Полномочия</label>

              <div
                :key="'permission_' + index"
                v-for="(permission, index) in permissionsList"
              >
                <label class="checkbox">
                  <input
                    type="checkbox"
                    :checked="isCheckedPermission(permission.id)"
                    @click="
                      permissionTrigger(
                        !isCheckedPermission(permission.id),
                        permission
                      )
                    "
                  />
                  {{ permission.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="modal-card-foot">
        <button class="button is-success">Сохранить изменения</button>
        <router-link
          :to="{ name: 'admin.roles.list' }"
          :tag="'button'"
          :class="'button'"
          >{{ $ml.get("back") }}</router-link
        >
      </footer>
    </form>
  </section>
</template>

<script>
import { displayError, getErrorsText } from "../../../services/helper";

export default {
  name: "RolesEdit",
  props: ["id"],
  data() {
    return {
      item: {
        name: "",
        code: "",
        permissions: []
      },
      permissionsList: [],
      apiResource: this.$apiResource.make("Roles"),
      permissionResource: this.$apiResource.make("Permissions")
    };
  },
  methods: {
    isCheckedPermission(id) {
      if (this.item.permissions.length > 0) {
        return !!this.item.permissions.find(
          permission => permission.id === parseInt(id)
        );
      }
      return false;
    },
    permissionTrigger(isAdd, permission) {
      if (isAdd) {
        this.item.permissions.push(permission);
      } else {
        this.item.permissions = this.item.permissions.filter(permissionItem => {
          return permissionItem.id !== permission.id;
        });
      }
    },
    submitForm(item) {
      if (item.id) {
        this.apiResource
          .update(item.id, item)
          .then(() => {
            return this.$router.push({ name: "admin.roles.list" });
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      } else {
        this.apiResource
          .store(item)
          .then(() => {
            return this.$router.push({ name: "admin.roles.list" });
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      }
    }
  },
  beforeMount() {
    if (this.id) {
      this.apiResource.edit(this.id).then(response => {
        this.item = response.data;
      });
    }

    this.permissionResource.list(1, 1000).then(response => {
      this.permissionsList = response.data.data;
    });
  }
};
</script>

<style scoped></style>
