<template>
  <div id="app">
    <v-app>
      <div class="pageloader" :class="loadingClass">
        <span class="title"
          >Получение пользовательских данных с сервера...</span
        >
      </div>
      <app-navbar></app-navbar>
      <breadcrumb v-if="hasToken"></breadcrumb>
      <router-view class="container" />
      <app-footer />
    </v-app>
  </div>
</template>

<script>
import Api from "./services/api/api-resource/api-instance";
import { mapGetters, mapActions } from "vuex";
import Navbar from "./components/Navbar";
import AppFooter from "./components/AppFooter";
import Breadcrumb from "./components/Breadcrumb";

export default {
  name: "Application",
  components: {
    AppFooter,
    "app-navbar": Navbar,
    Breadcrumb
  },
  metaInfo: () => {
    return {
      titleTemplate: "%s - Госуслуги",
      title: "Образовательные услуги"
    };
  },
  computed: {
    ...mapGetters({
      hasToken: "user/isLoggedIn",
      hasProfile: "user/isProfileLoaded",
      getExpiredAt: "user/getExpiredAt"
    }),
    isLoaded: function() {
      return this.hasToken && this.hasProfile;
    },
    needProfile: function() {
      return !this.isLoaded && this.hasToken;
    },
    loadingClass: function() {
      return this.needProfile ? "is-active" : "";
    }
  },
  methods: {
    loadProfile: function() {
      Api.make("Authorization")
        .getProfile()
        .then(response => {
          this.setUsersProfile(response.data.profile);
          this.setUserRole(response.data.role);
          this.setUserPermissions(response.data.role.permissions);
        });
    },
    ...mapActions({
      setUsersProfile: "user/setUsersProfile",
      setUserPermissions: "user/setUserPermissions",
      setUserRole: "user/setUserRole",
      extendTokenLife: "user/extendTokenLife",
      fetchDeclarerTypes: "services/fetchDeclarerTypes",
      getServiceList: "services/requestList",
      getAreas: "services/fetchAreas"
    }),
    setAuthChecker() {
      const self = this;
      setInterval(() => {
        if (self.hasToken) {
          const currentTimestamp = Math.floor(Date.now() / 1000);
          if (
            currentTimestamp > self.getExpiredAt ||
            !localStorage.getItem("session-token")
          ) {
            self.$router.push({ name: "remote.logout" });
          } else {
            self.extendTokenLife();
          }
        }
      }, 1000);
    }
  },
  watch: {
    isLoaded() {
      if (this.isLoaded) {
        this.getServiceList();
        this.fetchDeclarerTypes("teachers-attestation");
        this.fetchDeclarerTypes("principals-attestation");
        this.getAreas();
      }
    }
  },
  mounted: function() {
    if (this.needProfile) {
      this.loadProfile();
    }
    this.setAuthChecker();
  }
};
</script>
