import ResourceControllerApiService from "./resource-controller-api-service";

class DisabilityCategories extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/dictionaries/disability-categories";
  }

  list(data) {
    return this.axios.get(this.getMainRoute(), {
      params: data
    });
  }
}

export default DisabilityCategories;
