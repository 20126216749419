<template>
  <b-autocomplete
    v-model="text"
    :data="filteredDataArray"
    :placeholder="placeholder"
    field="text"
    @select="select"
  >
    <template slot="empty">{{ emptyText }}</template>
  </b-autocomplete>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default() {
        return [
          {
            text: "---",
            id: 0
          }
        ];
      }
    },
    value: {
      default: () => ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    minLength: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: "",
      selected: null
    };
  },
  computed: {
    filteredDataArray() {
      return this.text.length >= this.minLength
        ? this.options.filter(option => {
            let text = option.text.toString().toLowerCase();
            let value = this.text.toLowerCase();
            return text.indexOf(value) >= 0;
          })
        : [];
    },
    emptyText() {
      return this.text.length >= this.minLength
        ? "Ничего не найдено"
        : "Пожалуйста, введите не менее " + this.minLength + " символов";
    }
  },
  name: "BuefyCommonSelect",
  methods: {
    getOptionById(id) {
      return this.options.find(options => options.id === id);
    },
    select(option) {
      this.selected = option;
      const input = option && option.id ? option.id : null;
      this.$emit("input", input);
    },
    changeInitialOption() {
      const initialOption = this.getOptionById(this.value);
      if (initialOption) {
        this.selected = initialOption;
        this.text = this.selected.text;
      }
    }
  },
  watch: {
    value() {
      this.changeInitialOption();
    },
    options() {
      this.changeInitialOption();
    }
  },
  beforeMount() {
    this.changeInitialOption();
  }
};
</script>
<style lang="scss">
@import "../../assets/scss/variables";
.autocomplete.is-danger {
  input.input {
    border-color: $danger;
  }
}
</style>
