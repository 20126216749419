<template>
  <section class="section">
    <h2 class="subtitle">
      <template v-if="$ml.current === 'ru'">{{
        $ml.get("journal_homeschooling")
      }}</template>
      {{ $ml.get(currentService.slug) }}
      <template v-if="$ml.current === 'kk'">{{
        $ml.get("journal_homeschooling")
      }}</template>
    </h2>
    <div class="tabs" v-if="role.code !== 'declarer'">
      <ul>
        <li
          v-for="tab in tabs"
          :class="{ 'is-active': activeTab === tab }"
          :key="'view_tab_' + tab.key"
        >
          <a @click.prevent="setActiveTab(tab)">
            {{ $ml.get(tab.key) }}
          </a>
        </li>
      </ul>
    </div>

    <common-table
      :items="items"
      :route-name="'service.view-personal'"
      :slug="slug"
    />

    <VPagination
      @change="changePage"
      :icon-pack="'far'"
      :total="totalPages"
      :current="currentPage"
      :order="order"
      :per-page="perPage"
    />
  </section>
</template>

<script>
import { CommonListMixin } from "../../mixins/common-list-mixin";
import StatusesClasses from "../../configs/claim-statuses";
import ManagersListTabs from "../../configs/managers-list-tabs";
import { mapGetters } from "vuex";
import CommonTable from "../../components/lists/service-tables/CommonTable";

export default {
  name: "PersonalList",
  mixins: [CommonListMixin],
  props: {
    slug: {
      required: true
    }
  },
  computed: {
    tabs() {
      return this.managersListTabs["default"];
    },
    statuses() {
      return this.tabs;
    },
    ...mapGetters({
      services: "services/getList",
      role: "user/getUserRole"
    }),
    currentService() {
      return this.services.find(service => service.slug === this.slug) || {};
    }
  },
  data() {
    return {
      statusesClasses: StatusesClasses,
      managersListTabs: ManagersListTabs,
      requestParams: {
        service_id: 0,
        statuses: []
      },
      activeTab: ""
    };
  },
  components: {
    CommonTable
  },
  methods: {
    setActiveTab(tab) {
      if (this.activeTab !== tab) {
        this.currentPage = 1;
        this.activeTab = tab;
        this.requestParams.statuses = tab["statuses"];
        this.loadItems();
      }
    },
    setServiceId() {
      this.requestParams.service_id = this.services.find(
        service => service.slug === this.slug
      )["id"];
    }
  },
  mounted() {
    this.listMethod = "personalList";
    this.setServiceId();
    this.activeTab = this.tabs[0];
    this.loadItems();
  }
};
</script>

<style scoped></style>
