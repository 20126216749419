<template>
  <div class="field-body">
    <div class="field">
      <label class="label">{{ $ml.get("name_univer") }}</label>
      <div class="control">
        <BuefyCommonSelect
          v-model="school"
          :class="errors.has('school') ? 'is-danger' : ''"
          :options="schoolsOptions"
          :required="true"
        />
        <p class="help is-danger" v-if="showLabel">
          Если на данный момент организации не существует, необходимо заполнить
          поле значением "Иное"
        </p>
        <p class="help is-danger" v-if="errors.has('school')">
          {{ errors.first("school") }}
        </p>
      </div>
    </div>

    <div class="field" v-show="isAnotherType">
      <label class="label" for="schoolTextInput">Примечание</label>
      <div class="control">
        <input
          id="schoolTextInput"
          name="schoolTextInput"
          type="text"
          class="input"
          :placeholder="$ml.get('name_univer')"
          v-model="schoolTextInput"
        />
        <p class="help is-grey">
          При выборе "Иное" необходимо в примечании указать наименование
          организации обучения
        </p>
        <p class="help is-danger" v-if="errors.has('schoolTextInput')">
          {{ errors.first("schoolTextInput") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    title: {
      default: "Наименование учебного заведения"
    },
    showLabel: {
      default: false
    }
  },
  name: "SchoolSelect",
  data() {
    return {
      schoolsApi: this.$apiResource.make("Schools"),
      schoolsOptions: [],
      school: "",
      schoolTextInput: ""
    };
  },
  computed: {
    isAnotherType() {
      return this.school === "another";
    }
  },
  methods: {
    setSchoolsOptions() {
      this.schoolsApi.list().then(response => {
        this.schoolsOptions = response.data.map(item => {
          return {
            text: item.name,
            id: item.name
          };
        });
        this.schoolsOptions.unshift({
          text: "Иное",
          id: "another"
        });
      });
    },
    emitNewValue() {
      if (!this.isAnotherType) {
        this.$emit("input", this.school);
      } else {
        this.$emit("input", this.schoolTextInput);
      }
    }
  },
  watch: {
    school() {
      this.emitNewValue();
    },
    schoolTextInput() {
      this.emitNewValue();
    }
  },
  beforeMount() {
    this.setSchoolsOptions();
    this.school = this.value;
  }
};
</script>

<style scoped></style>
