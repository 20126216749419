import Vue from "./services/vue-instance";
import Vuex from "vuex";
import { user, services } from "./store/modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    services
  }
});
