import ServiceWizard from "../components/services/ServiceWizard";
import ServiceView from "../components/services/ServiceView";
import ServiceMain from "../components/services/ServiceMain";
import CreateAwaitingForSpecialNeedsSocialServicesViewPersonal from "../components/services/other-views/CreateAwaitingForSpecialNeedsSocialServicesViewPersonal";
import CreateSpaTreatmentViewPersonal from "../components/services/other-views/CreateSpaTreatmentViewPersonal";

export default [
  {
    name: "service.view-personal",
    path: "/services/:slug/view/:id/:tabName?",
    component: ServiceView,
    props: true,
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "list_yours_cliams",
          link: ":slug/personal-list"
        },
        { name: "show_claims" }
      ]
    }
  },
  {
    name: "service.view-manager",
    path: "/services/:slug/manager-view/:id/:tabName?",
    component: ServiceView,
    props: true,
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "list_cliams",
          link: "/services/:slug/manager-list"
        },
        { name: "show_claims" }
      ]
    }
  },
  {
    name: "service.main",
    path: "/services/:slug",
    component: ServiceMain,
    props: true,
    meta: {
      auth: true,
      breadcrumb: [{ name: "service" }]
    }
  },
  {
    name: "service.wizard",
    path: "/services/:slug/:step/:declarertype?",
    component: ServiceWizard,
    props: true,

    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "service",
          link: "/services/:slug"
        },
        { name: "create_claim" }
      ]
    }
  },
  {
    name: "service.manager.special-needs-social-services.add",
    path: "/services/manager/special-needs-social-services/add",
    component: CreateAwaitingForSpecialNeedsSocialServicesViewPersonal,
    props: true,
    meta: {
      auth: true
    }
  },
  {
    name: "service.manager.spa-treatment.add",
    path: "/services/manager/spa-treatment/add",
    component: CreateSpaTreatmentViewPersonal,
    props: {
      slug: "spa-treatment"
    },
    meta: {
      auth: true
    }
  }
];
