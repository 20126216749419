import uuid from "uuidv4";

const localStorageKey = "auth-nonce";

class RemoteAuthService {
  static getNonce() {
    return uuid();
  }
  static getLink(nonce) {
    localStorage.setItem(localStorageKey, nonce);
    const authParams = {
      client_id: process.env["VUE_APP_AUTH_CLIENT_ID"] || "",
      redirect_uri: process.env["VUE_APP_AUTH_REMOTE_RETURN"] || "",
      scope:
        "openid profile " + (process.env["VUE_APP_AUTH_CLIENT_SCOPE"] || ""),
      //response_mode: "form_post",
      response_type: "id_token token",
      nonce: nonce,
      ui_locales: "ru-RU"
    };

    const url = new URLSearchParams();
    for (const param in authParams) {
      url.set(param, authParams[param]);
    }
    return (
      process.env["VUE_APP_AUTH_REMOTE_URL"] +
      "/connect/authorize?" +
      url.toString()
    );
  }

  static getLogoutLink(idToken) {
    const url = new URLSearchParams();
    url.set("id_token_hint", idToken);
    return (
      process.env["VUE_APP_AUTH_REMOTE_URL"] +
      "/connect/endsession?" +
      url.toString()
    );
  }
}

export default RemoteAuthService;
