<template>
  <table v-if="items.length > 0" class="table is-striped">
    <thead>
      <tr>
        <th>{{ $ml.get("iin_claim_man") }}</th>
        <th style="width: 50%">{{ $ml.get("type_claim") }}</th>
        <th>{{ $ml.get("status") }}</th>
        <th>{{ $ml.get("date_claim") }}</th>
        <th>{{ $ml.get("actions") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="'table_item_' + itemIndex" v-for="(item, itemIndex) in items">
        <td>{{ item.declarer_iin }}</td>
        <td>{{ $ml.get(item.service.slug) }}</td>
        <td>
          <span class="tag" :class="statusesClasses[item.status.code].class">{{
            getStatusName(
              item.status,
              item.service.slug,
              $ml.get(statusesClasses[item.status.code].translate)
            )
          }}</span>
        </td>
        <td class="table-td-dates">
          <span
            class="no-break"
            :title="item.created_at | moment('DD.MM.YYYY HH:mm')"
            >{{ item.created_at | moment("D MMMM, YYYY") }}</span
          ><br />
          <small class="no-break has-text-steel">{{
            item.created_at | momentFrom
          }}</small>
        </td>
        <td>
          <router-link
            class="button is-info is-small"
            :to="{ name: routeName, params: { id: item.id, slug: slug } }"
          >
            {{ $ml.get("view") }}
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import StatusesClasses from "../../../configs/claim-statuses";
import { getStatusName } from "../../../services/helper";

export default {
  props: ["items", "routeName", "slug"],
  name: "CommonTable",
  data() {
    return {
      statusesClasses: StatusesClasses
    };
  },
  methods: {
    getStatusName
  }
};
</script>

<style scoped></style>
