export default {
  "nuclear-victims-akimat": [
    // {
    //   name: "Принять в работу",
    //   statusesToShow: ["REGISTERED"],
    //   statusToChange: "ON_CONSIDERATION",
    //   class: "is-info",
    //   key: "CHANGE_TO_IN_PROGRESS",
    //   translate: "button_in_progress"
    // },
    // {
    //   name: "Согласовать",
    //   statusesToShow: ["ON_CONSIDERATION"],
    //   statusToChange: "SENT",
    //   class: "is-success",
    //   key: "CHANGE_TO_ENDED",
    //   translate: "accept"
    // },
    // {
    //   name: "Отклонить",
    //   statusesToShow: ["ON_CONSIDERATION"],
    //   statusToChange: "REJECTED",
    //   class: "is-warning",
    //   rejectPopupShow: true,
    //   key: "CHANGE_TO_REJECTED",
    //   popupConfig: {
    //     popupTitle: "Отклонение",
    //     commentTitle: "Причина",
    //     commentValidationRules: "required|min:5",
    //     translate: "rejecting",
    //     commentTranslate: "reason"
    //   },
    //   translate: "reject_claim"
    // }

    {
      name: "Принять в работу",
      statusesToShow: ["SENT"],
      statusToChange: "IN_PROGRESS",
      class: "is-success",
      key: "CHANGE_TO_IN_PROGRESS",
      translate: "button_in_progress"
    },
    {
      name: "Согласовать",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "ENDED",
      class: "is-success",
      rejectPopupShow: false,
      key: "CHANGE_TO_ENDED"
    },
    {
      name: "Отказать",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "REJECTED",
      class: "is-warning",
      rejectPopupShow: true,
      key: "CHANGE_TO_REJECTED",
      popupConfig: {
        popupTitle: "Отказ",
        commentTitle: "Причина отказа",
        commentValidationRules: "required|min:5"
      }
    }
  ]
};
