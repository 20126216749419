<template>
  <section>
    <div class="content wizard-content" v-if="currentStep === formStep">
      <h3>{{ $ml.get("full_info") }}</h3>
      <main-form
        v-model="localValue"
        @onValidate="onValidateForm"
        @onInvalid="onInvalidForm"
        :service="currentService"
        :api-resource="apiResource"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === fileStep">
      <h4>{{ $ml.get("upload_req_files") }}</h4>
      <file-upload-list
        :list-key="'required_file'"
        :file-list="localValue['requiredFiles']"
        :is-translate="true"
        :single="false"
        :show-plus="true"
        :max-mb-size="5"
      />

      <div v-for="(item, index) in categoryTable" :key="index">
        <label class="table-input-label">{{
          item.cert.attachedFile.name
        }}</label>
        <v-form-upload
          v-model="item.cert.attachedFile"
          :label="item.cert.label"
          v-validate="'required'"
          :name="item.cert.name"
          :single="false"
          :show-plus="true"
          :save-path="true"
          :only="tableFormatsOnly"
          :max-mb-size="5"
        />
      </div>

      <div v-if="selectedDeclarerTypeId !== 9">
        <label class="table-input-label">{{
          $ml.get("copy_qualification")
        }}</label>
        <table class="attestation-table">
          <thead>
            <tr>
              <th>№</th>
              <th>{{ $ml.get("date_of_assignment") }}</th>
              <th>{{ $ml.get("certificate_number") }}</th>
              <th>{{ $ml.get("course_subject") }}</th>
              <th>{{ $ml.get("place_of_passage") }}</th>
              <th>{{ $ml.get("certificate") }}</th>
              <th>{{ $ml.get("actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in attestationTable" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <b-datepicker
                  icon-pack="far"
                  v-model="item.assignDate"
                  :month-names="JSON.parse($ml.get('months_names'))"
                  :day-names="JSON.parse($ml.get('days_names'))"
                  placeholder="Нажмите для выбора даты..."
                >
                </b-datepicker>
              </td>
              <td>
                <the-mask
                  mask="#############"
                  type="text"
                  v-model="item.certNumber"
                ></the-mask>
              </td>
              <td><input type="text" v-model="item.course" /></td>
              <td><input type="text" v-model="item.spot" /></td>
              <td>
                <v-form-upload
                  v-model="item.cert.attachedFile"
                  :label="item.cert.label"
                  v-validate="'required'"
                  :name="item.cert.name"
                  :single="false"
                  :save-path="true"
                  :only="tableFormatsOnly"
                  :max-mb-size="5"
                />
              </td>
              <td>
                <span
                  v-if="
                    !isEmpty(item.assignDate) &&
                      !isEmpty(item.certNumber) &&
                      !isEmpty(item.course) &&
                      !isEmpty(item.spot) &&
                      item.cert.attachedFile.files.length > 0
                  "
                  class="add-row"
                  @click="attestationTableAddRow"
                  >+</span
                >
                <span
                  v-if="index !== 0"
                  class="remove-row"
                  @click="attestationTableRemoveRow(index)"
                  >-</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="selectedDeclarerTypeId !== 9">
        <label class="table-input-label">{{
          $ml.get("declare_type_files_3_3_1")
        }}</label>
        <table class="attestation-table">
          <thead>
            <tr>
              <th>№</th>
              <th>{{ $ml.get("date_of_assignment") }}</th>
              <th>{{ $ml.get("course") }}</th>
              <th>{{ $ml.get("teacher_level") }}</th>
              <th>{{ $ml.get("certificate") }}</th>
              <th>{{ $ml.get("actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in awardsTeacherDocTable" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <b-datepicker
                  icon-pack="far"
                  v-model="item.assignDate"
                  :month-names="JSON.parse($ml.get('months_names'))"
                  :day-names="JSON.parse($ml.get('days_names'))"
                  placeholder="Нажмите для выбора даты..."
                >
                </b-datepicker>
              </td>
              <td><input type="text" v-model="item.course" /></td>
              <td>
                <select v-model="item.level" style="width: 100%;">
                  <option
                    v-if="$ml.current === 'ru'"
                    value="Уровень района (для педагогов-модераторов)"
                    >Уровень района (для педагогов-модераторов)</option
                  >
                  <option
                    v-if="$ml.current === 'kk'"
                    value="Аудан деңгейі (педагог-модераторлар үшін)"
                    >Аудан деңгейі (педагог-модераторлар үшін)</option
                  >
                  <option
                    v-if="$ml.current === 'ru'"
                    value="Уровень города (для педагогов-модераторов)"
                    >Уровень города (для педагогов-модераторов)</option
                  >
                  <option
                    value="Қала деңгейі (педагог-модераторлар үшін)"
                    v-if="$ml.current === 'kk'"
                    >Қала деңгейі (педагог-модераторлар үшін)</option
                  >
                  <option
                    v-if="$ml.current === 'ru'"
                    value="Уровень области (для педагогов-экспертов и педагогов-исследователей)"
                    >Уровень области (для педагогов-экспертов и
                    педагогов-исследователей)</option
                  >
                  <option
                    value="Облыстың деңгейі (педагог-сарапшылар және педагог-зерттеушілер үшін)"
                    v-if="$ml.current === 'kk'"
                    >Облыстың деңгейі (педагог-сарапшылар және
                    педагог-зерттеушілер үшін)</option
                  >
                  <option
                    v-if="$ml.current === 'ru'"
                    value="Уровень городов республиканского значения и столицы (для педагогов-экспертов и педагогов-исследователей)"
                    >Уровень городов республиканского значения и столицы (для
                    педагогов-экспертов и педагогов-исследователей)</option
                  >
                  <option
                    value="Облыстың деңгейі (педагог-сарапшылар және педагог-зерттеушілер үшін)"
                    v-if="$ml.current === 'kk'"
                    >Облыстың деңгейі (педагог-сарапшылар және
                    педагог-зерттеушілер үшін)</option
                  >
                </select>
              </td>
              <td>
                <v-form-upload
                  v-model="item.cert.attachedFile"
                  :label="item.cert.label"
                  v-validate="'required'"
                  :name="item.cert.name"
                  :single="false"
                  :save-path="true"
                  :only="tableFormatsOnly"
                  :max-mb-size="5"
                />
              </td>
              <td>
                <span
                  v-if="
                    !isEmpty(item.assignDate) &&
                      !isEmpty(item.course) &&
                      !isEmpty(item.level) &&
                      item.cert.attachedFile.files.length > 0
                  "
                  class="add-row"
                  @click="awardsTeacherDocTableAddRow"
                  >+</span
                >
                <span
                  v-if="index !== 0"
                  class="remove-row"
                  @click="awardsTeacherDocTableRemoveRow(index)"
                  >-</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!--      <br /><br />
      <file-upload-list
        :file-list="localValue.testDocsFiles"
        :is-translate="true"
        :single="false"
        :show-plus="true"
        :max-mb-size="5"
      />-->

      <!--      <br /><br />
      <label class="table-input-label">
        {{
          selectedDeclarerTypeId === 2 || selectedDeclarerTypeId === 3
            ? $ml.get("prof_pedagog_awards_info")
            : selectedDeclarerTypeId === 1
            ? $ml.get("prof_teacher_awards_info")
            : $ml.get("awards_teacher_doc")
        }}
      </label>
      <div v-for="(item, index) in proTable" :key="index">
        <v-form-upload
          v-model="item.cert.attachedFile"
          :label="item.cert.label"
          v-validate="'required'"
          :name="item.cert.name"
          :single="false"
          :show-plus="true"
          :save-path="true"
          :only="tableFormatsOnly"
          :max-mb-size="5"
        />
      </div>-->

      <div v-if="this.selectedDeclarerTypeId !== 9">
        <label class="table-input-label">
          {{
            selectedDeclarerTypeId === 2
              ? $ml.get("teacher_awards_childs_little")
              : selectedDeclarerTypeId === 1 || selectedDeclarerTypeId === 3
              ? $ml.get("teacher_awards_childs")
              : $ml.get("awards_child_doc")
          }}
        </label>
        <div v-for="(item, index) in pro2Table" :key="index">
          <v-form-upload
            v-model="item.cert.attachedFile"
            :label="item.cert.label"
            v-validate="'required'"
            :name="item.cert.name"
            :single="false"
            :show-plus="true"
            :save-path="true"
            :only="tableFormatsOnly"
            :max-mb-size="5"
          />
        </div>
      </div>
      <file-upload-list
        v-if="
          this.selectedDeclarerTypeId !== 7 && this.selectedDeclarerTypeId !== 9
        "
        :file-list="localValue.watchDocsFiles"
        :is-translate="true"
        :single="false"
        :show-plus="true"
        :max-mb-size="5"
      />
      <!--      <div
        v-if="selectedDeclarerTypeId !== 10"
        class="field"
        style="width: 50%;"
      >
        <label for="localValue.usersData.videoUrl" class="label">{{
          $ml.get("video_url")
        }}</label>
        <div class="control">
          <input
            id="localValue.usersData.videoUrl"
            name="localValue.usersData.videoUrl"
            v-model="localValue.usersData.videoUrl"
            v-validate="'required'"
            :data-vv-as="$ml.get('video_url')"
            type="text"
            class="input"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.videoUrl')"
          >
            {{ errors.first("localValue.usersData.videoUrl") }}
          </p>
        </div>
      </div>-->
      <file-upload-list
        :file-list="localValue.markDocsFiles"
        :is-translate="true"
        :single="false"
        :show-plus="true"
        :max-mb-size="5"
      />
      <file-upload-list
        :file-list="localValue.declarerTypeFilesFiltered"
        :is-translate="true"
        :single="false"
        :show-plus="true"
        :max-mb-size="5"
      />
      <div
        v-if="
          selectedDeclarerTypeId === 4 ||
            selectedDeclarerTypeId === 5 ||
            selectedDeclarerTypeId === 7 ||
            selectedDeclarerTypeId === 3
        "
      >
        <label class="table-input-label">{{
          $ml.get("students_achievements")
        }}</label>
        <table class="attestation-table">
          <thead>
            <tr>
              <th>№</th>
              <th>{{ $ml.get("date_of_assignment") }}</th>
              <th>{{ $ml.get("course") }}</th>
              <th>{{ $ml.get("teacher_level") }}</th>
              <th>{{ $ml.get("certificate") }}</th>
              <th>{{ $ml.get("actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in studentsAchievementsDocTable"
              :key="index"
            >
              <td>{{ index + 1 }}</td>
              <td>
                <b-datepicker
                  icon-pack="far"
                  v-model="item.assignDate"
                  :month-names="JSON.parse($ml.get('months_names'))"
                  :day-names="JSON.parse($ml.get('days_names'))"
                  placeholder="Нажмите для выбора даты..."
                >
                </b-datepicker>
              </td>
              <td><input type="text" v-model="item.course" /></td>
              <td>
                <select v-model="item.level" style="width: 100%;">
                  <option
                    v-if="$ml.current === 'ru'"
                    value="Уровень района (для педагогов-модераторов)"
                    >Уровень района (для педагогов-модераторов)</option
                  >
                  <option
                    v-if="$ml.current === 'kk'"
                    value="Аудан деңгейі (педагог-модераторлар үшін)"
                    >Аудан деңгейі (педагог-модераторлар үшін)</option
                  >
                  <option
                    v-if="$ml.current === 'ru'"
                    value="Уровень города (для педагогов-модераторов)"
                    >Уровень города (для педагогов-модераторов)</option
                  >
                  <option
                    value="Қала деңгейі (педагог-модераторлар үшін)"
                    v-if="$ml.current === 'kk'"
                    >Қала деңгейі (педагог-модераторлар үшін)</option
                  >
                  <option
                    v-if="$ml.current === 'ru'"
                    value="Уровень области (для педагогов-экспертов и педагогов-исследователей)"
                    >Уровень области (для педагогов-экспертов и
                    педагогов-исследователей)</option
                  >
                  <option
                    value="Облыстың деңгейі (педагог-сарапшылар және педагог-зерттеушілер үшін)"
                    v-if="$ml.current === 'kk'"
                    >Облыстың деңгейі (педагог-сарапшылар және
                    педагог-зерттеушілер үшін)</option
                  >
                  <option
                    v-if="$ml.current === 'ru'"
                    value="Уровень городов республиканского значения и столицы (для педагогов-экспертов и педагогов-исследователей)"
                    >Уровень городов республиканского значения и столицы (для
                    педагогов-экспертов и педагогов-исследователей)</option
                  >
                  <option
                    value="Облыстың деңгейі (педагог-сарапшылар және педагог-зерттеушілер үшін)"
                    v-if="$ml.current === 'kk'"
                    >Облыстың деңгейі (педагог-сарапшылар және
                    педагог-зерттеушілер үшін)</option
                  >
                </select>
              </td>
              <td>
                <v-form-upload
                  v-model="item.cert.attachedFile"
                  :label="item.cert.label"
                  v-validate="'required'"
                  :name="item.cert.name"
                  :single="false"
                  :save-path="true"
                  :only="tableFormatsOnly"
                  :max-mb-size="5"
                />
              </td>
              <td>
                <span
                  v-if="
                    !isEmpty(item.assignDate) &&
                      !isEmpty(item.course) &&
                      !isEmpty(item.level) &&
                      item.cert.attachedFile.files.length > 0
                  "
                  class="add-row"
                  @click="studentsAchievementsDocTableAddRow"
                  >+</span
                >
                <span
                  v-if="index !== 0"
                  class="remove-row"
                  @click="studentsAchievementsDocTableRemoveRow(index)"
                  >-</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <file-upload-list-with-input
        v-if="selectedDeclarerTypeId === 9"
        :file-list="localValue.methodistDocs"
        :is-translate="true"
        :single="false"
        :show-plus="true"
      />
    </div>
    <common-last-step v-if="currentStep === lastStep" />
  </section>
</template>

<script>
import { CommonWizardMixin } from "../../mixins/common-wizard-mixin";
import { TeachersAttestationForm } from "../forms";
import { TheMask } from "vue-the-mask";
import { mapGetters } from "vuex";

export default {
  mixins: [CommonWizardMixin],
  name: "TeachersAttestationWizard",
  components: {
    mainForm: TeachersAttestationForm,
    TheMask
  },
  data() {
    return {
      selectedDeclarerTypeId: 0,
      selectedAreaId: 0,
      tableFormatsOnly: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
      attestationTable: [
        {
          assignDate: new Date(),
          certNumber: 1,
          course: "",
          spot: "",
          cert: {
            attachedFile: {
              files: [],
              label: "Приложите файл",
              name: this.$ml.get("copy_qualification"),
              uploaded: false,
              filename: "",
              size: 0,
              id: 0,
              hash: ""
            }
          }
        }
      ],
      awardsTeacherDocTable: [
        {
          assignDate: new Date(),
          course: "",
          level: "",
          cert: {
            attachedFile: {
              files: [],
              label: "Приложите файл",
              name: this.$ml.get("copy_qualification"),
              uploaded: false,
              filename: "",
              size: 0,
              id: 0,
              hash: ""
            }
          }
        }
      ],
      studentsAchievementsDocTable: [
        {
          assignDate: new Date(),
          course: "",
          level: "",
          cert: {
            attachedFile: {
              files: [],
              label: "Приложите файл",
              name: this.$ml.get("copy_qualification"),
              uploaded: false,
              filename: "",
              size: 0,
              id: 0,
              hash: ""
            }
          }
        }
      ],

      categoryTable: [
        {
          assignDate: new Date(),
          certNumber: 1,
          category: "",
          spot: "",
          cert: {
            attachedFile: {
              files: [],
              label: "Приложите файл",
              name: this.$ml.get("copy_qual_category_and_order"),
              uploaded: false,
              filename: "",
              size: 0,
              id: 0,
              hash: ""
            }
          }
        }
      ],

      proTable: [
        {
          date: new Date(),
          type: 0,
          level: "",
          form: "",
          cert: {
            attachedFile: {
              files: [],
              label: "Приложите файл",
              name: this.$ml.get("prof_teacher_awards_info"),
              uploaded: false,
              filename: "",
              size: 0,
              id: 0,
              hash: ""
            }
          }
        }
      ],

      pro2Table: [
        {
          date: new Date(),
          type: 0,
          level: "",
          form: "",
          cert: {
            attachedFile: {
              files: [],
              label: "Приложите файл",
              name: this.$ml.get("awards_child_doc"),
              uploaded: false,
              filename: "",
              size: 0,
              id: 0,
              hash: ""
            }
          }
        }
      ],
      methodistDocs: [
        {
          label: this.$ml.get("methodist_1"),
          translate: "methodist_1",
          name: this.$ml
            .get("methodist_1")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          headerTitle: this.$ml.get("methodist_title"),
          files: [],
          required: true
        },
        {
          label: this.$ml.get("methodist_2"),
          translate: "methodist_2",
          name: this.$ml
            .get("methodist_2")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("methodist_3"),
          translate: "methodist_3",
          name: this.$ml
            .get("methodist_3")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("methodist_4"),
          translate: "methodist_4",
          name: this.$ml
            .get("methodist_4")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("methodist_5"),
          translate: "methodist_5",
          name: this.$ml
            .get("methodist_5")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("methodist_6"),
          translate: "methodist_6",
          name: this.$ml
            .get("methodist_6")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("methodist_7"),
          translate: "methodist_7",
          name: this.$ml
            .get("methodist_7")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("methodist_8"),
          translate: "methodist_8",
          name: this.$ml
            .get("methodist_8")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        },
        {
          label: this.$ml.get("methodist_9"),
          translate: "methodist_9",
          name: this.$ml
            .get("methodist_9")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          placeholder: "",
          files: [],
          required: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters("services", { declarerTypes: "getDeclarerTypes" })
  },
  watch: {
    attestationTable: {
      handler: function(val, oldVal) {
        val.filter(function(p, idx) {
          return Object.keys(p).some(function(prop) {
            return p[prop] !== oldVal[idx][prop];
          });
        });
        this.handleAttestationTableChange();
      },
      deep: true
    },

    categoryTable: {
      handler: function(val, oldVal) {
        val.filter(function(p, idx) {
          return Object.keys(p).some(function(prop) {
            return p[prop] !== oldVal[idx][prop];
          });
        });
        this.handleCategoryTableChange();
      },
      deep: true
    },

    proTable: {
      handler: function(val, oldVal) {
        val.filter(function(p, idx) {
          return Object.keys(p).some(function(prop) {
            return p[prop] !== oldVal[idx][prop];
          });
        });
        this.handleProTableChange();
      },
      deep: true
    },

    pro2Table: {
      handler: function(val, oldVal) {
        val.filter(function(p, idx) {
          return Object.keys(p).some(function(prop) {
            return p[prop] !== oldVal[idx][prop];
          });
        });
        this.handlePro2TableChange();
      },
      deep: true
    },

    "localValue.markDocsFiles": {
      handler: function() {
        this.checkAdditionalFilesFilled();
      },
      deep: true
    },

    "localValue.declarerTypeFilesFiltered": {
      handler: function() {
        this.checkAdditionalFilesFilled();
      },
      deep: true
    },

    "localValue.usersData.videoUrl": {
      handler: function() {
        this.checkAdditionalFilesFilled();
      },
      deep: true
    },

    "localValue.methodistDocs": {
      handler: function() {
        this.checkAdditionalFilesFilled();
      },
      deep: true
    },

    /*"localValue.testDocsFiles": {
      handler: function() {
        this.checkAdditionalFilesFilled();
      },
      deep: true
    },*/

    "localValue.watchDocsFiles": {
      handler: function() {
        this.checkAdditionalFilesFilled();
      },
      deep: true
    }
  },
  methods: {
    isEmpty(str) {
      return !str || 0 === str.length;
    },
    checkAdditionalFilesFilled() {
      let filteredFiles =
        this.selectedDeclarerTypeId !== 9 &&
        this.localValue.declarerTypeFilesFiltered.every(
          e => e.files.length > 0
        );
      let methodistFilesFilled =
        this.selectedDeclarerTypeId === 9 &&
        this.methodistDocs.every(
          e => e.files.length > 0 && e.placeholder.length > 0
        );
      let watchDocFiles =
        this.selectedDeclarerTypeId === 7 ||
        this.localValue.watchDocsFiles[0].files.length > 0;

      if (filteredFiles || methodistFilesFilled || watchDocFiles) {
        this.$emit("additionalFilesFilled", true);
      } else {
        this.$emit("additionalFilesFilled", false);
      }
    },
    checkTablesFilled() {
      let attestationTableFilled =
        (this.attestationTable.length > 0 &&
          this.attestationTable.every(
            row =>
              !this.isEmpty(row.assignDate) &&
              !this.isEmpty(row.certNumber) &&
              !this.isEmpty(row.course) &&
              !this.isEmpty(row.spot) &&
              row.cert.attachedFile.files.length > 0
          )) ||
        this.selectedDeclarerTypeId === 9;

      let categoryTableFilled =
        this.categoryTable.length > 0 &&
        this.categoryTable.every(row => row.cert.attachedFile.files.length > 0);

      /*let proTableFilled =
        this.proTable.length > 0 &&
        this.proTable.every(row => row.cert.attachedFile.files.length > 0);*/

      if (attestationTableFilled && categoryTableFilled) {
        this.$emit("tablesfilled", true);
      } else {
        this.$emit("tablesfilled", false);
      }
    },

    getStudyMarkDocLabel(declarerType) {
      switch (declarerType) {
        case 3:
          return "mark_study_accept_doc";
        case 4:
          return "mark_study_formed_doc";
        case 5:
          return "mark_study_formed_doc";
        case 6:
          return "mark_study_quality_doc";
        case 7:
          return "mark_study_mastering_doc";
        case 8:
          return "mark_study_result_doc";
        case 9:
          return "mark_study_accept_doc";
      }
    },

    handleAttestationTableChange() {
      this.localValue.usersData.tablesData.attestationTable = this.attestationTable;
      this.checkTablesFilled();
    },

    handleCategoryTableChange() {
      this.localValue.usersData.tablesData.categoryTable = this.categoryTable;
      this.checkTablesFilled();
    },

    handleProTableChange() {
      this.localValue.usersData.tablesData.proTable = this.proTable;
      this.checkTablesFilled();
    },

    handlePro2TableChange() {
      this.localValue.usersData.tablesData.pro2Table = this.pro2Table;
      this.checkTablesFilled();
    },
    attestationTableAddRow() {
      this.attestationTable.push({
        assignDate: new Date(),
        certNumber: 1,
        course: "",
        spot: "",
        cert: {
          attachedFile: {
            files: [],
            label: "Приложите файл",
            name: "file_1",
            uploaded: false,
            filename: "",
            size: 0,
            id: 0,
            hash: ""
          }
        }
      });
    },
    awardsTeacherDocTableAddRow() {
      this.awardsTeacherDocTable.push({
        assignDate: new Date(),
        course: "",
        level: "",
        cert: {
          attachedFile: {
            files: [],
            label: "Приложите файл",
            name: "file_1",
            uploaded: false,
            filename: "",
            size: 0,
            id: 0,
            hash: ""
          }
        }
      });
    },
    studentsAchievementsDocTableAddRow() {
      this.studentsAchievementsDocTable.push({
        assignDate: new Date(),
        course: "",
        level: "",
        cert: {
          attachedFile: {
            files: [],
            label: "Приложите файл",
            name: "file_1",
            uploaded: false,
            filename: "",
            size: 0,
            id: 0,
            hash: ""
          }
        }
      });
    },

    categoryTableAddRow() {
      this.categoryTable.push({
        assignDate: new Date(),
        certNumber: 1,
        category: "",
        spot: "",
        cert: {
          attachedFile: {
            files: [],
            label: "Приложите файл",
            name: "file_1",
            uploaded: false,
            filename: "",
            size: 0,
            id: 0,
            hash: ""
          }
        }
      });
    },

    proTableAddRow() {
      this.proTable.push({
        date: new Date(),
        type: 0,
        level: "",
        form: "",
        cert: {
          attachedFile: {
            files: [],
            label: "Приложите файл",
            name: "file_1",
            uploaded: false,
            filename: "",
            size: 0,
            id: 0,
            hash: ""
          }
        }
      });
    },

    pro2TableAddRow() {
      this.pro2Table.push({
        date: new Date(),
        type: 0,
        level: "",
        form: "",
        cert: {
          attachedFile: {
            files: [],
            label: "Приложите файл",
            name: "file_1",
            uploaded: false,
            filename: "",
            size: 0,
            id: 0,
            hash: ""
          }
        }
      });
    },

    attestationTableRemoveRow(index) {
      this.attestationTable.splice(index, 1);
    },
    awardsTeacherDocTableRemoveRow(index) {
      this.awardsTeacherDocTable.splice(index, 1);
    },
    studentsAchievementsDocTableRemoveRow(index) {
      this.studentsAchievementsDocTable.splice(index, 1);
    },
    categoryTableRemoveRow(index) {
      this.categoryTable.splice(index, 1);
    },
    proTableRemoveRow(index) {
      this.proTable.splice(index, 1);
    },
    pro2TableRemoveRow(index) {
      this.pro2Table.splice(index, 1);
    }
  },
  beforeMount() {
    this.deactivateStep(this.formStep);

    this.$set(this.localValue, "usersData", {
      iin: "",
      email: "",
      phone: "",

      jobPlace: "",
      position: "",
      category: "",
      declarerType: "",
      claimedCategory: "",
      categoryExpirationDate: "",

      studyPlace: "",
      studyPeriod: "",
      specialty: "",
      grade: "",

      experience: {
        total: "",
        bySpecialty: "",
        pedagogical: "",
        atThisOrganisation: ""
      },

      tablesData: {
        attestationTable: this.attestationTable,
        awardsTeacherDocTable: this.awardsTeacherDocTable,
        studentsAchievementsDocTable: this.studentsAchievementsDocTable,
        categoryTable: this.categoryTable,
        proTable: this.proTable,
        pro2Table: this.pro2Table
      },

      grades: ""
    });

    this.selectedDeclarerTypeId = parseInt(
      this.$route.params.declarertype ? this.$route.params.declarertype : 0
    );
    this.selectedAreaId = parseInt(
      this.$route.params.area ? this.$route.params.area : 0
    );
    const declarerTypeExists = this.declarerTypes(this.slug)
      .map(type => type.number)
      .includes(this.selectedDeclarerTypeId);
    if (!declarerTypeExists) {
      return this.$router.push({ name: "service.main" });
    }
    const type = this.declarerTypes(this.slug).find(
      type => type.number === this.selectedDeclarerTypeId
    );
    const declarerTypeAvailableForClaim = type ? type.availableForClaim : false;
    if (!declarerTypeAvailableForClaim) {
      return this.$router.push({ name: "service.main" });
    }

    this.localValue.usersData.declarerType = this.selectedDeclarerTypeId;
    this.localValue.usersData.area = this.selectedAreaId;

    /*this.$set(this.localValue, "testDocsFiles", [
      {
        label:
          "Документ о прохождении национального квалификационного тестирования",
        translate: "national_testing_doc",
        name: "Справка_НКТ",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      }
    ]);*/

    this.$set(this.localValue, "watchDocsFiles", [
      {
        label: "Листы наблюдения уроков/занятий",
        translate: "list_of_watching_doc",
        name: "Лист_наблюдения",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      }
    ]);

    /*this.$set(this.localValue, "markDocsFiles", [
      {
        label: this.$ml.get(
          this.getStudyMarkDocLabel(this.selectedDeclarerTypeId)
        ),
        translate: this.getStudyMarkDocLabel(this.selectedDeclarerTypeId),
        name: "Показатель",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      }
    ]);*/

    const declarerTypeFiles = {
      //Педагоги технического и профессионального образования
      3: [
        {
          label:
            "Качество знаний обучающихся за последние три года. С учетом динамики качества знаний обучающихся (студентов) (семестр/ год)\n",
          translate: "declare_type_files_3_1",
          name: this.$ml
            .get("declare_type_files_3_1")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }
        /*{
          label:
            "Наличие квалификационного разряда, " +
            "категории по профилю (для мастеров производственного обучения)",
          translate: "declare_type_files_3_2",
          name: "Наличие_квалификационного_разряда",
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label:
            "Достижения, обучающихся в конкурсах или олимпиадах, " +
            "или соревнованиях в соответствии с приказом № 514",
          translate: "declare_type_files_3_3",
          name: "Достижения_обучающихся_в_конкурсах_или_олимпиадах",
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }*/
      ],
      //Начальное, основное среднее и общее среднее образование
      4: [
        {
          label:
            "Качество знаний обучающихся за последние три учебных года. " +
            "С учетом динамики качества знаний (четверть/полугодие)",
          translate: "knowledge_quality",
          name: this.$ml
            .get("knowledge_quality")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label: "Работа со слабоуспевающими учащимися",
          translate: "underachieving_students",
          name: this.$ml
            .get("underachieving_students")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        /*{
          label:
            "Достижения, обучающихся в конкурсах или олимпиадах, " +
            "или соревнованиях в соответствии с приказом № 514",
          translate: "students_achievements",
          name: "Достижения_обучающихся",
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },*/
        {
          label: "Качество реализации индивидуальной развивающей программы",
          translate: "declare_type_files_4_3",
          name: this.$ml
            .get("declare_type_files_4_3")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }
      ],
      //Дошкольное воспитание и обучение
      5: [
        {
          label:
            "Сформированность умений и навыков воспитанников за последние" +
            " три учебных года. С учетом динамики сформированности умений " +
            "и навыков (стартовый/промежуточный/итоговый) ",
          translate: "formation_of_skills",
          name: this.$ml
            .get("formation_of_skills")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label:
            "Проведение исследования урока и применение инновационных методов",
          translate: "teachers_attestation_5_2",
          name: this.$ml
            .get("teachers_attestation_5_2")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label: "Качество реализации индивидуальной развивающей программы",
          translate: "declare_type_files_4_3",
          name: this.$ml
            .get("declare_type_files_4_3")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }
      ],
      //Дополнительное образование и другие
      7: [
        {
          label:
            "Уровень освоения выбранной образовательной программы обучающимися, " +
            "воспитанниками (согласно разработанному диагностическому инструментарию) " +
            "за последние три года",
          translate: "mastering_level",
          name: this.$ml
            .get("mastering_level")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }
        /*{
          label:
            "Достижения воспитанников в творческих конкурсах или соревнованиях, " +
            "или олимпиадах в соответствии с приказом № 514",
          translate: "students_achievements_1",
          name: "Достижения_воспитанников_в_творческих_конкурсах",
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }*/
      ],
      //Специальные коррекционные учреждения образования и другие
      8: [
        {
          label:
            "Качество реализации индивидуальной развивающей программы " +
            "(за исключением педагогов ПМПК) за последние три года " +
            "(для специальных педагогов, психологов, инструкторов ЛФК, социальных педагогов)",
          translate: "individual_mastering_level",
          name: this.$ml
            .get("individual_mastering_level")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }
        /*{
          label:
            "Качество знаний обучающихся за последние " +
            "три учебных года. С учетом динамики качества знаний " +
            "(четверть/полугодие) для педагогов-предметников " +
            "специальных школ (школ-интернатов, классов)",
          translate: "students_quality_3_years",
          name: "Качество_знаний_обучающихся_за_последние_три_учебных_года",
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }*/
      ],
      //Методические кабинеты (центры)
      9: [
        /*{
          label:
            "Автор/соавтор разработанных программ или методических пособий, " +
            "или рекомендаций или учебно-методических комплексов, одобренных " +
            "учебно-методическим советом соответствующего уровня или имеющих " +
            "свидетельство о внесении сведений в государственный реестр прав " +
            "на объекты, охраняемые авторским правом, на разработанные материалы",
          translate: "co_author",
          name: this.$ml
            .get("co_author")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label:
            "Публикации, выступления на научно-практических конференциях или семинарах, или форумах",
          translate: "publication",
          name: this.$ml
            .get("publication")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label: "Наблюдение уроков/занятий педагогов за последние три года",
          translate: "lessons_observation",
          name: this.$ml
            .get("lessons_observation")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label:
            "Участие в проектах, исследовательской или инновационной или " +
            "экспериментальной деятельности (организация и координация " +
            "деятельности экспериментальной/инновационной площадки, " +
            "проведение исследований, рецензирование проектов (методических, дипломных и др.)",
          translate: "project_participation",
          name: this.$ml
            .get("project_participation")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label:
            "Участие в рабочих группах или экспертных советах, или конкурсных комиссиях/жюри",
          translate: "working_groups_participation",
          name: this.$ml
            .get("working_groups_participation")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label: "Количество организованных семинаров, конференций",
          translate: "organized_seminars",
          name: this.$ml
            .get("organized_seminars")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label: "Эффективность инициатив в работе с педагогами",
          translate: "working_effectiveness",
          name: this.$ml
            .get("working_effectiveness")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label:
            "Наличие сертификата о курсах повышения квалификации, разрешенных уполномоченным органом",
          translate: "certificates",
          name: this.$ml
            .get("certificates")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label:
            "Распространение педагогического опыта методиста " +
            "по курируемому направлению в профессиональном сообществе через социальные сети",
          translate: "social_media",
          name: this.$ml
            .get("social_media")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }*/
      ],
      //Психолого-медико-педагогических консультации (ПМПК) и Кабинеты психолого- педагогической коррекции (КППК)
      10: [
        {
          label:
            "Качество реализации программы психолого-педагогического " +
            "обследования детей за последние три года " +
            "(для специальных педагогов, психологов, социальных педагогов)",
          translate: "children_quality_3_years",
          name: this.$ml
            .get("children_quality_3_years")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label:
            "Качество реализации программы психолого-педагогического " +
            "консультирования семьи ребенка за последние три года " +
            "(для специальных педагогов, психологов, социальных педагогов)",
          translate: "children_family_quality_3_years",
          name: this.$ml
            .get("children_family_quality_3_years")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }
      ],
      //Общеобразовательные школы интернаты (для воспитателей)
      11: [
        {
          label: "Разработка программ или рекомендаций, или пособий",
          translate: "guidelines_development",
          name: this.$ml
            .get("guidelines_development")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label:
            "Взаимодействие со структурными подразделениями социальными " +
            "партнерами, с НПО (количество проведенных мероприятий)",
          translate: "interaction_units",
          name: this.$ml
            .get("interaction_units")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        },
        {
          label: "Качество коррекционно-развивающих или воспитательных занятий",
          translate: "teachers_attestation_11_3",
          name: this.$ml
            .get("teachers_attestation_11_3")
            .replaceAll(" ", "_")
            .replaceAll('"', "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("\n", ""),
          only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
          files: []
        }
      ]
    };

    let declarerTypeFilesFiltered = Object.keys(declarerTypeFiles)
      .filter(e => this.selectedDeclarerTypeId === parseInt(e))
      .reduce((obj, key) => {
        obj = declarerTypeFiles[key];
        return obj;
      }, {});

    if (this.selectedDeclarerTypeId !== 9) {
      this.$set(
        this.localValue,
        "declarerTypeFilesFiltered",
        declarerTypeFilesFiltered
      );
    }
    let requiredFiles = [
      {
        label: "Заявление",
        translate: "statement_of_certified",
        name: "Заявление",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      },
      /*{
        label: "Копия удостоверения личности",
        translate: "copy_passport",
        name: "Удостоверение",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      },
      {
        label: "Эссе",
        translate: "essay",
        name: "Эссе",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      },*/
      {
        label:
          "Копия диплома об образовании, документ о прохождении курсов переподготовки (при наличии)",
        translate: "copy_diplom",
        name: "Диплом",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      },
      {
        label:
          "Копия документа, подтверждающего трудовую деятельность работника",
        translate: "copy_work_doc",
        name: "Трудовая_Деятельность",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      },
      {
        label: "Копия приказа о ранее присвоенной квалификационной категории",
        translate: "copy_qualifying_category",
        name: "Копия_приказа_о_ранее_присвоенной_квалификационной_категории",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      }
    ];

    if (this.selectedDeclarerTypeId === 9) {
      this.$set(this.localValue, "methodistDocs", this.methodistDocs);
    } else {
      requiredFiles.push({
        label:
          "Выписка из протокола педагогического совета организации образования (Приложение 29 к настоящим Правилам)",
        translate: "copy_vipiska",
        name: "Протокол",
        only: ["jpg", "jpeg", "gif", "png", "tif", "tiff", "pdf"],
        files: []
      });
    }
    this.setRequiredFiles(requiredFiles);
  }
};
</script>

<style lang="scss">
.attestation-table,
.category-table,
.pro-table,
.pro-2-table {
  width: 100%;
  input,
  select {
    border: 1px solid #dbdbdb;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
  }

  td {
    vertical-align: top;
  }

  tr {
    &:nth-child(2n) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .add-row,
  .remove-row {
    padding: 1px 8px;
    vertical-align: top;
    display: inline-block;
    margin-right: 7px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50px;
    background: #cad9ff;
  }

  .remove-row {
    padding: 1px 11px !important;
  }

  .file-upload-input-label {
    display: none !important;
  }
}

.table-input-label {
  font-weight: bold;
  margin: 0 5px;
}
</style>
