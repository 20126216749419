import Vue from "./services/vue-instance";
import Router from "vue-router";
import routes from "./routes";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
  linkActiveClass: "is-active",
  base: process.env.BASE_URL
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters["user/isLoggedIn"];

  let needLogin = false;
  to.matched.forEach(route => {
    if (route.meta.auth !== undefined) {
      needLogin = route.meta.auth;
    }
  });
  if (needLogin) {
    if (isLoggedIn) {
      return next();
    } else {
      localStorage.setItem("linkBeforeLogin", to.fullPath);
      return next({ name: "remote.login" });
    }
  }
  return next();
});

export default router;
