export default {
  "special-needs-social-services": [
    {
      name: "Записать на прием",
      statusesToShow: ["REGISTERED"],
      statusToChange: "AWAITING_FOR_RECEPTION",
      class: "is-info",
      rejectPopupShow: false,
      dateTimePopupShow: true,
      key: "CHANGE_TO_AWAITING_FOR_RECEPTION"
    },
    {
      name: "Обследование и консультация проведены",
      statusesToShow: ["AWAITING_FOR_RECEPTION"],
      statusToChange: "DIRECTION_ISSUED",
      class: "is-success",
      rejectPopupShow: false,
      dateTimePopupShow: false,
      PMPKPopupShow: true,
      key: "CHANGE_TO_DIRECTION_ISSUED"
    },
    {
      name: "Заявитель не подошел",
      statusesToShow: ["AWAITING_FOR_RECEPTION"],
      statusToChange: "ENDED_NON_APPEARANCE",
      class: "is-warning",
      rejectPopupShow: false,
      dateTimePopupShow: false,
      PMPKPopupShow: false,
      key: "CHANGE_TO_ENDED_NON_APPEARANCE"
    },
    {
      name: "Отказать в приеме",
      statusesToShow: ["AWAITING_FOR_RECEPTION"],
      statusToChange: "REJECTED",
      class: "is-warning",
      rejectPopupShow: true,
      dateTimePopupShow: false,
      PMPKPopupShow: false,
      key: "CHANGE_TO_REJECTED",
      popupConfig: {
        popupTitle: "Отказ",
        commentTitle: "Причина отказа",
        commentValidationRules: "required|min:5"
      }
    },
    {
      name: "Редактировать",
      statusesToShow: ["AWAITING_FOR_RECEPTION"],
      statusToChange: "AWAITING_FOR_RECEPTION",
      class: "is-info",
      rejectPopupShow: false,
      dateTimePopupShow: true,
      PMPKPopupShow: false,
      key: "CHANGE_FROM_AWAITING_FOR_RECEPTION_TO_AWAITING_FOR_RECEPTION"
    }
  ]
};
