<template>
  <section>
    <div class="modal-padded-body">
      <div class="field">
        <label class="label" for="localValue.usersData.iin">{{
          $ml.get("my_iin")
        }}</label>
        <div class="control">
          <input
            id="localValue.usersData.iin"
            type="text"
            class="input"
            v-model="localValue.usersData.iin"
            v-validate="usersIinRules"
            :data-vv-as="'ИИН'"
            readonly
            name="localValue.usersData.iin"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.iin')"
          >
            {{ errors.first("localValue.usersData.iin") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("fio") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getNameFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("birthday") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="usersProfile.birth_date"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("address_registr") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getAddressFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label" for="usersData.email">{{
          $ml.get("email")
        }}</label>
        <div class="control">
          <input
            id="usersData.email"
            type="text"
            class="input"
            v-validate="'required|email'"
            :data-vv-as="$ml.get('email')"
            v-model="localValue.usersData.email"
            name="localValue.usersData.email"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.email')"
          >
            {{ errors.first("localValue.usersData.email") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label" for="usersData.phone">{{
          $ml.get("phone")
        }}</label>
        <div class="control">
          <input
            id="usersData.phone"
            type="text"
            class="input"
            v-validate="'required|min:10|max:10|numeric'"
            :data-vv-as="$ml.get('phone')"
            v-model="localValue.usersData.phone"
            name="localValue.usersData.phone"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.phone')"
          >
            {{ errors.first("localValue.usersData.phone") }}
          </p>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.jobPlace" class="label">{{
              $ml.get("workplace")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.jobPlace"
                name="localValue.usersData.jobPlace"
                v-model="localValue.usersData.jobPlace"
                v-validate="'required'"
                :data-vv-as="$ml.get('workplace')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.jobPlace')"
              >
                {{ errors.first("localValue.usersData.jobPlace") }}
              </p>
            </div>
          </div>
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.position" class="label">{{
              $ml.get("position")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.position"
                name="localValue.usersData.position"
                v-model="localValue.usersData.position"
                v-validate="'required'"
                :data-vv-as="$ml.get('position')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.position')"
              >
                {{ errors.first("localValue.usersData.position") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.category" class="label">{{
              $ml.get("category_exist")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.category"
                name="localValue.usersData.category"
                v-model="localValue.usersData.category"
                :data-vv-as="$ml.get('category_exist')"
                v-validate="'required'"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.category')"
              >
                {{ errors.first("localValue.usersData.category") }}
              </p>
            </div>
          </div>
          <div class="field" style="width: 50%;">
            <label
              for="localValue.usersData.categoryExpirationDate"
              class="label"
              >{{ $ml.get("time_category") }}</label
            >
            <div class="control">
              <input
                id="localValue.usersData.categoryExpirationDate"
                name="localValue.usersData.categoryExpirationDate"
                v-model="localValue.usersData.categoryExpirationDate"
                v-validate="'required'"
                :data-vv-as="$ml.get('time_category')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.categoryExpirationDate')"
              >
                {{
                  errors.first("localValue.usersData.categoryExpirationDate")
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--http://jira.samgau.com/browse/UOALM-220-->
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.claimedCategory" class="label">{{
              $ml.get("claimed_qualification_category")
            }}</label>
            <div class="select">
              <!--              <input
                id="localValue.usersData.claimedCategory"
                name="localValue.usersData.claimedCategory"
                v-model="localValue.usersData.claimedCategory"
                v-validate="'required'"
                :data-vv-as="$ml.get('claimed_qualification_category')"
                type="text"
                class="input"
              />-->
              <select
                v-model="localValue.usersData.claimedCategory"
                name="localValue.usersData.claimedCategory"
                v-validate="'required'"
                id="localValue.usersData.claimedCategory"
                :data-vv-as="$ml.get('claimed_qualification_category')"
                type="text"
                style="width: 100%"
              >
                <option v-if="$ml.current === 'ru'" value="Педагог-модератор"
                  >Педагог-модератор</option
                >
                <option v-if="$ml.current === 'kk'" value="Педагог-модератор"
                  >Педагог-модератор</option
                >
                <option v-if="$ml.current === 'ru'" value="Педагог-эксперт"
                  >Педагог-эксперт</option
                >
                <option value="Педагог-эксперт" v-if="$ml.current === 'kk'"
                  >Педагог-сарапшы</option
                >
                <option
                  v-if="$ml.current === 'ru'"
                  value="Педагог-исследователь"
                  >Педагог-исследователь</option
                >
                <option
                  value="Педагог-исследователь"
                  v-if="$ml.current === 'kk'"
                  >Педагог–зерттеуші</option
                >
                <option
                  v-if="$ml.current === 'ru'"
                  value="Педагог-мастер"
                  >Педагог-мастер</option
                >
                <option
                  value="Педагог-мастер"
                  v-if="$ml.current === 'kk'"
                  >Педагог-шебер</option
                >
              </select>
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.claimedCategory')"
              >
                {{ errors.first("localValue.usersData.claimedCategory") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h4>{{ $ml.get("education") }}</h4>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.studyPlace" class="label">{{
              $ml.get("name_univer")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.studyPlace"
                name="localValue.usersData.studyPlace"
                v-model="localValue.usersData.studyPlace"
                v-validate="'required'"
                :data-vv-as="$ml.get('name_univer')"
                type="text"
                class="input"
              />
            </div>
          </div>
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.studyPeriod" class="label">{{
              $ml.get("time_education")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.studyPeriod"
                name="localValue.usersData.studyPeriod"
                v-model="localValue.usersData.studyPeriod"
                v-validate="'required'"
                :data-vv-as="$ml.get('time_education')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.studyPeriod')"
              >
                {{ errors.first("localValue.usersData.studyPeriod") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.specialty" class="label">{{
              $ml.get("speciality")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.specialty"
                name="localValue.usersData.specialty"
                v-model="localValue.usersData.specialty"
                v-validate="'required'"
                :data-vv-as="$ml.get('name_univer')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.specialty')"
              >
                {{ errors.first("localValue.usersData.specialty") }}
              </p>
            </div>
          </div>
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.grade" class="label">{{
              $ml.get("qualification")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.grade"
                name="localValue.usersData.grade"
                v-model="localValue.usersData.grade"
                v-validate="'required'"
                :data-vv-as="$ml.get('time_education')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.grade')"
              >
                {{ errors.first("localValue.usersData.grade") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h4>{{ $ml.get("worktime") }}</h4>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 25%;">
            <label for="localValue.usersData.experience.total" class="label">{{
              $ml.get("full")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.experience.total"
                name="localValue.usersData.experience.total"
                v-model="localValue.usersData.experience.total"
                v-validate="'required'"
                :data-vv-as="$ml.get('full')"
                type="number"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.experience.total')"
              >
                {{ errors.first("localValue.usersData.experience.total") }}
              </p>
            </div>
          </div>

          <div class="field" style="width: 25%;">
            <label
              for="localValue.usersData.experience.bySpecialty"
              class="label"
              >{{ $ml.get("from_spec") }}</label
            >
            <div class="control">
              <input
                id="localValue.usersData.experience.bySpecialty"
                name="localValue.usersData.experience.bySpecialty"
                v-model="localValue.usersData.experience.bySpecialty"
                v-validate="'required'"
                :data-vv-as="$ml.get('from_spec')"
                type="number"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.experience.bySpecialty')"
              >
                {{
                  errors.first("localValue.usersData.experience.bySpecialty")
                }}
              </p>
            </div>
          </div>

          <div class="field" style="width: 25%;">
            <label
              for="localValue.usersData.experience.pedagogical"
              class="label"
              >{{ $ml.get("teaching") }}</label
            >
            <div class="control">
              <input
                id="localValue.usersData.experience.pedagogical"
                name="localValue.usersData.experience.pedagogical"
                v-model="localValue.usersData.experience.pedagogical"
                v-validate="'required'"
                :data-vv-as="$ml.get('teaching')"
                type="number"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.experience.pedagogical')"
              >
                {{
                  errors.first("localValue.usersData.experience.pedagogical")
                }}
              </p>
            </div>
          </div>

          <div class="field" style="width: 25%;">
            <label
              for="localValue.usersData.experience.atThisOrganisation"
              class="label"
              >{{ $ml.get("in_current_organization") }}</label
            >
            <div class="control">
              <input
                id="localValue.usersData.experience.atThisOrganisation"
                name="localValue.usersData.experience.atThisOrganisation"
                v-model="localValue.usersData.experience.atThisOrganisation"
                v-validate="'required'"
                :data-vv-as="$ml.get('in_current_organization')"
                type="number"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="
                  errors.has(
                    'localValue.usersData.experience.atThisOrganisation'
                  )
                "
              >
                {{
                  errors.first(
                    "localValue.usersData.experience.atThisOrganisation"
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.grades" class="label">{{
              $ml.get("diploms")
            }}</label>
            <div class="control">
              <textarea
                id="localValue.usersData.grades"
                name="localValue.usersData.grades"
                v-model="localValue.usersData.grades"
                v-validate="'required'"
                :data-vv-as="$ml.get('diploms')"
                class="textarea"
              ></textarea>
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.grades')"
              >
                {{ errors.first("localValue.usersData.grades") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input
              id="localValue.agreement"
              type="checkbox"
              v-validate="'required'"
              :data-vv-as="$ml.get('agreement')"
              v-model="localValue.agreement"
              name="localValue.agreement"
            />
            {{ $ml.get("accept_my_info") }}
          </label>
          <p class="help is-danger" v-if="errors.has('localValue.agreement')">
            {{ errors.first("localValue.agreement") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CommonFormMixin } from "../../mixins/common-form-mixin";

export default {
  name: "TeachersAttestationForm",
  mixins: [CommonFormMixin]
};
</script>
