class Services {
  constructor(axios) {
    this.axios = axios;
  }
  list() {
    return this.axios.get("/api/service/list");
  }
}

export default Services;
