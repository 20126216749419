<template>
  <section>
    <div class="modal-padded-body">
      <div class="field">
        <label class="label" for="localValue.usersData.iin">{{
          $ml.get("my_iin")
        }}</label>
        <div class="control">
          <input
            id="localValue.usersData.iin"
            type="text"
            class="input"
            v-model="localValue.usersData.iin"
            v-validate="usersIinRules"
            :data-vv-as="'ИИН'"
            readonly
            name="localValue.usersData.iin"
          />
          <p
            class="help is-danger"
            v-if="errors.has('localValue.usersData.iin')"
          >
            {{ errors.first("localValue.usersData.iin") }}
          </p>
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("fio") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getNameFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("birthday") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="usersProfile.birth_date"
            disabled
          />
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $ml.get("address_registr") }}</label>
        <div class="control">
          <input
            type="text"
            class="input"
            :value="getAddressFromUserInfo(usersProfile)"
            disabled
          />
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%">
            <label class="label" for="usersData.email">{{
              $ml.get("email")
            }}</label>
            <div class="control">
              <input
                id="usersData.email"
                type="text"
                class="input"
                v-validate="'required|email'"
                :data-vv-as="$ml.get('email')"
                v-model="localValue.usersData.email"
                name="localValue.usersData.email"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.email')"
              >
                {{ errors.first("localValue.usersData.email") }}
              </p>
            </div>
          </div>
          <div class="field" style="width: 50%">
            <label class="label" for="usersData.phone">{{
              $ml.get("phone")
            }}</label>
            <div class="control">
              <input
                id="usersData.phone"
                type="text"
                class="input"
                v-validate="'required|min:10|max:10|numeric'"
                :data-vv-as="$ml.get('phone')"
                v-model="localValue.usersData.phone"
                name="localValue.usersData.phone"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.phone')"
              >
                {{ errors.first("localValue.usersData.phone") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.jobPlace" class="label">{{
              $ml.get("workplace")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.jobPlace"
                name="localValue.usersData.jobPlace"
                v-model="localValue.usersData.jobPlace"
                v-validate="'required'"
                :data-vv-as="$ml.get('workplace')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.jobPlace')"
              >
                {{ errors.first("localValue.usersData.jobPlace") }}
              </p>
            </div>
          </div>
          <div
            class="field"
            style="width: 50%;"
            v-if="localValue.usersData.declarerType == 1"
          >
            <label for="localValue.usersData.deputy" class="label">{{
              $ml.get("deputy")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.deputy"
                name="localValue.usersData.deputy"
                v-model="localValue.usersData.deputy"
                v-validate="'required'"
                :data-vv-as="$ml.get('deputy')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.deputy')"
              >
                {{ errors.first("localValue.usersData.deputy") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.category" class="label">{{
              $ml.get("category_exist")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.category"
                name="localValue.usersData.category"
                v-model="localValue.usersData.category"
                :data-vv-as="$ml.get('category_exist')"
                v-validate="'required'"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.category')"
              >
                {{ errors.first("localValue.usersData.category") }}
              </p>
            </div>
          </div>
          <div class="field" style="width: 50%;">
            <label
              for="localValue.usersData.categoryExpirationDate"
              class="label"
              >{{ $ml.get("time_category") }}</label
            >
            <div class="control">
              <input
                id="localValue.usersData.categoryExpirationDate"
                name="localValue.usersData.categoryExpirationDate"
                v-model="localValue.usersData.categoryExpirationDate"
                v-validate="'required'"
                :data-vv-as="$ml.get('time_category')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.categoryExpirationDate')"
              >
                {{
                  errors.first("localValue.usersData.categoryExpirationDate")
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.claimedCategory" class="label">{{
              $ml.get("claimed_qualification_category")
            }}</label>
            <div class="select">
              <!--              <input
                id="localValue.usersData.claimedCategory"
                name="localValue.usersData.claimedCategory"
                v-model="localValue.usersData.claimedCategory"
                v-validate="'required'"
                :data-vv-as="$ml.get('claimed_qualification_category')"
                type="text"
                class="input"
              />-->
              <select
                v-model="localValue.usersData.claimedCategory"
                name="localValue.usersData.claimedCategory"
                v-validate="'required'"
                id="localValue.usersData.claimedCategory"
                :data-vv-as="$ml.get('claimed_qualification_category')"
                type="text"
                style="width: 100%"
              >
                <option
                  v-if="
                    localValue.usersData.declarerType === 1 &&
                      $ml.current === 'ru'
                  "
                  value="1 – категория"
                  >1 – категория</option
                >
                <option
                  v-if="
                    localValue.usersData.declarerType === 1 &&
                      $ml.current === 'ru'
                  "
                  value="2 – категория"
                  >2 – категория</option
                >
                <option
                  v-if="
                    localValue.usersData.declarerType === 1 &&
                      $ml.current === 'ru'
                  "
                  value="3 – категория"
                  >3 – категория</option
                >
                <option
                  v-if="
                    localValue.usersData.declarerType === 1 &&
                      $ml.current === 'kk'
                  "
                  value="1 – санат"
                  >1 – санат</option
                >
                <option
                  v-if="
                    localValue.usersData.declarerType === 1 &&
                      $ml.current === 'kk'
                  "
                  value="2 – санат"
                  >2 – санат</option
                >
                <option
                  v-if="
                    localValue.usersData.declarerType === 1 &&
                      $ml.current === 'kk'
                  "
                  value="3 – санат"
                  >3 – санат</option
                >
                <option
                  v-if="
                    localValue.usersData.declarerType === 2 &&
                      $ml.current === 'ru'
                  "
                  value="Руководитель-организатор"
                  >Руководитель-организатор
                </option>
                <option
                  v-if="
                    localValue.usersData.declarerType === 2 &&
                      $ml.current === 'ru'
                  "
                  value="Руководитель-менеджер"
                  >Руководитель-менеджер
                </option>
                <option
                  v-if="
                    localValue.usersData.declarerType === 2 &&
                      $ml.current === 'ru'
                  "
                  value="Руководитель-лидер"
                  >Руководитель-лидер
                </option>
                <option
                  v-if="
                    localValue.usersData.declarerType === 2 &&
                      $ml.current === 'kk'
                  "
                  value="Руководитель-организатор"
                  >Басшы-ұйымдастырушы
                </option>
                <option
                  v-if="
                    localValue.usersData.declarerType === 2 &&
                      $ml.current === 'kk'
                  "
                  value="Руководитель-менеджер"
                  >Басшы-менеджер
                </option>
                <option
                  v-if="
                    localValue.usersData.declarerType === 2 &&
                      $ml.current === 'kk'
                  "
                  value="Руководитель-лидер"
                  >Басшы-көшбасшы
                </option>
              </select>
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.claimedCategory')"
              >
                {{ errors.first("localValue.usersData.claimedCategory") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h4>{{ $ml.get("education") }}</h4>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.studying" class="label">
              {{ $ml.get("name_univer2") }}
            </label>
            <div class="control">
              <input
                id="localValue.usersData.studying"
                name="localValue.usersData.studying"
                v-model="localValue.usersData.studying"
                v-validate="'required'"
                :data-vv-as="$ml.get('name_univer2')"
                type="text"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.studying')"
              >
                {{ errors.first("localValue.usersData.studying") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h4>{{ $ml.get("worktime") }}</h4>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 25%;">
            <label for="localValue.usersData.experience.total" class="label">{{
              $ml.get("full")
            }}</label>
            <div class="control">
              <input
                id="localValue.usersData.experience.total"
                name="localValue.usersData.experience.total"
                v-model="localValue.usersData.experience.total"
                v-validate="'required'"
                :data-vv-as="$ml.get('full')"
                type="number"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.experience.total')"
              >
                {{ errors.first("localValue.usersData.experience.total") }}
              </p>
            </div>
          </div>

          <div class="field" style="width: 25%;">
            <label
              for="localValue.usersData.experience.pedagogical"
              class="label"
              >{{ $ml.get("teaching") }}</label
            >
            <div class="control">
              <input
                id="localValue.usersData.experience.pedagogical"
                name="localValue.usersData.experience.pedagogical"
                v-model="localValue.usersData.experience.pedagogical"
                v-validate="'required'"
                :data-vv-as="$ml.get('teaching')"
                type="number"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.experience.pedagogical')"
              >
                {{
                  errors.first("localValue.usersData.experience.pedagogical")
                }}
              </p>
            </div>
          </div>

          <div class="field" style="width: 25%;">
            <label
              for="localValue.usersData.experience.atThisPosition"
              class="label"
              >{{ $ml.get("at_this_position") }}</label
            >
            <div class="control">
              <input
                id="localValue.usersData.experience.atThisPosition"
                name="localValue.usersData.experience.atThisPosition"
                v-model="localValue.usersData.experience.atThisPosition"
                v-validate="'required'"
                :data-vv-as="$ml.get('at_this_position')"
                type="number"
                class="input"
              />
              <p
                class="help is-danger"
                v-if="
                  errors.has('localValue.usersData.experience.atThisPosition')
                "
              >
                {{
                  errors.first("localValue.usersData.experience.atThisPosition")
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field" style="width: 50%;">
            <label for="localValue.usersData.grades" class="label">{{
              $ml.get("diploms")
            }}</label>
            <div class="control">
              <textarea
                id="localValue.usersData.grades"
                name="localValue.usersData.grades"
                v-model="localValue.usersData.grades"
                v-validate="'required'"
                :data-vv-as="$ml.get('diploms')"
                class="textarea"
              ></textarea>
              <p
                class="help is-danger"
                v-if="errors.has('localValue.usersData.grades')"
              >
                {{ errors.first("localValue.usersData.grades") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input
              id="localValue.agreement"
              type="checkbox"
              v-validate="'required'"
              :data-vv-as="$ml.get('agreement')"
              v-model="localValue.agreement"
              name="localValue.agreement"
            />
            {{ $ml.get("accept_my_info") }}
          </label>
          <p class="help is-danger" v-if="errors.has('localValue.agreement')">
            {{ errors.first("localValue.agreement") }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CommonFormMixin } from "../../mixins/common-form-mixin";

export default {
  name: "PrincipalsAttestationForm",
  mixins: [CommonFormMixin]
};
</script>
