<template>
  <section class="section">
    <div class="pageloader" :class="{ 'is-active': inProgress }">
      <span class="title">Поиск...</span>
    </div>

    <h2 class="service subtitle" v-html="$ml.get(title)" v-if="title"></h2>

    <!--        <div class="field">-->
    <!--            <label for="search" class="label">{{ $ml.get('search') }}</label>-->
    <!--            <div class="control">-->
    <!--                <input v-model="requestParams.search_query"-->
    <!--                       id="search"-->
    <!--                       type="text"-->
    <!--                       class="input">-->
    <!--            </div>-->
    <!--        </div>-->

    <form @submit.prevent="loadItems()">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <b-field :label="$ml.get(`${translationSlug}.iin`)">
              <input
                v-model="requestParams.iin"
                id="iin"
                type="text"
                class="input"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="$ml.get(`${translationSlug}.firstName`)">
              <input
                v-model="requestParams.name"
                id="name"
                type="text"
                class="input"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="$ml.get(`${translationSlug}.lastName`)">
              <input
                v-model="requestParams.surname"
                id="surname"
                type="text"
                class="input"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="$ml.get(`${translationSlug}.middleName`)">
              <input
                v-model="requestParams.middle_name"
                id="middle_name"
                type="text"
                class="input"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="$ml.get('status')">
              <input
                v-model="requestParams.status_name"
                id="status_name"
                type="text"
                class="input"
              />
            </b-field>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <b-field :label="$ml.get('date_from')">
              <b-datepicker
                icon-pack="far"
                :month-names="getMonthNames"
                :day-names="getDayNames"
                @input="dateChanged()"
                v-model="created_at_start"
                placeholder="Нажмите для выбора даты..."
              >
              </b-datepicker>
            </b-field>
            <input
              id="requestParams.datepicker_enabled"
              type="checkbox"
              title="Учитывать дату"
              @click="loadItems()"
              v-model="requestParams.datepicker_enabled"
            />
          </div>
          <div class="field">
            <b-field :label="$ml.get('date_to')">
              <b-datepicker
                icon-pack="far"
                :month-names="getMonthNames"
                :day-names="getDayNames"
                @input="dateChanged()"
                v-model="created_at_end"
                placeholder="Нажмите для выбора даты..."
              >
              </b-datepicker>
            </b-field>
          </div>

          <b-field :label="$ml.get('area')" v-if="isShowArea">
            <div class="select">
              <select
                v-model="requestParams.area"
                name="area_id"
                v-validate="'required'"
                id="area_id"
              >
                <option
                  v-for="type in areas"
                  :key="`item_area_${type.id}`"
                  :value="type.id"
                  >{{ $ml.get(type.slug) }}
                </option>
              </select>
            </div>
          </b-field>
          <b-field
            :label="$ml.get('employment')"
            v-if="isShowEmployment"
            style="max-width: 400px"
          >
            <div class="select">
              <select
                name="employment_id"
                id="employment_id"
                v-model="requestParams.employment"
              >
                <option
                  v-for="employment in employments"
                  :key="`item_employment_${employment.number}`"
                  :value="employment.number"
                >
                  {{ $ml.get(employment.slug) }}
                </option>
              </select>
            </div>
          </b-field>
          <b-field
            :label="$ml.get('category')"
            v-if="isShowEmployment"
            style="max-width: 400px"
          >
            <div class="select">
              <select
                name="claimed_category"
                id="claimed_category"
                v-model="requestParams.claimedCategory"
              >
                <option
                  v-if="declarerType === 1 && $ml.current === 'ru'"
                  value="1 – категория"
                  >1 – категория</option
                >
                <option
                  v-if="declarerType === 1 && $ml.current === 'ru'"
                  value="2 – категория"
                  >2 – категория</option
                >
                <option
                  v-if="declarerType === 1 && $ml.current === 'ru'"
                  value="3 – категория"
                  >3 – категория</option
                >
                <option
                  v-if="declarerType === 1 && $ml.current === 'kk'"
                  value="1 – санат"
                  >1 – санат</option
                >
                <option
                  v-if="declarerType === 1 && $ml.current === 'kk'"
                  value="2 – санат"
                  >2 – санат</option
                >
                <option
                  v-if="declarerType === 1 && $ml.current === 'kk'"
                  value="3 – санат"
                  >3 – санат</option
                >
                <option
                  v-if="declarerType === 2 && $ml.current === 'ru'"
                  value="Руководитель-организатор"
                  >Руководитель-организатор
                </option>
                <option
                  v-if="declarerType === 2 && $ml.current === 'ru'"
                  value="Руководитель-менеджер"
                  >Руководитель-менеджер
                </option>
                <option
                  v-if="declarerType === 2 && $ml.current === 'ru'"
                  value="Руководитель-лидер"
                  >Руководитель-лидер
                </option>
                <option
                  v-if="declarerType === 2 && $ml.current === 'kk'"
                  value="Руководитель-организатор"
                  >Басшы-ұйымдастырушы
                </option>
                <option
                  v-if="declarerType === 2 && $ml.current === 'kk'"
                  value="Руководитель-менеджер"
                  >Басшы-менеджер
                </option>
                <option
                  v-if="declarerType === 2 && $ml.current === 'kk'"
                  value="Руководитель-лидер"
                  >Басшы-көшбасшы
                </option>
              </select>
            </div>
          </b-field>
          <b-field
            :label="$ml.get('category')"
            v-if="isShowClaimCategory"
            style="max-width: 400px"
          >
            <div class="select">
              <select
                name="claimed_category"
                id="claimed_category"
                v-model="requestParams.claimedCategory"
              >
                <option v-if="$ml.current === 'ru'" value="Педагог-модератор"
                  >Педагог-модератор</option
                >
                <option v-if="$ml.current === 'kk'" value="Педагог-модератор"
                  >Педагог-модератор</option
                >
                <option v-if="$ml.current === 'ru'" value="Педагог-эксперт"
                  >Педагог-эксперт</option
                >
                <option value="Педагог-эксперт" v-if="$ml.current === 'kk'"
                  >педагог-сарапшы</option
                >
                <option
                  v-if="$ml.current === 'ru'"
                  value="Педагог-исследователь"
                  >Педагог-исследователь</option
                >
                <option
                  value="Педагог-исследователь"
                  v-if="$ml.current === 'kk'"
                  >педагог–зерттеуші</option
                >
              </select>
            </div>
          </b-field>
        </div>
      </div>

      <div
        class="field is-horizontal"
        v-if="slug === 'graduate-docs-duplicate' || slug === 'homeschooling'"
      >
        <div class="field-body">
          <div class="field">
            <b-field :label="$ml.get('name_univer')">
              <BuefyCommonSelect
                v-model="requestParams.school_id"
                @input="loadItems()"
                :options="schoolsOptions"
              />
            </b-field>
            <input
              id="requestParams.school_enabled"
              type="checkbox"
              title="Учитывать школу"
              @click="loadItems()"
              v-model="requestParams.school_enabled"
            />
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <button class="button is-success" type="submit">
            {{ $ml.get("search_short") }}
          </button>
        </div>
      </div>
    </form>

    <div class="tabs">
      <ul>
        <li
          v-for="tab in tabs"
          :class="{ 'is-active': activeTab === tab }"
          :key="'view_tab_' + tab.key"
        >
          <a @click.prevent="setActiveTab(tab)">
            {{ tab.translate ? $ml.get(tab.translate) : tab.title }}
            <!--{{ $ml.get(tab.key) }}-->
          </a>
        </li>
      </ul>
    </div>

    <div
      style="padding-bottom: 20px"
      v-if="
        slug === 'special-needs-social-services' &&
          activeTab['statuses'][0] === 'AWAITING_FOR_RECEPTION'
      "
    >
      <router-link
        :tag="'button'"
        :class="'button is-info'"
        :to="{ name: 'service.manager.special-needs-social-services.add' }"
      >
        Записать на приём
      </router-link>
    </div>

    <div style="padding-bottom: 20px" v-if="slug === 'spa-treatment'">
      <router-link
        :tag="'button'"
        :class="'button is-info'"
        :to="{ name: 'service.manager.spa-treatment.add' }"
      >
        Подать от имени заявителя
      </router-link>
    </div>

    <manager-table
      :items="items"
      :route-name="'service.view-manager'"
      :declarer-type="declarerType"
      :slug="slug"
    />

    <VPagination
      @change="changePage"
      :icon-pack="'far'"
      :total="totalPages"
      :current="currentPage"
      :order="order"
      :per-page="perPage"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { CommonListMixin } from "../../mixins/common-list-mixin";
import { DatepickerMixin } from "../../mixins/datepicker-mixin";
import ManagersListTabs from "../../configs/managers-list-tabs";
import ManagerTable from "../../components/lists/service-tables/ManagerTable";
import moment from "moment";

export default {
  components: { ManagerTable },
  props: {
    slug: {
      required: true
    }
  },
  name: "ManagersList",
  mixins: [CommonListMixin, DatepickerMixin],
  computed: {
    tabs() {
      return this.managersListTabs[this.slug]
        ? this.managersListTabs[this.slug]
        : this.managersListTabs["default"];
    },
    statuses() {
      return this.tabs;
    },
    ...mapGetters({
      services: "services/getList"
    }),
    ...mapGetters({
      areas: "services/getAreas",
      employments: "services/getEmploymentTypes"
    }),
    isShowArea() {
      return (
        (this.slug === "teachers-attestation" &&
          [4, 5, 7, 8].includes(this.declarerType)) ||
        this.slug === "principals-attestation"
      );
    },
    isShowEmployment() {
      return this.slug == "principals-attestation";
    },
    isShowClaimCategory() {
      return this.slug === "teachers-attestation";
    },
    translationSlug() {
      return this.searchByChild ? "child" : "declarerData";
    }
    // formattedCreatedAt() {
    //   return moment(this.created_at).format("YYYY-MM-DD");
    // }
  },
  data() {
    return {
      schoolsOptions: [],
      activeTab: "",
      managersListTabs: ManagersListTabs,
      created_at_start: new Date(),
      created_at_end: new Date(),
      schoolsApi: this.$apiResource.make("Schools"),
      requestParams: {
        // search_query: "",
        iin: "",
        name: "",
        surname: "",
        middle_name: "",
        status_name: "",
        area: "",

        claim_number: "",
        fio: "",
        declarer_iin: "",
        created_at_start: "",
        created_at_end: "",
        datepicker_enabled: false,
        school_id: "",
        school_enabled: false,

        service_id: 0,
        statuses: []
      }
    };
  },
  mounted() {
    if (this.isShowEmployment && !this.employments.length) {
      this.fetchEmploymentTypes();
    }
  },
  methods: {
    ...mapActions("services", ["fetchEmploymentTypes"]),
    setSchoolsOptions() {
      this.schoolsApi.list().then(response => {
        this.schoolsOptions = response.data.map(item => {
          return {
            text: item.name,
            id: item.id
          };
        });
      });
    },
    setActiveTab(tab) {
      if (this.activeTab !== tab) {
        this.currentPage = 1;
        this.activeTab = tab;
        this.requestParams.statuses = tab["statuses"];
        this.loadItems();
      }
    },
    setServiceId() {
      let slug = "";
      switch (this.slug) {
        case "reabilitation-and-adaptation":
          slug = "special-needs-social-services";
          break;
        case "nuclear-victims-akimat":
          slug = "nuclear-victims";
          break;
        default:
          slug = this.slug;
      }

      this.requestParams.service_id = this.services.find(
        service => service.slug === slug
      )["id"];
    },
    dateChanged() {
      this.requestParams.created_at_start = moment(
        this.created_at_start
      ).format("YYYY-MM-DD");
      this.requestParams.created_at_end = moment(this.created_at_end).format(
        "YYYY-MM-DD"
      );
      this.loadItems();
    }
  },
  watch: {
    slug() {
      this.setServiceId();
      this.setActiveTab(this.tabs[0]);
      this.loadItems();
    },
    "$route.params.declarertype": function() {
      this.changeDeclarerType();
      this.loadItems();
    }
  },
  beforeMount() {
    this.setActiveTab(this.tabs[0]);
    this.setServiceId();
    this.setSchoolsOptions();
  }
};
</script>
