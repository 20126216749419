<template>
  <section>
    <div class="content wizard-content" v-if="currentStep === formStep">
      <h3>{{ $ml.get("full_info") }}</h3>
      <main-form
        v-model="localValue"
        @onValidate="onValidateForm"
        @onInvalid="onInvalidForm"
        :service="currentService"
        :api-resource="apiResource"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === fileStep">
      <h3>{{ $ml.get("upload_req_files") }}</h3>
      <file-upload-list
        :list-key="'required_file'"
        :file-list="localValue['requiredFiles']"
      />
      <hr />
      <file-upload-list
        :list-key="'not_required_file'"
        :file-list="localValue['notRequiredFiles']"
        :postfix="' (не обязательно)'"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === signStep">
      <p>
        Просим обратить внимание!<br />
        При подписании заявления электронно-цифровой подписью (ЭЦП), вы сможете
        получить результат оказания услуги (Договор, решение о назначении
        денежных средств) в электронном виде на портале.<br />
        В случае отправки заявления без подписи ЭЦП, результат оказания услуги
        (Договор, решение о назначении денежных средств) необходимо будет
        получать в бумажном виде у Услугодателя
      </p>
    </div>
    <common-last-step v-if="currentStep === lastStep" />
  </section>
</template>

<script>
import { CommonWizardMixin } from "../../mixins/common-wizard-mixin";
import { AdoptionFamilyPaymentsForm } from "../forms";

export default {
  mixins: [CommonWizardMixin],
  name: "AdoptionFamilyPaymentsWizard",
  components: {
    mainForm: AdoptionFamilyPaymentsForm
  },
  beforeMount() {
    this.deactivateStep(this.formStep);
    this.deactivateStep(this.fileStep);

    this.$set(this.localValue, "usersData", {
      iin: "",
      phone: "",
      email: "",
      area: ""
    });

    this.$set(this.localValue, "children", []);

    this.setRequiredFiles([
      {
        label:
          "Автобиография услугополучателя и супруга(-и), если состоит в браке",
        name: "file_1",
        files: []
      },
      {
        label:
          "Характеристика с места работы услугополучателя и супруга(-и), если состоит в браке",
        name: "file_2",
        files: []
      },
      {
        label:
          "Справка о заработной плате услугополучателя и супруга(-и), если состоит в браке",
        name: "file_3",
        files: []
      },
      {
        label:
          "Справка с места работы услугополучателя и супруга(-и), если состоит в браке",
        name: "file_4",
        files: []
      },
      {
        label: "Акт обследования жилищно-бытовых условий",
        name: "file_5",
        files: []
      },
      {
        label:
          "Справки о состоянии здоровья услугополучателя и супруга(-и), если состоит в браке",
        name: "file_6",
        files: []
      },
      {
        label:
          "Справка с наркологического диспансера услугополучателя и супруга(-и), если состоит в браке",
        name: "file_7",
        files: []
      },
      {
        label:
          "Справка с психиатрического диспансера услугополучателя и супруга(-и), если состоит в браке",
        name: "file_8",
        files: []
      },
      {
        label:
          "Справка с туберкулезного диспансера услугополучателя и супруга(-и), если состоит в браке",
        name: "file_9",
        files: []
      },
      {
        label:
          "Копии справок о наличии либо отсутствии судимости услугополучателя и супруга(-и), если состоит в браке",
        name: "file_10",
        files: []
      },
      {
        label:
          "Документы, подтверждающие право собственности на жилище или право пользования жилищем (договор аренды) услугополучателя и (или) супруга(-и), если состоит в браке",
        name: "file_11",
        files: []
      },
      {
        label:
          "Копия договора об открытии текущего счета в банке второго уровня",
        name: "file_12",
        files: []
      },
      {
        label: "Копии документов, удостоверяющих личность",
        name: "file_13",
        files: []
      }
    ]);

    this.localValue.notRequiredFiles.unshift({
      label: "Копия свидетельства о заключении брака, если состоит в браке",
      name: "file_2",
      files: []
    });
  }
};
</script>
