import { mapGetters } from "vuex";
import {
  userHasPermission,
  turnSlugToPermissionName
} from "../services/helper";

export const PermissionsMixin = {
  computed: {
    ...mapGetters({
      userPermissions: "user/getUserPermissions"
    })
  },
  methods: {
    hasPermission(permissionCode) {
      return userHasPermission(this.userPermissions, permissionCode);
    },
    hasManagerListPermission() {
      return (
        this.hasPermission("MANAGER_LIST_HOMESCHOOLING") ||
        this.hasPermission("MANAGER_LIST_GRADUATE_DOCS_DUPLICATE") ||
        this.hasPermission("MANAGER_LIST_PARENTAL_VISIT_PERMIT") ||
        this.hasPermission("MANAGER_LIST_SPECIAL_NEEDS_SOCIAL_SERVICES") ||
        this.hasPermission("MANAGER_LIST_ADOPTION_FAMILY_PAYMENTS") ||
        this.hasPermission("MANAGER_LIST_REHABILITATION_AND_ADAPTATION") ||
        this.hasPermission("MANAGER_LIST_SPA_TREATMENT") ||
        this.hasPermission("MANAGER_LIST_TEACHERS_ATTESTATION") ||
        this.hasPermission("MANAGER_LIST_TEACHERS_ATTESTATION_TEACHER") ||
        this.hasPermission("MANAGER_LIST_TEACHERS_ATTESTATION_DDO") ||
        this.hasPermission("MANAGER_LIST_TEACHERS_ATTESTATION_TIPO")
      );
    },
    hasManagerEditPermission() {
      return (
        this.hasPermission("MANAGER_EDIT_HOMESCHOOLING") ||
        this.hasPermission("MANAGER_EDIT_GRADUATE_DOCS_DUPLICATE") ||
        this.hasPermission("MANAGER_EDIT_PARENTAL_VISIT_PERMIT") ||
        this.hasPermission("MANAGER_EDIT_SPECIAL_NEEDS_SOCIAL_SERVICES") ||
        this.hasPermission("MANAGER_EDIT_ADOPTION_FAMILY_PAYMENTS") ||
        this.hasPermission("MANAGER_EDIT_REHABILITATION_AND_ADAPTATION") ||
        this.hasPermission("MANAGER_EDIT_SPA_TREATMENT") ||
        this.hasPermission("MANAGER_EDIT_TEACHERS_ATTESTATION") ||
        this.hasPermission("MANAGER_EDIT_TEACHERS_ATTESTATION_TEACHER") ||
        this.hasPermission("MANAGER_EDIT_TEACHERS_ATTESTATION_DDO") ||
        this.hasPermission("MANAGER_EDIT_TEACHERS_ATTESTATION_TIPO")
      );
    },
    hasReadServicePermission(serviceSlug) {
      return this.hasPermission(
        "MANAGER_LIST_" + turnSlugToPermissionName(serviceSlug)
      );
    },
    hasEditServicePermission(serviceSlug) {
      return this.hasPermission(
        "MANAGER_EDIT_" + turnSlugToPermissionName(serviceSlug)
      );
    }
  }
};
