class PersonalIdentity {
  constructor(axios) {
    this.axios = axios;
  }

  getMainRoute() {
    return "/api/personal_identity";
  }

  getUserInfo(iin) {
    return this.axios.get(this.getMainRoute() + "/user_info/" + iin);
  }
}

export default PersonalIdentity;
