import {
  getNameFromUserInfo,
  getAddressFromUserInfo
} from "../services/helper.js";

export const ViewPersonalMixin = {
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    itemLoaded() {
      return this.item && this.item["id"];
    }
  },
  methods: {
    getNameFromUserInfo,
    getAddressFromUserInfo
  }
};
