<template>
  <div class="modal-padded-body" v-if="item.claim_data">
    <div class="field">
      <label class="label" for="usersData.iin">{{ $ml.get("my_iin") }}</label>
      <div class="control">
        <input
          id="usersData.iin"
          type="text"
          class="input"
          :value="item.claim_data.usersData.iin"
          disabled
          name="item.claim_data.usersData.iin"
        />
      </div>
    </div>

    <div class="field">
      <label class="label">{{ $ml.get("fio") }}</label>
      <div class="control">
        <input
          type="text"
          class="input"
          :value="getNameFromUserInfo(item.claim_data.usersData)"
          disabled
        />
      </div>
    </div>

    <div class="field">
      <label class="label">{{ $ml.get("birthday") }}</label>
      <div class="control">
        <input
          type="text"
          class="input"
          :value="item.claim_data.usersData.birth_date"
          disabled
        />
      </div>
    </div>

    <div class="field">
      <label class="label">{{ $ml.get("address_registr") }}</label>
      <div class="control">
        <input
          type="text"
          class="input"
          :value="getAddressFromUserInfo(item.claim_data.usersData)"
          disabled
        />
      </div>
    </div>

    <div class="field">
      <label class="label" for="usersData.email">{{ $ml.get("email") }}</label>
      <div class="control">
        <input
          id="usersData.email"
          type="text"
          class="input"
          disabled
          v-model="item.claim_data.usersData.email"
          name="item.claim_data.usersData.email"
        />
      </div>
    </div>

    <div class="field">
      <label class="label" for="usersData.phone">{{ $ml.get("phone") }}</label>
      <div class="control">
        <input
          id="usersData.phone"
          type="text"
          class="input"
          disabled
          v-model="item.claim_data.usersData.phone"
          name="item.claim_data.usersData.phone"
        />
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field" style="width: 50%;">
          <label for="item.claim_data.usersData.jobPlace" class="label">{{
            $ml.get("workplace")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.jobPlace"
              name="item.claim_data.usersData.jobPlace"
              v-model="item.claim_data.usersData.jobPlace"
              disabled
              type="text"
              class="input"
            />
          </div>
        </div>
        <div
          class="field"
          style="width: 50%;"
          v-if="item.claim_data.usersData.declarerType == 1"
        >
          <label for="item.claim_data.usersData.deputy" class="label">{{
            $ml.get("deputy")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.deputy"
              name="item.claim_data.usersData.deputy"
              v-model="item.claim_data.usersData.deputy"
              disabled
              type="text"
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field" style="width: 50%;">
          <label for="item.claim_data.usersData.category" class="label">{{
            $ml.get("category_exist")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.category"
              name="item.claim_data.usersData.category"
              v-model="item.claim_data.usersData.category"
              disabled
              type="text"
              class="input"
            />
          </div>
        </div>
        <div
          class="field"
          style="width: 50%;"
          v-if="item.claim_data.usersData.category"
        >
          <label
            for="item.claim_data.usersData.categoryExpirationDate"
            class="label"
            >{{ $ml.get("time_category") }}</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.categoryExpirationDate"
              name="item.claim_data.usersData.categoryExpirationDate"
              v-model="item.claim_data.usersData.categoryExpirationDate"
              disabled
              type="text"
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <!--http://jira.samgau.com/browse/UOALM-220-->
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field" style="width: 50%;">
          <label
            for="item.claim_data.usersData.claimedCategory"
            class="label"
            >{{ $ml.get("claimed_qualification_category") }}</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.claimedCategory"
              name="item.claim_data.usersData.claimedCategory"
              v-model="item.claim_data.usersData.claimedCategory"
              disabled
              type="text"
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field" style="width: 50%;">
          <label for="item.claim_data.usersData.studying" class="label">{{
            $ml.get("name_univer2")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.studying"
              name="item.claim_data.usersData.studying"
              v-model="item.claim_data.usersData.studying"
              disabled
              type="text"
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field" style="width: 25%;">
          <label
            for="item.claim_data.usersData.experience.total"
            class="label"
            >{{ $ml.get("full") }}</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.experience.total"
              name="item.claim_data.usersData.experience.total"
              v-model="item.claim_data.usersData.experience.total"
              disabled
              type="number"
              class="input"
            />
          </div>
        </div>

        <div class="field" style="width: 25%;">
          <label
            for="item.claim_data.usersData.experience.pedagogical"
            class="label"
            >{{ $ml.get("teaching") }}</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.experience.pedagogical"
              name="item.claim_data.usersData.experience.pedagogical"
              v-model="item.claim_data.usersData.experience.pedagogical"
              disabled
              type="number"
              class="input"
            />
          </div>
        </div>

        <div class="field" style="width: 25%;">
          <label
            for="item.claim_data.usersData.experience.atThisPosition"
            class="label"
            >{{ $ml.get("at_this_position") }}</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.experience.atThisPosition"
              name="item.claim_data.usersData.experience.atThisPosition"
              v-model="item.claim_data.usersData.experience.atThisPosition"
              disabled
              type="number"
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <hr />

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field" style="width: 50%;">
          <label for="item.claim_data.usersData.grades" class="label">{{
            $ml.get("diploms")
          }}</label>
          <div class="control">
            <textarea
              id="item.claim_data.usersData.grades"
              name="item.claim_data.usersData.grades"
              v-model="item.claim_data.usersData.grades"
              disabled
              class="textarea"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ViewPersonalMixin } from "../../../mixins/view-personal-mixin";

export default {
  name: "PrincipalsAttestationViewPersonal",
  mixins: [ViewPersonalMixin]
};
</script>

<style scoped></style>
