<template>
  <section class="section">
    <h2 class="subtitle service">Запись на прием</h2>
    <section>
      <div class="content wizard-content">
        <manager-special-needs-social-services-form
          v-model="localValue"
          @onValidate="onValidate"
          @onInvalid="onInvalid"
        />
      </div>

      <div class="field is-grouped is-grouped-right">
        <div class="control">
          <button
            class="button"
            :key="'status_pre_button_' + statusButton.key"
            :disabled="!formValid"
            :class="statusButton.class"
            @click="openedPopups[statusButton.key] = true"
          >
            {{ statusButton.name }}
          </button>
        </div>
      </div>

      <b-modal
        :key="'status_b_modal_' + statusButton.key"
        :active.sync="openedPopups[statusButton.key]"
        v-if="openedPopups[statusButton.key]"
        has-modal-card
      >
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Выбор даты и времени</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Дата">
              <b-datepicker
                placeholder="Нажмите для выбора даты..."
                v-model="timeOfReceipt"
              >
              </b-datepicker>
            </b-field>

            <b-field label="Время">
              <b-timepicker
                placeholder="Нажмите для выбора времени..."
                v-model="timeOfReceipt"
              >
              </b-timepicker>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button"
              type="button"
              @click="openedPopups[statusButton.key] = false"
            >
              {{ $ml.get("cancel") }}
            </button>
            <sign-data-button
              :key="'status_button_' + statusButton.key"
              :classes="statusButton.class"
              :data="dataToSign()"
              :name="statusButton.name"
              @onSign="onSubmitSigned"
            />
          </footer>
        </div>
      </b-modal>

      <!--<div class="field is-grouped is-grouped-right">-->
      <!--<div class="control">-->
      <!--<sign-data-button-->
      <!--@onSign="onSubmitSigned"-->
      <!--:data="localValue"-->
      <!--:name="'Записать на прием'"-->
      <!--:disabled="!formValid"-->
      <!--:classes="'is-info'"-->
      <!--/>-->
      <!--</div>-->
      <!--</div>-->
    </section>
  </section>
</template>

<script>
import ManagerSpecialNeedsSocialServicesForm from "../../forms/ManagerSpecialNeedsSocialServicesForm";
import SignDataButton from "../../form-components/SignDataButton";
import Api from "../../../services/api/api-resource/api-instance";
import moment from "moment";

export default {
  name: "CreateAwaitingForSpecialNeedsSocialServicesViewPersonal",
  components: {
    SignDataButton,
    ManagerSpecialNeedsSocialServicesForm
  },
  data() {
    const today = new Date();
    return {
      timeOfReceipt: today,
      minDate: today,
      maxDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 30
      ),
      statusButton: {
        name: "Записать на прием",
        statusesToShow: ["REGISTERED"],
        statusToChange: "AWAITING_FOR_RECEPTION",
        class: "is-info",
        rejectPopupShow: false,
        dateTimePopupShow: true,
        key: "CHANGE_TO_AWAITING_FOR_RECEPTION"
      },
      openedPopups: {
        CHANGE_TO_AWAITING_FOR_RECEPTION: false
      },
      localValue: {},
      formValid: false,
      slug: "special-needs-social-services",
      apiResource: Api.make("Claims")
    };
  },
  methods: {
    onValidate() {
      this.formValid = true;
    },
    onInvalid() {
      this.formValid = false;
    },
    onSubmitSigned(xml) {
      this.apiResource
        .storeSigned(this.slug, { xml: xml })
        .then(response => {
          if (response.status && response.status === 200) {
            this.registeredClaim = response.data;
            this.$router.push({
              name: "claims.managers-list",
              params: { slug: this.slug }
            });
          } else {
            alert(response.message); // @TODO Обработать ошибку
          }
        })
        .catch(error => {
          alert(error.message); // @TODO Обработать ошибку
        });
    },
    dataToSign() {
      return {
        ...this.localValue,
        time_of_receipt: moment(this.timeOfReceipt).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      };
    }
  },
  beforeMount() {
    this.$set(this.localValue, "pmpk_id", "");
    this.$set(this.localValue, "usersData", {
      iin: "",
      resident: true,
      email: "",
      phone: ""
    });

    this.$set(this.localValue, "childsData", {
      iin: "",
      actualAddress: "",
      area: "Алатауский",
      isDisabled: false,
      multiplicityOfReception: "Первичное",
      reason: "Проблемы с развитием речи"
    });

    this.$set(this.localValue, "createdByManager", true);
  }
};
</script>

<style scoped></style>
