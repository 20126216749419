class Pmpks {
  constructor(axios) {
    this.axios = axios;
  }
  list() {
    return this.axios.get("/api/pmpks");
  }
}

export default Pmpks;
