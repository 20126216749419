<template>
  <nav
    class="breadcrumb is-centered container"
    style="margin-bottom: -45px;z-index: 1; max-height: 75px"
    aria-label="breadcrumbs"
  >
    <ul>
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :class="{ 'is-active': !breadcrumb.link }"
        :key="idx"
      >
        <a
          @click.prevent="routeTo(idx)"
          :class="{ linked: !!breadcrumb.link }"
          href=""
        >
          {{ $ml.get(breadcrumb.name) }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbList: []
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    }
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link)
        this.$router.push(this.breadcrumbList[pRouteTo].link);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    }
  }
};
</script>

<style scoped></style>
