<template>
  <div
    style="display: inline-block; margin: 0 15px 15px 0;"
    v-if="userHasOffers"
  >
    <button
      class="button"
      :key="'status_pre_button_' + statusButton.key"
      :class="statusButton.class"
      v-if="statusButton.statusesToShow.indexOf(item.status.code) !== -1"
      @click="openPopup(statusButton.key)"
    >
      {{ statusButton.name }}
    </button>

    <b-modal
      :key="'status_b_modal_' + statusButton.key"
      :active.sync="localPopupOpened"
      v-if="localPopupOpened"
      has-modal-card
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Выбор санатория</p>
        </header>
        <section class="modal-card-body">
          <form>
            <BuefySimpleSelect
              :options="availableSanatoriumsOptions"
              v-model="additionalData.roomId"
            />
          </form>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button"
            type="button"
            @click="closePopup(statusButton.key)"
          >
            {{ $ml.get("cancel") }}
          </button>
          <sign-data-button
            :disabled="!isFormValid"
            :title="!isFormValid ? 'Пожалуйста, заполните все данные' : ''"
            :key="'status_button_' + statusButton.key"
            :classes="statusButton.class"
            :data="dataToSign"
            :name="statusButton.name"
            :isConfirmBeforeSubmit="!!isConfirmBeforeSubmit"
            :confirmMessage="
              'Вы уверены в выборе санатория (' + confirmSanatoriumName + ')?'
            "
            v-if="statusButton.statusesToShow.indexOf(item.status.code) !== -1"
            @onSign="onSign"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ExternalPopupMixin } from "../../../../mixins/external-popup-mixin";
import axios from "../../../../services/axios-instance";
import { displayError, getErrorsText } from "../../../../services/helper";

export default {
  mixins: [ExternalPopupMixin],
  name: "SelectSanatoriumPopup",
  data() {
    return {
      availableSanatoriumsOptions: [],
      confirmSanatoriumName: ""
    };
  },
  props: {
    isConfirmBeforeSubmit: {
      default: false
    }
  },
  computed: {
    userHasOffers() {
      return this.availableSanatoriumsOptions.length > 0;
    },
    isFormValid() {
      return this.isFormValidVee && this.additionalData.roomId;
    }
  },
  watch: {
    "additionalData.roomId"() {
      if (
        this.isConfirmBeforeSubmit &&
        this.availableSanatoriumsOptions.length > 0 &&
        this.additionalData.roomId
      ) {
        this.confirmSanatoriumName = this.availableSanatoriumsOptions.filter(
          x => x.id === this.additionalData.roomId
        )[0].name;
      }
    }
  },
  methods: {
    setAvailableSanatoriums() {
      axios
        .get("/api/claims/sanatoriums-available/" + this.item.id)
        .then(response => {
          this.availableSanatoriumsOptions = response["data"];
        })
        .catch(error => {
          displayError(this, getErrorsText(error));
        });
    }
  },
  created() {
    this.setAvailableSanatoriums();
  }
};
</script>

<style scoped></style>
