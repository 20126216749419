<template>
  <section>
    <div class="content wizard-content" v-if="currentStep === formStep">
      <h3>Основная информация</h3>
      <main-form
        v-model="localValue"
        @onValidate="onValidateForm"
        @onInvalid="onInvalidForm"
        :service="currentService"
        :api-resource="apiResource"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === fileStep">
      <h4>Загрузка обязательных файлов</h4>
      <file-upload-list
        :list-key="'required_file'"
        :file-list="localValue['requiredFiles']"
      />
    </div>
    <common-last-step v-if="currentStep === lastStep" />
  </section>
</template>

<script>
import { CommonWizardMixin } from "../../mixins/common-wizard-mixin";
import { SpaTreatmentForm } from "../forms";
import moment from "moment";

export default {
  mixins: [CommonWizardMixin],
  name: "SpaTreatmentWizard",
  components: {
    mainForm: SpaTreatmentForm
  },
  beforeMount() {
    this.deactivateStep(this.formStep);

    this.$set(this.localValue, "usersData", {
      iin: "",
      email: "",
      phone: "",
      month: "",
      disability_category_id: "",
      doc_type: "Удостоверение личности",
      issued_by: "",
      issued_date: moment().format("YYYY-MM-DD")
    });

    this.setRequiredFiles([
      {
        label: "Копия удостоверения личности",
        name: "file_1",
        only: [],
        files: []
      }
    ]);
  }
};
</script>

<style scoped></style>
