import Api from "../../services/api/api-resource/api-instance";

const servicesListKey = "servicesList";
const declarerTypesListKey = "declarerTypesList";
const areaListKey = "areaListKey";

const getFromLocalStorage = slug => {
  const list = localStorage.getItem(slug);
  if (!list) {
    return [];
  } else {
    try {
      return JSON.parse(list);
    } catch (e) {
      return [];
    }
  }
};

const state = {
  list: getFromLocalStorage(servicesListKey),
  declarerTypes: {},
  employmentTypes: [],
  areas: []
};
const getters = {
  getList: state => state.list,
  getDeclarerTypes: state => serviceSlug => {
    if (!state.declarerTypes[serviceSlug]) {
      return [];
    }
    return state.declarerTypes[serviceSlug].map(type => {
      type.slug = `${serviceSlug}_declarer_type_${type.number}`;
      return type;
    });
  },
  getEmploymentTypes: state =>
    state.employmentTypes.map(type => {
      type.slug = `employment_type_${type.number}`;
      return type;
    }),
  getAreas: state =>
    state.areas.map(type => {
      type.slug = `area_${type.id}`;
      return type;
    })
};
const mutations = {
  setServices(state, list) {
    state.list = list;
    localStorage.setItem(servicesListKey, JSON.stringify(list));
  },
  setDeclarerTypes(state, { data, serviceSlug }) {
    let types = {};
    types[serviceSlug] = data;
    state.declarerTypes = Object.assign({}, state.declarerTypes, types); // see https://vuejs.org/v2/guide/reactivity.html#For-Objects
    localStorage.setItem(
      declarerTypesListKey + serviceSlug,
      JSON.stringify(data)
    );
  },
  setEmploymentTypes(state, data) {
    state.employmentTypes = data;
  },
  setAreas(state, areas) {
    state.areas = areas;
    localStorage.setItem(areaListKey, JSON.stringify(areas));
  },
  clearStorage() {
    localStorage.removeItem(servicesListKey);
    localStorage.removeItem(declarerTypesListKey);
    localStorage.removeItem(areaListKey);
  }
};
const actions = {
  requestList: function({ commit }) {
    Api.make("Services")
      .list()
      .then(response => commit("setServices", response.data));
  },
  fetchDeclarerTypes: ({ commit }, serviceSlug) =>
    Api.make("DeclarerTypes")
      .list(serviceSlug)
      .then(({ data: { data } }) =>
        commit("setDeclarerTypes", { data, serviceSlug })
      ),

  fetchEmploymentTypes: ({ commit }) =>
    Api.make("EmploymentTypes")
      .list()
      .then(({ data: { data } }) => {
        commit("setEmploymentTypes", data);
      }),

  fetchAreas: ({ commit }) =>
    Api.make("Areas")
      .list()
      .then(({ data: { data } }) => commit("setAreas", data))
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
