<template>
  <tr>
    <td>{{ item.claim_id }}</td>
    <td>{{ item.user.name | ifUndef("") }}</td>
    <td>{{ item.user ? getNameFromUserInfo(item.user.profile) : "--" }}</td>
    <td
      class="table-td-dates"
      :title="item.created_at | moment('DD.MM.YYYY HH:mm')"
    >
      <span class="no-break">{{
        item.created_at | moment("D MMMM, YYYY")
      }}</span
      ><br />
      <small class="no-break has-text-steel">{{
        item.created_at | momentFrom
      }}</small>
    </td>
    <td>
      <span class="tag" :class="getStatusClass">{{
        getStatusName(
          item.new_status,
          serviceSlug,
          $ml.get(statusesClasses[this.item.new_status.code].translate)
        )
      }}</span>
    </td>
  </tr>
</template>
<script>
import StatusesClasses from "../../configs/claim-statuses";
import { getNameFromUserInfo, getStatusName } from "../../services/helper";
export default {
  name: "ClaimHistoryRow",
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          claim_id: null,
          user: {
            name: "-",
            profile: {}
          },
          created_at: "",
          new_status: ""
        };
      }
    },
    serviceSlug: {}
  },
  data() {
    return {
      statusesClasses: StatusesClasses
    };
  },
  computed: {
    getStatusClass: function() {
      return this.statusesClasses[this.item.new_status.code].class;
    }
  },
  methods: {
    getNameFromUserInfo,
    getStatusName
  }
};
</script>
