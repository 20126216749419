<template>
  <section class="section">
    <h2 class="subtitle service">Запись на прием</h2>
    <section>
      <div class="content wizard-content">
        <manager-spa-treatment-form
          v-model="localValue"
          @onValidate="onValidate"
          @onInvalid="onInvalid"
          :service="currentService"
          :api-resource="apiResource"
          :is-manager="true"
        />
      </div>
      <div class="content wizard-content">
        <h4>Загрузка обязательных файлов</h4>
        <file-upload-list
          :list-key="'required_file'"
          :file-list="localValue['requiredFiles']"
        />
      </div>
      <footer class="wizard-footer">
        <div class="columns is-gapless is-mobile">
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <sign-data-button
                :data="localValue"
                :disabled="submitButtonDisabled"
                :title="
                  !submitButtonDisabled
                    ? 'Подать заявку'
                    : 'Пожалуйста, заполните все данные.'
                "
                @onSign="onSubmitSigned"
              />
            </div>
          </div>
        </div>
      </footer>
    </section>
  </section>
</template>

<script>
import ManagerSpaTreatmentForm from "../../forms/ManagerSpaTreatmentForm";
import moment from "moment";
import { CommonWizardMixin } from "../../../mixins/common-wizard-mixin";
import SignDataButton from "../../form-components/SignDataButton";

export default {
  mixins: [CommonWizardMixin],
  name: "CreateSpaTreatmentViewPersonal",
  components: {
    SignDataButton,
    ManagerSpaTreatmentForm
  },
  data() {
    return {
      formValid: false,
      filesUploaded: false
    };
  },
  computed: {
    submitButtonDisabled() {
      return !this.formValid || this.inProgress || !this.filesUploaded;
    }
  },
  methods: {
    onValidate() {
      this.formValid = true;
    },
    onInvalid() {
      this.formValid = false;
    },
    onSubmitSigned(xml) {
      this.apiResource.storeSigned(this.slug, { xml: xml }).then(response => {
        if (response.status && response.status === 200) {
          this.registeredClaim = response.data;
          this.$router.push({
            name: "claims.managers-list",
            params: { slug: this.slug }
          });
        }
      });
    },
    dataToSign() {
      return {
        ...this.localValue,
        time_of_receipt: moment(this.timeOfReceipt).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      };
    }
  },
  beforeMount() {
    this.$set(this.localValue, "usersData", {
      iin: "",
      email: "",
      phone: "",
      disability_category_id: "",
      doc_type: "Удостоверение личности",
      issued_by: "",
      month: "",
      issued_date: moment().format("YYYY-MM-DD")
    });

    this.$set(this.localValue, "createdByManager", true);

    this.setRequiredFiles([
      {
        label: "Копия удостоверения личности",
        name: "file_1",
        only: [],
        files: []
      }
    ]);

    this.$on("activateStep", step => {
      if (step === this.fileStep) {
        this.filesUploaded = true;
      }
    });

    this.$on("deactivateStep", step => {
      if (step === this.fileStep) {
        this.filesUploaded = false;
      }
    });
  }
};
</script>

<style scoped></style>
