<template>
  <div class="content wizard-content">
    {{ $ml.get("thanks") }}
    <router-link :to="{ name: 'claims.personal-list' }">{{
      $ml.get("thanks_link")
    }}</router-link>
  </div>
</template>

<script>
export default {
  name: "common-last-step"
};
</script>

<style scoped></style>
