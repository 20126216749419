<template>
  <div class="home hero">
    <div class="hero-body">
      {{ $ml.get("choose_directory") }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "home",
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn"
    })
  },
  methods: {
    lastLinkRedirect() {
      const linkBeforeLogin = localStorage.getItem("linkBeforeLogin");
      if (linkBeforeLogin && this.isLoggedIn) {
        localStorage.setItem("linkBeforeLogin", "");
        this.$router.push(linkBeforeLogin);
      }
    }
  },
  mounted() {
    this.lastLinkRedirect();
  }
};
</script>
