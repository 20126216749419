class ImageEdit {
  constructor(axios) {
    this.axios = axios;
  }

  static getRoute(url) {
    return "/api/file-edit/" + url;
  }

  rotateLeft(fileId) {
    return this.axios.get(ImageEdit.getRoute("rotate-left/" + fileId), {
      responseType: "json"
    });
  }

  rotateRight(fileId) {
    return this.axios.get(ImageEdit.getRoute("rotate-right/" + fileId), {
      responseType: "json"
    });
  }
}

export default ImageEdit;
