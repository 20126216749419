<template>
  <div class="file-upload-input-parent">
    <h3 v-if="headerTitle.length > 0" v-text="headerTitle"></h3>
    <label
      v-if="translate"
      v-html="$ml.get(translate)"
      class="file-upload-input-label"
    ></label>
    <label v-else v-text="label" class="file-upload-input-label"></label>

    <div class="columns mb-0">
      <div class="column is-5">
        <div class="control" style="margin: 3px 5px">
          <input
            type="text"
            class="input"
            v-model="placeholder"
            @input="handleInputChange"
          />
        </div>
      </div>
      <div class="column is-5">
        <div class="file-upload-input input" :class="selectedClass">
          <input
            type="file"
            ref="file-upload-input-ref"
            :name="name"
            @change="doUpload"
            :multiple="!single"
          />
          <aside class="icon">
            <fa-icon :icon="uploadIcon"></fa-icon>
          </aside>
          <section class="file-info" @click="doFileSelect">
            <p class="placeholder">{{ $ml.get("attach_file") }}</p>
          </section>
        </div>
      </div>
      <div class="column is-1">
        <div
          class="plus-custom has-text-centered"
          style="width:25px; border-radius:50px"
          v-if="files.length > 0"
          @click="doFileSelect"
        >
          <fa-icon :icon="'plus'"></fa-icon>
        </div>
      </div>
    </div>
    <template>
      <div
        class="file-upload-input input"
        :class="selectedClass"
        :key="'uploaded_file_' + index"
        v-for="(file, index) in files"
      >
        <aside class="icon">
          <fa-icon v-if="!file.uploading" :icon="uploadIcon"></fa-icon>
          <fa-icon v-else icon="spinner" :spin="true"></fa-icon>
        </aside>
        <section class="file-info">
          <div
            class="upload-progress"
            v-if="file.uploading"
            :style="uploadGradient(file.percentage)"
          ></div>
          <div class="info-text">
            <div class="info-block">
              <div class="text">{{ file.name }}</div>
              <div class="size">{{ filesize(file.size) }}</div>
            </div>
            <div class="clear-button" @click.prevent="doClear(index)">
              <fa-icon icon="times"></fa-icon>
            </div>
          </div>
        </section>
      </div>
    </template>

    <div class="files-preview" v-for="(file, idx) in files" :key="idx">
      <div class="preview" v-if="file.base64">
        <img :src="'data:image/png;base64, ' + file.base64" alt="Red dot" />
      </div>
    </div>

    <b-notification
      auto-close
      type="is-danger"
      :active.sync="notification.isNotificationActive"
      aria-close-label="Закрыть"
      >{{ notification.text }}</b-notification
    >
  </div>
</template>

<style scoped lang="scss">
.preview {
  img {
    max-width: 100px;
  }
}
</style>

<script>
import Api from "../../services/api/api-resource/api-instance";
import eventBus from "../../services/event-bus";

export default {
  name: "VFormUploadWithInput",
  props: {
    label: {
      type: [Boolean, String],
      default: ""
    },
    translate: {
      type: [String, Boolean],
      default: false
    },
    name: {
      type: String,
      default: "file"
    },
    preview: {
      type: Boolean,
      default: false
    },
    fileEncode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {
        return {
          label: "Название файлового поля",
          translate: "",
          files: []
        };
      }
    },
    single: {
      type: Boolean,
      default: false
    },
    savePath: {
      type: Boolean,
      default: false
    },
    only: {
      type: Array,
      default: () => []
    },
    maxMbSize: {
      type: Number,
      default: 20
    },
    headerTitle: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      files: [],
      uploadApi: Api.make("Upload"),
      placeholder: "",
      notification: {
        isNotificationActive: false,
        text: ""
      }
    };
  },
  computed: {
    selectedClass: function() {
      return this.selected ? "is-success" : "";
    },
    uploadIcon: function() {
      return this.selected ? "file" : "upload";
    }
  },
  mounted() {
    if (Array.isArray(this.value.files)) {
      this.value.files.forEach((file, idx) =>
        this.addFile({
          name: file.filename,
          size: file.size,
          percentage: 0,
          uploading: false,
          uploadedNumber: idx - 1
        })
      );
    }
  },
  methods: {
    makePreview(fileObject) {
      this.uploadApi.download(fileObject.id).then(response => {
        const URL = window.URL || window.webkitURL;
        const blob = new Blob([response.data], {
          type: response.headers["Content-Type"]
        });

        const img = document.createElement("img");
        const url = URL.createObjectURL(blob);

        img.src = url;

        return img;
        // img.download = fileObject.filename;
        // img.click();

        // URL.revokeObjectURL(url);
        // a.remove();
      });
    },
    doClear: function(index) {
      this.files.splice(index, 1);
      this.value.files.splice(index, 1);

      const ref = this.$refs["file-upload-input-ref"];
      ref.value = null;

      eventBus.$emit("file-uploaded", this.value.files);

      this.$emit("input", this.value);
    },
    getFileExtension: function(file) {
      return file.split(".").pop();
    },
    checkFileByExtension: function(fileExtension) {
      fileExtension = fileExtension.toLowerCase();
      return this.only.indexOf(fileExtension) !== -1;
    },
    doFileSelect: function() {
      const ref = this.$refs["file-upload-input-ref"];
      ref.click();
    },
    doUpload: function(event) {
      // const target = event.srcElement;
      const target = event.target;
      let i = this.files.length - 1;
      Array.from(target.files).forEach(file => {
        /*фильтруем файлы по расширению http://jira.samgau.com/browse/UOALM-237*/
        if (this.only !== undefined && this.only.length) {
          let fileExtension = this.getFileExtension(file.name);
          let checkResult = this.checkFileByExtension(fileExtension);
          if (!checkResult) {
            this.notification.isNotificationActive = true;
            this.notification.text =
              "Допускаются только файлы c расширениями: " +
              this.only.join(", ");
            return;
          }
        }

        // Проверка максимального размера файла
        if (file.size > this.maxMbSize * 1024 * 1024) {
          this.notification.isNotificationActive = true;
          this.notification.text =
            "Размер файла не должен привышать " + this.maxMbSize + "MB";
          return;
        }

        file.uploadedNumber = i;

        this.addFile({
          name: file.name,
          size: file.size,
          percentage: 0,
          uploading: true,
          base64: null,
          uploadedNumber: file.uploadedNumber
        });

        eventBus.$emit("file-uploading", this.value.files);

        const UploadApi = Api.make("Upload");
        UploadApi.upload(
          this.name,
          file,
          this.onProgress,
          this.fileEncode
        ).then(({ data }) => {
          if (data.hasOwnProperty("uploaded_number")) {
            this.files.find(
              x => x.uploadedNumber === parseInt(data.uploaded_number)
            ).uploading = false;
          } else {
            this.files[this.files.length - 1].percentage = 0;
            this.files[this.files.length - 1].uploading = false;
          }

          if (Array.isArray(this.value.files)) {
            this.value.files.push({
              uploaded: true,
              label: this.label,
              name: this.value.name,
              filename: data.filename,
              size: data.size,
              id: data.id,
              hash: data.hash,
              path: this.savePath ? data.filepath : false,
              placeholder: this.placeholder
            });
          } else {
            this.value.uploaded = true;
            this.value.size = data.size;
            this.value.id = data.id;
            this.value.hash = data.hash;
            this.value.path = this.savePath ? data.filePath : false;
            this.value.filename = data.filename;
            this.value.placeholder = this.placeholder;
          }

          if (this.fileEncode) {
            this.files[this.files.length - 1].base64 = data.base64;
          }

          eventBus.$emit("file-uploaded", this.value.files);
        });

        i++;
      });
      this.value.placeholder = this.placeholder;
      this.$emit("input", this.value);
    },
    handleInputChange: function(event) {
      this.value.files.forEach(e => (e.placeholder = this.placeholder));
      this.value.placeholder = this.placeholder;
      this.$emit("input", this.value);
    },
    onProgress: function(progressevent) {
      const progress = Math.floor(
        (progressevent.loaded / progressevent.total) * 100
      );
      this.files[this.files.length - 1].percentage = progress;
    },
    filesize: function(size) {
      let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["байт", "килобайт", "мегабайт", "гигабайт", "терабайт"][i]
      );
    },
    uploadGradient: function(percent) {
      const green = "#23d160";
      const white = "#ffffff";
      if (this.uploading) {
        return {
          background: `linear-gradient(90deg, ${green}, ${green} ${percent}%, ${white} ${percent}%, ${white})`
        };
      } else {
        return {
          background:
            "linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(229,229,229,1) 100%)"
        };
      }
    },
    addFile({ name, size, percentage, uploading, base64, uploadedNumber }) {
      this.files.push({
        name,
        size,
        percentage,
        uploading,
        base64,
        uploadedNumber
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.file-upload-input-parent {
  label.file-upload-input-label {
    font-weight: bold;
    margin: 0 5px;
  }

  .file-upload-input {
    cursor: pointer;
    margin: 3px 5px;
    display: flex;
    border-radius: 3px;
    border: 1px solid $grey-light;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(239, 239, 239, 1) 100%
    );

    &:hover {
      border-color: $grey-dark;
    }

    &.is-success {
      border-color: $green;
    }

    .upload-progress {
      min-height: 6px;
      height: 6px;
      width: 100%;
      border-radius: 3px;
      border: 1px solid $white-bis;
    }

    input[type="file"] {
      display: none;
    }

    .icon {
      flex-grow: 0;
      vertical-align: middle;
      justify-self: center;
    }

    .file-info {
      flex-grow: 1;
      text-shadow: 0 1px 0 #fff;

      .placeholder {
        font-style: italic;
        color: $black-bis;
        font-size: 16px;
      }

      .info-text {
        display: flex;
        flex-grow: 1;

        .info-block {
          flex-grow: 1;
          line-height: 1.1;
          font-size: 0.8em;
        }

        .clear-button {
          flex-grow: 0;
          padding: 0 10px;
          border-left: 1px solid $grey-light;
          vertical-align: middle;

          &:hover {
            background-color: lighten($red, 20%);
          }
        }
      }
    }
  }
  .plus-custom {
    background-color: #f5f5f5;
    &:hover {
      background-color: #0000004a;
      cursor: pointer;
    }
  }
}
</style>
