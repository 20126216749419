import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faFile as farFile,
  faFileAlt as farFileAlt
} from "@fortawesome/free-regular-svg-icons";
import {
  faGraduationCap,
  faSignInAlt,
  faSignOutAlt,
  faListUl,
  faFeatherAlt,
  faPlus,
  faCheck,
  faUpload,
  faFile,
  faTimes,
  faArrowRight,
  faArrowLeft,
  faCheckDouble,
  faAngleLeft,
  faAngleRight,
  faMinus,
  faSpinner,
  faList
} from "@fortawesome/free-solid-svg-icons";

const enabledIcons = {
  faSignInAlt,
  faSignOutAlt,
  faGraduationCap,
  faListUl,
  faFeatherAlt,
  faPlus,
  faCheck,
  faUpload,
  faFile,
  faTimes,
  faUser,
  faArrowRight,
  faArrowLeft,
  faCheckDouble,
  faAngleLeft,
  faAngleRight,
  faMinus,
  faList,
  faSpinner,
  farFile,
  farFileAlt
};

export default function(Vue) {
  library.add(enabledIcons);

  Vue.component("fa-icon", FontAwesomeIcon);
}
