<template>
  <section>
    <div class="content wizard-content" v-if="currentStep === formStep">
      <h3>{{ $ml.get("full_info") }}</h3>
      <main-form
        v-model="localValue"
        @onValidate="onValidateForm"
        @onInvalid="onInvalidForm"
        :service="currentService"
        :api-resource="apiResource"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === fileStep">
      <h3>Загрузка файлов</h3>
      <file-upload-list
        :list-key="'not_required_file'"
        :file-list="localValue['notRequiredFiles']"
        :postfix="' (не обязательно)'"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === lastStep">
      <p>
        «Ваше заявление зарегистрировано под номером ({{
          registeredClaimNumber
        }}) и направлено на обработку.
      </p>
      <p>Просим обратить внимание!</p>
      <p>
        На приеме обязательное присутствии матери ребенка или законных
        представителей (обязательное наличие подтверждающих документов об
        опекунстве).
      </p>
      <p>Также необходимо при себе иметь следующие документы:</p>
      <ul>
        <li>Свидетельство о рождении ребенка (+копия);</li>
        <li>Копия заключения об инвалидности (при наличии);</li>
        <li>Заключение ВКК о необходимости обучения на дому (при наличии);</li>
        <li>
          Амбулаторная карта с поликлиники (копии всех мед.выписок, о
          прохождениии лечения, при наличии осмотр врача невропатолога,
          окулиста, лор-врача);
        </li>
        <li>Характеристика с места обучения/воспитания;</li>
        <li>
          Письменные работы по языку обучения, математике, рисунки и другие
          результаты деятельности»
        </li>
        <li>
          Адресная справка по городу Алматы (информация о постоянной или
          временной регистрации)
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { CommonWizardMixin } from "../../mixins/common-wizard-mixin";
import { SpecialNeedsSocialServicesForm } from "../forms";

export default {
  mixins: [CommonWizardMixin],
  name: "SpecialNeedsSocialServicesWizard",
  components: {
    mainForm: SpecialNeedsSocialServicesForm
  },
  beforeMount() {
    this.deactivateStep(this.formStep);

    this.$set(this.localValue, "pmpk_id", "");
    this.$set(this.localValue, "usersData", {
      iin: "",
      resident: true,
      email: "",
      phone: ""
    });

    this.$set(this.localValue, "childsData", {
      iin: "",
      actualAddress: "",
      isDisabled: false,
      multiplicityOfReception: "Первичное",
      reason: "Проблемы с развитием речи"
    });
  }
};
</script>

<style scoped></style>
