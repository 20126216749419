import SanatoriumsList from "../views/admin/sanatoriums/SanatoriumsList";
import SanatoriumEdit from "../views/admin/sanatoriums/SanatoriumEdit";
import RightChecker from "../services/rights-checker";

export default [
  // sanatoriums
  {
    path: "/sanatoriums",
    name: "sanatoriums.list",
    props: {
      perPage: 20
    },
    component: SanatoriumsList,
    beforeEnter(to, from, next) {
      return RightChecker.check(["LIST_SANATORIUMS"], next);
    },
    meta: {
      auth: true,
      breadcrumb: [{ name: "Список санаториев" }]
    }
  },
  {
    path: "/sanatoriums/:id/edit",
    name: "sanatoriums.edit",
    component: SanatoriumEdit,
    beforeEnter(to, from, next) {
      return RightChecker.check(["EDIT_SANATORIUMS"], next);
    },
    props: true,
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "Список санаториев",
          link: "/sanatoriums"
        },
        { name: "Редактирование санатория" }
      ]
    }
  },
  {
    path: "/sanatoriums/create",
    name: "sanatoriums.create",
    component: SanatoriumEdit,
    beforeEnter(to, from, next) {
      return RightChecker.check(["EDIT_SANATORIUMS"], next);
    },
    props: true,
    meta: {
      auth: true,
      breadcrumb: [
        {
          name: "Список санаториев",
          link: "/sanatoriums"
        },
        { name: "Создание санатория" }
      ]
    }
  }
];
