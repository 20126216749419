import Vue from "vue";
import { MLInstaller, MLCreate, MLanguage } from "vue-multilanguage";
import ru from "./translates/ru.js";
import kk from "./translates/kk.js";

Vue.use(MLInstaller);

export default new MLCreate({
  initial: "ru",
  languages: [new MLanguage("ru").create(ru), new MLanguage("kk").create(kk)],
  save: false,
  middleware: null,
  gettingStrategy: "return-path-by-default-without-error"
});
