<template>
  <section class="section">
    <h2 class="subtitle service" v-if="currentService">
      <template v-if="$ml.current === 'ru'">{{
        $ml.get("creating_homeschooling")
      }}</template>
      "{{ $ml.get(currentService.slug) }}"
      <template v-if="$ml.current === 'kk'">{{
        $ml.get("creating_homeschooling")
      }}</template>
    </h2>
    <ul class="steps">
      <li
        class="step-item"
        v-for="stepR in steps"
        :key="'step_' + stepR"
        :class="{
          'is-completed': step > stepR,
          'is-active': currentStep === stepR
        }"
      >
        <div class="step-marker">
          <span class="icon"> <i class="fa fa-check"></i> {{ stepR }} </span>
        </div>
      </li>
    </ul>

    <component
      v-model="model"
      :is="componentName"
      :slug="slug"
      :required-files-mode="requiredFilesMode"
      :registered-claim="registeredClaim"
      :api-resource="apiResource"
      :current-step="currentStep"
      :first-step="firstStep"
      :last-step="lastStep"
      @onModelChange="onModelChange"
      @tablesfilled="checkTablesFilled"
      @additionalFilesFilled="checkAdditionalFilesFilled"
      @nextStep="nextStep()"
      @previousStep="previousStep()"
      :declarertype="this.declarertype ? this.declarertype : null"
      @activateStep="activateStep"
      @deactivateStep="deactivateStep"
      @onSumbit="onSubmit"
      @onSubmitSigned="onSubmitSigned"
      @doneCallback="doneCallback()"
    />

    <footer class="wizard-footer">
      <div class="columns is-gapless is-mobile">
        <div class="column is-half">
          <div class="field">
            <div class="control" v-if="canStepBack">
              <button class="button" @click="previousStep()">
                <fa-icon icon="arrow-left"></fa-icon>&nbsp;{{ $ml.get("back") }}
              </button>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <span
                v-if="
                  currentStep !== lastStep &&
                    currentStep + 1 !== lastStep &&
                    $ml.current === 'ru'
                "
              >
                Кнопка "Далее" будет активна, только при заполнении всех полей
              </span>
              <span
                v-if="
                  currentStep !== lastStep &&
                    currentStep + 1 !== lastStep &&
                    $ml.current === 'kk'
                "
              >
                Барлық өрістер толтырылған кезде ғана, «Келесі» түймесі белсенді
                болады
              </span>
              <button
                class="button is-info"
                v-if="currentStep !== lastStep && currentStep + 1 !== lastStep"
                :disabled="
                  !currentStepFinished ||
                    !fileUploaded ||
                    (currentStep === 2 &&
                    ['teachers-attestation', 'principals-attestation'].contains(
                      slug
                    )
                      ? !tableFilled || !additionalFilesFilled
                      : false)
                "
                :title="
                  currentStepFinished
                    ? $ml.get('next')
                    : 'Пожалуйста, заполните все данные.'
                "
                @click="nextStep()"
              >
                {{ $ml.get("next") }}&nbsp;<fa-icon
                  :icon="currentStepFinished ? 'arrow-right' : 'times'"
                ></fa-icon>
              </button>
            </div>

            <template v-if="currentStep + 1 === lastStep">
              <div class="control" v-if="canGoFurtherWithoutSign">
                <button
                  :disabled="
                    !currentStepFinished || inProgress || !fileUploaded
                  "
                  :title="
                    currentStepFinished
                      ? $ml.get('next')
                      : 'Пожалуйста, заполните все данные.'
                  "
                  class="button is-info"
                  @click="onSubmit"
                >
                  {{ $ml.get("send_claim") }}&nbsp;<fa-icon
                    icon="check"
                  ></fa-icon>
                </button>
              </div>
              <div class="control">
                <sign-data-button
                  :data="model"
                  :disabled="
                    !currentStepFinished || inProgress || !fileUploaded
                  "
                  :title="
                    currentStepFinished
                      ? $ml.get('next')
                      : 'Пожалуйста, заполните все данные.'
                  "
                  @onSign="onSubmitSigned"
                />
              </div>
            </template>
            <div class="control" v-if="currentStep === lastStep">
              <button class="button is-success" @click="doneCallback()">
                {{ doneButtonName }}
                <fa-icon icon="check"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>
<script>
import * as Wizards from "../wizards";
import { mapGetters, mapActions } from "vuex";
import SignDataButton from "../form-components/SignDataButton";
import { displayError, getErrorsText } from "../../services/helper";
import eventBus from "../../services/event-bus";

export default {
  name: "ServiceWizard",
  props: {
    step: {
      default: 1
    },
    slug: {
      type: String
    },
    declarertype: {}
  },
  components: {
    SignDataButton,
    ...Wizards
  },
  computed: {
    requiredFilesMode() {
      return ["homeschooling"].indexOf(this.slug) !== -1 ? "OR" : "AND";
    },
    canGoFurtherWithoutSign() {
      return ["spa-treatment"].indexOf(this.slug) === -1;
    },
    doneButtonName() {
      let result = this.$ml.get("done");

      if (this.slug === "special-needs-social-services") {
        result = "Ознакомился";
      }

      return result;
    },
    currentStepFinished() {
      return this.stepFinished[this.currentStep] === true;
    },
    canStepBack: function() {
      return (
        this.currentStep !== this.firstStep &&
        this.currentStep !== this.lastStep
      );
    },
    componentName() {
      return this.slug + "-wizard";
    },
    currentStep() {
      return parseInt(this.step);
    },
    lastStep() {
      return Math.max.apply(null, this.steps);
    },
    firstStep() {
      return Math.min.apply(null, this.steps);
    },
    ...mapGetters({
      services: "services/getList"
    }),
    currentService() {
      return this.services.find(service => service.slug === this.slug) || {};
    }
  },
  data() {
    return {
      tableFilled: false,
      additionalFilesFilled: false,
      apiResource: this.$apiResource.make("Claims"),
      registeredClaim: {},
      steps: [1, 2, 3, 4],
      model: null,
      inProgress: false,
      fileUploaded: true, //Индикатор загрузки всех файлов
      stepFinished: {
        1: true,
        2: true,
        3: true,
        4: true
      }
    };
  },
  methods: {
    onModelChange(data) {
      this.model = data;
    },
    checkTablesFilled(value) {
      this.tableFilled = value;
    },
    checkAdditionalFilesFilled(value) {
      this.additionalFilesFilled = value;
    },
    onSubmit() {
      if (!this.inProgress) {
        this.inProgress = true;
        this.apiResource
          .store(this.slug, this.model)
          .then(response => {
            this.inProgress = false;
            if (response.status && response.status === 200) {
              this.registeredClaim = response.data;
              this.nextStep();
            } else {
              displayError(this, response.message);
            }
          })
          .catch(error => {
            this.inProgress = false;
            displayError(this, getErrorsText(error));
          });
      }
    },
    onSubmitSigned(xml) {
      if (!this.inProgress) {
        this.inProgress = true;
        this.apiResource
          .storeSigned(this.slug, { xml: xml })
          .then(response => {
            this.inProgress = false;
            if (response.status && response.status === 200) {
              this.registeredClaim = response.data;
              this.nextStep();
            } else {
              displayError(this, response.message);
            }
          })
          .catch(error => {
            this.inProgress = false;
            displayError(this, getErrorsText(error));
          });
      }
    },
    activateStep(step) {
      this.stepFinished[step] = true;
    },
    deactivateStep(step) {
      this.stepFinished[step] = false;
    },
    changeStep(step) {
      if (
        ["teachers-attestation", "principals-attestation"].includes(this.slug)
      ) {
        this.$router.push({
          name: "service.wizard",
          params: {
            step: "" + step,
            slug: this.slug,
            declarertype: this.declarertype,
            model: this.model
          }
        });
      } else {
        this.$router.push({
          name: "service.wizard",
          params: { step: "" + step, slug: this.slug, model: this.model }
        });
      }
    },
    nextStep() {
      this.changeStep(this.currentStep + 1);
    },
    previousStep() {
      this.changeStep(this.currentStep - 1);
    },
    doneCallback() {
      this.$router.push({ name: "claims.personal-list" });
    },
    ...mapActions({
      getServiceList: "services/requestList"
    })
  },
  watch: {
    slug() {
      this.model = {};
    }
  },
  created() {
    if (!this.model && this.step !== this.firstStep) {
      this.changeStep(this.firstStep);
    }
    if (!this.services) {
      this.getServiceList();
    }

    if (this.firstStep && !this.model) {
      this.model = {};
    }
  },
  beforeMount() {
    eventBus.$on("file-uploading", () => {
      this.fileUploaded = false;
    });

    eventBus.$on("file-uploaded", () => {
      this.fileUploaded = true;
    });

    this.$route.meta.breadcrumb[0]["link"] = "/services/" + this.slug;
  }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";

.wizard-footer {
  margin-top: 1rem;
  padding: 2rem 1rem 1rem;
  border-radius: 6px;
  background-color: $white-ter;
}
</style>
