import * as classMap from "./resources";

class ApiResourceFactory {
  constructor(axios) {
    this.axios = axios;
    this._instaces = {};
    this.classMap = classMap;
  }

  make(className) {
    if (!this._instaces[className]) {
      this._instaces[className] = new this.classMap[className](this.axios);
    }
    return this._instaces[className];
  }
}

export default ApiResourceFactory;
