import SignDataButton from "../components/form-components/SignDataButton";

export const ExternalPopupMixin = {
  components: { SignDataButton },
  props: {
    item: {
      type: Object
    },
    statusButton: {
      type: Object,
      required: true
    },
    value: {
      type: Object
    },
    popupOpened: {
      type: Boolean
    },
    dataToSign: {}
  },
  name: "ExternalPopupMixin",
  data() {
    return {
      additionalData: {},
      isFormValidVee: false,
      localPopupOpened: false
    };
  },
  computed: {
    isFormValid() {
      return this.isFormValidVee;
    }
  },
  methods: {
    onSign(xml) {
      this.$emit("onSign", xml);
    },
    closePopup(key) {
      this.$emit("closePopup", key);
    },
    openPopup(key) {
      this.$emit("openPopup", key);
    }
  },
  watch: {
    additionalData: {
      handler() {
        this.$validator.validateAll().then(isFormValidVee => {
          this.isFormValidVee = isFormValidVee;
          if (this.isFormValid) {
            this.$emit("input", this.additionalData);
          }
        });
      },
      deep: true
    },
    popupOpened() {
      this.localPopupOpened = this.popupOpened;
    },
    localPopupOpened() {
      if (!this.localPopupOpened) {
        this.closePopup(this.statusButton.key);
      } else {
        this.openPopup(this.statusButton.key);
      }
    }
  },
  mounted() {
    this.additionalData = this.value;
  }
};
