<template>
  <section class="section">
    <h2 class="subtitle">{{ $ml.get("journal_claims") }}</h2>
    <div class="field">
      <label for="search" class="label">{{ $ml.get("search") }}</label>
      <div class="control">
        <input
          v-model="requestParams.search_query"
          @keyup="loadItems()"
          id="search"
          type="text"
          class="input"
        />
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <b-field
            :label="
              'Дата' +
                (searchFilled ? ' (не учитывается при быстром поиске)' : '')
            "
          >
            <b-datepicker
              :disabled="searchFilled"
              icon-pack="far"
              @input="dateChanged()"
              v-model="created_at_start"
              placeholder="Нажмите для выбора даты..."
            >
            </b-datepicker>
          </b-field>
        </div>
      </div>
    </div>

    <appointment-table :items="formattedItems" />

    <VPagination
      @change="changePage"
      :icon-pack="'far'"
      :total="totalPages"
      :current="currentPage"
      :order="order"
      :per-page="perPage"
    />
  </section>
</template>

<script>
import { CommonListMixin } from "../../mixins/common-list-mixin";
import { getNameFromUserInfo, formatDate } from "../../services/helper";
import AppointmentTable from "../../components/lists/service-tables/AppointmentTable";
import moment from "moment";

export default {
  components: { AppointmentTable },
  name: "PersonalList",
  mixins: [CommonListMixin],
  computed: {
    searchFilled() {
      return this.requestParams.search_query !== "";
    },
    formattedItems() {
      return this.items.map(item => {
        return {
          dateOfReceipt: this.formatDate(item.time_of_receipt, "DD.MM.YYYY"),
          timeOfReceipt: this.formatDate(item.time_of_receipt, "H:mm"),
          iin:
            item.claim_data.childsData !== undefined
              ? item.claim_data.childsData.iin
              : "",
          name: this.getNameFromUserInfo(item.claim_data.childsData),
          claim: item
        };
      });
    }
  },
  data() {
    return {
      created_at_start: new Date(),
      requestParams: {
        search_query: "",
        created_at_start: new Date()
      }
    };
  },
  methods: {
    getNameFromUserInfo,
    formatDate,
    dateChanged() {
      this.requestParams.created_at_start = moment(
        this.created_at_start
      ).format("YYYY-MM-DD");
      this.loadItems();
    }
  },
  beforeMount() {
    this.apiResource = this.$apiResource.make("PmpkClaim");
    this.listMethod = "employeeList";
  }
};
</script>

<style scoped></style>
