<template>
  <div style="display: inline-block; margin: 0 15px 15px 0;">
    <button
      class="button"
      :key="'status_pre_button_' + statusButton.key"
      :class="statusButton.class"
      v-if="statusButton.statusesToShow.indexOf(item.status.code) !== -1"
      @click="openPopup(statusButton.key)"
    >
      {{ statusButton.name }}
    </button>

    <b-modal
      :key="'status_b_modal_' + statusButton.key"
      :active.sync="localPopupOpened"
      v-if="localPopupOpened"
      has-modal-card
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Дополнительная информация</p>
        </header>
        <section class="modal-card-body">
          <form>
            <b-field label="Номер удостоверения личности заявителя">
              <b-input
                type="text"
                id="additionalData.numberPassport"
                name="additionalData.numberPassport"
                v-validate="'required|min:5|max:255'"
                v-model="additionalData.numberPassport"
              >
              </b-input>
            </b-field>

            <b-field label="Кем выдано">
              <b-input
                type="text"
                id="additionalData.departmentPassport"
                name="additionalData.departmentPassport"
                v-validate="'required|min:5|max:255'"
                v-model="additionalData.departmentPassport"
              >
              </b-input>
            </b-field>

            <b-field label="Дата выдачи">
              <b-datepicker
                placeholder="Нажмите для выбора даты..."
                v-model="additionalData.datePassport"
              >
              </b-datepicker>
            </b-field>

            <b-field label="ФИО законного представителя">
              <b-input
                type="text"
                id="additionalData.legalRepresentative"
                name="additionalData.legalRepresentative"
                v-validate="'required|min:5|max:255'"
                v-model="additionalData.legalRepresentative"
              >
              </b-input>
            </b-field>

            <b-field :label="$ml.get('position')">
              <b-input
                type="text"
                id="additionalData.position"
                name="additionalData.position"
                v-validate="'required|min:3|max:255'"
                v-model="additionalData.position"
              >
              </b-input>
            </b-field>

            <b-field
              label="Наименование учреждения, в котором находится ребенок"
            >
              <b-input
                type="text"
                id="additionalData.organisationName"
                name="additionalData.organisationName"
                v-validate="'required|min:5|max:255'"
                v-model="additionalData.organisationName"
              >
              </b-input>
            </b-field>

            <b-field label="Дата заключения договора">
              <b-datepicker
                placeholder="Нажмите для выбора даты..."
                v-model="additionalData.contractDate"
              >
              </b-datepicker>
            </b-field>

            <b-field label="Дата решения">
              <b-datepicker
                placeholder="Нажмите для выбора даты..."
                v-model="additionalData.decisionDate"
              >
              </b-datepicker>
            </b-field>

            <b-field label="№ решения">
              <b-input
                type="text"
                id="additionalData.decisionNumber"
                name="additionalData.decisionNumber"
                v-validate="'required|numeric'"
                v-model="additionalData.decisionNumber"
              >
              </b-input>
            </b-field>

            <b-field label="№ дела">
              <b-input
                type="text"
                id="additionalData.caseNumber"
                name="additionalData.caseNumber"
                v-validate="'required|numeric'"
                v-model="additionalData.caseNumber"
              >
              </b-input>
            </b-field>

            <div class="field is-horizontal">
              <div class="field-body">
                <b-field label="С">
                  <b-datepicker
                    placeholder="Нажмите для выбора даты..."
                    v-model="additionalData.paymentsPeriodStart"
                  >
                  </b-datepicker>
                </b-field>
                <b-field label="По">
                  <b-datepicker
                    placeholder="Нажмите для выбора даты..."
                    v-model="additionalData.paymentsPeriodEnd"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>

            <b-field label="Сумма">
              <b-input
                type="number"
                step="0.01"
                id="additionalData.amount"
                name="additionalData.amount"
                v-validate="'required|numeric'"
                v-model="additionalData.amount"
              >
              </b-input>
            </b-field>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button"
            type="button"
            @click="closePopup(statusButton.key)"
          >
            {{ $ml.get("cancel") }}
          </button>
          <sign-data-button
            :disabled="!isFormValid"
            :title="!isFormValid ? 'Пожалуйста, заполните все данные' : ''"
            :key="'status_button_' + statusButton.key"
            :classes="statusButton.class"
            :data="dataToSign"
            :name="statusButton.name"
            v-if="statusButton.statusesToShow.indexOf(item.status.code) !== -1"
            @onSign="onSign"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ExternalPopupMixin } from "../../../../mixins/external-popup-mixin";

export default {
  mixins: [ExternalPopupMixin],
  name: "AdoptionPopup"
};
</script>

<style scoped></style>
