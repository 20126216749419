<template>
  <section class="section">
    <h2 class="subtitle">{{ $ml.get("base_pmpk") }}</h2>
    <v-card>
      <VPagination
        @change="changePage"
        :icon-pack="'far'"
        :total="totalPages"
        :current="currentPage"
        :order="order"
        :per-page="perPage"
      />
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="requestParams.search"
          append-icon="search"
          label="Поиск"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="formattedItems"
        no-results-text="Нет результатов"
        no-data-text="Нет данных"
        :loading="inProgress"
        :hide-actions="true"
        class="elevation-1"
      >
        <template slot="headerCell" slot-scope="props">
          <v-tooltip bottom>
            <span slot="activator">
              {{ props.header.text }}
            </span>
            <span>
              {{ props.header.text }}
            </span>
          </v-tooltip>
        </template>

        <template slot="items" slot-scope="props">
          <tr>
            <td>{{ props.item.timeOfReceipt }}</td>
            <td>{{ props.item.iin }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.multiplicityOfReception }}</td>
            <td>{{ props.item.pmpkName }}</td>
            <td>{{ props.item.isDisabled }}</td>
            <td>{{ props.item.direction }}</td>
            <td>{{ props.item.organisationName }}</td>
            <td>{{ props.item.childCategory }}</td>
            <td>{{ props.item.conclusion }}</td>
            <td>{{ props.item.recommendations }}</td>
            <td>
              <router-link
                class="button is-info is-small"
                :to="{
                  name: 'service.view-personal',
                  params: { id: props.item.claimId, slug: props.item.slug }
                }"
              >
                {{ $ml.get("view") }}
              </router-link>
            </td>
          </tr>
        </template>
      </v-data-table>
      <VPagination
        @change="changePage"
        :icon-pack="'far'"
        :total="totalPages"
        :current="currentPage"
        :order="order"
        :per-page="perPage"
      />
    </v-card>
  </section>
</template>

<script>
import { CommonListMixin } from "../../mixins/common-list-mixin";
import { mapGetters } from "vuex";
import { getNameFromUserInfo, formatDate } from "../../services/helper";
import areasPmpk from "../../configs/areas-pmpk.json";

export default {
  name: "PersonalList",
  mixins: [CommonListMixin],
  computed: {
    ...mapGetters({
      role: "user/getUserRole"
    }),
    formattedItems() {
      return this.items.map(item => {
        return {
          timeOfReceipt: this.formatDate(item.claim.time_of_receipt),
          iin: item.claim.claim_data.childsData.iin,
          name: this.getNameFromUserInfo(item.claim.claim_data.childsData),
          multiplicityOfReception:
            item.claim.claim_data.childsData.multiplicityOfReception,
          pmpkName: this.getPmpkName(item.claim.claim_data.childsData.area),
          isDisabled: item.claim.claim_data.childsData.isDisabled
            ? "Есть"
            : "Нет",
          direction: item.claim_history.additional_data.direction,
          organisationName: item.claim_history.additional_data.organisationName,
          childCategory: item.claim_history.additional_data.childCategory,
          conclusion: item.claim_history.additional_data.conclusion,
          recommendations: item.claim_history.additional_data.recommendations,

          claimId: item.claim_id,
          slug: item.claim.service.slug
        };
      });
    }
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Дата прохождения ПМПК",
          align: "left",
          value: "timeOfReceipt"
        },
        {
          text: "ИИН ребенка",
          align: "left",
          value: "iin"
        },
        {
          text: "ФИО ребенка",
          align: "left",
          value: "name"
        },
        {
          text: "Кратность приема",
          align: "left",
          value: "multiplicityOfReception"
        },
        {
          text: "№ ПМПК",
          align: "left",
          value: "pmpkName"
        },
        {
          text: "Наличие инвалидности",
          align: "left",
          value: "isDisabled"
        },
        {
          text: "Направление",
          align: "left",
          value: "direction"
        },
        {
          text: "Наименование организации",
          align: "left",
          value: "organisationName"
        },
        {
          text: "Категория",
          align: "left",
          value: "childCategory"
        },
        {
          text: "Заключение",
          align: "left",
          value: "conclusion"
        },
        {
          text: "Рекомендации",
          align: "left",
          value: "recommendations"
        },
        {
          text: "",
          sortable: false,
          value: "id"
        }
      ],
      requestParams: {
        search: this.search
      }
    };
  },
  methods: {
    getNameFromUserInfo,
    formatDate,
    getPmpkName(area) {
      return areasPmpk[area] ? areasPmpk[area].name : "";
    }
  },
  watch: {
    requestParams: {
      handler() {
        this.loadItems();
      },
      deep: true
    }
  },
  beforeMount() {
    this.apiResource = this.$apiResource.make("PmpkClaim");
    this.listMethod = "list";
  }
};
</script>

<style scoped></style>
