export default {
  homeschooling: [
    {
      name: "Принять в работу",
      statusesToShow: ["REGISTERED"],
      statusToChange: "IN_PROGRESS",
      class: "is-info",
      key: "CHANGE_TO_IN_PROGRESS",
      translate: "button_in_progress"
    },
    {
      name: "Подтвердить",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "AWAITING_FOR_COURSE",
      class: "is-success",
      key: "CHANGE_TO_AWAITING_FOR_COURSE"
    },
    {
      name: "Отклонить",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "REJECTED",
      class: "is-warning",
      rejectPopupShow: true,
      key: "CHANGE_TO_REJECTED",
      popupConfig: {
        popupTitle: "Отклонение",
        commentTitle: "Причина",
        commentValidationRules: "required|min:5",
        translate: "rejecting",
        commentTranslate: "reason"
      },
      translate: "reject_claim"
    },
    {
      name: "Добавить дату начала обучения",
      statusesToShow: ["AWAITING_FOR_COURSE"],
      statusToChange: "COURSE_IN_PROGRESS",
      class: "is-info",
      homeschoolingStartPopupShow: true,
      key: "CHANGE_TO_COURSE_IN_PROGRESS"
    },
    {
      name: "Прекращение обучения",
      statusesToShow: ["COURSE_IN_PROGRESS"],
      statusToChange: "COURSE_ENDED",
      class: "is-warning",
      homeschoolingEndPopupShow: true,
      key: "CHANGE_TO_COURSE_ENDED"
    }
  ]
};
