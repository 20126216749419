import SpecialNeedsSocialServices from "./claim-change-status-buttons/special-needs-social-services";
import Homeschooling from "./claim-change-status-buttons/homeschooling";
import AdoptionFamilyPayments from "./claim-change-status-buttons/adoption-family-payments";
import RehabilitationAndAdaptation from "./claim-change-status-buttons/reabilitation-and-adaptation";
import GraduateDocsDuplicate from "./claim-change-status-buttons/graduate-docs-duplicate";
import ParentalVisitPermit from "./claim-change-status-buttons/parental-visit-permit";
import TeachersAttestation from "./claim-change-status-buttons/teachers-attestation";
import NuclearVictims from "./claim-change-status-buttons/nuclear-victims";
import NuclearVictimsAkimat from "./claim-change-status-buttons/nuclear-victims-akimat";
import SpaTreatment from "./claim-change-status-buttons/spa-treatment";
import PrincipalsAttestation from "./claim-change-status-buttons/principals-attestation";

export default {
  default: [
    {
      name: "Принять в работу",
      statusesToShow: ["REGISTERED"],
      statusToChange: "IN_PROGRESS",
      class: "is-info",
      rejectPopupShow: false,
      key: "CHANGE_TO_IN_PROGRESS",
      translate: "button_in_progress"
    },
    {
      name: "Одобрить",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "ENDED",
      class: "is-success",
      rejectPopupShow: false,
      key: "CHANGE_TO_ENDED"
    },
    {
      name: "Отказ",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "REJECTED",
      class: "is-warning",
      rejectPopupShow: true,
      key: "CHANGE_TO_REJECTED",
      popupConfig: {
        popupTitle: "Отказ",
        commentTitle: "Причина отказа",
        commentValidationRules: "required|min:5"
      }
    }
  ],
  ...SpecialNeedsSocialServices,
  ...Homeschooling,
  ...AdoptionFamilyPayments,
  ...RehabilitationAndAdaptation,
  ...GraduateDocsDuplicate,
  ...ParentalVisitPermit,
  ...TeachersAttestation,
  ...NuclearVictims,
  ...NuclearVictimsAkimat,
  ...SpaTreatment,
  ...PrincipalsAttestation
};
