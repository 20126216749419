<template>
  <ul>
    <template v-for="(fileObject, index) in filteredFiles">
      <li v-if="fileObject['id']" :key="listKey + '_p_' + index">
        <a
          class="download-item-link"
          download
          @click.prevent="downloadFile(fileObject, true)"
          title="Скачать"
          ><fa-icon :icon="['far', 'file-alt']"></fa-icon>&nbsp;<span>{{
            fileObject.label
          }}</span></a
        >
      </li>
    </template>
  </ul>
</template>

<script>
import Api from "../../../services/api/api-resource/api-instance";

export default {
  props: {
    listKey: {
      type: String
    },
    filesList: {
      type: Array
    }
  },
  name: "FileDownloadList",
  computed: {
    filteredFiles() {
      const data = [];
      if (this.filesList) {
        this.filesList.forEach(item => {
          if (item["id"] !== undefined) {
            data.push(item);
          } else {
            item.forEach(child => {
              data.push(child);
            });
          }
        });
      }
      return data;
    }
  },
  data() {
    return {
      uploadApi: Api.make("Upload")
    };
  },
  methods: {
    // @TODO: переделать на скачивание по прямой ссылке
    downloadFile(fileObject, encode) {
      this.uploadApi.download(fileObject.id, encode).then(response => {
        const URL = window.URL || window.webkitURL;
        const blob = new Blob([response.data], {
          type: response.headers["Content-Type"]
        });

        const a = document.createElement("a");
        const url = URL.createObjectURL(blob);

        a.href = url;
        a.download = fileObject.filename;
        a.click();

        URL.revokeObjectURL(url);
        a.remove();
      });
    }
  }
};
</script>
