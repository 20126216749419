<template>
  <table class="table is-striped is-fullwidth">
    <thead>
      <tr>
        <th>{{ $ml.get("reg_number") }}</th>
        <th>{{ $ml.get("iin_claim_man") }}</th>
        <th>{{ $ml.get("fio_claim_man") }}</th>
        <th>{{ $ml.get("creation_date") }}</th>
        <th>{{ $ml.get("status") }}</th>
      </tr>
    </thead>
    <tbody>
      <ClaimHistoryRow
        v-for="item in history"
        :item="item"
        :key="item.id"
        :service-slug="serviceSlug"
      />
    </tbody>
  </table>
</template>

<script>
import ClaimHistoryRow from "./ClaimHistoryRow";

export default {
  components: { ClaimHistoryRow },
  props: {
    history: {
      type: Array,
      default: () => {
        return [];
      }
    },
    serviceSlug: {}
  },
  data() {
    return {};
  },
  name: "ClaimHistoryBlock"
};
</script>
