<template>
  <table v-if="items.length > 0" class="table is-striped is-fullwidth">
    <thead>
      <tr>
        <th>Дата</th>
        <th>Время</th>
        <th>ФИО ребенка(ИИН)</th>
        <th>{{ $ml.get("status") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="'table_item_' + itemIndex" v-for="(item, itemIndex) in items">
        <td>{{ item.dateOfReceipt }}</td>
        <td>{{ item.timeOfReceipt }}</td>
        <td>{{ item.name }}({{ item.iin }})</td>
        <td>
          <span
            class="tag"
            :class="statusesClasses[item.claim.status.code].class"
            >{{
              getStatusName(
                item.claim.status,
                item.claim.service.slug,
                $ml.get(statusesClasses[item.claim.status.code].translate)
              )
            }}</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import StatusesClasses from "../../../configs/claim-statuses";
import { getStatusName } from "../../../services/helper";

export default {
  props: ["items"],
  name: "AppointmentTable",
  data() {
    return {
      statusesClasses: StatusesClasses
    };
  },
  methods: {
    getStatusName
  }
};
</script>

<style scoped></style>
