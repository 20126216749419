<template>
  <section class="section">
    <h2 class="subtitle" v-if="item.service">
      <b>#{{ item.id }}</b> {{ $ml.get(routeSlugService.slug) }}
    </h2>
    <span
      ><span class="tag is-success" v-if="item.sign">{{
        $ml.get("claim_signed_ecp")
      }}</span></span
    >
    <span
      ><span class="tag is-info" v-if="!item.sign">{{
        $ml.get("claim_witn_no_ecp")
      }}</span></span
    >

    <hr />

    <div class="grayed-footer" style="margin-top: 25px" v-if="isManager">
      <div class="field is-grouped-left">
        <div class="control" v-if="item.status">
          <template v-for="statusButton in changeStatusButtons">
            <template v-if="serviceSlug === 'principals-attestation'">
              <template
                v-if="
                  ['CHANGE_TO_IN_PROGRESS', 'CHANGE_TO_ENDED'].includes(
                    statusButton.key
                  )
                "
              >
                <sign-data-button
                  :key="'status_button_' + statusButton.key"
                  :classes="statusButton.class"
                  :data="dataToSign(statusButton.statusToChange)"
                  :name="statusButton.translate"
                  :button-name="
                    serviceSlug === 'special-needs-social-services' &&
                    statusButton.key === 'CHANGE_TO_ENDED_NON_APPEARANCE'
                      ? 'Заявитель не подошел'
                      : ''
                  "
                  v-if="
                    statusButton.statusesToShow.indexOf(item.status.code) !== -1
                  "
                  @onSign="onSign"
                />
              </template>
              <template v-else>
                <b-button
                  :key="'status_button_' + statusButton.key"
                  :type="statusButton.class"
                  @click="changeStatusCustom(statusButton.statusToChange)"
                  v-if="
                    statusButton.statusesToShow.indexOf(item.status.code) !==
                      -1 && !statusButton.rejectPopupShow
                  "
                >
                  {{ $ml.get(statusButton.translate) }}
                </b-button>
              </template>
              <template v-if="statusButton.rejectPopupShow">
                <button
                  class="button"
                  :key="'status_pre_button_' + statusButton.key"
                  :class="statusButton.class"
                  v-if="
                    statusButton.statusesToShow.indexOf(item.status.code) !== -1
                  "
                  @click="openedPopups[statusButton.key] = true"
                >
                  {{
                    statusButton.translate
                      ? $ml.get(statusButton.translate)
                      : statusButton.name
                  }}
                </button>

                <b-modal
                  :key="'status_b_modal_' + statusButton.key"
                  :active.sync="openedPopups[statusButton.key]"
                  v-if="openedPopups[statusButton.key]"
                  has-modal-card
                >
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">
                        {{
                          statusButton.popupConfig.translate
                            ? $ml.get(statusButton.popupConfig.translate)
                            : statusButton.popupConfig.popupTitle
                        }}
                      </p>
                    </header>
                    <section class="modal-card-body">
                      <b-field
                        :label="
                          statusButton.popupConfig.commentTranslate
                            ? $ml.get(statusButton.popupConfig.commentTranslate)
                            : statusButton.popupConfig.commentTitle
                        "
                      >
                        <b-input
                          type="textarea"
                          id="reject_reason"
                          name="reject_reason"
                          @input="validateComment()"
                          v-validate="
                            statusButton.popupConfig.commentValidationRules
                          "
                          v-model="reject_reason"
                        >
                        </b-input>
                      </b-field>
                    </section>
                    <footer class="modal-card-foot">
                      <button
                        class="button"
                        type="button"
                        @click="openedPopups[statusButton.key] = false"
                      >
                        {{ $ml.get("cancel") }}
                      </button>
                      <sign-data-button
                        :disabled="!isCommentValid"
                        :title="
                          isCommentValid
                            ? ''
                            : (statusButton.popupConfig.commentTranslate
                                ? $ml.get(
                                    statusButton.popupConfig.commentTranslate
                                  )
                                : statusButton.popupConfig.commentTitle) +
                              ' обязательна к заполнению'
                        "
                        :key="'status_button_' + statusButton.key"
                        :type="statusButton.class"
                        :name="
                          statusButton.translate
                            ? $ml.get('reject_claim')
                            : statusButton.name
                        "
                        v-if="
                          statusButton.statusesToShow.indexOf(
                            item.status.code
                          ) !== -1
                        "
                        @onSign="onSign"
                        :data="dataToSign(statusButton.statusToChange)"
                      >
                        {{ $ml.get(statusButton.translate) }}
                      </sign-data-button>
                    </footer>
                  </div>
                </b-modal>
              </template>
            </template>
            <template v-else>
              <template v-if="isSimpleButton(statusButton)">
                <sign-data-button
                  :key="'status_button_' + statusButton.key"
                  :classes="statusButton.class"
                  :data="dataToSign(statusButton.statusToChange)"
                  :name="statusButton.translate"
                  :button-name="
                    serviceSlug === 'special-needs-social-services' &&
                    statusButton.key === 'CHANGE_TO_ENDED_NON_APPEARANCE'
                      ? 'Заявитель не подошел'
                      : ''
                  "
                  v-if="
                    statusButton.statusesToShow.indexOf(item.status.code) !== -1
                  "
                  @onSign="onSign"
                />
              </template>

              <template v-if="statusButton.dateTimePopupShow">
                <button
                  class="button"
                  :key="'status_pre_button_' + statusButton.key"
                  :class="statusButton.class"
                  v-if="
                    statusButton.statusesToShow.indexOf(item.status.code) !== -1
                  "
                  @click="openedPopups[statusButton.key] = true"
                >
                  {{ statusButton.name }}
                </button>

                <b-modal
                  :key="'status_b_modal_' + statusButton.key"
                  :active.sync="openedPopups[statusButton.key]"
                  v-if="openedPopups[statusButton.key]"
                  has-modal-card
                >
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">Выбор даты и времени</p>
                    </header>
                    <section class="modal-card-body">
                      <b-field label="Дата">
                        <b-datepicker
                          placeholder="Нажмите для выбора даты..."
                          v-model="timeOfReceipt"
                        >
                        </b-datepicker>
                      </b-field>

                      <b-field label="Время">
                        <b-timepicker
                          placeholder="Нажмите для выбора времени..."
                          v-model="timeOfReceipt"
                        >
                        </b-timepicker>
                      </b-field>
                    </section>
                    <footer class="modal-card-foot">
                      <button
                        class="button"
                        type="button"
                        @click="openedPopups[statusButton.key] = false"
                      >
                        {{ $ml.get("cancel") }}
                      </button>
                      <sign-data-button
                        :key="'status_button_' + statusButton.key"
                        :classes="statusButton.class"
                        :data="dataToSign(statusButton.statusToChange)"
                        :name="statusButton.name"
                        v-if="
                          statusButton.statusesToShow.indexOf(
                            item.status.code
                          ) !== -1
                        "
                        @onSign="onSign"
                      />
                    </footer>
                  </div>
                </b-modal>
              </template>

              <template v-if="statusButton.rejectPopupShow">
                <button
                  class="button"
                  :key="'status_pre_button_' + statusButton.key"
                  :class="statusButton.class"
                  v-if="
                    statusButton.statusesToShow.indexOf(item.status.code) !== -1
                  "
                  @click="openedPopups[statusButton.key] = true"
                >
                  {{
                    statusButton.translate
                      ? $ml.get(statusButton.translate)
                      : statusButton.name
                  }}
                </button>

                <b-modal
                  :key="'status_b_modal_' + statusButton.key"
                  :active.sync="openedPopups[statusButton.key]"
                  v-if="openedPopups[statusButton.key]"
                  has-modal-card
                >
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">
                        {{
                          statusButton.popupConfig.translate
                            ? $ml.get(statusButton.popupConfig.translate)
                            : statusButton.popupConfig.popupTitle
                        }}
                      </p>
                    </header>
                    <section class="modal-card-body">
                      <b-field
                        :label="
                          statusButton.popupConfig.commentTranslate
                            ? $ml.get(statusButton.popupConfig.commentTranslate)
                            : statusButton.popupConfig.commentTitle
                        "
                      >
                        <b-input
                          type="textarea"
                          id="reject_reason"
                          name="reject_reason"
                          @input="validateComment()"
                          v-validate="
                            statusButton.popupConfig.commentValidationRules
                          "
                          v-model="reject_reason"
                        >
                        </b-input>
                      </b-field>
                    </section>
                    <footer class="modal-card-foot">
                      <button
                        class="button"
                        type="button"
                        @click="openedPopups[statusButton.key] = false"
                      >
                        {{ $ml.get("cancel") }}
                      </button>
                      <sign-data-button
                        :disabled="!isCommentValid"
                        :title="
                          isCommentValid
                            ? ''
                            : (statusButton.popupConfig.commentTranslate
                                ? $ml.get(
                                    statusButton.popupConfig.commentTranslate
                                  )
                                : statusButton.popupConfig.commentTitle) +
                              ' обязательна к заполнению'
                        "
                        :key="'status_button_' + statusButton.key"
                        :classes="statusButton.class"
                        :data="dataToSign(statusButton.statusToChange)"
                        :name="
                          statusButton.translate
                            ? $ml.get('reject_claim')
                            : statusButton.name
                        "
                        v-if="
                          statusButton.statusesToShow.indexOf(
                            item.status.code
                          ) !== -1
                        "
                        @onSign="onSign"
                      />
                    </footer>
                  </div>
                </b-modal>
              </template>

              <template v-if="statusButton.PMPKPopupShow">
                <button
                  class="button"
                  :key="'status_pre_button_' + statusButton.key"
                  :class="statusButton.class"
                  v-if="
                    statusButton.statusesToShow.indexOf(item.status.code) !== -1
                  "
                  @click="openedPopups[statusButton.key] = true"
                >
                  {{ statusButton.name }}
                </button>

                <b-modal
                  :key="'status_b_modal_' + statusButton.key"
                  :active.sync="openedPopups[statusButton.key]"
                  v-if="openedPopups[statusButton.key]"
                  has-modal-card
                >
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">Дополнительная информация</p>
                    </header>
                    <section class="modal-card-body">
                      <form data-vv-scope="pmpk-form">
                        <b-field :label="'Регистрационный номер ПМПК'">
                          <b-input
                            rows="1"
                            type="textarea"
                            id="additionalData.registrationNumbPmpk"
                            name="additionalData.registrationNumbPmpk"
                            @input="validatePMPKForm"
                            v-validate="'required'"
                            v-model="additionalData.registrationNumbPmpk"
                          >
                          </b-input>
                        </b-field>

                        <b-field label="Направление">
                          <b-select
                            placeholder="Направление"
                            name="additionalData.direction"
                            @input="validatePMPKForm"
                            v-validate="'required'"
                            v-model="additionalData.direction"
                          >
                            <option
                              v-for="item in directions"
                              :value="item.key"
                              :key="`kk_${item.key}`"
                            >
                              {{ item.name }}
                            </option>
                          </b-select>
                        </b-field>

                        <b-field v-if="additionalData.direction !== 'kppk'">
                          <b-checkbox
                            v-model="additionalData.rehabilitationCenters"
                            @input="changeRehabilitation"
                          >
                            Реабилитационные центры
                          </b-checkbox>
                          <b-checkbox
                            v-model="additionalData.logopedicCenters"
                            class="m-0-0-0-10"
                            @input="changeLogopedic"
                          >
                            Логопедические пункты
                          </b-checkbox>
                        </b-field>
                        <b-field v-if="isShowPushToCorrectKinderGarten">
                          <b-checkbox
                            v-model="
                              additionalData.pushToCorrectingKinderGarten
                            "
                            @input="changePushToCorrectingKinderGarten"
                          >
                            {{
                              $ml.get(
                                "pushing_to_queue_correcting_kindergarten"
                              )
                            }}
                          </b-checkbox>
                        </b-field>

                        <b-field
                          label="Наименование организации"
                          v-if="
                            additionalData.direction !== 'not_students' &&
                              additionalData.direction !==
                                'special_organizations'
                          "
                        >
                          <b-select
                            placeholder="Наименование организации"
                            @input="validatePMPKForm"
                            v-if="
                              additionalData.direction === 'kppk' ||
                                additionalData.direction === 'kgd_pmpk'
                            "
                            v-validate="'required'"
                            name="organisation_name_select"
                            v-model="additionalData.organisationName"
                          >
                            <option
                              v-for="item in organisationsOptions"
                              :value="item.name"
                              :key="`in_${item.name}`"
                            >
                              {{ item.name }}
                              {{ item.address ? "(" + item.address + ")" : "" }}
                            </option>
                          </b-select>

                          <b-input
                            v-if="
                              additionalData.direction !== 'kppk' &&
                                additionalData.direction !== 'kgd_pmpk'
                            "
                            type="text"
                            id="organisation_name"
                            name="organisation_name"
                            @input="validatePMPKForm"
                            v-validate="'required'"
                            v-model="additionalData.organisationName"
                          >
                          </b-input>
                        </b-field>

                        <b-field label="Категория">
                          <b-select
                            placeholder="Категория"
                            @input="changeChildCategory"
                            v-validate="'required'"
                            name="child_category_select"
                            v-model="additionalData.childCategory"
                          >
                            <option
                              v-for="item in childCategories"
                              :value="item.name"
                              :key="`inn_${item.name}`"
                            >
                              {{ item.name }}
                            </option>
                          </b-select>
                        </b-field>

                        <b-field
                          :label="'Язык обучения'"
                          v-if="
                            additionalData.direction === 'special_organizations'
                          "
                        >
                          <b-select
                            v-validate="'required'"
                            name="additionalData.instructionLanguage"
                            v-model="additionalData.instructionLanguage"
                            @input="additionalData.organisationName = ''"
                            placeholder="Язык обучения"
                          >
                            <option
                              v-for="item in instructionLanguagesItems"
                              :value="item.name"
                              :key="`in3_${item.name}`"
                            >
                              {{ item.name }}
                            </option>
                          </b-select>
                        </b-field>

                        <b-field
                          :label="'Класс'"
                          v-if="
                            additionalData.direction === 'special_organizations'
                          "
                        >
                          <b-select
                            v-validate="'required'"
                            name="additionalData.class"
                            v-model="additionalData.instructionClass"
                            @input="additionalData.organisationName = ''"
                            placeholder="Класс"
                          >
                            <option
                              v-for="item in instructionClassesItems"
                              :value="item.name"
                              :key="`in4_${item.name}`"
                            >
                              {{ item.name }}
                            </option>
                          </b-select>
                        </b-field>
                        <b-field
                          label="Наименование организации"
                          v-if="
                            additionalData.direction === 'special_organizations'
                          "
                          v-show="
                            additionalData.childCategory &&
                              additionalData.childCategory.length > 0 &&
                              (additionalData.instructionClass &&
                                additionalData.instructionClass.length) > 0 &&
                              additionalData.instructionLanguage &&
                              additionalData.instructionLanguage.length > 0
                          "
                        >
                          <b-select
                            v-validate="'required'"
                            name="organisation_name_select"
                            v-if="specialSchoolsPlacesOption.length > 0"
                            v-model="additionalData.organisationName"
                            placeholder="Наименование организации"
                          >
                            <option
                              v-for="item in specialSchoolsPlacesOption"
                              :value="item.special_school.name"
                              :key="`iss4_${item.special_school.id}`"
                            >
                              {{ item.special_school.name }}
                            </option>
                          </b-select>

                          <p v-else>
                            Организации с данными параметрами отсутствуют.
                          </p>
                        </b-field>

                        <b-field :label="'Заключение'">
                          <b-input
                            rows="1"
                            type="textarea"
                            id="additionalData.conclusion"
                            name="additionalData.conclusion"
                            @input="validatePMPKForm"
                            v-validate="'required'"
                            v-model="additionalData.conclusion"
                          >
                          </b-input>
                        </b-field>

                        <b-field :label="'Рекомендации'">
                          <b-input
                            rows="1"
                            type="textarea"
                            id="additionalData.recommendations"
                            name="additionalData.recommendations"
                            @input="validatePMPKForm"
                            v-validate="'required'"
                            v-model="additionalData.recommendations"
                          >
                          </b-input>
                        </b-field>

                        <b-field :label="'Медицинские услуги'">
                          <b-input
                            rows="1"
                            type="textarea"
                            id="additionalData.medicalServices"
                            name="additionalData.medicalServices"
                            @input="validatePMPKForm"
                            v-validate="'required'"
                            v-model="additionalData.medicalServices"
                          >
                          </b-input>
                        </b-field>

                        <b-field :label="'Образовательные услуги'">
                          <b-input
                            rows="1"
                            type="textarea"
                            id="additionalData.teachingServices"
                            name="additionalData.teachingServices"
                            @input="validatePMPKForm"
                            v-validate="'required'"
                            v-model="additionalData.teachingServices"
                          >
                          </b-input>
                        </b-field>

                        <b-field :label="'Социальные услуги'">
                          <b-input
                            rows="1"
                            type="textarea"
                            id="additionalData.socialServices"
                            name="additionalData.socialServices"
                            @input="validatePMPKForm"
                            v-validate="'required'"
                            v-model="additionalData.socialServices"
                          >
                          </b-input>
                        </b-field>
                        <b-field
                          label="Заключение и рекомендации действительны"
                        >
                          <b-datepicker
                            placeholder="Нажмите для выбора даты..."
                            v-validate="'required'"
                            v-model="additionalData.validUntilDate"
                            id="additionalData.validUntilDate"
                            name="additionalData.validUntilDate"
                            @input="validatePMPKForm"
                          >
                          </b-datepicker>
                        </b-field>
                      </form>
                    </section>
                    <footer class="modal-card-foot">
                      <button
                        class="button"
                        type="button"
                        @click="openedPopups[statusButton.key] = false"
                      >
                        {{ $ml.get("cancel") }}
                      </button>
                      <span
                        v-if="
                          statusButton.statusesToShow.indexOf(
                            item.status.code
                          ) !== -1
                        "
                      >
                        <sign-data-button
                          v-if="
                            additionalData.direction === 'special_organizations'
                          "
                          :disabled="
                            !PMPKFormValid || !enableSpecialSchoolSubmitButton
                          "
                          :title="
                            !PMPKFormValid
                              ? 'Пожалуйста, заполните все данные'
                              : ''
                          "
                          :key="'status_button_' + statusButton.key"
                          :classes="statusButton.class"
                          :data="dataToSign('DIRECTION_ISSUED_SPECIAL_SCHOOL')"
                          :name="statusButton.name"
                          @onSign="onSign"
                        />
                        <sign-data-button
                          v-else
                          :disabled="!PMPKFormValid"
                          :title="
                            !PMPKFormValid
                              ? 'Пожалуйста, заполните все данные'
                              : ''
                          "
                          :key="'status_button_' + statusButton.key"
                          :classes="statusButton.class"
                          :data="dataToSign(statusButton.statusToChange)"
                          :name="statusButton.name"
                          @onSign="onSign"
                        />
                      </span>
                    </footer>
                  </div>
                </b-modal>
              </template>

              <adoption-popup
                v-if="statusButton.adoptionPopupShow"
                :key="'popup_component_' + statusButton.key"
                :item="item"
                :status-button="statusButton"
                :popup-opened="openedPopups[statusButton.key] === true"
                :data-to-sign="dataToSign(statusButton.statusToChange)"
                @closePopup="closePopup"
                @openPopup="openPopup"
                @onSign="onSign"
                v-model="additionalData"
              />

              <undefined-date-popup
                v-if="statusButton.showUndefinedDatePopup"
                :key="'popup_component_' + statusButton.key"
                :item="item"
                :status-button="statusButton"
                :popup-opened="openedPopups[statusButton.key] === true"
                :data-to-sign="dataToSign(statusButton.statusToChange)"
                @closePopup="closePopup"
                @openPopup="openPopup"
                @onSign="onSign"
                v-model="additionalData"
              />

              <select-sanatorium-popup
                v-if="statusButton.showSanatoriumSelectPopup"
                :key="'popup_component_' + statusButton.key"
                :item="item"
                :status-button="statusButton"
                :popup-opened="openedPopups[statusButton.key] === true"
                :data-to-sign="dataToSign(statusButton.statusToChange)"
                @closePopup="closePopup"
                @openPopup="openPopup"
                @onSign="onSign"
                v-model="additionalData"
              />

              <template v-if="statusButton.homeschoolingStartPopupShow">
                <button
                  class="button"
                  :key="'status_pre_button_' + statusButton.key"
                  :class="statusButton.class"
                  v-if="
                    statusButton.statusesToShow.indexOf(item.status.code) !== -1
                  "
                  @click="openedPopups[statusButton.key] = true"
                >
                  {{ statusButton.name }}
                </button>

                <b-modal
                  :key="'status_b_modal_' + statusButton.key"
                  :active.sync="openedPopups[statusButton.key]"
                  v-if="openedPopups[statusButton.key]"
                  has-modal-card
                >
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">Выбор даты и времени</p>
                    </header>
                    <section class="modal-card-body">
                      <form data-vv-scope="info-form">
                        <b-field label="Дата">
                          <b-datepicker
                            placeholder="Нажмите для выбора даты..."
                            v-model="additionalData.paymentsPeriodStart"
                          >
                          </b-datepicker>
                        </b-field>

                        <b-field :label="'Класс зачисления'">
                          <b-input
                            type="text"
                            id="additionalData.organisation_name"
                            name="additionalData.organisation_name"
                            v-model="additionalData.organisation_name"
                          >
                          </b-input>
                        </b-field>

                        <v-form-upload
                          v-model="additionalData.attachedFile"
                          :label="additionalData.attachedFile.label"
                          @input="validateInfoForm"
                          v-validate="'required'"
                          :name="additionalData.attachedFile.name"
                        />
                      </form>
                    </section>
                    <footer class="modal-card-foot">
                      <button
                        class="button"
                        type="button"
                        @click="openedPopups[statusButton.key] = false"
                      >
                        {{ $ml.get("cancel") }}
                      </button>
                      <sign-data-button
                        :disabled="!InfoFormValid"
                        :title="
                          !InfoFormValid
                            ? 'Пожалуйста, заполните все данные'
                            : ''
                        "
                        :key="'status_button_' + statusButton.key"
                        :classes="statusButton.class"
                        :data="dataToSign(statusButton.statusToChange)"
                        :name="statusButton.translate"
                        v-if="
                          statusButton.statusesToShow.indexOf(
                            item.status.code
                          ) !== -1
                        "
                        @onSign="onSign"
                      />
                    </footer>
                  </div>
                </b-modal>
              </template>

              <template v-if="statusButton.homeschoolingEndPopupShow">
                <button
                  class="button"
                  :key="'status_pre_button_' + statusButton.key"
                  :class="statusButton.class"
                  v-if="
                    statusButton.statusesToShow.indexOf(item.status.code) !== -1
                  "
                  @click="openedPopups[statusButton.key] = true"
                >
                  {{ statusButton.name }}
                </button>

                <b-modal
                  :key="'status_b_modal_' + statusButton.key"
                  :active.sync="openedPopups[statusButton.key]"
                  v-if="openedPopups[statusButton.key]"
                  has-modal-card
                >
                  <div class="modal-card">
                    <header class="modal-card-head">
                      <p class="modal-card-title">Выбор даты и времени</p>
                    </header>
                    <section class="modal-card-body">
                      <form data-vv-scope="homeschoolingEnd-form">
                        <b-field label="Дата">
                          <b-datepicker
                            placeholder="Нажмите для выбора даты..."
                            v-model="additionalData.paymentsPeriodEnd"
                          >
                          </b-datepicker>
                        </b-field>

                        <b-field :label="$ml.get('reason')">
                          <b-input
                            @input="validateHomeschoolingEndForm"
                            v-validate="'required'"
                            type="text"
                            id="additionalData.reason"
                            name="additionalData.reason"
                            v-model="additionalData.reason"
                          >
                          </b-input>
                        </b-field>
                      </form>
                    </section>
                    <footer class="modal-card-foot">
                      <button
                        class="button"
                        type="button"
                        @click="openedPopups[statusButton.key] = false"
                      >
                        {{ $ml.get("cancel") }}
                      </button>
                      <sign-data-button
                        :disabled="!HomeschoolingEndFormValid"
                        :title="
                          !HomeschoolingEndFormValid
                            ? 'Пожалуйста, заполните все данные'
                            : ''
                        "
                        :key="'status_button_' + statusButton.key"
                        :classes="statusButton.class"
                        :data="dataToSign(statusButton.statusToChange)"
                        :name="statusButton.name"
                        v-if="
                          statusButton.statusesToShow.indexOf(
                            item.status.code
                          ) !== -1
                        "
                        @onSign="onSign"
                      />
                    </footer>
                  </div>
                </b-modal>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>

    <div class="grayed-footer" style="margin-top: 25px" v-else-if="showToUser">
      <div class="field is-grouped-left">
        <div class="control" v-if="item.status">
          <template v-for="statusButton in userChangeStatusButtons">
            <select-sanatorium-popup
              v-if="statusButton.showSanatoriumSelectPopup"
              :key="'popup_component_' + statusButton.key"
              :item="item"
              :status-button="statusButton"
              :popup-opened="openedPopups[statusButton.key] === true"
              :data-to-sign="dataToSign(statusButton.statusToChange)"
              @closePopup="closePopup"
              @openPopup="openPopup"
              @onSign="onSign"
              v-model="additionalData"
            />
          </template>
        </div>
      </div>
    </div>

    <div class="tabs">
      <ul>
        <li
          v-for="tab in tabs"
          :class="{ 'is-active': tab.name === tabName }"
          :key="'view_tab_' + tab.name"
        >
          <a @click.prevent="setActiveTab(tab.name)">
            {{ tab.title }}
          </a>
        </li>
      </ul>
    </div>

    <div class="tab-content">
      <template v-if="tabName === 'info'">
        <component v-if="componentName" :is="componentName" :item="item" />
      </template>

      <template v-if="tabName === 'history'">
        <claim-history-block
          v-if="item.history"
          :history="item.history"
          :service-slug="slug"
        />
      </template>

      <template v-if="tabName === 'docs'">
        <div class="tabs">
          <ul>
            <li
              v-for="tab in fileSubTabs"
              :class="{ 'is-active': tab.name === fileTabName }"
              :key="'view_tab_' + tab.name"
              v-show="
                !(
                  ['teachers-attestation', 'principals-attestation'].includes(
                    item.claim_type
                  ) && tab.name === 'gbd'
                )
              "
            >
              <a @click.prevent="setActiveFileTab(tab.name)">
                {{ $ml.get(tab.title) }}
              </a>
            </li>
          </ul>
        </div>

        <template v-if="fileTabName === 'common'">
          <h3>{{ $ml.get("full_docs") }}</h3>
          <claim-generated-files-component :files="item.files" />
        </template>

        <div v-if="fileTabName === 'attachments'">
          <div v-if="item.claim_type === 'teachers-attestation'">
            <TeachersAttestationFilesTabs
              @showFilePopup="showFilePopup"
              :declarer-type="declarerType"
              :claim-data="item.claim_data"
            />
          </div>
          <div v-else-if="item.claim_type === 'principals-attestation'">
            <PrincipalsAttestationFilesTabs
              @showFilePopup="showFilePopup"
              :declarer-type="declarerType"
              :claim-data="item.claim_data"
            />
          </div>

          <div v-else>
            <h3>{{ $ml.get("attached_docs") }}</h3>
            <a
              @click.prevent="downloadZipFile(item.id)"
              class="button is-small is-success"
              >Скачать в .zip</a
            >

            <file-download-list
              :list-key="'requiredFiles'"
              :files-list="item.claim_data.requiredFiles"
            />
            <file-download-list
              :list-key="'notRequiredFiles'"
              :files-list="item.claim_data.notRequiredFiles"
            />
          </div>
        </div>

        <template v-if="fileTabName === 'gbd'">
          {{ $ml.get("docs_from_gbd") }}
        </template>
      </template>
    </div>
    <template v-if="filePopup.show">
      <div class="file-popup">
        <div class="file-popup__root">
          <div class="file-popup__backdrop" @click="filePopup.show = false">
            &nbsp;
          </div>
          <div class="file-popup__content" v-if="filePopup.file">
            <div class="file-popup__header">
              <h2>
                {{ $ml.get("download_document") }}
                <span @click="filePopup.show = false">&times;</span>
              </h2>
            </div>
            <div class="file-popup__preview" v-if="filePopup.file.id">
              <template
                v-if="getFileExtension(filePopup.file.filename) === 'pdf'"
              >
                <embed
                  :src="additionalFileDownloadSrc(filePopup.file.id)"
                  height="600px"
                  width="100%"
                />
              </template>
              <template v-else>
                <img
                  :src="
                    additionalFileDownloadSrc(filePopup.file.id) +
                      (filePopup.refreshToken
                        ? '&' + filePopup.refreshToken
                        : '')
                  "
                />
              </template>
            </div>
            <div class="file-popup__download">
              <a
                :href="
                  additionalFileDownloadSrc(filePopup.file.id) +
                    '&download=true' +
                    (filePopup.withFilename
                      ? '&filename=' +
                        filePopup.file.name +
                        '.' +
                        getFileExtension(filePopup.file.filename)
                      : '')
                "
                target="_blank"
                :download="filePopup.file.filename"
                >{{ $ml.get("download_file") }}</a
              >
              <button
                v-if="getFileExtension(filePopup.file.filename) !== 'pdf'"
                @click="
                  rotateImage(
                    'right',
                    filePopup.file.id,
                    filePopup.withFilename,
                    filePopup.file.name
                  )
                "
                class="file-popup__rotate"
              >
                ↻
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import * as personalViews from "./view-personal";
import SignDataButton from "../form-components/SignDataButton";
import ClaimGeneratedFilesComponent from "./ClaimGeneratedFilesComponent";
import FileDownloadList from "./view-components/FileDownloadList";
import ClaimHistoryBlock from "./ClaimHistoryBlock";
import { toCamelCase } from "../../services/helper";
import ClaimChangeStatusButtons from "../../configs/claim-change-status-buttons";
import moment from "moment";
import Directions from "../../configs/directions";
import ChildCategories from "../../configs/child-categories";
import AdoptionPopup from "./view-personal/manager-popups/AdoptionPopup";
import { mapGetters } from "vuex";
import UndefinedDatePopup from "./view-personal/manager-popups/UndefinedDatePopup";
import VFormUpload from "../form-components/VFormUpload";
import { PermissionsMixin } from "../../mixins/permissions-mixin";
import { displayError, getErrorsText } from "../../services/helper";
import Api from "../../services/api/api-resource/api-instance";
import SelectSanatoriumPopup from "./view-personal/manager-popups/SelectSanatoriumPopup";
import TeachersAttestationFilesTabs from "./files-tabs/TeachersAttestationFilesTabs";
import PrincipalsAttestationFilesTabs from "./files-tabs/PrincipalsAttestationFilesTabs";
import uniqBy from "lodash.uniqby";
import claimStatuses from "../../configs/claim-statuses";

const dateToString = date => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

export default {
  mixins: [PermissionsMixin],
  name: "ServiceView",
  props: {
    tabName: {
      type: String,
      default: "info"
    },
    id: {
      required: true
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    slug: {
      type: String,
      required: true
    }
  },
  components: {
    SelectSanatoriumPopup,
    VFormUpload,
    UndefinedDatePopup,
    AdoptionPopup,
    ClaimHistoryBlock,
    FileDownloadList,
    ClaimGeneratedFilesComponent,
    SignDataButton,
    TeachersAttestationFilesTabs,
    PrincipalsAttestationFilesTabs,
    ...personalViews
  },
  computed: {
    specialSchoolsPlacesOption() {
      let filtered = this.specialSchoolsPlacesItems.filter(i => {
        return (
          i.instruction_language.name ===
            this.additionalData.instructionLanguage &&
          i.class.name === this.additionalData.instructionClass &&
          i.disability_category.name ===
            this.additionalData.disabilityCategory &&
          i.count_free_places > 0
        );
      });

      return uniqBy(filtered, i => {
        return i.special_school.name;
      });
    },
    showToUser() {
      return this.slug === "spa-treatment" && !this.isManager;
    },
    organisationsOptions() {
      let result = this.KPPKTypes;
      if (this.additionalData.direction === "kgd_pmpk") {
        result = [];
        result.push({
          name: "КДГ при ПМПК №1",
          address: ""
        });
        result.push({
          name: "КДГ при ПМПК №3",
          address: ""
        });
      }
      return result;
    },
    isManager() {
      return this.hasEditServicePermission(this.serviceSlug);
    },
    routeSlugService() {
      return this.services.find(service => service.slug === this.slug) || {};
    },
    ...mapGetters({
      services: "services/getList"
    }),
    serviceSlug() {
      return this.routeSlugService ? this.routeSlugService.slug : "common";
    },
    componentName() {
      let slug = this.serviceSlug;
      if (slug === "reabilitation-and-adaptation") {
        slug = "special-needs-social-services";
      }
      const viewName = toCamelCase(slug + "-view-personal");
      return this.$options.components[viewName]
        ? viewName
        : "CommonViewPersonal";
    },
    userChangeStatusButtons() {
      return this.changeStatusButtons.filter(item => {
        return item.key === "CHANGE_TO_DIRECTION_ISSUED";
      });
    },
    changeStatusButtons() {
      return this.claimChangeStatusButtons[this.serviceSlug]
        ? this.claimChangeStatusButtons[this.serviceSlug]
        : this.claimChangeStatusButtons["default"];
    },
    isKppk() {
      return this.additionalData.direction === "kppk";
    },
    additionalDataToSave() {
      const kppk = this.KPPKTypes.find(
        type => type.name === this.additionalData.organisationName
      );
      return {
        direction: this.additionalData.direction
          ? this.directions.find(
              dir => dir.key === this.additionalData.direction
            ).name
          : "",
        direction_key: this.additionalData.direction,
        organisation_name: this.additionalData.organisationName,
        conclusion: this.additionalData.conclusion,
        class: this.additionalData.instructionClass,
        instructionLanguage: this.additionalData.instructionLanguage,
        disabilityCategory: this.additionalData.disabilityCategory,
        recommendations: this.additionalData.recommendations,
        numberPassport: this.additionalData.numberPassport,
        departmentPassport: this.additionalData.departmentPassport,
        datePassport: dateToString(this.additionalData.datePassport),
        legalRepresentative: this.additionalData.legalRepresentative,
        position: this.additionalData.position,
        contractDate: dateToString(this.additionalData.contractDate),
        decisionDate: dateToString(this.additionalData.decisionDate),
        decisionNumber: this.additionalData.decisionNumber,
        caseNumber: this.additionalData.caseNumber,
        paymentsPeriodStart: dateToString(
          this.additionalData.paymentsPeriodStart
        ),
        paymentsPeriodEnd: dateToString(this.additionalData.paymentsPeriodEnd),
        amount: this.additionalData.amount,
        organisationName: this.additionalData.organisationName,
        isKppk: this.isKppk,
        kppkNumber: this.isKppk && kppk ? kppk.number : "",
        undefinedDate: dateToString(this.additionalData.undefinedDate),
        childCategory: this.additionalData.childCategory,
        attachedFile: this.additionalData.attachedFile.id
          ? this.additionalData.attachedFile
          : {},
        reason: this.additionalData.reason,
        rehabilitationCenters: this.additionalData.rehabilitationCenters,
        logopedicCenters: this.additionalData.logopedicCenters,
        medicalServices: this.additionalData.medicalServices,
        teachingServices: this.additionalData.teachingServices,
        socialServices: this.additionalData.socialServices,
        validUntilDate: this.additionalData.validUntilDate,
        registrationNumbPmpk: this.additionalData.registrationNumbPmpk,
        roomId: this.additionalData.roomId,
        pushToCorrectingKinderGarten:
          this.isShowPushToCorrectKinderGarten &&
          this.additionalData.pushToCorrectingKinderGarten
      };
    },
    isShowPushToCorrectKinderGarten() {
      return [
        "Неслышащие",
        "Слабослышащие",
        "Незрячие",
        "Слабовидящие",
        "Нарушение речи",
        "Нарушение опорно-двигательного аппарата",
        "Легкая и умеренная умственная отсталость",
        "Задержка психического развития"
      ].includes(this.additionalData.childCategory);
    }
  },
  data() {
    const today = new Date();

    return {
      filePopup: {
        show: false,
        file: null,
        withFilename: false
      },
      teacherUploadFileList: [],
      declarerType: null,
      timeOfReceipt: today,
      reject_reason: "",
      uploadApi: Api.make("Upload"),
      imageEditApi: Api.make("ImageEdit"),

      additionalData: {
        reason: "",
        rehabilitationCenters: false, // rehabilitation | logopedic
        logopedicCenters: false, // rehabilitation | logopedic
        direction: "",
        organisationName: "",
        conclusion: "",
        instructionClass: "",
        instructionLanguage: "",
        disabilityCategory: "Задержка психического развития",
        recommendations: "",
        medicalServices: "",
        teachingServices: "",
        socialServices: "",
        validUntilDate: null,

        numberPassport: "",
        departmentPassport: "",
        datePassport: today,
        legalRepresentative: "",
        position: "",
        contractDate: today,
        decisionDate: today,
        decisionNumber: "",
        caseNumber: "",
        paymentsPeriodStart: today,
        paymentsPeriodEnd: today,
        amount: "",
        undefinedDate: today,
        childCategory: "Задержка психического развития",
        registrationNumbPmpk: "",
        roomId: "",

        attachedFile: {
          label: "Приказ об организации обучения на дому",
          name: "file_1",
          uploaded: false,
          filename: "",
          size: 0,
          id: 0,
          hash: ""
        },
        pushToCorrectingKinderGarten: false
      },

      // KPPKTypes: KPPKTypes,
      KPPKTypes: [],
      directions: Directions,
      childCategories: ChildCategories,
      minDate: today,
      maxDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 30
      ),

      isCommentValid: false,
      PMPKFormValid: false,
      InfoFormValid: false,
      HomeschoolingEndFormValid: false,

      openedPopups: {},
      claimChangeStatusButtons: ClaimChangeStatusButtons,
      isRejectPopupOpened: false,
      apiResource: this.$apiResource.make("Claims"),
      kppkApiResource: this.$apiResource.make("Kppks"),
      specialSchoolsPlacesApi: this.$apiResource.make("SpecialSchoolsPlaces"),
      specialSchoolsPlacesItems: [],
      instructionLanguagesApi: this.$apiResource.make("InstructionLanguages"),
      instructionLanguagesItems: [],
      instructionClassesApi: this.$apiResource.make("InstructionClasses"),
      instructionClassesItems: [],
      item: {},
      tabs: [
        {
          name: "info",
          title: this.$ml.get("info_claim")
        },
        {
          name: "history",
          title: this.$ml.get("history_changes")
        },
        {
          name: "docs",
          title: this.$ml.get("full_docs")
        }
      ],
      fileTabName: "common",
      fileSubTabs: [
        {
          name: "common",
          title: "full_docs"
        },
        {
          name: "attachments",
          title: "attached_docs"
        },
        {
          name: "gbd",
          title: "docs_from_gbd"
        }
      ],
      enableSpecialSchoolSubmitButton: false
    };
  },
  watch: {
    "additionalData.organisationName"() {
      this.enableSpecialSchoolSubmitButton =
        this.additionalData.organisationName.length > 0 &&
        this.additionalData.childCategory.length > 0 &&
        this.additionalData.instructionClass.length > 0 &&
        this.additionalData.instructionLanguage.length > 0;
    }
  },
  methods: {
    changeChildCategory(event) {
      this.validatePMPKForm(event);
      this.additionalData.organisationName = "";
      let childCategory = this.childCategories.find(
        category => category.name === this.additionalData.childCategory
      );

      if (childCategory.server_disability_category !== null) {
        this.additionalData.disabilityCategory =
          childCategory.server_disability_category;
      }
    },
    changeRehabilitation(event) {
      if (this.additionalData.logopedicCenters && event) {
        this.additionalData.logopedicCenters = false;
      }
    },
    changeLogopedic(event) {
      if (this.additionalData.rehabilitationCenters && event) {
        this.additionalData.rehabilitationCenters = false;
      }
    },
    changePushToCorrectingKinderGarten(event) {
      this.additionalData.pushToCorrectingKinderGarten = event;
    },
    rotateImage(direction, fileId, filename = false, name) {
      this.imageEditApi.rotateRight(fileId).then(({ data }) => {
        let file = data;
        file.name = name;
        this.showFilePopup({
          file,
          filename,
          refreshToken: Math.random()
            .toString(36)
            .substring(7)
        });
      });
    },
    showFilePopup({ file, filename = false, refreshToken = null }) {
      this.filePopup.show = true;
      this.filePopup.withFilename = filename;
      this.filePopup.file = file;
      this.filePopup.refreshToken = refreshToken;
    },
    getFileExtension(fileName) {
      let reg = /(?:\.([^.]+))?$/;
      return reg.exec(fileName)[1].toLowerCase();
    },
    additionalFileDownloadSrc(fileId) {
      return (
        process.env["VUE_APP_API_BASE_URL"] +
        "api/claims" +
        "/additional-file/" +
        fileId +
        "?_token=" +
        this.$store.getters["user/getToken"]
      );
    },
    isSimpleButton(statusButton) {
      return (
        !statusButton.dateTimePopupShow &&
        !statusButton.rejectPopupShow &&
        !statusButton.PMPKPopupShow &&
        !statusButton.adoptionPopupShow &&
        !statusButton.showUndefinedDatePopup &&
        !statusButton.homeschoolingStartPopupShow &&
        !statusButton.homeschoolingEndPopupShow &&
        !statusButton.showSanatoriumSelectPopup
      );
    },
    downloadZipFile(claimId) {
      this.uploadApi.downloadZip(claimId).then(response => {
        const URL = window.URL || window.webkitURL;
        const blob = new Blob([response.data], {
          type: response.headers["Content-Type"]
        });
        const a = document.createElement("a");
        const url = URL.createObjectURL(blob);

        a.href = url;
        a.download = claimId + ".zip";
        a.click();

        URL.revokeObjectURL(url);
        a.remove();
      });
    },
    closePopup(key) {
      this.openedPopups[key] = false;
    },
    openPopup(key) {
      this.openedPopups[key] = true;
    },
    validatePMPKForm() {
      this.$validator.validateAll("pmpk-form").then(result => {
        this.PMPKFormValid = result;
      });
    },
    validateInfoForm() {
      this.$validator.validateAll("info-form").then(result => {
        this.InfoFormValid = result;
      });
    },
    validateHomeschoolingEndForm() {
      this.$validator.validateAll("homeschoolingEnd-form").then(result => {
        this.HomeschoolingEndFormValid = result;
      });
    },
    validateComment() {
      this.$validator.validate("reject_reason").then(result => {
        this.isCommentValid = result;
      });
    },
    setActiveFileTab(tabName) {
      this.fileTabName = tabName;
    },
    setActiveTab(tabName) {
      this.$router.push({
        name:
          this.$route.name === "service.view-manager"
            ? "service.view-manager"
            : "service.view-personal",
        params: {
          tabName: tabName,
          data: this.item,
          id: this.id,
          slug: this.slug
        }
      });
      if (tabName === "docs") {
        this.setItem();
      }
    },
    setItem() {
      this.apiResource.view(this.id).then(response => {
        this.item = response["data"];
        if (this.item.time_of_receipt) {
          this.timeOfReceipt = moment(this.item.time_of_receipt).toDate();
          this.maxDate = new Date(
            this.timeOfReceipt.getFullYear(),
            this.timeOfReceipt.getMonth(),
            this.timeOfReceipt.getDate() + 30
          );
        }

        this.declarerType = this.item.claim_data.usersData.declarerType;
        this.$route.meta.breadcrumb[0]["link"] =
          "/claims/" + this.slug + "/managers-list/" + this.declarerType;
      });
    },
    dataToSign(newStatus) {
      let timeOfReceipt =
        newStatus === "DIRECTION_ISSUED_SPECIAL_SCHOOL" ||
        newStatus === "DIRECTION_ISSUED"
          ? moment()
          : moment(this.timeOfReceipt);

      return {
        ...this.item.claim_data,
        changedStatus: newStatus,
        claimId: this.item.id,

        reject_reason: this.reject_reason,
        time_of_receipt: timeOfReceipt.format("YYYY-MM-DD HH:mm:ss"),

        additional_data: this.additionalDataToSave
      };
    },
    changeStatus(data, signed = false) {
      if (confirm("Вы уверены что хотите выполнить это действие?")) {
        const method = signed ? "changeStatusSigned" : "changeStatus";

        this.apiResource[method](data)
          .then(() => {
            this.setItem();
            this.reject_reason = "";
            this.closePopups();
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      }
    },
    changeStatusCustom(newStatus) {
      if (confirm("Вы уверены что хотите выполнить это действие?")) {
        let data = this.dataToSign(newStatus);
        this.apiResource["changeStatus"](data)
          .then(() => {
            this.setItem();
            this.reject_reason = "";
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          })
          .finally(() => {
            this.closePopups();
          });
      }
    },
    onSign(xml) {
      this.isRejectPopupOpened = false;
      this.changeStatus({ xml }, true);
    },
    closePopups() {
      Object.keys(this.openedPopups).forEach(key => {
        this.openedPopups[key] = false;
      });
    },
    prepareStatusButtons() {
      this.changeStatusButtons.map(item => {
        this.$set(this.openedPopups, item.key, false);
      });
    }
  },
  beforeMount() {
    this.prepareStatusButtons();

    if (!this.data["id"]) {
      this.setItem();
    } else {
      this.item = this.data;
    }

    this.kppkApiResource.list().then(response => {
      this.KPPKTypes = response.data;
    });

    this.$route.meta.breadcrumb[0]["link"] =
      "/claims/" + this.slug + "/managers-list";

    this.specialSchoolsPlacesApi.list().then(response => {
      this.specialSchoolsPlacesItems = response.data.data;
    });

    this.instructionLanguagesApi.list().then(response => {
      this.instructionLanguagesItems = response.data;
    });

    this.instructionClassesApi.list().then(response => {
      this.instructionClassesItems = response.data;
    });
  }
};
</script>

<style lang="scss">
.field .control button {
  margin: 0 15px 15px 0;
}

/*tabs-no-inline*/
/*attachment-tab-title*/

.attachment-tab-title {
  font-weight: bold;
  font-size: 20px;
  margin-top: 15px;
  margin-top: 8px;
}

.required-attachment-image-row {
  display: inline-block;
  vertical-align: top;

  > div {
    display: inline-block;
  }
}

.required-attachment-image {
  width: 203px;
  margin: 7px;
  padding: 5px;
  vertical-align: top;
  display: inline-block;
  border: 1px solid #d6d6d6;
  cursor: pointer;

  .pdf {
    display: block;
    height: 150px;
    font-weight: bold;
    text-align: center;
    font-size: 50px;
    padding: 39px 0;
  }
}

.required-attachment-name {
  text-align: center;
  border-top: 1px solid #d6d6d6;
  padding-top: 3px;
}

.tabs-no-inline {
  display: block;

  ul {
    margin-left: 20px;
    display: block;

    li {
      display: block;

      a {
        text-align: left;
        display: block;
      }
    }
  }
}

.file-popup {
  position: fixed;
  top: 40px;
  overflow-y: scroll;
  z-index: 999;
  width: 90%;
  max-width: 1500px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px #666666;
  animation: fade 1s ease;

  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  right: 0;
  border: 3px solid #01579d;
  background: white;

  & > * {
    height: 100%;
  }

  &__preview {
    height: 90%;

    embed,
    img {
      height: 100%;
    }
  }

  &__content {
    width: 100%;
    height: 90%;
    margin: 0 auto;
    background-color: #fff;
    position: relative;
    z-index: 1000;
    text-align: center;

    img {
      max-width: 100%;
    }

    span {
      font-size: 38px;
      float: right;
      line-height: 26px;
      cursor: pointer;
    }
  }

  &__header {
    font-size: 20px;
    font-weight: bold;
    padding: 15px;
    color: #fff;
    background-color: #0e61a7;
  }

  &__download {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 7px;

    a,
    button {
      color: #0e61a7;
      display: inline-block;
      padding: 15px;
      vertical-align: top;
    }

    a:hover {
      color: #003b6c;
    }

    button {
      margin-left: 10px;
    }
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 333;
  }
}

.attestation-table,
.category-table,
.pro-table,
.pro-2-table {
  .file {
    background-color: #0e61a7;
    color: #fff;
    padding: 5px;
    text-align: center;
    margin: 4px;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
  }

  width: 100%;
  margin-top: 15px;

  input,
  select {
    border: 1px solid #dbdbdb;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
  }

  td {
    vertical-align: top;
  }

  tr {
    &:nth-child(2n) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  thead tr {
    background-color: #e1dbff;
  }

  th,
  td {
    padding: 5px;
  }

  .add-row,
  .remove-row {
    padding: 1px 8px;
    vertical-align: top;
    display: inline-block;
    margin-right: 7px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 50px;
    background: #cad9ff;
  }

  .remove-row {
    padding: 1px 11px !important;
  }

  .file-upload-input-label {
    display: none !important;
  }
}

@keyframes fade {
  from {
    opacity: 0;
    top: 20px;
  }
  to {
    opacity: 1;
    top: 40px;
  }
}
</style>
