import Ncalayer from "../services/ncalayer";

export const SignDataButtonMixin = {
  props: {
    data: {
      required: false,
      default: () => {}
    },
    classes: {
      default: "is-success"
    },
    name: {
      default: "send_with_ecp"
    },
    disabled: {
      default: false
    },
    title: {
      default: ""
    },
    isConfirmBeforeSubmit: {
      default: false
    },
    confirmMessage: {
      default: ""
    }
  },
  data() {
    return {
      connectionOpened: false,
      ncalayer: {}
    };
  },
  methods: {
    initNcalayer() {
      this.ncalayer = new Ncalayer(
        this.onWebSocketOpen,
        this.onWebSocketClose,
        this.onWebSocketMessage
      );
    },
    onWebSocketOpen() {
      this.connectionOpened = true;
    },
    onWebSocketClose() {
      this.connectionOpened = false;
    },
    onWebSocketMessage(event) {
      this.connectionOpened = true;
      const result = JSON.parse(event.data);

      if (
        result &&
        result["code"] &&
        parseInt(result["code"]) === 200 &&
        result["responseObject"]
      ) {
        this.$emit("onSign", result["responseObject"]);
      }
    },
    onSign() {
      if (this.isConfirmBeforeSubmit) {
        if (!confirm(this.confirmMessage)) {
          return false;
        }
      }
      if (!this.connectionOpened) {
        this.initNcalayer();
        if (!this.connectionOpened) {
          alert(
            "Ошибка при подключении к NCALayer. " +
              "Пожалуйста, запустите NCALayer и попробуйте ещё раз."
          );
        }
      } else {
        this.ncalayer.signXml(this.ncalayer.getDefaultXml(this.data));
      }
    }
  },
  beforeMount() {
    this.initNcalayer();
  }
};
