export default {
  "teachers-attestation": [
    {
      name: "Принять в работу",
      statusesToShow: ["REGISTERED"],
      statusToChange: "IN_PROGRESS",
      class: "is-info",
      rejectPopupShow: false,
      key: "CHANGE_TO_IN_PROGRESS",
      translate: "button_in_progress"
    },
    {
      name: "Принять заявление",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "ENDED",
      class: "is-success",
      rejectPopupShow: false,
      key: "CHANGE_TO_ENDED",
      popupConfig: {
        popupTitle: "Принятие заявления",
        commentTitle: "№ расписки",
        commentValidationRules: "required",
        translate: "accepting_claim",
        commentTranslate: "numb_hand_doc"
      },
      translate: "accept_claim"
    },
    {
      name: "Отклонить",
      statusesToShow: ["IN_PROGRESS"],
      statusToChange: "REJECTED",
      class: "is-warning",
      rejectPopupShow: true,
      key: "CHANGE_TO_REJECTED",
      translate: "reject_claim",
      popupConfig: {
        popupTitle: "Отклонение",
        commentTitle: "Причина",
        commentValidationRules: "required|min:5",
        translate: "rejecting",
        commentTranslate: "reason"
      }
    }
  ]
};
