import axiosInstance from "./axios-instance";
import ApiResourceFactory from "./api/api-resource/api-resource-factory";

const apiResurceFactory = new ApiResourceFactory(axiosInstance);
const validationResource = apiResurceFactory.make("Validation");

const iinControl = value =>
  new Promise(resolve => {
    const valueString = "" + value;
    const b1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const b2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
    let a = [];
    let control = 0;
    for (let i = 0; i < 12; i++) {
      a[i] = parseInt(valueString.substring(i, i + 1));
      if (i < 11) control += a[i] * b1[i];
    }
    control = control % 11;
    if (control === 10) {
      control = 0;
      for (let i = 0; i < 11; i++) {
        control += a[i] * b2[i];
      }
      control = control % 11;
    }
    if (control !== a[11]) {
      return resolve({ valid: false, data: { message: "Некорректный ИИН" } });
    } else {
      return resolve({ valid: true });
    }
  });

const uniqueActiveChildsIin = (value, ref) =>
  new Promise(resolve => {
    if (("" + value).length === 12) {
      validationResource
        .uniqueActiveChildsIin(value, ref[0])
        .then(() => {
          return resolve({ valid: true });
        })
        .catch(error => {
          const fieldName = "iin";
          const response = error.response;
          const message =
            response.data.errors && response.data.errors[fieldName]
              ? response.data.errors[fieldName][0]
              : "";
          return resolve({ valid: false, data: { message: message } });
        });
    } else {
      return resolve({ valid: false, data: { message: "Некорректный ИИН" } });
    }
  });

const uniqueActiveUsersIin = (value, ref) =>
  new Promise(resolve => {
    if (("" + value).length === 12) {
      validationResource
        .uniqueActiveUsersIin(value, ref[0])
        .then(() => {
          return resolve({ valid: true });
        })
        .catch(error => {
          const fieldName = "iin";
          const response = error.response;
          const message =
            response.data.errors && response.data.errors[fieldName]
              ? response.data.errors[fieldName][0]
              : "";
          return resolve({ valid: false, data: { message: message } });
        });
    } else {
      return resolve({ valid: false, data: { message: "Некорректный ИИН" } });
    }
  });

const uniqueActiveTeachersIin = (value, ref) =>
  new Promise(resolve => {
    if (("" + value).length === 12) {
      validationResource
        .uniqueActiveTeachersIin(value, ref[0])
        .then(() => {
          return resolve({ valid: true });
        })
        .catch(error => {
          const fieldName = "iin";
          const response = error.response;
          const message =
            response.data.errors && response.data.errors[fieldName]
              ? response.data.errors[fieldName][0]
              : "";
          return resolve({ valid: false, data: { message: message } });
        });
    } else {
      return resolve({ valid: false, data: { message: "Некорректный ИИН" } });
    }
  });

const uniqueActiveSpaTreatmentIin = (value, ref) =>
  new Promise(resolve => {
    if (("" + value).length === 12) {
      validationResource
        .uniqueActiveSpaTreatmentIin(value, ref[0])
        .then(() => {
          return resolve({ valid: true });
        })
        .catch(error => {
          const fieldName = "iin";
          const response = error.response;
          const message =
            response.data.errors && response.data.errors[fieldName]
              ? response.data.errors[fieldName][0]
              : "";
          return resolve({ valid: false, data: { message: message } });
        });
    } else {
      return resolve({ valid: false, data: { message: "Некорректный ИИН" } });
    }
  });

const addValidationRule = (Validator, name, newValidator) => {
  Validator.extend(name, {
    validate: newValidator,
    getMessage: (field, params, data) => data.message
  });
};

export const ExtendValidator = Validator => {
  addValidationRule(Validator, "iin_control", iinControl);
  addValidationRule(
    Validator,
    "unique_active_teachers_iin",
    uniqueActiveTeachersIin
  );
  addValidationRule(
    Validator,
    "unique_active_childs_iin",
    uniqueActiveChildsIin
  );
  addValidationRule(Validator, "unique_active_users_iin", uniqueActiveUsersIin);
  addValidationRule(
    Validator,
    "unique_active_spa_treatment_iin",
    uniqueActiveSpaTreatmentIin
  );
  return Validator;
};
