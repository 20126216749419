import moment from "moment";
import intersectionBy from "lodash.intersectionby";

export const getNameFromUserInfo = userInfo => {
  if (userInfo && userInfo.iin) {
    return (
      userInfo.last_name +
      " " +
      userInfo.first_name +
      " " +
      userInfo.middle_name
    );
  }
  return "";
};

export const getAddressFromUserInfo = userInfo => {
  if (userInfo) {
    return (
      "область " +
      userInfo.district +
      ", " +
      "город " +
      userInfo.city +
      ", " +
      "улица " +
      userInfo.street +
      ", " +
      userInfo.building
    );
  }

  return "";
};

export const toCamelCase = str => {
  str = str.replace(/[-_\s]+(.)?/g, (match, ch) =>
    ch ? ch.toUpperCase() : ""
  );
  return str.substr(0, 1).toUpperCase() + str.substr(1);
};

export const displayError = (vueInstance, message) => {
  vueInstance.$snackbar.open({
    message: message,
    type: "is-danger"
  });
};

export const getErrorsText = error => {
  if (error instanceof String) {
    return error;
  }

  let errors = "";

  let status = error.status || null;
  if (!status) {
    status =
      error && error.response && error.response.status
        ? error.response.status
        : null;
  }

  if (status === 422) {
    let errorsArray = [];
    Object.values(error.response.data.errors).forEach(values => {
      errorsArray = [...errorsArray, ...values];
    });
    errors = errorsArray.join(", ");
  } else if (status === 400 || error.response.data.message) {
    errors = error.response.data.message;
  } else {
    errors = "Произошла ошибка при выполнении действия";
  }

  return errors;
};

export const getStatusName = (status, serviceSlug = "", translate) => {
  if (
    status.code === "AWAITING_FOR_COURSE" &&
    serviceSlug === "homeschooling"
  ) {
    return "Ожидающие зачисления";
  }

  if (status.code === "AWAITING_FOR_COURSE") {
    return "Ожидающие курса";
  }

  if (status.code === "COURSE_ENDED" || status.code === "ENDED") {
    return translate;
  }

  if (status.code === "AWAITING_FOR_RECEPTION") {
    return "Ожидающие прием";
  }

  if (status.code === "ENDED_NON_APPEARANCE") {
    return "Завершено (неявка заявителя)";
  }

  if (status.code === "IN_QUEUE") {
    return "В очереди";
  }

  if (status.code === "REJECTED") {
    return translate;
  }

  if (status.code === "ON_CONSIDERATION") {
    return "На рассмотрении";
  }

  // if (status.code === "IN_PROGRESS") {
  //   return "В процессе";
  // }

  if (status.code === "DIRECTION_ISSUED") {
    return "Выдано направление";
  }

  if (status.code === "COURSE_IN_PROGRESS" && serviceSlug === "homeschooling") {
    return "Зачислен";
  }

  if (status.code === "COURSE_IN_PROGRESS") {
    return "В процессе";
  }

  if (status.code === "DIRECTION_ISSUED_SPECIAL_SCHOOL") {
    return "Выдано направление в интернат";
  }

  return translate;
};

export const formatDate = (dateString, format = "MM/DD/YYYY") => {
  return moment(String(dateString)).format(format);
};

export const userHasPermission = (permissions, code) => {
  return !!permissions.find(permission => permission.code === code);
};

export const userHasManyPermissions = (permissions, code) => {
  if (code.isArray === false) {
    return !!permissions.find(permission => permission.code === code);
  } else {
    let codes = code.map(item => {
      return { code: item };
    });
    return intersectionBy(permissions, codes, "code").length !== 0;
  }
};

export const turnSlugToPermissionName = serviceSlug => {
  if (serviceSlug === "reabilitation-and-adaptation") {
    return "REHABILITATION_AND_ADAPTATION";
  }

  return serviceSlug.replace(/-/gi, "_").toUpperCase();
};

function checkService(slug) {
  return (
    slug === "special-needs-social-services" ||
    slug === "reabilitation-and-adaptation"
  );
}

export const getFieldNameForManagerTable = slug => {
  if (checkService(slug)) {
    return "ребенка";
  }
  return "заявителя";
};

export const getNameForManagerTable = (slug, item) => {
  if (checkService(slug)) {
    return item.claim_data.childsData;
  }
  if (item.declarer_user) {
    return item.declarer_user.profile;
  }
  return {
    iin: item.claim_data_usersData_iin,
    last_name: item.claim_data_usersData_last_name,
    first_name: item.claim_data_usersData_first_name,
    middle_name: item.claim_data_usersData_middle_name
  };
};

export const getIinForManagerTable = (slug, item) => {
  if (checkService(slug)) {
    return item.claim_data.childsData.iin;
  }
  return item.declarer_iin;
};

export const isSpecialNeeds = slug => {
  return slug === "special-needs-social-services";
};

export const childLabels = slug => {
  return checkService(slug);
};

export const getRegistrationNumbPmpk = item => {
  var result = "";
  item.history.forEach(value => {
    if (value.new_claim_status_id === 7) {
      result = value.additional_data.registrationNumbPmpk || "";
    }
  });
  return result;
};
