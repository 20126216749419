import ResourceControllerApiService from "./resource-controller-api-service";

class PmpkClaim extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/pmpk_claims";
  }

  list(data) {
    return this.axios.get(this.getMainRoute(), {
      params: data
    });
  }

  employeeList(data) {
    return this.axios.get(this.getMainRoute() + "/employee_list", {
      params: data
    });
  }
}

export default PmpkClaim;
