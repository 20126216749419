<template>
  <div>
    <p v-if="!itemLoaded">
      Загрузка...
    </p>

    <p v-else>
      Отображение в разработке.
    </p>
  </div>
</template>

<script>
import { ViewPersonalMixin } from "../../../mixins/view-personal-mixin";

export default {
  name: "CommonViewPersonal",
  mixins: [ViewPersonalMixin]
};
</script>

<style scoped></style>
