import ResourceControllerApiService from "./resource-controller-api-service";

class Areas extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/admin/areas";
  }

  list(data) {
    return this.axios.get(this.getMainRoute(), {
      params: data
    });
  }
}

export default Areas;
