<template>
  <section class="section">
    <h2 class="service subtitle">Список пользователей</h2>
    <div class="field">
      <label for="search" class="label">{{ $ml.get("search") }}</label>
      <div class="control">
        <input
          v-model="requestParams.search_query"
          @keyup="loadItems()"
          id="search"
          type="text"
          class="input"
        />
      </div>
    </div>
    <table v-if="items.length > 0" class="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>Резидент РК</th>
          <th>ИИН</th>
          <th>{{ $ml.get("fio") }}</th>
          <th>{{ $ml.get("email") }}</th>
          <th>Номер пользователя</th>
          <th>{{ $ml.get("workplace") }}</th>
          <th>Роль</th>
          <th>{{ $ml.get("actions") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="'table_item_' + itemIndex" v-for="(item, itemIndex) in items">
          <td>{{ item.resident ? "Да" : "Нет" }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.profile.full_name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.phone_number }}</td>
          <td>{{ item.work_place }}</td>
          <td>{{ item.role.name }}</td>
          <td>
            <router-link
              class="button is-info is-small"
              :to="{ name: 'admin.users.edit', params: { id: item.id } }"
            >
              Редактировать
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <VPagination
      @change="changePage"
      :icon-pack="'far'"
      :total="totalPages"
      :current="currentPage"
      :order="order"
      :per-page="perPage"
    />
  </section>
</template>

<script>
import { CommonListMixin } from "../../../mixins/common-list-mixin";

export default {
  name: "UsersList",
  mixins: [CommonListMixin],
  data() {
    return {
      items: [],
      requestParams: {
        search_query: ""
      }
    };
  },
  beforeMount() {
    this.listMethod = "list";
    this.apiResource = this.$apiResource.make("Users");
  }
};
</script>

<style scoped></style>
