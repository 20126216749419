import ResourceControllerApiService from "./resource-controller-api-service";

class SpecialSchoolsPlaces extends ResourceControllerApiService {
  getMainRoute() {
    return "/api/dictionaries/special-schools-places";
  }

  list(params) {
    return this.axios.get(this.getMainRoute(), { params });
  }
}

export default SpecialSchoolsPlaces;
