<template>
  <section>
    <div class="tabs">
      <ul>
        <li
          v-for="tab in updatedAttachmentTabs"
          :class="{ 'is-active': tab.name === attachmentTabName }"
          :key="'view_tab_' + tab.name"
        >
          <a @click.prevent="setActiveAttachentTab(tab.name)">{{
            $ml.get(tab.title)
          }}</a>
        </li>
      </ul>
    </div>

    <div v-if="attachmentTabName === 'attachment_tab_1'">
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.requiredFiles"
        :key="`att1_${index}`"
      >
        <div
          v-for="(file, index2) in files.filter(
            e =>
              e.name.replace(/_/g, ' ') !==
              'Копия приказа о ранее присвоенной квалификационной категории'
          )"
          :key="`att12_${index2}`"
        >
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Заявление"
                  ? $ml.get("statement")
                  : file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Трудовая_Деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.testDocsFiles"
        :key="`att22_${index}`"
      >
        <div v-for="(file, index2) in files" :key="`att222_${index2}`">
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Справка_НКТ"
                  ? $ml.get("nkt_statement")
                  : file.name === "Трудовая_деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.watchDocsFiles"
        :key="`att32_${index}`"
      >
        <div v-for="(file, index2) in files" :key="`att33_${index2}`">
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Трудовая_деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Лист_наблюдения"
                  ? $ml.get("watch_list")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_2'">
      <table class="attestation-table">
        <thead>
          <tr>
            <th>№</th>
            <th>{{ $ml.get("date_of_assignment") }}</th>
            <th>{{ $ml.get("certificate_number") }}</th>
            <th>{{ $ml.get("course_subject") }}</th>
            <th>{{ $ml.get("place_of_passage") }}</th>
            <th>{{ $ml.get("certificate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in claimData.usersData.tablesData
              .attestationTable"
            :key="`row_${index}`"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{
                new Date(Date.parse(row.assignDate)).getDate() +
                  "-" +
                  (new Date(Date.parse(row.assignDate)).getMonth() + 1) +
                  "-" +
                  new Date(Date.parse(row.assignDate)).getFullYear()
              }}
            </td>
            <td>{{ row.certNumber }}</td>
            <td>{{ row.course }}</td>
            <td>{{ row.spot }}</td>
            <td>
              <span
                v-for="(file, index) in row.cert.attachedFile.files"
                :key="`file_${index}`"
                class="file"
                @click="showFilePopup(file)"
                >{{ $ml.get("download_word") }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_3'">
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.usersData.tablesData.categoryTable"
        :key="`tab_${index}`"
      >
        <div
          v-for="(file, index2) in files.cert.attachedFile.files"
          :key="`tab2_${index2}`"
        >
          <div
            v-if="![undefined, null, ''].includes(file)"
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                ![undefined, null, ""].includes(file.name)
                  ? file.name.replace(/_/g, " ")
                  : file.filename.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.requiredFiles"
        :key="`att1_${index}`"
      >
        <div
          v-for="(file, index2) in files.filter(
            e =>
              e.name.replace(/_/g, ' ') ===
              'Копия приказа о ранее присвоенной квалификационной категории'
          )"
          :key="`att12_${index2}`"
        >
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Заявление"
                  ? $ml.get("statement")
                  : file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Трудовая_Деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_4'">
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.usersData.tablesData.proTable"
        :key="`tab_${index}`"
      >
        <div
          v-for="(file, index2) in files.cert.attachedFile.files"
          :key="`tab2_${index2}`"
        >
          <div
            v-if="![undefined, null, ''].includes(file)"
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                ![undefined, null, ""].includes(file.name)
                  ? file.name.replace(/_/g, " ")
                  : file.filename.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_5'">
      <table
        class="pro-2-table"
        v-if="[9].includes(claimData.usersData.declarerType)"
      >
        <thead>
          <tr>
            <th>№</th>
            <th>{{ $ml.get("date_word") }}</th>
            <th>{{ $ml.get("type_of_awards") }}</th>
            <th>{{ $ml.get("teacher_level") }}</th>
            <th>{{ $ml.get("teacher_form_participation") }}</th>
            <th>{{ $ml.get("certificate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in claimData.usersData.tablesData.pro2Table"
            :key="`row_${index}`"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{
                new Date(Date.parse(row.date)).getDate() +
                  "-" +
                  (new Date(Date.parse(row.date)).getMonth() + 1) +
                  "-" +
                  new Date(Date.parse(row.date)).getFullYear()
              }}
            </td>
            <td>{{ $ml.get(row.type || "") }}</td>
            <td>{{ $ml.get(row.level || "") }}</td>
            <td>{{ $ml.get(row.form || "") }}</td>
            <td>
              <span
                v-for="(file, idx) in row.cert.attachedFile.files"
                class="file"
                @click="showFilePopup(file)"
                :key="`file_${idx}`"
                >{{ $ml.get("download_word") }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-else
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.usersData.tablesData.pro2Table"
        :key="`tab_${index}`"
      >
        <div
          v-for="(file, index2) in files.cert.attachedFile.files"
          :key="`tab2_${index2}`"
        >
          <div
            v-if="![undefined, null, ''].includes(file)"
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                ![undefined, null, ""].includes(file.name)
                  ? file.name.replace(/_/g, " ")
                  : file.filename.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="attachmentTabName === 'attachment_tab_6'">
      <div v-if="declarerType === 9">
        <table
          class="attestation-table"
          v-for="(files, index) in claimData.methodistDocs"
          :key="`row_${index}`"
        >
          <thead>
            <tr>
              <th>№</th>
              <th style="width: 50%">{{ $ml.get("document_name") }}</th>
              <th style="width: 50%">{{ $ml.get("info_from_applicant") }}</th>
              <th>{{ $ml.get("certificate") }}</th>
            </tr>
          </thead>
          <tbody v-for="(file, index2) in files" :key="`tab2_${index2}`">
            <tr :key="`row_${index}`">
              <td>{{ index2 + 1 }}</td>
              <td>{{ file.label }}</td>
              <td>{{ file.placeholder }}</td>
              <td>
                <span @click="showFilePopup(file, true)" class="file">{{
                  $ml.get("download_word")
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.markDocsFiles"
        v-else
        :key="`tab_${index}`"
      >
        <div v-for="(file, index2) in files" :key="`tab2_${index2}`">
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Трудовая_деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Показатель"
                  ? $ml.get("mark_doc")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.declarerTypeFilesFiltered"
        :key="`tab_${index}`"
      >
        <div v-for="(file, index2) in files" :key="`tab2_${index2}`">
          <div
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <!--                                            <embed @click="showFilePopup(file)" :src="additionalFileDownloadSrc(file.id)" width="150px" />-->
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                file.name === "Удостоверение"
                  ? $ml.get("identity_card")
                  : file.name === "Трудовая_деятельность"
                  ? $ml.get("labor_activity")
                  : file.name === "Показатель"
                  ? $ml.get("mark_doc")
                  : file.name === "Диплом"
                  ? $ml.get("diplom")
                  : file.name.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="required-attachment-image-row"
        v-for="(files, index) in claimData.usersData.tablesData.pro2Table"
        :key="`tab_${index}`"
      >
        <div
          v-for="(file, index2) in files.cert.attachedFile.files"
          :key="`tab2_${index2}`"
        >
          <div
            v-if="![undefined, null, ''].includes(file)"
            class="required-attachment-image"
            @click="showFilePopup(file, true)"
          >
            <template v-if="getFileExtension(file.filename) === 'pdf'">
              <span class="pdf">PDF</span>
            </template>
            <template v-else>
              <img :src="additionalFileDownloadSrc(file.id)" />
            </template>
            <div class="required-attachment-name">
              {{
                ![undefined, null, ""].includes(file.name)
                  ? file.name.replace(/_/g, " ")
                  : file.filename.replace(/_/g, " ")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div v-if="attachmentTabName === 'attachment_tab_7'">
          <div class="required-attachment-image-row">
            <a :href="claimData.usersData.videoUrl" target="_blank">{{
              claimData.usersData.videoUrl
            }}</a>
          </div>
        </div>-->
    <div v-if="attachmentTabName === 'attachment_tab_7'">
      <label class="table-input-label">{{
        $ml.get("declare_type_files_3_3_1")
      }}</label>
      <table class="attestation-table">
        <thead>
          <tr>
            <th>№</th>
            <th>{{ $ml.get("date_of_assignment") }}</th>
            <th>{{ $ml.get("course") }}</th>
            <th>{{ $ml.get("teacher_level") }}</th>
            <th>{{ $ml.get("certificate") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in claimData.usersData.tablesData
              .awardsTeacherDocTable"
            :key="`row_${index}`"
          >
            <td>{{ index + 1 }}</td>
            <td>
              {{
                new Date(Date.parse(row.assignDate)).getDate() +
                  "-" +
                  (new Date(Date.parse(row.assignDate)).getMonth() + 1) +
                  "-" +
                  new Date(Date.parse(row.assignDate)).getFullYear()
              }}
            </td>
            <td>{{ row.course }}</td>
            <td>{{ row.level }}</td>
            <td>
              <span
                v-for="(file, index) in row.cert.attachedFile.files"
                :key="`file_${index}`"
                class="file"
                @click="showFilePopup(file)"
                >{{ $ml.get("download_word") }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="
          Object.keys(
            claimData.usersData.tablesData.studentsAchievementsDocTable[0].cert
              .attachedFile.files
          ).length > 0
        "
      >
        <label class="table-input-label">{{
          $ml.get("students_achievements")
        }}</label>
        <table class="attestation-table">
          <thead>
            <tr>
              <th>№</th>
              <th>{{ $ml.get("date_of_assignment") }}</th>
              <th>{{ $ml.get("course") }}</th>
              <th>{{ $ml.get("teacher_level") }}</th>
              <th>{{ $ml.get("certificate") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in claimData.usersData.tablesData
                .studentsAchievementsDocTable"
              :key="`row_${index}`"
            >
              <td>{{ index + 1 }}</td>
              <td>
                {{
                  new Date(Date.parse(row.assignDate)).getDate() +
                    "-" +
                    (new Date(Date.parse(row.assignDate)).getMonth() + 1) +
                    "-" +
                    new Date(Date.parse(row.assignDate)).getFullYear()
                }}
              </td>
              <td>{{ row.course }}</td>
              <td>{{ row.level }}</td>
              <td>
                <span
                  v-for="(file, index) in row.cert.attachedFile.files"
                  :key="`file_${index}`"
                  class="file"
                  @click="showFilePopup(file)"
                  >{{ $ml.get("download_word") }}</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "TeachersAttestationFilesTabs",
  props: {
    claimData: {
      type: Object,
      required: true
    },
    declarerType: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    attachmentTabName: "attachment_tab_1"
  }),
  computed: {
    updatedAttachmentTabs() {
      let tabs = [
        {
          name: "attachment_tab_1",
          title: "tabs_basic_info"
        },
        {
          name: "attachment_tab_2",
          title: "tabs_qualification"
        },
        {
          name: "attachment_tab_3",
          title: "tabs_attestationo"
        },
        /*{
                  name: "attachment_tab_4",
                  title: this.declarerType
                    ? this.declarerType === 2 || this.declarerType === 3
                      ? "tabs_awards_pedagog"
                      : "tabs_awards_teacher"
                    : "tabs_awards_teacher"
                },
                {
                  name: "attachment_tab_5",
                  title: this.declarerType
                    ? this.declarerType === 3
                      ? "tabs_awards_childs"
                      : "tabs_awards_students"
                    : "tabs_awards_students"
                },*/
        {
          name: "attachment_tab_6",
          title: "additional_docs"
        },
        {
          name: "attachment_tab_7",
          title: "awards_tab"
        }
      ];
      console.log(
        this.claimData.usersData.tablesData.studentsAchievementsDocTable
      );
      return tabs;
    }
  },
  methods: {
    setActiveAttachentTab(tabName) {
      this.attachmentTabName = tabName;
    },
    showFilePopup(file, filename = false, refreshToken = null) {
      this.$emit("showFilePopup", { file, filename, refreshToken });
    },
    additionalFileDownloadSrc(fileId) {
      return (
        process.env["VUE_APP_API_BASE_URL"] +
        "api/claims" +
        "/additional-file/" +
        fileId +
        "?_token=" +
        this.$store.getters["user/getToken"]
      );
    },
    getFileExtension(fileName) {
      let reg = /(?:\.([^.]+))?$/;
      return reg.exec(fileName)[1].toLowerCase();
    }
  }
});
</script>
