<template>
  <section class="section">
    <form @submit.prevent="submitForm(item)">
      <h2 class="service subtitle">Редактирование услуги</h2>
      <div class="modal-padded-body">
        <div class="field">
          <label for="name" class="label">Название</label>
          <div class="control">
            <input
              id="name"
              v-model="item.title"
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="description" class="label">Описание</label>
          <div class="control">
            <textarea
              id="description"
              v-model="item.description"
              style="height: 200px"
              required
              type="text"
              class="input"
            ></textarea>
          </div>
        </div>

        <div class="field">
          <label for="code" class="label">Код(уникальное)</label>
          <div class="control">
            <input
              id="code"
              v-model="item.slug"
              disabled
              required
              type="text"
              class="input"
            />
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <label class="label">Районы</label>

              <div :key="'area_' + index" v-for="(area, index) in areasList">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    :checked="isCheckedArea(area.id)"
                    @click="areaTrigger(!isCheckedArea(area.id), area)"
                  />
                  {{ area.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="modal-card-foot">
        <button class="button is-success">Сохранить изменения</button>
        <router-link
          :to="{ name: 'admin.services.list' }"
          :tag="'button'"
          :class="'button'"
          >{{ $ml.get("back") }}</router-link
        >
      </footer>
    </form>
  </section>
</template>

<script>
import { displayError, getErrorsText } from "../../../services/helper";

export default {
  name: "ServicesEdit",
  props: ["id"],
  data() {
    return {
      item: {
        name: "",
        code: "",
        areas: []
      },
      areasList: [],
      apiResource: this.$apiResource.make("Service"),
      areaResource: this.$apiResource.make("Areas")
    };
  },
  methods: {
    isCheckedArea(id) {
      if (this.item.areas.length > 0) {
        return !!this.item.areas.find(area => area.id === parseInt(id));
      }
      return false;
    },
    areaTrigger(isAdd, area) {
      if (isAdd) {
        this.item.areas.push(area);
      } else {
        this.item.areas = this.item.areas.filter(areaItem => {
          return areaItem.id !== area.id;
        });
      }
    },
    submitForm(item) {
      if (item.id) {
        this.apiResource
          .update(item.id, item)
          .then(() => {
            return this.$router.push({ name: "admin.services.list" });
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      } else {
        this.apiResource
          .store(item)
          .then(() => {
            return this.$router.push({ name: "admin.services.list" });
          })
          .catch(error => {
            displayError(this, getErrorsText(error));
          });
      }
    }
  },
  beforeMount() {
    if (this.id) {
      this.apiResource.edit(this.id).then(response => {
        this.item = response.data;
      });
    }

    this.areaResource.list(1, 1000).then(response => {
      this.areasList = response.data.data;
    });
  }
};
</script>

<style scoped></style>
