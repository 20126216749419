<template>
  <section class="section">
    <div class="pageloader" :class="{ 'is-active': inProgress }">
      <span class="title">Поиск...</span>
    </div>

    <h2 class="service subtitle">{{ translate("claims_log") }}</h2>

    <form @submit.prevent="updateList()">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <b-field :label="translate('child.iin')">
              <input
                v-model="requestParams.iin"
                id="iin"
                type="text"
                class="input"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('child.firstName')">
              <input
                v-model="requestParams.name"
                id="name"
                type="text"
                class="input"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('child.lastName')">
              <input
                v-model="requestParams.surname"
                id="surname"
                type="text"
                class="input"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('child.middleName')">
              <input
                v-model="requestParams.middle_name"
                id="middle_name"
                type="text"
                class="input"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('special_school')">
              <BuefySimpleSelect
                :options="specialSchoolsOptions()"
                v-model="requestParams.special_school_name"
              />
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('category')">
              <BuefySimpleSelect
                :options="disabilityCategoriesOptions()"
                v-model="filter_category_name"
              />
            </b-field>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <b-field :label="translate('date_from')">
              <b-datepicker
                :month-names="getMonthNames"
                :day-names="getDayNames"
                icon-pack="far"
                v-model="requestParams.created_at_start"
                :placeholder="translate('press_to_choose_date') + '...'"
              ></b-datepicker>
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('date_to')">
              <b-datepicker
                :month-names="getMonthNames"
                :day-names="getDayNames"
                icon-pack="far"
                v-model="requestParams.created_at_end"
                :placeholder="translate('press_to_choose_date') + '...'"
              ></b-datepicker>
            </b-field>
          </div>
          <div class="field">
            <b-field :label="translate('class')">
              <BuefySimpleSelect
                :options="classesOptions()"
                v-model="requestParams.class_name"
              />
            </b-field>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <button class="button is-success" type="submit">
            {{ translate("search") }}
          </button>
          <button class="button is-light m-0-0-0-10" @click="clearFilters">
            {{ translate("to_clear") }}
          </button>
          <button
            class="button is-success m-0-0-0-10"
            type="button"
            @click="showConclusionModal = true"
          >
            {{ translate("to_add") }}
          </button>
        </div>
      </div>
    </form>

    <div class="tabs m-20-0">
      <ul>
        <li
          v-for="tab in tabs"
          :class="{ 'is-active': activeTab === tab }"
          :key="'view_tab_' + tab.key"
        >
          <a @click.prevent="setActiveTab(tab)">{{
            tab.translate ? translate(tab.translate) : tab.title
          }}</a>
        </li>
      </ul>
    </div>

    <div class="claim_popup_background" v-if="showConclusionModal">
      <div class="claim_popup_container p-20-10">
        <div
          class="claim_popup_close_button"
          @click="showConclusionModal = false"
        >
          <fa-icon icon="times"></fa-icon>
        </div>
        <div class="claim_popup_header border-bottom">
          <b-field grouped>
            <p class="control">{{ translate("search_by_iin") }}</p>
            <p class="control">
              <input
                type="text"
                class="input"
                v-model="conclusionModal.search_iin"
              />
            </p>
            <p class="control">
              <button class="button is-primary" @click="modalSearch">
                {{ translate("to_find") }}
              </button>
            </p>
            <p class="control">
              <button class="button is-light" @click="conclusionModalClear">
                {{ translate("to_clear") }}
              </button>
            </p>
          </b-field>
        </div>
        <div class="claim_popup_body tile is-vertical">
          <div class="tile is-12 m-10-0">
            <div class="tile is-6">
              <p class="control">{{ translate("child.iin") }}:</p>
              <p class="control m-0-0-0-10">{{ modal_iin }}</p>
            </div>
            <div class="tile is-6">
              <p class="control">{{ translate("special_school") }}:</p>
              <p class="control m-0-0-0-10">
                {{ translate(modal_organisation_name) }}
              </p>
            </div>
          </div>
          <div class="tile is-12 m-10-0">
            <div class="tile is-6">
              <p class="control">{{ translate("fio_claim_child") }}:</p>
              <p class="control m-0-0-0-10">{{ translate(modal_child_fio) }}</p>
            </div>
            <div class="tile is-6">
              <p class="control">{{ translate("category") }}:</p>
              <p class="control m-0-0-0-10">{{ translate(modal_category) }}</p>
            </div>
          </div>
          <div class="tile is-12 m-10-0">
            <div class="tile is-6">
              <p class="control">{{ translate("class") }}:</p>
              <p class="control m-0-0-0-10">{{ translate(modal_class) }}</p>
            </div>
            <div class="tile is-6">
              <p class="control">{{ translate("instruction_language") }}:</p>
              <p class="control m-0-0-0-10">{{ translate(modal_language) }}</p>
            </div>
          </div>
          <div class="tile is-12 m-10-0" v-if="showRejectInput">
            <div class="tile is-4">
              <p class="control">{{ translate("reject_reason") }}:</p>
            </div>
            <div class="tile is-8">
              <input
                style="width:100%"
                class="input m-0-0-0-10"
                v-model="conclusionModal.reject_reason"
              />
              <p class="control m-0-0-0-10">
                <button
                  @click="conclusionChangeStatus('REJECTED_SPECIAL_SCHOOL')"
                  :disabled="conclusionModal.reject_reason.length === 0"
                  class="button is-success"
                >
                  {{ translate("to_confirm_rejection") }}
                </button>
              </p>
            </div>
          </div>
        </div>
        <div class="claim_popup_footer p-20-10">
          <div
            class="level-left f-bold"
            v-if="
              conclusionModal.claim.status &&
                conclusionModal.claim.status.code === 'RECEIVED_SPECIAL_SCHOOL'
            "
          >
            <p>
              Данный ИИН находится в статусе "Зачислен в интернат".
            </p>
          </div>
          <div class="level-right">
            <button
              class="button is-info"
              @click="conclusionChangeStatus('RECEIVED_SPECIAL_SCHOOL')"
              :disabled="
                !conclusionModal.claim.claim_data ||
                  showRejectInput ||
                  conclusionModal.claim.status.code ===
                    'RECEIVED_SPECIAL_SCHOOL'
              "
            >
              {{ translate("to_receive") }}
            </button>
            <button
              class="button is-light m-0-0-0-10"
              :class="{
                'is-light': !showRejectInput,
                'is-info': showRejectInput
              }"
              @click="showRejectInput = !showRejectInput"
              :disabled="
                !conclusionModal.claim.claim_data ||
                  conclusionModal.claim.status.code ===
                    'RECEIVED_SPECIAL_SCHOOL'
              "
            >
              {{ translate("to_reject") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="claim_popup_background" v-if="showLeavedModal">
      <div class="claim_popup_container">
        <div class="claim_popup_close_button" @click="showLeavedModal = false">
          <fa-icon icon="times"></fa-icon>
        </div>
        <div class="claim_popup_header orange color-white p-20-10">
          <div>{{ translate("direction_processing") }}</div>
        </div>
        <div class="claim_popup_body p-20-10">
          <div>
            <p class="control">
              <span class="f-bold">{{ translate("child.iin") }}:&nbsp;</span>
              <span>{{ leavedModal.claim.claim_data.childsData.iin }}</span>
            </p>
          </div>
          <div>
            <p class="control">
              <span class="f-bold"
                >{{ translate("fio_claim_child") }}:&nbsp;</span
              >
              <span>{{ fio(leavedModal.claim.claim_data.childsData) }}</span>
            </p>
          </div>
          <div>
            <p class="control f-bold">{{ translate("leaving_date") }}:&nbsp;</p>
            <p class="control">
              <b-datepicker
                :month-names="getMonthNames"
                :day-names="getDayNames"
                :placeholder="translate('press_to_choose_date') + '...'"
                icon="calendar-today"
                v-model="leavedModal.date_leave"
              ></b-datepicker>
            </p>
          </div>
          <div>
            <p class="control f-bold">
              {{ translate("leaving_reason") }}:&nbsp;
            </p>
            <p class="control">
              <textarea class="textarea" v-model="leavedModal.reject_reason" />
            </p>
          </div>
        </div>
        <div class="claim_popup_footer border-bottom p-20-10">
          <div class="level-right">
            <button
              class="button is-primary"
              @click="leavedChangeStatus('LEAVED_SPECIAL_SCHOOL')"
              :disabled="
                leavedModal.reject_reason.length == 0 ||
                  leavedModal.date_leave.length == 0
              "
            >
              {{ translate("to_sign_and_send") }}
            </button>
            <button class="button is-light" @click="showLeavedModal = false">
              {{ translate("cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <table class="table is-striped is-fullwidth">
      <thead>
        <tr>
          <th>{{ translate("claim_number") }}</th>
          <th>{{ translate("child_fio") }}</th>
          <th>{{ translate("child_iin") }}</th>
          <th>{{ translate("special_school") }}</th>
          <th>{{ translate("category") }}</th>
          <th>{{ translate("class") }}</th>
          <th>{{ translate("instruction_language") }}</th>
          <th>{{ translate("direction_datetime") }}</th>
          <th>{{ translate("actions") }}</th>
        </tr>
      </thead>
      <tbody v-if="claims.length > 0">
        <template v-for="(item, itemIndex) in claims">
          <tr :key="'table_item_' + itemIndex" v-if="item.history.length > 0">
            <td>{{ item.claim_number }}</td>
            <td>{{ fio(item.claim_data.childsData) }}</td>
            <td>{{ item.claim_data.childsData.iin }}</td>
            <td>
              {{
                translate(
                  getLastHistory(item).additional_data.organisation_name || ""
                )
              }}
            </td>
            <td>
              {{
                translate(
                  getLastHistory(item).additional_data.childCategory || ""
                )
              }}
            </td>
            <td>
              {{ translate(getLastHistory(item).additional_data.class || "") }}
            </td>
            <td>
              {{
                translate(
                  getLastHistory(item).additional_data.instructionLanguage || ""
                )
              }}
            </td>
            <td>{{ moment(item.time_of_receipt, "DD:MM:YYYY HH:mm:ss") }}</td>
            <td>
              <button
                class="button is-danger"
                @click="openLeavedClaimModal(item)"
                :disabled="!(item.status.code === 'RECEIVED_SPECIAL_SCHOOL')"
              >
                {{ translate("leaving") }}
              </button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <VPagination
      @change="changePage"
      :icon-pack="'far'"
      :total="pagination.total"
      :current="pagination.current_page"
      :per-page="requestParams.per_page"
    />
  </section>
</template>

<script>
import { PermissionsMixin } from "@/mixins/permissions-mixin";
import { DatepickerMixin } from "@/mixins/datepicker-mixin";
import { TranslateMixin } from "@/mixins/translate-mixin";
import ManagersListTabs from "@/configs/managers-list-tabs";
import { SignDataButtonMixin } from "@/mixins/sign-data-button-mixin";
import moment from "@/services/moment-instance.js";
import ChildCategories from "@/configs/child-categories";

export default {
  name: "SpecialSchoolsClaimsList",
  mixins: [
    PermissionsMixin,
    SignDataButtonMixin,
    DatepickerMixin,
    TranslateMixin
  ],
  data() {
    return {
      managersListTabs: ManagersListTabs,
      claimsApiResource: this.$apiResource.make("Claims"),
      claims: [],
      conclusionModal: {
        search_iin: "",
        claim: {},
        reject_reason: ""
      },
      leavedModal: {
        claim: {},
        reject_reason: "",
        date_leave: new Date()
      },
      activeTabNum: 0,
      inProgress: true,
      showConclusionModal: false,
      showRejectInput: false,
      showLeavedModal: false,
      params: {},
      classesApi: this.$apiResource.make("InstructionClasses"),
      specialSchoolsApi: this.$apiResource.make("SpecialSchools"),
      disabilityCategoriesApi: this.$apiResource.make("DisabilityCategories"),
      instructionLanguages: [],
      disabilityCategories: [],
      instructionClasses: [],
      users: [],
      specialSchools: [],
      requestDefaultParams: {
        iin: "",
        name: "",
        surname: "",
        middle_name: "",
        special_school_name: null,
        categories: [],
        class_name: null,
        created_at_start: null,
        created_at_end: null,
        per_page: 10,
        page: 1,
        service_id: 5,
        search_by_child: true,
        special_school_claims: true,
        statuses: null
      },
      requestParams: {},
      pagination: {
        current_page: null,
        from: null,
        last_page: null,
        to: null,
        total: null
      },
      filter_category_name: null
    };
  },
  computed: {
    tabs() {
      return ManagersListTabs["special-schools-claims-list"];
    },
    activeTab() {
      return ManagersListTabs["special-schools-claims-list"][this.activeTabNum];
    },
    modal_iin() {
      if (this.conclusionModal.claim.claim_data) {
        return this.conclusionModal.claim.claim_data.childsData.iin;
      }
      return "";
    },
    modal_organisation_name() {
      if (
        this.conclusionModal.claim.history &&
        this.conclusionModal.claim.history.length > 0
      ) {
        return this.conclusionModal.claim.history[
          this.conclusionModal.claim.history.length - 1
        ].additional_data.organisation_name;
      }
      return "";
    },
    modal_child_fio() {
      if (this.conclusionModal.claim.claim_data) {
        return this.fio(this.conclusionModal.claim.claim_data.childsData);
      }
      return "";
    },
    modal_category() {
      if (
        this.conclusionModal.claim.history &&
        this.conclusionModal.claim.history.length > 0
      ) {
        return this.conclusionModal.claim.history[
          this.conclusionModal.claim.history.length - 1
        ].additional_data.childCategory;
      }
      return "";
    },
    modal_class() {
      if (
        this.conclusionModal.claim.history &&
        this.conclusionModal.claim.history.length > 0
      ) {
        return this.conclusionModal.claim.history[
          this.conclusionModal.claim.history.length - 1
        ].additional_data.class;
      }
      return "";
    },
    modal_language() {
      if (
        this.conclusionModal.claim.history &&
        this.conclusionModal.claim.history.length > 0
      ) {
        return this.conclusionModal.claim.history[
          this.conclusionModal.claim.history.length - 1
        ].additional_data.instructionLanguage;
      }
      return "";
    }
  },
  watch: {
    "requestParams.created_at_start"(val) {
      if (val != null) {
        this.requestParams.created_at_start = moment(val).format("YYYY-MM-DD");
      }
    },
    "requestParams.created_at_end"(val) {
      if (val != null) {
        this.requestParams.created_at_end = moment(val).format("YYYY-MM-DD");
      }
    },
    filter_category_name(val) {
      this.requestParams.categories = [];
      ChildCategories.forEach(category => {
        if (category.server_disability_category == val) {
          this.requestParams.categories.push(category.name);
        }
      });
    }
  },
  methods: {
    classesOptions() {
      return this.instructionClasses.map(v => ({
        id: v.name,
        text: this.translate(v.name)
      }));
    },
    specialSchoolsOptions() {
      return this.specialSchools.map(v => ({
        id: v.name,
        text: this.translate(v.short_name)
      }));
    },
    disabilityCategoriesOptions() {
      return this.disabilityCategories.map(item => {
        return {
          id: item.name,
          text: this.translate(item.name)
        };
      });
    },
    getLastHistory(item) {
      return item.history[item.history.length - 1];
    },
    openLeavedClaimModal(item) {
      this.leavedModal.claim = item;
      this.showLeavedModal = true;
    },
    isShowClaim(item) {
      let isShow = false;

      if (this.activeTab.key === "all") {
        isShow = true;
      } else {
        this.activeTab.statuses.forEach(status => {
          if (status === item.status.code) {
            isShow = true;
          }
        });
      }

      return isShow;
    },
    modalSearch() {
      this.conclusionModal.claim = {};
      let params = this.requestDefaultParams;
      params.iin = this.conclusionModal.search_iin;
      params.statuses = ["DIRECTION_ISSUED_SPECIAL_SCHOOL"];

      this.claimsApiResource
        .managersList(params)
        .then(response => {
          if (response.data.data.length > 0) {
            this.conclusionModal.claim = response.data.data[0];
            this.parseAndSaveManagersSign(this.conclusionModal.claim);
          }
        })
        .then(() => {
          this.checkCoclusionModal();
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          this.checkCoclusionModal();
        });
    },
    checkCoclusionModal() {
      if (this.conclusionModal.claim.id === undefined) {
        this.$snackbar.open({
          message: "Внимание! По Вашему запросу данных не найдено",
          type: "is-info"
        });
      }
    },
    conclusionModalClear() {
      this.conclusionModal.claim = {};
      this.conclusionModal.search_iin = "";
    },
    conclusionChangeStatus(status) {
      this.data = this.conclusionModal.claim.parsedManagersSign;
      this.data.changedStatus = status;
      this.data.reject_reason = this.conclusionModal.reject_reason;
      this.data.time_of_receipt = this.conclusionModal.claim.time_of_receipt;
      this.params = {
        changedStatus: this.data.changedStatus
      };

      this.onSign();
    },
    leavedChangeStatus(status) {
      this.data = this.leavedModal.claim.parsedManagersSign;
      this.data.changedStatus = status;
      this.data.reject_reason = this.leavedModal.reject_reason;
      this.data.time_of_receipt = this.leavedModal.claim.time_of_receipt;

      this.params = {
        changedStatus: this.data.changedStatus,
        leaveDate: this.leavedModal.date_leave
      };

      this.onSign();
    },
    fio(data) {
      return data.last_name + " " + data.first_name + " " + data.middle_name;
    },
    setActiveTab(tab) {
      this.tabs.forEach((item, num) => {
        if (item === tab) {
          this.activeTabNum = num;
        }
      });
      this.requestParams.statuses = this.activeTab.statuses;
      this.updateList();
    },
    moment(data, format) {
      return moment(data).format(format);
    },
    parseAndSaveManagersSign(item) {
      let parser = new DOMParser();

      let parsedXML = parser.parseFromString(item.managers_sign, "text/xml");
      let jsonInXml = parser.parseFromString(
        parsedXML.firstChild.firstChild.data,
        "text/xml"
      );
      let rawJson = jsonInXml.firstChild.innerHTML;
      item.parsedManagersSign = JSON.parse(rawJson);
    },
    updateList() {
      this.inProgress = true;

      this.claimsApiResource
        .managersList(this.requestParams)
        .then(response => {
          this.claims = response.data.data.filter(
            val => val.history.length > 0 && val.managers_sign != undefined
          );

          this.claims.forEach(val => {
            this.parseAndSaveManagersSign(val);
          });

          ["current_page", "from", "last_page", "to", "total"].forEach(name => {
            this.pagination[name] = response.data[name];
          });
          this.inProgress = false;
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          this.inProgress = false;
        });
    },
    changeStatusRequest(xml) {
      this.showConclusionModal = false;
      this.showLeavedModal = false;
      this.conclusionModalClear();

      this.params["xml"] = xml;
      this.params["claimId"] = this.data.claimId;
      this.params["additional_data"] = this.data.additional_data;
      this.params["reject_reason"] = this.data.reject_reason;
      this.params["time_of_receipt"] = this.data.time_of_receipt;

      this.claimsApiResource
        .changeStatus(this.params)
        .then(() => {
          this.updateList();
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
        });
    },
    clearFilters() {
      this.requestParams = JSON.parse(
        JSON.stringify(this.requestDefaultParams)
      );
    },
    changePage(num) {
      this.requestParams.page = num;
      this.updateList();
    }
  },
  beforeMount() {
    this.$on("onSign", this.changeStatusRequest);
    this.clearFilters();
    this.updateList();

    this.classesApi.list().then(response => {
      this.instructionClasses = response.data;
    });

    this.disabilityCategoriesApi.list().then(response => {
      this.disabilityCategories = response.data;
    });

    this.specialSchoolsApi.list().then(response => {
      this.specialSchools = response.data;
    });
  }
};
</script>
<style scoped>
.claim_popup_background {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.26);
  z-index: 9;
}
.claim_popup_container {
  position: relative;
  max-width: 1024px;
  margin: 15% auto 0;
  background-color: white;
  border: 1px solid blue;
  border-radius: 4px;
}
.claim_popup_close_button {
  position: absolute;
  right: 15px;
  top: 0;
}
</style>
