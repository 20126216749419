<template>
  <section class="content">
    <div class="content wizard-content" v-if="currentStep === formStep">
      <h3>{{ $ml.get("full_info") }}</h3>
      <main-form
        v-model="localValue"
        @onValidate="onValidateForm"
        @onInvalid="onInvalidForm"
        :service="currentService"
        :api-resource="apiResource"
      />
    </div>
    <div class="content wizard-content" v-if="currentStep === fileStep">
      <h3>Загрузка файлов</h3>
      <file-upload-list
        :list-key="'required_file'"
        :file-list="localValue['requiredFiles']"
      />
      <hr />
      <file-upload-list
        :list-key="'not_required_file'"
        :file-list="localValue['notRequiredFiles']"
        :postfix="' (не обязательно)'"
      />
    </div>
    <common-last-step v-if="currentStep === lastStep" />
  </section>
</template>

<script>
import { CommonWizardMixin } from "../../mixins/common-wizard-mixin";
import { HomeschoolingForm } from "../forms";

export default {
  mixins: [CommonWizardMixin],
  name: "HomeschoolingWizard",
  components: {
    "main-form": HomeschoolingForm
  },
  beforeMount() {
    this.localValue = this.value;
    this.deactivateStep(this.formStep);

    this.$set(this.localValue, "usersData", {
      iin: "",
      phone: "",
      email: ""
    });

    this.$set(this.localValue, "childsData", {
      iin: "",
      school: ""
    });

    this.setRequiredFiles([
      {
        label:
          "Справка врачебно-консультационной комиссии с рекомендацией по обучению на дому",
        name: "file_1",
        files: []
      },
      {
        label:
          "Заключение психолого-медико-педагогической консультации " +
          "о рекомендуемой образовательной учебной программе для детей-инвалидов",
        name: "file_2",
        files: []
      }
    ]);
  }
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
