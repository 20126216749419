class ResourceControllerApiService {
  constructor(axios) {
    this.axios = axios;
  }

  getMainRoute() {
    return "";
  }

  list(page = 1, count = 20) {
    return this.axios.get(this.getMainRoute(), { page, count });
  }

  create() {
    return this.axios.get(this.getMainRoute() + "/create");
  }

  store(item) {
    return this.axios.post(this.getMainRoute(), item);
  }

  show(id) {
    return this.axios.get(this.getMainRoute() + "/" + id);
  }

  edit(id) {
    return this.axios.get(this.getMainRoute() + "/" + id + "/edit");
  }

  update(id, item) {
    return this.axios.put(this.getMainRoute() + "/" + id, item);
  }

  destroy(id) {
    return this.axios.delete(this.getMainRoute() + "/" + id);
  }
}

export default ResourceControllerApiService;
