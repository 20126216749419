export default {
  "reabilitation-and-adaptation": [
    {
      name: "Записать на курс",
      statusesToShow: ["DIRECTION_ISSUED"],
      statusToChange: "AWAITING_FOR_COURSE",
      class: "is-info",
      key: "CHANGE_TO_AWAITING_FOR_COURSE"
    },
    {
      name: "Отметка о начале курса",
      statusesToShow: ["AWAITING_FOR_COURSE"],
      statusToChange: "COURSE_IN_PROGRESS",
      class: "is-success",
      key: "CHANGE_TO_COURSE_IN_PROGRESS",
      showUndefinedDatePopup: true
    },
    {
      name: "Отказать в посещении",
      statusesToShow: ["AWAITING_FOR_COURSE"],
      statusToChange: "REJECTED",
      class: "is-warning",
      rejectPopupShow: true,
      key: "CHANGE_TO_REJECTED",
      popupConfig: {
        popupTitle: "Отклонение",
        commentTitle: "Причина",
        commentValidationRules: "required|min:5",
        translate: "rejecting",
        commentTranslate: "reason"
      }
    },
    {
      name: "Перенос курса",
      statusesToShow: ["AWAITING_FOR_COURSE"],
      statusToChange: "AWAITING_FOR_COURSE",
      class: "is-warning",
      rejectPopupShow: true,
      key: "CHANGE_TO_TRANSFER",
      popupConfig: {
        popupTitle: "Перенос курса",
        commentTitle: "Причина переноса",
        commentValidationRules: "required|min:5",
        translate: "rejecting",
        commentTranslate: "reason"
      }
    },
    {
      name: "Отметка о завершении курса",
      statusesToShow: ["COURSE_IN_PROGRESS"],
      statusToChange: "COURSE_ENDED",
      class: "is-success",
      key: "CHANGE_TO_COURSE_ENDED",
      showUndefinedDatePopup: true
    }
  ]
};
