<template>
  <div class="modal-padded-body" v-if="item.claim_data">
    <div class="field">
      <label for="item.claim_data.usersData.iin" class="label">{{
        $ml.get("iin_claim_man")
      }}</label>
      <div class="control">
        <input
          id="item.claim_data.usersData.iin"
          :value="item.claim_data.usersData.iin"
          type="text"
          disabled
          class="input"
        />
      </div>
    </div>
    <div class="field">
      <label for="item.claim_data.usersData.fio" class="label">{{
        $ml.get("fio_claim_man")
      }}</label>
      <div class="control">
        <input
          id="item.claim_data.usersData.fio"
          :value="getNameFromUserInfo(item.claim_data.usersData)"
          type="text"
          disabled
          class="input"
        />
      </div>
    </div>
    <div class="field">
      <label for="item.claim_data.usersData.birth_date" class="label"
        >Дата рождения заявителя</label
      >
      <div class="control">
        <input
          id="item.claim_data.usersData.birth_date"
          :value="item.claim_data.usersData.birth_date"
          type="text"
          disabled
          class="input"
        />
      </div>
    </div>
    <div class="field">
      <label for="item.claim_data.usersData.fullAddress" class="label"
        >Адрес</label
      >
      <div class="control">
        <input
          id="item.claim_data.usersData.fullAddress"
          :value="getAddressFromUserInfo(item.claim_data.usersData)"
          type="text"
          disabled
          class="input"
        />
      </div>
    </div>
    <div class="field">
      <label for="item.claim_data.usersData.email" class="label"
        >Электронная почта</label
      >
      <div class="control">
        <input
          id="item.claim_data.usersData.email"
          :value="item.claim_data.usersData.email"
          type="text"
          disabled
          class="input"
        />
      </div>
    </div>
    <div class="field">
      <label for="item.claim_data.usersData.phone" class="label">{{
        $ml.get("phone")
      }}</label>
      <div class="control">
        <input
          id="item.claim_data.usersData.phone"
          :value="item.claim_data.usersData.phone"
          type="text"
          disabled
          class="input"
        />
      </div>
    </div>
    <div class="field">
      <label for="item.claim_data.usersData.area" class="label"
        >Район проживания</label
      >
      <div class="control">
        <input
          id="item.claim_data.usersData.area"
          :value="item.claim_data.usersData.area"
          type="text"
          disabled
          class="input"
        />
      </div>
    </div>
    <hr />
    <template v-for="childsData in item.claim_data.childrenData">
      <div class="field is-horizontal" :key="'child_' + childsData.iin">
        <div class="field-body">
          <div class="field">
            <label for="childsData.iin" class="label">ИИН ребёнка</label>
            <div class="control">
              <input
                id="childsData.iin"
                :value="childsData.iin"
                type="text"
                disabled
                class="input"
              />
            </div>
          </div>
          <div class="field">
            <label for="childsData.fio" class="label">ФИО ребёнка</label>
            <div class="control">
              <input
                id="childsData.fio"
                :value="getNameFromUserInfo(childsData)"
                type="text"
                disabled
                class="input"
              />
            </div>
          </div>
          <div class="field">
            <label for="childsData.birth_date" class="label"
              >Дата рождения ребёнка</label
            >
            <div class="control">
              <input
                id="childsData.birth_date"
                :value="childsData.birth_date"
                type="text"
                disabled
                class="input"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ViewPersonalMixin } from "../../../mixins/view-personal-mixin";

export default {
  name: "ParentalVisitPermitViewPersonal",
  mixins: [ViewPersonalMixin]
};
</script>

<style scoped></style>
