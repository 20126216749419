<template>
  <div class="modal-padded-body" v-if="item.claim_data">
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label for="item.claim_data.usersData.iin" class="label">ИИН</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.iin"
              :value="item.claim_data.usersData.iin"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <label for="item.claim_data.usersData.fio" class="label">{{
            $ml.get("fio")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.fio"
              :value="getNameFromUserInfo(item.claim_data.usersData)"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label for="item.claim_data.usersData.birth_date" class="label">{{
            $ml.get("birthday")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.birth_date"
              :value="item.claim_data.usersData.birth_date"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <label for="item.claim_data.usersData.fullAddress" class="label">{{
            $ml.get("address_registr")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.fullAddress"
              :value="getAddressFromUserInfo(item.claim_data.usersData)"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label for="item.claim_data.usersData.email" class="label">{{
            $ml.get("email")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.email"
              :value="item.claim_data.usersData.email"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <label for="item.claim_data.usersData.phone" class="label">{{
            $ml.get("phone")
          }}</label>
          <div class="control">
            <input
              id="item.claim_data.usersData.phone"
              :value="item.claim_data.usersData.phone"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label for="item.claim_data.usersData.doc_type" class="label"
            >Тип документа</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.doc_type"
              :value="item.claim_data.usersData.doc_type"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <label for="item.claim_data.usersData.issued_by" class="label"
            >Кем выдано</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.issued_by"
              :value="item.claim_data.usersData.issued_by"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
        <div class="field">
          <label for="item.claim_data.usersData.issued_date" class="label"
            >Дата выдачи</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.issued_date"
              :value="item.claim_data.usersData.issued_date"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label
            for="item.claim_data.usersData.disability_category_id"
            class="label"
            >Инвалидность</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.disability_category_id"
              :value="disabilityCategory"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>

        <div class="field">
          <label for="item.claim_data.usersData.month" class="label"
            >Месяц заезда</label
          >
          <div class="control">
            <input
              id="item.claim_data.usersData.month"
              :value="item.claim_data.usersData.month"
              type="text"
              disabled
              class="input"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ViewPersonalMixin } from "../../../mixins/view-personal-mixin";

export default {
  name: "SpaTreatmentViewPersonal",
  mixins: [ViewPersonalMixin],
  data() {
    return {
      disabilityCategories: [],
      disabilityCategoryApi: this.$apiResource.make("DisabilityCategories")
    };
  },
  computed: {
    disabilityCategory() {
      if (this.item.claim_data) {
        const item = this.disabilityCategories.find(
          category =>
            category.id ===
            this.item.claim_data.usersData.disability_category_id
        );
        return item ? item.name : "";
      } else {
        return "";
      }
    }
  },
  beforeMount() {
    this.disabilityCategoryApi.list().then(response => {
      this.disabilityCategories = response.data;
    });
  }
};
</script>
